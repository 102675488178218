import { IconButton } from '@material-ui/core'
import { Lock, LockOpen } from '@material-ui/icons'
import { FC } from 'react'
import { usePost } from '../../../hooks/usePost'
import { blockCp, unblockCp, UpdateCpDto } from '../../../services/information_s'

export const BlockCp: FC<Props> = ({ cp, refetchFn }) => {
  const { fetchData } = usePost(blockCp)
  const data: UpdateCpDto = {
    banned: {
      cp: cp,
    },
  }

  const changeCpState = async () => {
    await fetchData(data)
    refetchFn()
  }

  return (
    <IconButton onClick={changeCpState}>
      <Lock />
    </IconButton>
  )
}

export const UnBlockCp: FC<Props> = ({ cp, refetchFn }) => {
  const { fetchData } = usePost(unblockCp)

  const data: UpdateCpDto = {
    banned: {
      cp: cp,
    },
  }

  const changeCpState = async () => {
    await fetchData(data)
    refetchFn()
  }

  return (
    <IconButton onClick={changeCpState}>
      <LockOpen />
    </IconButton>
  )
}

type Props = {
  cp: number
  refetchFn: () => void
}
