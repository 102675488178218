export const allCarriersObject = {
    // Paqueterias disponibles para envios Nacionales
    National: {
        MX: [
            'noventa9Minutos',
            'aExpress',
            'ampm',
            'carssa',
            'dhl',
            'estafeta',
            'fedex',
            'ivoy',
            'mensajerosUrbanos',
            'noventa9Minutos',
            'paquetexpress',
            'quiken',
            'scm',
            'sendex',
            'tracusa',
            'uber',
            'ups',
            'vencedor',
            'jtexpress',
            'entrega',
            'borzo',
            'tresguerras',
            'treggo',
            'pickit'
        ],
        CO: [
            'fedex',
            'interrapidisimo',
            'redServi',
            'serviEntrega',
            'mensajerosUrbanos',
            'noventa9Minutos',
            'dhl',
            'coordinadora',
            'moova',
            'deprisa',
            'envia',
            'picap',
            'tcc',
        ],
        PE:['olva', 'nirex']
    },

    // Paqueterias disponibles para envios Internacionales
    International: ['dhl', 'fedex', 'ups', 'estafeta'],
    InternationalCo: ['dhl', 'fedex', 'coordinadora'],
    Import: ['dhl'],

    // Paqueterias disponibles para envios en Tarima
    Pallet: ['paquetexpress', 'estafeta'],

    // Aqui las Futuras Paqueterias de ContraEntrega
    ContraEntrega: {
        MX: [],
        PE: [],
        CO: ['test', 'serviEntrega', 'coordinadora', 'interrapidisimo'],
    },
}