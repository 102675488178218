import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import CancelIcon from "@material-ui/icons/Cancel";

export enum ShipmentStatus {
  Solicited = "Solicitada",
  CancelledButUsed = "Cancelada y usada",
  Refunded = "Saldo abonado",
  Active = "Activa",
  Delivered = "Entregado",
  CancelledFromProvider = "Cancelada de prov",
  InTransit = "En transito",
  Exception = "Excepcion"
}
export const CancelStatus = ({
  status,
  withIcon = true,
  customText,
}: {
  status: string;
  withIcon?: boolean;
  customText?: string;
}) => {
  const statusbadged = (status: ShipmentStatus | any) => {
    switch (status) {
      case "Entregado":
        return (
          <div style={{ backgroundColor: '#07E414', ...chipStyle }}>
            {withIcon && <CheckCircleIcon style={chipStyle.imgStye} />}
            {customText || status}
          </div>
        )
      case "Excepcion":
        return (
          <div style={{ backgroundColor: '#FFE800', ...chipStyle }}>
            {withIcon && <CheckCircleIcon style={chipStyle.imgStye} />}
            {customText || status}
          </div>
        )
      case "En transito":
        return (
          <div style={{ backgroundColor: '#D48129', ...chipStyle }}>
            {withIcon && <CheckCircleIcon style={chipStyle.imgStye} />}
            {customText || status}
          </div>
        )
      case "Solicitada":
      case "Pendiente":
        return (
          <div style={{ backgroundColor: "#F07C54", ...chipStyle }}>
            {withIcon && <ReportProblemIcon style={chipStyle.imgStye} />}
            {customText || status}
          </div>
        );
      case "Cancelada y usada":
      case "Cerrado":
      case "No disponible":
        return (
          <div style={{ backgroundColor: "#FF413F", ...chipStyle }}>
            {withIcon && <CancelIcon style={chipStyle.imgStye} />}
            {customText || status === "Cancelada y usada"
              ? "C. y usada"
              : status}
          </div>
        );
      case "Saldo abonado":
      case "Abierto":
      case "Cancelada de prov":
        return (
          <div
            style={{
              backgroundColor: "#5FCE8C",
              ...chipStyle,
            }}
          >
            {withIcon && <AttachMoneyIcon style={chipStyle.imgStye} />}
            {customText || status === "Saldo abonado" ? "Abonada" : status}
          </div>
        );
      case "Activa":
      case "Resuelto":
        return (
          <div style={{ backgroundColor: "#54C0F9", ...chipStyle }}>
            {withIcon && <CheckCircleIcon style={chipStyle.imgStye} />}
            {customText || status}
          </div>
        );
      default:
        return <div></div>
    }
  };
  return <>{statusbadged(status)}</>;
};

const chipStyle = {
  display: "flex",
  fontWeight: "500",
  width: "170px",
  height: "25px",
  borderRadius: "30px",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  imgStye: { margin: "5px", width: "17px", color: "white" },
};
