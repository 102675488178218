import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../context/apiContext";
import { RequestStatus, UseGet } from "../interfaces/app.interfaces";

export const useGet = <T>(callback: UseGet<T>) => {
  const { backendApiCall, serviceIsReady, userToken } = useContext(ApiContext);
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refetch, setRefetch] = useState(1);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      setIsLoading(true);
      const response = await callback(backendApiCall);
      if (isMounted) {
        if (response.status !== RequestStatus.Error) {
          setData(response.data.data);
        }
        setIsLoading(false);
      }
    };
    if (serviceIsReady) fetchData();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, serviceIsReady, userToken]);

  const refetchFn = () => {
    setRefetch(refetch + 1);
  };

  return {
    data,
    isLoading,
    refetch: refetchFn,
  };
};
