import { AxiosResponse } from 'axios';
import { useContext, useState } from 'react';
import { ApiContext } from '../context/apiContext';
import { apiProps, apiResponse } from '../interfaces/app.interfaces';

export const usePatch = <T, U>(
	fn: (
		Id: String,
		dto: T
	) => (
		backendApiCall: (data: apiProps) => Promise<apiResponse<AxiosResponse<U>>>
	) => Promise<apiResponse<AxiosResponse<U>>>
) => {
	const { backendApiCall, serviceIsReady } = useContext(ApiContext);
	const [dataPatch, setDataPatch] = useState<U | null>(null);
	const [isLoadingPatch, setIsLoadingPatch] = useState(false);

	const fetchDataPatch = async (Id: String, dto: T) => {
		if (!serviceIsReady) return;

		setIsLoadingPatch(true);
		const data = await fn(Id, dto)(backendApiCall);
		//@ts-ignore
		setDataPatch(data.data.data);
		setIsLoadingPatch(false);

		//@ts-ignore
		return data.data.data;
	};

	return {
		dataPatch,
		isLoadingPatch,
		fetchDataPatch,
	};
};
