import { makeStyles } from "@material-ui/core";


export const useStyles = makeStyles({
    box: {
        border: "1px #e0e0e0 dashed",
        borderRadius: 6,
        backgroundColor: "white",
        color: "#9e9e9e",
    },
    input: {
        display: "grid",
        placeItems: "center",
        border: "1px #e0e0e0 dashed",
        color: "gray",
        width: "100%",
        height: "12rem",
        textAlign: "center",
        padding: 10,
        "&:focus": {
            outline: "none",
        },
        "&:hover": {
            cursor: "pointer",
        },
    },
    divButton: {
        display: "flex",
        marginTop: 10,
        justifyContent: "flex-end",
        "@media (max-width: 768px)": {
            width: "100%",
        },
    },
    modal: {
        top: `50%`,
        position: "absolute",
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        maxWidth: 800,
        width: "80%",
        padding: "20px 10px",
        backgroundColor: "white",
        "&:focus": {
            outline: "none",
        },
    },
    greenButton: {
        color: "white",
        backgroundColor: "#4caf50",
        "&:hover": {
            backgroundColor: "#2e7d32",
        },
        margin: 10,
    },
    redButton: {
        color: "white",
        backgroundColor: "#e53935",
        "&:hover": {
            backgroundColor: "#f44336",
        },
        margin: 10,
    },
});