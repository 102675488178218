import { IconButton } from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"
import { Info } from "@material-ui/icons"
import { FC, useRef, useState } from "react"
import { ShipmentTable } from "../../interfaces/shipments.interface"
import { ShipmentDetail } from "./forms/ShipmentDetail"

export const ShipmentsActions: FC<ShipmentsActionsProps> = ({ data, disabledMenu }) => {
  const elRef = useRef(null)
  const [infoModalOpen, setInfoModalOpen] = useState(false)

  const handleInfoModalOpen = () => {
    setInfoModalOpen(true)
  }
  return (
    <>
      <div style={{ display: "flex" }}>
        <Tooltip title="Informacion">
          <IconButton
            data-testid="icon-button"
            disabled={disabledMenu}
            onClick={handleInfoModalOpen}
            ref={elRef}
          >
            <Info />
          </IconButton>
        </Tooltip>
      </div>

      {infoModalOpen && (
        <ShipmentDetail visible={infoModalOpen} setIsVisible={setInfoModalOpen} shipment={data} />
      )}
    </>
  )
}

interface ShipmentsActionsProps {
  // onClick: MouseEventHandler<HTMLButtonElement> | undefined
  data: ShipmentTable
  disabledMenu: boolean
}
