import { Col, Row, Spin } from "antd";
import { FC } from "react";
import CardContainer from "../../Containers/CardContainer"

interface Props {
    isloading: boolean;
}
const styles = {
    text: {
        display: "grid",
        placeItems: "center",
        fontSize: "1rem",
        margin: 0,
        fontWeight: "bold",
        height: "3.5rem"
    }
}

const ResultError: FC<Props> = ({ isloading }) => {
    return (
        <Row style={{ width: '100%' }}>
            <Col span={24}>
                <Spin spinning={isloading} >
                    <CardContainer>
                        <p style={styles.text}>No se ha encontrado el número de guía. Por favor, verifique que el número sea correcto, que no haya sido creada en otra versión de la plataforma y que no sea un número de guía de devolución.</p>
                    </CardContainer>
                </Spin>
            </Col>
        </Row>

    )
}

export default ResultError