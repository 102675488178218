import { Col, Row, Typography, notification } from "antd"
import { UserItemI } from "../Search/SearchItem";
import { CopyOutlined, EyeOutlined } from '@ant-design/icons';
import { FC, useState } from "react";
import ProfileSkeleton from "../Mocks/ProfileSkeleton";
import { DataTypeT, ModalTableI, dataToShowI, userActionsT } from "../interfaces";
import { balanceFixed, countryName, regMethodImg, switchCountry } from "../utils";
import { moneyISO } from "../../../../constants/moneyISO";
import SectionLayout from "./SectionLayout";
import moment from "moment";
import SendersModal from "../Components/Modals/SendersModal";
import TablesModal from "../Components/Modals/TablesModal";
import BlockAlert from "../Components/BlockAlert";
import PendingAlert from "../Components/PendingAlert";
import useDevice from "../../../../hooks/useDevice";


interface props {
    userFound: UserItemI;
    fullUser: dataToShowI | null;
    changeActions: (type: userActionsT) => void;
    setModalTable: (value: ModalTableI) => void;
    showDrawer: () => void;
}

interface Styles {
    dataRow: React.CSSProperties;
    dataRowCards: React.CSSProperties;
    dataRowCardsMobile: React.CSSProperties;
    sectionItem: React.CSSProperties;
    sectionTitle: React.CSSProperties;
    countCard: React.CSSProperties;
    countCardMobile: React.CSSProperties;
    countCardTitle: React.CSSProperties;
    countCardValue: React.CSSProperties;
    currencySpan: React.CSSProperties;
    negativeCurrency: React.CSSProperties;
    blockedTag: React.CSSProperties;
    copyUserId: React.CSSProperties;
    sendersItem: React.CSSProperties;
}

const styles: Styles = {
    dataRow: {
        width: '100%'
    },
    dataRowCards: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '1.5rem',
        paddingTop: '32px',
        marginTop: '1rem',
        width: '100%',
        border: '1px solid #DFDCDC',
        borderWidth: '1px 0 0 0',
        flexWrap: 'wrap'
    },
    dataRowCardsMobile: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '1.5rem',
        paddingTop: '32px',
        marginTop: '1rem',
        width: '100%',
        border: '1px solid #DFDCDC',
        borderWidth: '1px 0 0 0',
        flexWrap: 'wrap'
    },
    sectionItem: {
        padding: '16px 16px 16px 0',
        fontSize: '0.95rem'
    },
    sectionTitle: {
        fontWeight: '600',
        fontSize: 'inherit'
    },
    countCard: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1.5rem',
        padding: '16px',
        border: '1px solid #DFDCDC',
        borderRadius: '4px',
        minWidth: '10rem',
        marginBottom: '16px'
    },
    countCardMobile: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1.5rem',
        padding: '16px',
        border: '1px solid #DFDCDC',
        borderRadius: '4px',
        width: '100%',
        marginBottom: '16px'
    },
    countCardTitle: {
        fontSize: '14px',
        fontWeight: 'bold',
        width: '100%',
        height: 'inherit',
        color: 'inherit'
    },
    countCardValue: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.3rem',
        fontSize: '20px',
        fontWeight: 'bold',
        color: 'inherit',
    },
    currencySpan: {
        fontSize: '1rem',
        fontWeight: 'normal'
    },
    negativeCurrency: {
        color: '#F5222D'
    },
    blockedTag: {
        color: '#F5222D',
        backgroundColor: '#FFF1F0',
        border: '1px solid #FFA39E',
    },
    copyUserId: {
        color: 'inherit'
    },
    sendersItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '4px'
    }
}

const ProfilePage: FC<props> = ({ userFound, fullUser, changeActions, setModalTable, showDrawer }) => {
    const [sendersModal, setSendersModal] = useState(false)
    const [dataModal, setDataModal] = useState(false)
    const [dataType, setDataType] = useState<DataTypeT>('others')
    const { isTablet } = useDevice()

    const openSendersModal = () => {
        setSendersModal(!sendersModal)
    }

    const openDataModal = (type?: DataTypeT) => {

        if (type) {
            setDataType(type)
        }

        setDataModal(!dataModal)
    }

    return (
        <SectionLayout 
            title="Perfil" 
            userFound={userFound} 
            changeActions={changeActions} 
            showDrawer={showDrawer} 
            roles={fullUser?.roles || []}>
            <Row>
                {
                    !fullUser ? (
                        <ProfileSkeleton />
                    ) : (
                        <>
                            {
                                fullUser.blacklist ? (
                                    <BlockAlert fullUser={fullUser} />
                                ) : fullUser?.app_country === 'PENDIENTE' && (
                                    <PendingAlert />
                                )
                            }

                            <Row style={styles.dataRow}>
                                <Col xs={24} md={12} lg={8}>
                                    <section style={styles.sectionItem}>
                                        <Typography style={styles.sectionTitle}>País</Typography>
                                        <Typography>
                                            <img alt='country' src={switchCountry(userFound.app_country)} height={22} />
                                            {' '}{countryName(userFound.app_country)}
                                        </Typography>
                                    </section>
                                </Col>
                                <Col xs={24} md={12} lg={8}>
                                    <section style={styles.sectionItem}>
                                        <Typography style={styles.sectionTitle}>Empresa</Typography>
                                        <Typography>{fullUser.company}</Typography>
                                    </section>
                                </Col>
                                <Col xs={24} md={12} lg={8}>
                                    <section style={styles.sectionItem}>
                                        <Typography style={styles.sectionTitle}>Medio de registro</Typography>
                                        <Typography>
                                            <img alt='register method' src={regMethodImg(fullUser.user).logo} height={regMethodImg(fullUser.user).height} />
                                        </Typography>
                                    </section>
                                </Col>
                            </Row>

                            <Row style={styles.dataRow}>
                                <Col xs={24} md={12} lg={8}>
                                    <section style={styles.sectionItem}>
                                        <Typography style={styles.sectionTitle}>Teléfono</Typography>
                                        <Typography>{fullUser.phone}</Typography>
                                    </section>
                                </Col>
                                <Col xs={24} md={12} lg={8}>
                                    <section style={styles.sectionItem}>
                                        <Typography style={styles.sectionTitle}>Enterado por</Typography>
                                        <Typography>{fullUser.recommended || 'N/A'}</Typography>
                                    </section>
                                </Col>
                                <Col xs={24} md={12} lg={8}>
                                    <section style={styles.sectionItem}>
                                        <Typography style={styles.sectionTitle}>Fecha de registro</Typography>
                                        <Typography>{moment(fullUser.created_at).format('lll')}</Typography>
                                    </section>
                                </Col>
                            </Row>

                            <Row style={styles.dataRow}>
                            <Col xs={24} md={12} lg={8}>
                                    <section style={styles.sectionItem}>
                                        <Typography style={styles.sectionTitle}>Último envío</Typography>
                                        <Typography>{
                                            fullUser?.last_shipment ?
                                            moment(fullUser?.last_shipment).format('lll') :
                                            'N/A'
                                        }</Typography>
                                    </section>
                                </Col>
                                <Col xs={24} md={12} lg={8}>
                                    <section style={styles.sectionItem}>
                                        <Typography style={styles.sectionTitle}>Clabe STP</Typography>
                                        <Typography>{fullUser.metadata?.stp_account}</Typography>
                                    </section>
                                </Col>
                                <Col xs={24} md={12} lg={8}>
                                    <section
                                        className="user-advanced-options-settings"
                                        style={styles.sectionItem}
                                        onClick={() => {
                                            if (fullUser?.security?.senders?.length >= 8) {
                                                openSendersModal()
                                            }
                                        }
                                        }
                                    >
                                        <Typography style={styles.sectionTitle}>Remitentes</Typography>
                                        <Typography style={{ color: 'inherit' }}>
                                            {
                                                fullUser?.security?.senders?.length !== 0 ?
                                                    (fullUser?.security?.senders?.length < 8) ?
                                                        fullUser?.security?.senders.join(',')
                                                        : <span style={styles.sendersItem}>
                                                            <EyeOutlined style={{ fontSize: '20px' }} />
                                                            {' ' + (fullUser?.security?.senders as any)?.length}
                                                        </span>
                                                    : 'No hay remitentes'
                                            }
                                        </Typography>
                                    </section>
                                </Col>
                            </Row>
                            <Row style={styles.dataRow}>
                            <Col xs={24} md={12} lg={8}>
                                    <section
                                        style={styles.sectionItem}
                                        className="user-advanced-options-settings"
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                fullUser?.user
                                            );
                                            notification.success({
                                                message: "Usuario copiado",
                                            });
                                        }}
                                    >
                                        <Typography style={styles.sectionTitle}>User ID</Typography>
                                        <Typography style={styles.copyUserId}>{fullUser.user}</Typography>
                                        <Typography style={styles.copyUserId}><CopyOutlined style={{ fontSize: '20px' }} /></Typography>
                                    </section>
                                </Col>
                            </Row>
                            <Row style={isTablet ? styles.dataRowCardsMobile : styles.dataRowCards}>
                                <section style={Math.sign(userFound.balance) === -1 ? isTablet ? { ...styles.blockedTag, ...styles.countCardMobile } : { ...styles.countCard, ...styles.blockedTag } : isTablet ? styles.countCardMobile : styles.countCard}>
                                    <Typography style={styles.countCardTitle}>Saldo</Typography>
                                    <Typography style={styles.countCardValue}>
                                        {`$${balanceFixed(fullUser)}`} <span style={styles.currencySpan}>{`${moneyISO[userFound?.app_country] || "N/A"}`}</span>
                                    </Typography>
                                </section>
                                <section
                                    style={isTablet ? styles.countCardMobile : styles.countCard}
                                    className="user-search-item"
                                    onClick={() => {
                                        if (fullUser.shipments_count > 0) {
                                            setModalTable({
                                                visible: true,
                                                currentTable: 'shipments',
                                                user: fullUser?.user || '',

                                            })
                                        }

                                    }}
                                >
                                    <Typography style={styles.countCardTitle}>Envíos</Typography>
                                    <Typography style={styles.countCardValue}>
                                        {fullUser.shipments_count}
                                    </Typography>
                                </section>
                                <section
                                    style={isTablet ? styles.countCardMobile : styles.countCard}
                                    className="user-search-item"
                                    onClick={() => {
                                        if (fullUser.movements_count > 0) {
                                            setModalTable({
                                                visible: true,
                                                currentTable: 'movements',
                                                user: fullUser?.user || '',

                                            })
                                        }
                                    }}
                                >
                                    <Typography style={styles.countCardTitle}>Movimientos</Typography>
                                    <Typography style={styles.countCardValue}>
                                        {fullUser.movements_count}
                                    </Typography>
                                </section>
                                <section
                                    style={isTablet ? styles.countCardMobile : styles.countCard}
                                    className="user-search-item"
                                    onClick={() => {
                                        setModalTable({
                                            visible: true,
                                            currentTable: 'facturas',
                                            user: fullUser?.user || '',

                                        })

                                    }}
                                >
                                    <Typography style={styles.countCardTitle}>Facturas</Typography>
                                    <Typography style={styles.countCardValue}>
                                        {0}
                                    </Typography>
                                </section>
                            </Row>
                        </>
                    )
                }

            </Row>
            <SendersModal
                visible={sendersModal}
                userFound={fullUser as dataToShowI}
                manageModal={openSendersModal}
            />
            <TablesModal
                type={dataType}
                visible={dataModal}
                userFound={fullUser as dataToShowI}
                manageModal={openDataModal}
            />
        </SectionLayout>

    )
}

export default ProfilePage