import { Col, Row } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { IconButton } from "@material-ui/core";
import { useState } from "react";
import {
  FormatedShipmentForTables,
  ShipmentV2,
} from "../../../../interfaces/shipments.interface";
import { ShipmentDetail } from "../../../../components/Common/forms/ShipmentDetail";

export type CancellationColumn = {
  created_at: string;
  user: string;
  mail: string;
  shipment_status: string;
  tracking: string;
  shipmentData: ShipmentV2;
  index: number;
  data: FormatedShipmentForTables[];
};

type Props = {
  data: CancellationColumn;
  refetch: any;
};

export const Actions = ({ data, refetch }: Props) => {
  const [shipmentDetailModalOpen, setShipmentDetailModalOpen] = useState(false);

  return (
    <>
      <Row align="middle">
        <Col style={{ textAlign: "center" }}>
          <IconButton
            size="medium"
            title="Informacion del status"
            style={{ padding: 0 }}
            onClick={() => setShipmentDetailModalOpen(!shipmentDetailModalOpen)}
          >
            <QuestionCircleFilled style={{ cursor: "pointer" }} />
          </IconButton>
        </Col>
      </Row>
      {shipmentDetailModalOpen && (
        <ShipmentDetail
          visible={shipmentDetailModalOpen}
          setIsVisible={setShipmentDetailModalOpen}
          shipment={{ shipmentData: data.shipmentData }}
        />
      )}
    </>
  );
};
