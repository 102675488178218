import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const LogoutSuccessPage = () => {
  const history = useNavigate()

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      history('/')
    }, 2000)

    return () => clearTimeout(redirectTimer)
  }, [history])

  return (
    <div>
    </div>
  )
}

export default LogoutSuccessPage