import True from "../../../src/assets/images/Movements/shipment_white.svg";
import False from "../../../src/assets/images/Movements/bloquear.png";

const chipStyle = {
  display: "flex",
  fontWeight: "500",
  width: "120px",
  height: "25px",
  borderRadius: "30px",
  padding: "3%",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  imgStye: { width: "14px", marginRight: "8px" },
};

export const PersonalAccountStatus = (status:any ) => {

  return (
    <>
      {status.active === true ? (
        <div style={{ backgroundColor: "#5FCE8C", ...chipStyle }}>
          <img style={chipStyle.imgStye} src={True} alt="-"/>
          Activa
        </div>
      ) : status.active === false ? (
        <div style={{ backgroundColor: "#FF413F", ...chipStyle }}>
          <img style={chipStyle.imgStye} src={False} alt="x"/>
          Inactiva
        </div>
      ) : (
        <div style={{ backgroundColor: "#000000", ...chipStyle }}>
          <img style={chipStyle.imgStye} alt="No Info" />
          No Info
        </div>
      )}
    </>
  );
};
