import React from "react";
import ReactDOM from "react-dom";
import "./index.less";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthWrapper from "./middleware/AuthWrapper";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Components from "./pages/Components";
import AppLayout from "./components/AppLayout";
import { I18nextProvider } from "react-i18next";
import i18n from "./languages/langs";
import STORE from "./store";
import { Provider } from "react-redux";
import PrivacyPolicy from "./pages/Politics/PrivacyPolicy";
import DataPolicy from "./pages/Politics/DataPolicy";
import ShippingPolicy from "./pages/Politics/ShippingPolicy";
import { AdminDataContextProvider } from "./context/adminContext";
import GlobalContextProvider from "./context/GlobalContextProvider";
import LogoutSuccessPage from "./pages/logout/LogoutSuccessPage";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* Router Configuration> */}
      <Provider store={STORE}>
        {/* Redux Configurations */}
        <AuthWrapper>
          {/* Auth0 Configuration> */}
          <I18nextProvider i18n={i18n}>
            {/* Langs Configuration> */}
            <AdminDataContextProvider>
              {/* Quote useContext Data> */}
              <Routes>
                <Route path="/" element={
                  <GlobalContextProvider>
                    <AppLayout />
                  </GlobalContextProvider>
              }/>
                <Route path="components" element={<Components />} />
                <Route path="login" element={<Login></Login>} />

                <Route path="politica-de-privacidad" element={<PrivacyPolicy />} />
                <Route path="politica-de-datos" element={<DataPolicy />} />
                <Route path="politica-de-envios" element={<ShippingPolicy />} />
                <Route path="logout-success" element={<LogoutSuccessPage />} />
              </Routes>
            </AdminDataContextProvider>
          </I18nextProvider>
        </AuthWrapper>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
