import planeLogo from "../images/plane-logo.png";
import CotizarNacional from "../images/Nacional.png";
import CotizarInternacional from "../images/Internacional.png";
import CotizarTarimas from "../images/Tarima.png";

export const CommonAssets: { [key: string]: any } = {
  planeLogo,
  CotizarInternacional,
  CotizarNacional,
  CotizarTarimas,
};
