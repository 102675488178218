export enum ServicesId_MX {
	estafeta_mx_directo_web360_2dias = 'estafeta_mx_D-C03_2dias',
	estafeta_mx_directo_web360_terrestre = 'estafeta_mx_D-C03_terrestre',
	estafeta_mx_directo_web360_terrestre_especial = 'estafeta_mx_D-C03_terrestre_especial',
	estafeta_mx_directo_web360_terrestre_sin_recoleccion = 'estafeta_mx_D-C03_terrestre_without_pickup',
	estafeta_mx_nextshipping_terrestre_sin_recoleccion = 'estafeta_mx_A-P07_terrestre_without_pickup',
	estafeta_mx_directo_web360_next_day = 'estafeta_mx_D-C03_next_day',
	estafeta_mx_directo_web360_next_day_especial = 'estafeta_mx_D-C03_next_day_especial',
	estafeta_mx_directo_web360_next_day_sin_recoleccion = 'estafeta_mx_D-C03_next_day_without_pickup',
	estafeta_mx_nextshipping_next_day_sin_recoleccion = 'estafeta_mx_A-P07_next_day_without_pickup',
	estafeta_mx_directo_web360_metropolitano = 'estafeta_mx_D-C03_metropolitano',
	estafeta_mx_personal_2dias = 'estafeta_mx_P-C00_2dias',
	estafeta_mx_nextshipping_ground = 'estafeta_mx_A-P07_terrestre',
	estafeta_mx_nextshipping_ground_plus = 'estafeta_mx_A-P07_terrestre_plus',
	estafeta_mx_nextshipping_next_day = 'estafeta_mx_A-P07_next_day',
	estafeta_mx_pakke_ground = 'estafeta_mx_A-P08_ground',
	estafeta_mx_pakke_ground_stfrt = 'estafeta_mx_A-P08_ground_stfrt',
	estafeta_mx_pakke_next_day = 'estafeta_mx_A-P08_next_day',
	estafeta_mx_pakke_next_day_stfrt = 'estafeta_mx_A-P08_next_day_stfrt',
	estafeta_mx_pack2go_ground = 'estafeta_mx_A-P03_ground',
	estafeta_mx_pack2go_next_day = 'estafeta_mx_A-P03_next_day',
	estafeta_mx_pack2go_next_day_drevsto = 'estafeta_mx_A-P03_next_day_drevsto',
	estafeta_mx_pack2go_ground_drevsto = 'estafeta_mx_A-P03_ground_drevsto',
	estafeta_mx_pack2go_metropolitano_drevsto = 'estafeta_mx_A-P03_metropolitano_drevsto',

	estafeta_mx_personal_terrestre = 'estafeta_mx_P-C00_terrestre',
	estafeta_mx_personal_next_day = 'estafeta_mx_P-C00_next_day',
	fedex_mx_directo_drenvio_ground = 'fedex_mx_D-C01_ground',
	fedex_mx_directo_drenvio_express = 'fedex_mx_D-C01_express',
	fedex_mx_directo_drevsto_ground = 'fedex_mx_D-C02_ground',
	fedex_mx_directo_drevsto_express = 'fedex_mx_D-C02_express',
	fedex_mx_directo_web360_ground = 'fedex_mx_D-C03_ground',
	fedex_mx_directo_web360_ground_express = 'fedex_mx_D-C03_ground_express',
	fedex_mx_directo_web360_express = 'fedex_mx_D-C03_express',
	fedex_mx_pack2go_ground = 'fedex_mx_A-P03_ground',
	fedex_mx_pack2go_express = 'fedex_mx_A-P03_express',
	fedex_mx_nextshipping_ground_smart = 'fedex_mx_A-P07_ground_smart',
	fedex_mx_nextshipping_ground_ac = 'fedex_mx_A-P07_ground_ac',
	fedex_mx_nextshipping_express_smart = 'fedex_mx_A-P07_express_smart',
	fedex_mx_personal_ground = 'fedex_mx_P-C00_ground',
	fedex_mx_personal_express = 'fedex_mx_P-C00_express',
	paquetexpress_mx_directo_drenvio_ground = 'paquetexpress_mx__ground',
	paquetexpress_mx_directo_drenvio_express = 'paquetexpress_mx__express',
	paquetexpress_mx_directo_web360_ground = 'paquetexpress_mx__ground',
	paquetexpress_mx_directo_web360_express = 'paquetexpress_mx__express',
	paquetexpress_mx_pack2go_ground = 'paquetexpress_mx_A-P03_ground',
	paquetexpress_mx_pack2go_ground_bulto = 'paquetexpress_mx_A-P03_ground_bulto',
	paquetexpress_mx_pack2go_express = 'paquetexpress_mx_A-P03_express',
	paquetexpress_mx_nextshipping_ground = 'paquetexpress_mx_A-P07_ground',
	paquetexpress_mx_nextshipping_ground_2 = 'paquetexpress_mx_A-P07_ground_2',
	paquetexpress_mx_pakke_ground = 'paquetexpress_mx_A-P08_ground',
	paquetexpress_mx_pack2go_ground_drevsto = 'paquetexpress_mx_A-P03_ground_drevsto',

	estafeta_mx_pack2go_pallet = 'estafeta_mx_A-P03_pallet',
	paquetexpress_mx_pack2go_pallet = 'paquetexpress_mx_A-P03_pallet',
	paquetexpress_mx_pack2go_pallet_drevsto = 'paquetexpress_mx_A-P03_pallet_drevsto',
	paquetexpress_mx_nextshipping_pallet = 'paquetexpress_mx_A-P07_pallet', 
//   
	dhl_mx_shippo_ground = 'dhl_mx_A-P02_ground',
	dhl_mx_shippo_express = 'dhl_mx_A-P02_express',
	dhl_mx_shippo_ground_express = 'dhl_mx_A-P02_ground_express',
	dhl_mx_shippo_ground_drevsto = 'dhl_mx_A-P05_ground',
	dhl_mx_shippo_express_drevsto = 'dhl_mx_A-P05_express',
	dhl_mx_shippo_ground_web360 = 'dhl_mx_A-P06_ground',
	dhl_mx_shippo_express_web360 = 'dhl_mx_A-P06_express',
	dhl_mx_shippo_ground_express_web360 = 'dhl_mx_A-P06_ground_express',

	dhl_mx_directo_web360_express = 'dhl_mx_D-C03_express',
	dhl_mx_directo_web360_import = 'dhl_mx_D-C03_import',
	dhl_mx_directo_web360_ground = 'dhl_mx_D-C03_ground',
	dhl_mx_directo_web360_int = 'dhl_mx_D-C03_int',
	dhl_mx_directo_ground_express_web360 = 'dhl_mx_D-C03_ground_express',
	dhl_mx_directo_web360_express_1030 = 'dhl_mx_D-C03_express_1030',

	dhl_mx_directo_drenvio_express_especial = 'dhl_mx_D-C01_express_especial',
	dhl_mx_directo_web360_pallet = 'dhl_mx_D-C03_pallet',
	dhl_mx_directo_drenvio_pallet = 'dhl_mx_D-C01_pallet',

	dhl_mx_directo_drenvio_express = 'dhl_mx_D-C01_express',
	dhl_mx_directo_drenvio_ground = 'dhl_mx_D-C01_ground',
	dhl_mx_directo_drenvio_int = 'dhl_mx_D-C01_int',
	dhl_mx_directo_drenvio_ground_express = 'dhl_mx_D-C01_ground_express',
	dhl_mx_nextshipping_ground = 'dhl_mx_A-P07_ground',
	dhl_mx_nextshipping_ground_express = 'dhl_mx_A-P07_ground_express',
	dhl_mx_nextshipping_express = 'dhl_mx_A-P07_express',
	dhl_mx_nextshipping_ground_2 = 'dhl_mx_A-P07_ground_2',
	dhl_mx_nextshipping_express_2 = 'dhl_mx_A-P07_express_2',
	dhl_mx_pack2go_ground = 'dhl_mx_A-P03_ground',
	dhl_mx_pack2go_ground_drevsto = 'dhl_mx_A-P03_ground_drevsto',
	dhl_mx_pack2go_ground_express = 'dhl_mx_A-P03_ground_express',
	dhl_mx_pack2go_ground_special = 'dhl_mx_A-P03_ground_special',
	dhl_mx_pack2go_ground_special_drevsto = 'dhl_mx_A-P03_ground_special_drevsto',
	dhl_mx_pack2go_express = 'dhl_mx_A-P03_express',
	dhl_mx_pack2go_express_drevsto = 'dhl_mx_A-P03_express_drevsto',
	dhl_mx_shipango_ground = 'dhl_mx_A-P04_ground',
	dhl_mx_shipango_express = 'dhl_mx_A-P04_express',

	dhl_mx_personal_express = 'dhl_mx_P-C00_express',
	dhl_mx_personal_ground = 'dhl_mx_P-C00_ground',
	dhl_mx_personal_int = 'dhl_mx_P-C00_int',

	sendex_mx_nextshipping_ground = 'sendex_mx_A-P07_ground',
	sendex_mx_directo_drenvio_ground = 'sendex_mx_D-C01_ground',
	sendex_mx_directo_drenvio_ground6dias = 'sendex_mx_D-C01_ground6dias',
	sendex_mx_personal_ground = 'sendex_mx_P-C00_ground',
	sendex_mx_personal_ground6dias = 'sendex_mx_P-C00_ground6dias',
	sendex_mx_envia_ground = 'sendex_mx_A-P01_ground',
	sendex_mx_envia_ground6dias = 'sendex_mx_A-P01_ground6dias',
	ampm_mx_directo_drenvio_ground = 'ampm_mx_D-C01_ground',
	ampm_mx_directo_drenvio_express = 'ampm_mx_D-C01_express',
	ampm_mx_personal_ground = 'ampm_mx_P-C00_ground',
	ampm_mx_personal_express = 'ampm_mx_P-C00_express',
	quiken_mx_directo_drenvio_ground36hrs = 'quiken_mx_D-C01_ground36hrs',
	quiken_mx_directo_drenvio_ground = 'quiken_mx_D-C01_ground',
	quiken_mx_personal_ground36hrs = 'quiken_mx_P-C00_ground36hrs',
	quiken_mx_personal_ground = 'quiken_mx_P-C00_ground',
	noventa9Minutos_mx_directo_drenvio_next_day = 'noventa9Minutos_mx_D-C01_next_day',
	noventa9Minutos_mx_directo_drenvio_same_day = 'noventa9Minutos_mx_D-C01_same_day',
	noventa9Minutos_mx_directo_drenvio_co2_free = 'noventa9Minutos_mx_D-C01_co2_free',

	noventa9Minutos_mx_pakke_next_day = 'noventa9Minutos_mx_A-P08_next_day',
	noventa9Minutos_mx_pakke_same_day = 'noventa9Minutos_mx_A-P08_same_day',

	noventa9Minutos_mx_pack2go_next_day = 'noventa9Minutos_mx_A-P03_next_day',
	noventa9Minutos_mx_pack2go_ground = 'noventa9Minutos_mx_A-P03_ground',

	noventa9Minutos_mx_pack2go_next_day_drevsto = 'noventa9Minutos_mx_A-P03_next_day_drevsto',
	noventa9Minutos_mx_pack2go_ground_drevsto = 'noventa9Minutos_mx_A-P03_ground_drevsto',

	noventa9Minutos_mx_personal_next_day = 'noventa9Minutos_mx_P-C00_next_day',
	noventa9Minutos_mx_personal_same_day = 'noventa9Minutos_mx_P-C00_same_day',
	noventa9Minutos_mx_envia_next_day = 'noventa9Minutos_mx_A-P01_next_day',
	carssa_mx_envia_ground = 'carssa_mx_A-P01_ground',
	scm_mx_envia_ground = 'scm_mx_A-P01_ground',
	mensajerosUrbanos_mx_envia_ground = 'mensajerosUrbanos_mx_A-P01_ground',
	mensajerosUrbanos_mx_pakke_ground = 'mensajerosUrbanos_mx_A-P08_ground',
	ups_mx_envia_ground = 'ups_mx_A-P01_ground',
	ups_mx_envia_express = 'ups_mx_A-P01_express',
	ups_mx_shippo_ground = 'ups_mx_A-P02_ground',
	ups_mx_shippo_express = 'ups_mx_A-P02_express',
	ups_mx_shippo_ground_drenvio_int = 'ups_mx_A-P03_ground_int',
	ups_mx_nextshipping_ground='ups_mx_A-P07_ground',

	jtexpress_mx_directo_drenvio_ground = 'jtexpress_mx_D-C01_ground',
	jtexpress_mx_personal_ground = 'jtexpress_mx_P-C00_ground',
	entrega_mx_personal_ground = 'entrega_mx_P-C00_ground',
	entrega_mx_directo_drenvio_ground = 'entrega_mx_D-C01_ground',
	dhl_mx_shippo_ground_int = 'dhl_mx_A-P02_ground_int',
	dhl_mx_shippo_ground_drevsto_int = 'dhl_mx_A-P05_ground_int',
	dhl_mx_shippo_ground_web360_int = 'dhl_mx_A-P06_ground_int',

	fedex_mx_directo_drevsto_int_eco = 'fedex_mx_D-C02_int_economy',
	fedex_mx_directo_drevsto_int_pri = 'fedex_mx_D-C02_int_priority',
	fedex_mx_directo_web360_int_eco = 'fedex_mx_D-C03_int_economy',
	fedex_mx_directo_web360_int_pri = 'fedex_mx_D-C03_int_priority',
	fedex_mx_directo_web360_int_pri_express = 'fedex_mx_D-C03_int_priority_express',
	fedex_mx_personal_int_eco = 'fedex_mx_P-C00_int_economy',
	fedex_mx_personal_int_pri = 'fedex_mx_P-C00_int_priority',
	fedex_mx_directo_drevsto_import_eco = 'fedex_mx_D-C02_import_economy',
	fedex_mx_directo_drevsto_import_pri = 'fedex_mx_D-C02_import_priority',
	fedex_mx_directo_web360_import_eco = 'fedex_mx_D-C03_import_economy',
	fedex_mx_directo_web360_import_pri = 'fedex_mx_D-C03_import_priority',
	ups_mx_envia_int = 'ups_mx_A-P01_int',
	ups_mx_shippo_int = 'ups_mx_A-P02_int',
	estafeta_mx_directo_web360_int = 'estafeta_mx_D-C03_int',
	estafeta_mx_personal_int = 'estafeta_mx_P-C00_int',
	borzo_mx_directo_drenvio_express = 'borzo_mx_D-C01_express',
	borzo_mx_directo_drenvio_standard = 'borzo_mx_D-C01_standard',
	borzo_mx_personal_ground = 'borzo_mx_P-C00_ground',
	testing_mx_service_standard = 'testing_mx_D-C01_standard',
	tres_guerras_mx_directo_drenvio_standard='tres_guerras_mx_D-C01_standard',
	tres_guerras_mx_directo_drenvio_pallet='tres_guerras_mx_D-C01_pallet',
	tres_guerras_mx_personal_drenvio_standard='tres_guerras_mx_P-C01_standard',
	treggo_mx_directo_drenvio_standard='treggo_mx_D-C01_standard',
	uber_mx_directo_drenvio_ground='uber_mx_D-C01_ground',
	pickit_mx_directo_drenvio_standard='pickit_mx_D-C01_standard',
	pickit_mx_directo_drenvio_express='pickit_mx_D-C01_express',
	ups_mx_directo_drenvio_standard_int='ups_mx_D-C01_standard_int',
	ups_mx_directo_drenvio_express_plus_int='ups_mx_D-C01_express_plus_int',
	ups_mx_directo_drenvio_saver='ups_mx_D-C01_saver',
	estafeta_mx_globalpaq_ground='estafeta_mx_A-P09_ground',
	estafeta_mx_globalpaq_next_day='estafeta_mx_A-P09_next_day',
	fedex_mx_globalpaq_express='fedex_mx_A-P09_express',
	fedex_mx_globalpaq_ground='fedex_mx_A-P09_ground',
	dhl_mx_globalpaq_ground='dhl_mx_A-P09_ground',
	dhl_mx_globalpaq_express='dhl_mx_A-P09_express',
	// services_id para costos

	estafeta_mx_nextshipping_ground_cost = 'estafeta_mx_A-P07_terrestre_cost',
	paquetexpress_mx_nextshipping_ground_cost = 'paquetexpress_mx_A-P07_ground_cost',
	estafeta_mx_nextshipping_ground_plus_cost = 'estafeta_mx_A-P07_terrestre_plus_cost',
	estafeta_mx_nextshipping_next_day_cost = 'estafeta_mx_A-P07_next_day_cots',
	fedex_mx_nextshipping_ground_cost = 'fedex_mx_A-P07_ground_cost',
	fedex_mx_nextshipping_ground_flat_cost = 'fedex_mx_A-P07_ground_flat_cost',
	sendex_mx_nextshipping_ground_cost = 'sendex_mx_A-P07_ground_cost',
	global_mx_nextshipping = 'global_mx_nextshipping'
}

enum ServicesId_CO {
	fedex_co_envia_ground='fedex_co_A-P01_ground',
	coordinadora_co_envia_ground='coordinadora_co_A-P01_ground',
	redServi_co_envia_ground='redServi_co_A-P01_ground',
	serviEntrega_co_envia_industrial='serviEntrega_co_A-P01_industrial',
	mensajerosUrbanos_co_envia_ground='mensajerosUrbanos_co_A-P01_ground',
	noventa9Minutos_co_envia_ground='noventa9Minutos_co_A-P01_ground',
}

export enum ShipmentStatus {
	Solicited = 'Solicitada',
	CancelledButUsed = 'Cancelada y usada',
	Refunded = 'Saldo abonado',
	Active = 'Activa',
	Delivered = 'Entregada',
	CancelledFromProvider = 'Cancelada de prov',
	InTransit = 'En transito',
}


export const GlobalServices = {
	...ServicesId_MX, 
	...ServicesId_CO 
};

export const getProviderName = (code: string) => {
	switch (code.toUpperCase()) {
		case "D-C02-NATIONAL":
		case "DIRECTO-DREVSTO":
		case "DIRECTO-DRENVIO":
		case "DIRECTO_DRENVIO":
			return "DRENVIO"
		case "D-C03-NATIONAL":
		case "DIRECTO-WEB360":
			return "WEB360"
		case "A-P03-NATIONAL":
		case "PACK2GO":
			return "P2G"
		case "A-P07-NATIONAL":
		case "NEXTSHIPPING":
			return "NS"
		case "A-P09-NATIONAL":
		case "GLOBALPAQ":
			return "GP"
		case "SHIPANGO":
			return "SHPG"
		case "SHIPPO":
			return "SP"
		case "D-C03-INTERNATIONAL":
			return "WEB360 Int"
		case "D-C02-INTERNATIONAL":
			return "DRENVIO Int"
		case "A-P07-INTERNATIONAL":
			return "NS Int"
		case "A-P09-INTERNATIONAL":
			return "GP Int"
		case "A-P03-INTERNATIONAL":
			return "P2G Int"
		default:
			return code.toUpperCase()
	}
}

export const serviceTitles: { [key: string]: string } = {
	'estafeta_mx_D-C03_2dias': 'Estafeta MX D-C03 2 Dias',
	'estafeta_mx_D-C03_terrestre': 'Estafeta MX D-C03 Terrestre',
	'estafeta_mx_D-C03_terrestre_especial': 'Estafeta MX D-C03 Terrestre Especial',
	'estafeta_mx_D-C03_terrestre_without_pickup': 'Estafeta MX D-C03 Terrestre Without Pickup',
	'estafeta_mx_A-P07_terrestre_without_pickup': 'Estafeta MX A-P07 Terrestre Without Pickup',
	'estafeta_mx_D-C03_next_day': 'Estafeta MX D-C03 Next Day',
	'estafeta_mx_D-C03_next_day_especial': 'Estafeta MX D-C03 Next Day Especial',
	'estafeta_mx_D-C03_next_day_without_pickup': 'Estafeta MX D-C03 Next Day Without Pickup',
	'estafeta_mx_A-P07_next_day_without_pickup': 'Estafeta MX A-P07 Next Day Without Pickup',
	'estafeta_mx_D-C03_metropolitano': 'Estafeta MX D-C03 Metropolitano',
	'estafeta_mx_P-C00_2dias': 'Estafeta MX P-C00 2 Dias',
	'estafeta_mx_A-P07_terrestre': 'Estafeta MX A-P07 Terrestre',
	'estafeta_mx_A-P07_terrestre_plus': 'Estafeta MX A-P07 Terrestre Plus',
	'estafeta_mx_A-P07_next_day': 'Estafeta MX A-P07 Next Day',
	'estafeta_mx_A-P08_ground': 'Estafeta MX A-P08 Ground',
	'estafeta_mx_A-P08_ground_stfrt': 'Estafeta MX A-P08 Ground Stfrt',
	'estafeta_mx_A-P08_next_day': 'Estafeta MX A-P08 Next Day',
	'estafeta_mx_A-P08_next_day_stfrt': 'Estafeta MX A-P08 Next Day Stfrt',
	'estafeta_mx_A-P03_ground': 'Estafeta MX A-P03 Ground',
	'estafeta_mx_A-P03_next_day': 'Estafeta MX A-P03 Next Day',
	'estafeta_mx_A-P03_next_day_drevsto': 'Estafeta MX A-P03 Next Day Drevsto',
	'estafeta_mx_A-P03_ground_drevsto': 'Estafeta MX A-P03 Ground Drevsto',
	'estafeta_mx_A-P03_metropolitano_drevsto': 'Estafeta MX A-P03 Metropolitano Drevsto',
	'estafeta_mx_P-C00_terrestre': 'Estafeta MX P-C00 Terrestre',
	'estafeta_mx_P-C00_next_day': 'Estafeta MX P-C00 Next Day',
	'fedex_mx_D-C01_ground': 'FedEx MX D-C01 Ground',
	'fedex_mx_D-C01_express': 'FedEx MX D-C01 Express',
	'fedex_mx_D-C02_ground': 'FedEx MX D-C02 Ground',
	'fedex_mx_D-C02_express': 'FedEx MX D-C02 Express',
	'fedex_mx_D-C03_ground': 'FedEx MX D-C03 Ground',
	'fedex_mx_D-C03_ground_express': 'FedEx MX D-C03 Ground Express',
	'fedex_mx_D-C03_express': 'FedEx MX D-C03 Express',
	'fedex_mx_A-P03_ground': 'FedEx MX A-P03 Ground',
	'fedex_mx_A-P03_express': 'FedEx MX A-P03 Express',
	'fedex_mx_A-P07_ground_smart': 'FedEx MX A-P07 Ground Smart',
	'fedex_mx_A-P07_ground_ac': 'FedEx MX A-P07 Ground AC',
	'fedex_mx_A-P07_express_smart': 'FedEx MX A-P07 Express Smart',
	'fedex_mx_P-C00_ground': 'FedEx MX P-C00 Ground',
	'fedex_mx_P-C00_express': 'FedEx MX P-C00 Express',
	'paquetexpress_mx__ground': 'Paquetexpress MX Ground',
	'paquetexpress_mx__express': 'Paquetexpress MX Express',
	'paquetexpress_mx_A-P03_ground': 'Paquetexpress MX A-P03 Ground',
	'paquetexpress_mx_A-P03_ground_bulto': 'Paquetexpress MX A-P03 Ground Bulto',
	'paquetexpress_mx_A-P03_express': 'Paquetexpress MX A-P03 Express',
	'paquetexpress_mx_A-P07_ground': 'Paquetexpress MX A-P07 Ground',
	'paquetexpress_mx_A-P07_ground_2': 'Paquetexpress MX A-P07 Ground 2',
	'paquetexpress_mx_A-P08_ground': 'Paquetexpress MX A-P08 Ground',
	'paquetexpress_mx_A-P03_ground_drevsto': 'Paquetexpress MX A-P03 Ground Drevsto',
	'estafeta_mx_A-P03_pallet': 'Estafeta MX A-P03 Pallet',
	'paquetexpress_mx_A-P03_pallet': 'Paquetexpress MX A-P03 Pallet',
	'paquetexpress_mx_A-P03_pallet_drevsto': 'Paquetexpress MX A-P03 Pallet Drevsto',
	'paquetexpress_mx_A-P07_pallet': 'Paquetexpress MX A-P07 Pallet',
	'dhl_mx_A-P02_ground': 'DHL MX A-P02 Ground',
	'dhl_mx_A-P02_express': 'DHL MX A-P02 Express',
	'dhl_mx_A-P02_ground_express': 'DHL MX A-P02 Ground Express',
	'dhl_mx_A-P05_ground': 'DHL MX A-P05 Ground',
	'dhl_mx_A-P05_express': 'DHL MX A-P05 Express',
	'dhl_mx_A-P06_ground': 'DHL MX A-P06 Ground',
	'dhl_mx_A-P06_express': 'DHL MX A-P06 Express',
	'dhl_mx_A-P06_ground_express': 'DHL MX A-P06 Ground Express',
	'dhl_mx_D-C03_express': 'DHL MX D-C03 Express',
	'dhl_mx_D-C03_import': 'DHL MX D-C03 Import',
	'dhl_mx_D-C03_ground': 'DHL MX D-C03 Ground',
	'dhl_mx_D-C03_int': 'DHL MX D-C03 Int',
	'dhl_mx_D-C03_ground_express': 'DHL MX D-C03 Ground Express',
	'dhl_mx_D-C03_express_1030': 'DHL MX D-C03 Express 1030',
	'dhl_mx_D-C01_express_especial': 'DHL MX D-C01 Express Especial',
	'dhl_mx_D-C03_pallet': 'DHL MX D-C03 Pallet',
	'dhl_mx_D-C01_pallet': 'DHL MX D-C01 Pallet',
	'dhl_mx_D-C01_express': 'DHL MX D-C01 Express',
	'dhl_mx_D-C01_ground': 'DHL MX D-C01 Ground',
	'dhl_mx_D-C01_int': 'DHL MX D-C01 Int',
	'dhl_mx_D-C01_ground_express': 'DHL MX D-C01 Ground Express',
	'dhl_mx_A-P07_ground': 'DHL MX A-P07 Ground',
	'dhl_mx_A-P07_ground_express': 'DHL MX A-P07 Ground Express',
	'dhl_mx_A-P07_express': 'DHL MX A-P07 Express',
	'dhl_mx_A-P07_ground_2': 'DHL MX A-P07 Ground 2',
	'dhl_mx_A-P07_express_2': 'DHL MX A-P07 Express 2',
	'dhl_mx_A-P03_ground': 'DHL MX A-P03 Ground',
	'dhl_mx_A-P03_ground_drevsto': 'DHL MX A-P03 Ground Drevsto',
	'dhl_mx_A-P03_ground_express': 'DHL MX A-P03 Ground Express',
	'dhl_mx_A-P03_ground_special': 'DHL MX A-P03 Ground Special',
	'dhl_mx_A-P03_ground_special_drevsto': 'DHL MX A-P03 Ground Special Drevsto',
	'dhl_mx_A-P03_express': 'DHL MX A-P03 Express',
	'dhl_mx_A-P03_express_drevsto': 'DHL MX A-P03 Express Drevsto',
	'dhl_mx_A-P04_ground': 'DHL MX A-P04 Ground',
	'dhl_mx_A-P04_express': 'DHL MX A-P04 Express',
	'dhl_mx_P-C00_express': 'DHL MX P-C00 Express',
	'dhl_mx_P-C00_ground': 'DHL MX P-C00 Ground',
	'dhl_mx_P-C00_int': 'DHL MX P-C00 Int',
	'sendex_mx_A-P07_ground': 'Sendex MX A-P07 Ground',
	'sendex_mx_D-C01_ground': 'Sendex MX D-C01 Ground',
	'sendex_mx_D-C01_ground6dias': 'Sendex MX D-C01 Ground 6 Dias',
	'sendex_mx_P-C00_ground': 'Sendex MX P-C00 Ground',
	'sendex_mx_P-C00_ground6dias': 'Sendex MX P-C00 Ground 6 Dias',
	'sendex_mx_A-P01_ground': 'Sendex MX A-P01 Ground',
	'sendex_mx_A-P01_ground6dias': 'Sendex MX A-P01 Ground 6 Dias',
	'ampm_mx_D-C01_ground': 'AMPm MX D-C01 Ground',
	'ampm_mx_D-C01_express': 'AMPm MX D-C01 Express',
	'ampm_mx_P-C00_ground': 'AMPm MX P-C00 Ground',
	'ampm_mx_P-C00_express': 'AMPm MX P-C00 Express',
	'quiken_mx_D-C01_ground36hrs': 'Quiken MX D-C01 Ground 36 Hrs',
	'quiken_mx_D-C01_ground': 'Quiken MX D-C01 Ground',
	'quiken_mx_P-C00_ground36hrs': 'Quiken MX P-C00 Ground 36 Hrs',
	'quiken_mx_P-C00_ground': 'Quiken MX P-C00 Ground',
	'noventa9Minutos_mx_D-C01_next_day': '99 Minutos MX D-C01 Next Day',
	'noventa9Minutos_mx_D-C01_same_day': '99 Minutos MX D-C01 Same Day',
	'noventa9Minutos_mx_D-C01_co2_free': '99 Minutos MX D-C01 CO2 Free',
	'noventa9Minutos_mx_A-P08_next_day': '99 Minutos MX A-P08 Next Day',
	'noventa9Minutos_mx_A-P08_same_day': '99 Minutos MX A-P08 Same Day',
	'noventa9Minutos_mx_A-P03_next_day': '99 Minutos MX A-P03 Next Day',
	'noventa9Minutos_mx_A-P03_ground': '99 Minutos MX A-P03 Ground',
	'noventa9Minutos_mx_A-P03_next_day_drevsto': '99 Minutos MX A-P03 Next Day Drevsto',
	'noventa9Minutos_mx_A-P03_ground_drevsto': '99 Minutos MX A-P03 Ground Drevsto',
	'noventa9Minutos_mx_P-C00_next_day': '99 Minutos MX P-C00 Next Day',
	'noventa9Minutos_mx_P-C00_same_day': '99 Minutos MX P-C00 Same Day',
	'noventa9Minutos_mx_A-P01_next_day': '99 Minutos MX A-P01 Next Day',
	'carssa_mx_A-P01_ground': 'Carssa MX A-P01 Ground',
	'scm_mx_A-P01_ground': 'SCM MX A-P01 Ground',
	'mensajerosUrbanos_mx_A-P01_ground': 'Mensajeros Urbanos MX A-P01 Ground',
	'mensajerosUrbanos_mx_A-P08_ground': 'Mensajeros Urbanos MX A-P08 Ground',
	'ups_mx_A-P01_ground': 'UPS MX A-P01 Ground',
	'ups_mx_A-P01_express': 'UPS MX A-P01 Express',
	'ups_mx_A-P02_ground': 'UPS MX A-P02 Ground',
	'ups_mx_A-P02_express': 'UPS MX A-P02 Express',
	'ups_mx_A-P03_ground_int': 'UPS MX A-P03 Ground Int',
	'ups_mx_A-P07_ground': 'UPS MX A-P07 Ground',
	'jtexpress_mx_D-C01_ground': 'JT Express MX D-C01 Ground',
	'jtexpress_mx_P-C00_ground': 'JT Express MX P-C00 Ground',
	'entrega_mx_P-C00_ground': 'Entrega MX P-C00 Ground',
	'entrega_mx_D-C01_ground': 'Entrega MX D-C01 Ground',
	'dhl_mx_A-P02_ground_int': 'DHL MX A-P02 Ground Int',
	'dhl_mx_A-P05_ground_int': 'DHL MX A-P05 Ground Int',
	'dhl_mx_A-P06_ground_int': 'DHL MX A-P06 Ground Int',
	'fedex_mx_D-C02_int_economy': 'FedEx MX D-C02 Int Economy',
	'fedex_mx_D-C02_int_priority': 'FedEx MX D-C02 Int Priority',
	'fedex_mx_D-C03_int_economy': 'FedEx MX D-C03 Int Economy',
	'fedex_mx_D-C03_int_priority': 'FedEx MX D-C03 Int Priority',
	'fedex_mx_P-C00_int_economy': 'FedEx MX P-C00 Int Economy',
	'fedex_mx_P-C00_int_priority': 'FedEx MX P-C00 Int Priority',
	'fedex_mx_D-C02_import_economy': 'FedEx MX D-C02 Import Economy',
	'fedex_mx_D-C02_import_priority': 'FedEx MX D-C02 Import Priority',
	'fedex_mx_D-C03_import_economy': 'FedEx MX D-C03 Import Economy',
	'fedex_mx_D-C03_import_priority': 'FedEx MX D-C03 Import Priority',
	'ups_mx_A-P01_int': 'UPS MX A-P01 Int',
	'ups_mx_A-P02_int': 'UPS MX A-P02 Int',
	'estafeta_mx_D-C03_int': 'Estafeta MX D-C03 Int',
	'estafeta_mx_P-C00_int': 'Estafeta MX P-C00 Int',
	'borzo_mx_D-C01_express': 'Borzo MX D-C01 Express',
	'borzo_mx_D-C01_standard': 'Borzo MX D-C01 Standard',
	'borzo_mx_P-C00_ground': 'Borzo MX P-C00 Ground',
	'testing_mx_D-C01_standard': 'Testing MX D-C01 Standard',
	'tres_guerras_mx_D-C01_standard': 'Tres Guerras MX D-C01 Standard',
	'tres_guerras_mx_D-C01_pallet': 'Tres Guerras MX D-C01 Pallet',
	'tres_guerras_mx_P-C01_standard': 'Tres Guerras MX P-C01 Standard',
	'treggo_mx_D-C01_standard': 'Treggo MX D-C01 Standard',
	'uber_mx_D-C01_ground': 'Uber MX D-C01 Ground',
	'pickit_mx_D-C01_standard': 'Pickit MX D-C01 Standard',
	'pickit_mx_D-C01_express': 'Pickit MX D-C01 Express',
	'ups_mx_D-C01_standard_int': 'UPS MX D-C01 Standard Int',
	'ups_mx_D-C01_express_plus_int': 'UPS MX D-C01 Express Plus Int',
	'ups_mx_D-C01_saver': 'UPS MX D-C01 Saver',
	'estafeta_mx_A-P09_ground': 'Estafeta MX A-P09 Ground',
	'estafeta_mx_A-P09_next_day': 'Estafeta MX A-P09 Next Day',
	'fedex_mx_A-P09_express': 'FedEx MX A-P09 Express',
	'fedex_mx_A-P09_ground': 'FedEx MX A-P09 Ground',
	'dhl_mx_A-P09_ground': 'DHL MX A-P09 Ground',
	'dhl_mx_A-P09_express': 'DHL MX A-P09 Express',
	'estafeta_mx_A-P07_terrestre_cost': 'Estafeta MX A-P07 Terrestre Cost',
	'paquetexpress_mx_A-P07_ground_cost': 'Paquetexpress MX A-P07 Ground Cost',
	'estafeta_mx_A-P07_terrestre_plus_cost': 'Estafeta MX A-P07 Terrestre Plus Cost',
	'estafeta_mx_A-P07_next_day_cots': 'Estafeta MX A-P07 Next Day Cost',
	'fedex_mx_A-P07_ground_cost': 'FedEx MX A-P07 Ground Cost',
	'fedex_mx_A-P07_ground_flat_cost': 'FedEx MX A-P07 Ground Flat Cost',
	'sendex_mx_A-P07_ground_cost': 'Sendex MX A-P07 Ground Cost',
	'global_mx_nextshipping': 'Global MX Next Shipping'
};
  
export function getValueService(key: string): string  {	
	return serviceTitles[key];
}

