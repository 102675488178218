import i18n from "i18next";

import english from "./English.json";
import spanish from "./Spanish.json";

const resources: Resource = {
  english: {
    translation: english,
  },
  español: {
    translation: spanish,
  },
};

i18n.init({
  resources: resources,
  lng: "español",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
