import { Alert, Col } from "antd"
import { FC } from "react";
import { ShipmentV2 } from "../../../interfaces/shipments.interface";

interface Props {
    editMode: boolean;
    shipment: ShipmentV2
}

const styles = {
    dateContainer: {
        display: "grid",
        placeItems: "center",
        fontWeight: "bold",
        height: "4rem"
    },
    dateText:{
        display: "grid",
        placeItems: "center",
        fontWeight: "bold",
        paddingTop: "1rem"
    },
    alertContainer: {
        display: "grid",
        placeItems: "center",
        height: "4rem"
    },
    alert: {
        width: "16rem",
        borderRadius: "12px",
    }
}

const FooterMsg: FC<Props> = ({ editMode, shipment }) => {
    return (
        <>
            {
                !editMode ? (
                    <Col span={24} style={styles.dateContainer} >
                        <p style={styles.dateText}>Guía generada el {new Date(shipment?.created_at).toLocaleDateString()}</p>
                    </Col>
                ) : (
                    <Col span={24} style={styles.alertContainer}>
                        <Alert
                            style={styles.alert}
                            message="Modifica el estatus del envío"
                            type="info"
                            showIcon />
                    </Col>

                )
            }
        </>
    )
}

export default FooterMsg