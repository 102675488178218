import { useCallback, useEffect } from "react";
import { useGet } from "../../../../hooks/useGet";
import { usePost } from "../../../../hooks/usePost";
import { Col, Row } from "antd";
import {
  getAverageWeightAndCost,
  getTotalShipmentsToday,
  getShipmentsCurrentMonth,
} from "../../../../services/admin_s";
import { FilterRequest } from "../../../../services/user_d";
import { DashboardAnalyticsRes } from "../../../../interfaces/admin.interface";
import CardContainer from "../../../../components/Containers/CardContainer";
import { ReactComponent as PaperPlane } from "../../../../assets/icons/paper-plane.svg";

type Props = {
  filteredData: DashboardAnalyticsRes;
  isLoading: boolean;
  country: string;
};
interface DataAverages {
  res: {
    averageCost: number;
    averageWeight: number;
  };
}

interface DataTotalShipmentsToday {
  data: {
    total: number;
  };
}

export const TopCards = ({ filteredData, country, isLoading }: Props) => {
  const {
    data: totalShipmentsMonth,
    isLoading: isLoadingTotalShipmentMonth,
    refetch,
  } = useGet(getShipmentsCurrentMonth(country)) as {
    data: { data: { total: number } };
    isLoading: boolean;
    refetch: () => void;
  };

  const {
    fetchData: fetchTotalShipmentsToday,
    data: totalShipmentsToday,
    isLoading: isLoadingTotalShipmentsToday,
  } = usePost<FilterRequest, DataTotalShipmentsToday>(getTotalShipmentsToday);

  const {
    fetchData,
    data: dataAverages,
    isLoading: isLoadingAverages,
  } = usePost<FilterRequest, DataAverages>(getAverageWeightAndCost);

  const getDataTotalShipmentsToday = useCallback(async () => {
    fetchTotalShipmentsToday({
      "origin.country": country,
    });
  }, [fetchTotalShipmentsToday, country]);

  const getData = useCallback(async () => {
    await fetchData({
      "origin.country": country,
    });
  }, [fetchData, country]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    let isMounted = true;
    const getDataWithCheck = async () => {
      if (isMounted) {
        await getDataTotalShipmentsToday();
        await getData();
      }
    };
    getDataWithCheck();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSubtitle = (isLoading: boolean, text: string) =>
    isLoading ? "Cargando..." : text;

  return (
    <Row gutter={[20, 10]}>
      <Col lg={2} xs={0} sm={0} md={0}></Col>
      <Col lg={4} sm={12} xs={24}>
        <CardContainer
          title="Envios mes actual"
          subtitle={showSubtitle(
            isLoadingTotalShipmentMonth,
            `${totalShipmentsMonth?.data.total.toLocaleString() || 0}`
          )}
          itemsSpacing={[0, 10]}
          iconOrImg={<PaperPlane />}
          contrastColor="#54c0f9"
        />
      </Col>
      <Col lg={4} sm={12} xs={24}>
        <CardContainer
          title="Envíos de hoy"
          subtitle={showSubtitle(
            isLoadingTotalShipmentsToday,
            `${totalShipmentsToday?.data.total.toLocaleString() || 0}`
          )}
          itemsSpacing={[0, 10]}
          iconOrImg={<PaperPlane />}
          contrastColor="#6d71f6"
        />
      </Col>
      <Col lg={4} sm={12} xs={24}>
        <CardContainer
          title="Costo promedio"
          subtitle={showSubtitle(
            isLoadingAverages,
            `$${dataAverages?.res.averageCost.toFixed(2) || 0} ${
              country === "CO" ? "COP" : "MXN"
            }`
          )}
          itemsSpacing={[0, 10]}
          iconOrImg={<PaperPlane />}
          contrastColor="#5fce8c"
        />
      </Col>
      <Col lg={4} sm={12} xs={24}>
        <CardContainer
          title="Peso promedio"
          subtitle={showSubtitle(
            isLoadingAverages,
            `${
              dataAverages?.res?.averageWeight
                ? Number.isInteger(dataAverages?.res?.averageWeight)
                  ? dataAverages?.res?.averageWeight
                  : parseFloat(dataAverages?.res?.averageWeight.toFixed(2))
                : 0
            } Kg`
          )}
          itemsSpacing={[0, 10]}
          iconOrImg={<PaperPlane />}
          contrastColor="#ce5fb8"
        />
      </Col>
      <Col lg={4} sm={12} xs={24}>
        <CardContainer
          title="Registros hoy"
          subtitle={showSubtitle(
            isLoading,
            `${filteredData?.registersByDay || 0}`
          )}
          itemsSpacing={[0, 10]}
          iconOrImg={<PaperPlane />}
          contrastColor="#ceba05"
        />
      </Col>
      <Col lg={2} xs={0} sm={0} md={0}></Col>
    </Row>
  );
};
