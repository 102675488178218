import { Fragment } from 'react'
import BlogText from '../../components/Common/BlogText'
import Header from './Header'
import PolicyLayout from './PolicyLayout'
import PolicyTitle from './PolicyTile'

const ShippingPolicy = () => {
  return (
    <Fragment>
      <Header />
      <PolicyLayout>
        <PolicyTitle title='Politica de envíos' />
        <BlogText>
          Al hacer un envío con nosotros automáticamente aceptas las siguientes políticas de envío.
        </BlogText>
        <ol>
          <li>
            <BlogText>
              Nuestros tiempos de entrega de paquetes, recolecciones, respuesta sobre reembolsos de
              paquetes dañados, perdidos o con sobrepeso erróneos dependen directamente de los
              tiempos de respuesta de las paqueterías, DR ENVIO S.A. DE C.V. no puede acelerar estos
              procesos.
            </BlogText>
          </li>
          <li>
            <BlogText>
              Todos nuestros paquetes tienen un seguro máximo de $2,000 MXN, en caso de que el valor
              declarado del envío sea menor, no se cubrirá el seguro máximo, se procederá al pago de
              ese monto, para solicitarlo se debe comprobar con servicio al cliente que tu paquete
              esta perdido o dañado, así mismo anexar la factura original de compra del producto con
              fechas anteriores al envío de la guia y timbrada por el SAT, en caso de que tu
              proveedor sea extranjero se deberá mostrar la factura timbrada por el homologo
              correspondiente, la aprobación de reembolso depende de la decisión de cada paquetería,
              en caso de negativa por parte de la paquetería DR ENVIO S.A. DE C.V. no podrá tramitar
              el reembolso, en caso de que la respuesta sea positiva el proceso de reembolso se hará
              directamente a tu cuenta de DR ENVIO S.A. DE C.V. en un proceso de 3 a 4 semanas.
            </BlogText>
          </li>
          <li>
            <BlogText>
              En caso de envíos internacionales, al realizarlos con DR ENVIO S.A. DE C.V. usted es
              responsable de cualquier tipo de impuesto de entrada al país debido al material que
              envía, para nosotros es imposible saber el impuesto exacto de cada producto en cada
              país, así que DR ENVIO S.A. DE C.V. sólo se hace responsable por el costo de su guia
              sin incluir los sobrepesos que pudieran ocasionar.
            </BlogText>
          </li>
          <li>
            <BlogText>
              En cuestión de sobre cargos / sobre pesos de sus envíos ya sea nacionales o
              internacionales serán cargados directamente a su cuenta, en caso que no sean pagados
              en un plazo de 1 a 2 semanas después de haber sido cargados a su cuenta, DR ENVIO S.A.
              DE C.V. ejercerá su derecho a proceder legalmente bajo cualquier instancia, congelar
              sus envíos y reportar a buro de crédito el plazo de la deuda.
            </BlogText>
          </li>
          <li>
            <BlogText>
              Se hace de su saber que los tiempos de recolección (1 día hábil), retornos a remitente
              (5 a 7 días hábiles), redirecciones (5-7 días hábiles) son tiempos estimados y en
              ningún momento son exactos ya que estos dependen directamente de las paqueterías.
            </BlogText>
          </li>
          <li>
            <BlogText>
              En caso de que tu envío haya sido roto, perdido, confiscado, retenido y el contenido
              sea algún artículo prohibido DR ENVIO S.A. DE C.V. se reserva el derecho a reembolsar
              el mismo, automáticamente se cancela el seguro. Algunos de los artículos prohibidos
              son los siguientes: Líquidos, Jabones, Plantas, Polvos, Comidas, Madera, Juguetes para
              niños menores de 3 años, Químicos, Maquillajes, Insecticida, masas moldeables,
              preservativos, toallas húmedas, lociones, armas artificiales, suplementos
              alimenticios, cápsulas, tabletas, restos humanos o de animales, carbones o diamantes
              industriales, pornografía, billetes de lotería, cheques, obras de arte, antiguedades,
              tarjetas de crédito activadas, piratería, entre otros, los artículos prohibidos
              aumentan cada día según las reglas de cada paquetería, así que DR ENVIO S.A. DE C.V.
              se reserva el derecho a reembolsar y se alinea a los nuevos artículos prohibidos para
              cada paquetería aunque no estén escritos en este medio.
            </BlogText>
          </li>
          <li>
            <BlogText>
              El cliente es responsable de checar que los datos de sus guias estén correctos, DR
              ENVIO S.A. DE C.V. no se hace responsable en caso de que la dirección o cualquier otro
              dato este incorrecto, más sin embargo podemos apoyarlos a crear alguna redirección a
              través de nuestro chat de soporte en línea.
            </BlogText>
          </li>
          <li>
            <BlogText>
              Nos reservamos el derecho a cualquier reembolso de saldo, bajo cualquier situación.
            </BlogText>
          </li>
          <li>
            <BlogText>
              Por motivos de contabilidad sólo se podrán generar facturas del mes en curso.
            </BlogText>
          </li>
          <li>
            <BlogText>
              Cualquier reclamación o ticket de sobrepesos de menos de 10kg nos reservamos el
              derecho a levantar investigación con la paquetería correspondiente, esto por el motivo
              que la mayoría de los casos son paquetes mal empacados, les recomendamos lo
              siguiente:a) Cambiar de paquetería. b) Cambiar de sucursal. Y si después de esto el
              problema persiste avisar el personal de DrEnvío.
            </BlogText>
          </li>
          <li>
            <BlogText>
              DrEnvío se reserva el derecho a cobrar los sobrepesos siempre y cuando la IP del
              usuario sea la misma (aunque sea otra cuenta distinta).
            </BlogText>
          </li>
          <li>
            <BlogText>
              Tus datos sólo serán usados para fines de marketing de nuestra empresa y nunca serán
              vendidos a terceros.
            </BlogText>
          </li>
          <li>
            <BlogText>
              Nos reservamos el derecho a depositar/integrar a saldo cualquier error en promociones
              de marketing que realicemos.
            </BlogText>
          </li>
        </ol>
      </PolicyLayout>
    </Fragment>
  )
}

export default ShippingPolicy
