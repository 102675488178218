import axios from "axios"
import { CustomerDataI } from "./Sells"
import { useContext, useEffect, useState } from "react"
import { ApiContext } from "../../context/apiContext"
import { Badge, Button,notification } from "antd"

const UserView = (
    {
        refetch,
        data,
        setOpenModal,
        openModal
    }:{
        refetch: () => void,
        data: CustomerDataI,
        setOpenModal: (data: { type: string, open: boolean, data: object }) => void,
        openModal: { type: string, open: boolean, data: object }
    }
) => {

    const [ sellerInfo, setSellerInfo ] = useState<any>(undefined)
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const { backendURL, userToken } = useContext(ApiContext)

    console.log(data)

    const getCustomerData = async() => {
        try {
            setIsLoading(true)
            const response = await axios.get(
                `${backendURL}admin/getCustomerInfo/${data?._id ?? data.user}`,
                { headers: { Authorization: `Bearer ${ userToken }` } }
            )
            console.log(response.data)
            if(response.data.length > 0){
                setSellerInfo(response.data[0])
                setIsLoading(false)
                return
            }
            setSellerInfo(undefined)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    const onDeleteSeller = async() => {
        try {
            setIsLoading(true)
            const response = await axios.delete(
                `${backendURL}admin/deleteCustomer/${data?._id ?? data.user}`,
                { headers: { Authorization: `Bearer ${ userToken }` } }
            )
            if(response.status === 200){
                refetch()
                setIsLoading(false)
                setOpenModal({ ...openModal, open: false })
                return notification.success({
                    message: "Vendedor desasignado correctamente"
                })
            }
            setIsLoading(false)
            return notification.success({
                message: "Error al desasignar vendedor"
            })
        } catch (error) {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getCustomerData()
        //eslint-disable-next-line
    },[data])

  return (
    <div style={{ textAlign:'center' }}>
        {
            isLoading
                ? <p>Cargando ...</p>
                : !sellerInfo
                ? (
                    <p>SIN VENDEDOR ASIGNADO</p>
                )
                : (
                    <>
                        <p>Correo: <span style={{ fontWeight:'600' }}>{ sellerInfo.sellerProfile.email }</span></p>
                        <p>Teléfono: <span style={{ fontWeight:'600' }}>{ sellerInfo.sellerProfile.phone }</span></p>
                        <Badge status="success" text={sellerInfo.type} style={{ margin:'20px 0', background:'#59ff007e', color:'#007a00', padding:'2px 10px' }} />
                        <Button htmlType="submit" type="primary" style={{ width:'100%' }} onClick={onDeleteSeller}>Desasignar</Button>
                    </>
                )
        }
    </div>
  )
}

export default UserView