import { Col, Form, notification, Row, Spin } from "antd"
import { FC, useEffect, useState } from "react"
import { FinderAccessI } from "."
import { t } from "i18next";
import useDevice from "../../../hooks/useDevice"
import { usePost } from "../../../hooks/usePost"
import { ShipmentStatus, ShipmentStatusV1, ShipmentV2 } from "../../../interfaces/shipments.interface"
import { getParcelImage, updateShipmentStatus, updateShipmentStatusV1 } from "../../../services/records"
import CardContainer from "../../Containers/CardContainer"
import { CancelStatus } from "../CancelStatus"
import { ShipmentsActions } from "../ShipmentsActions"
import FooterMsg from "./FooterMsg"
import ModifyAction from "./ModifyAction"
import StatusInput from "./StatusInput"
import { carrierNames, limitTextSize } from "./utils"
import { formatPrice2 } from "../../../utils/prices";

interface Props {
    shipment: ShipmentV2;
    isloading: boolean;
    finderAccess: FinderAccessI;
}

const styles = {
    listItemFlexStyles: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 0,
        height: "3rem"
    },
    listItemStyle: {
        display: "grid",
        placeItems: "center",
        margin: 0,
        height: "auto"
    },
    cardStyle: {
        width: "100%"
    },
    containerCardStyle: {
        marginBottom: "1rem"
    },
    textlistStyle: {
        fontSize: "0.9rem",
        fontWeight: "bold"
    },
    cardOutline: {
        width: "100%",
        boxShadow: '4px 0px 14px rgba(45, 27, 110, 0.13)',
    },
    secondCardList: {
        fontSize: "0.9rem"
    }
}

const ShipmentResult: FC<Props> = ({ shipment, isloading, finderAccess }) => {
    const [editMode, setEditMode] = useState<boolean>(false)
    const [newStatus, setNewStatus] = useState<undefined | ShipmentStatus>(undefined)
    const [form] = Form.useForm();
    const { isBigDesktop } = useDevice()
    const { fetchData, isLoading: isLoadingStatus } = usePost(updateShipmentStatus);
    const { fetchData: fetchDataV1, isLoading: isLoadingStatusV1 } = usePost(updateShipmentStatusV1);
    const changeEditMode = () => {
        setEditMode(!editMode)
    }

    useEffect(() => {
        form.setFieldsValue({
            change_status: shipment.shipment_status
        })
        setNewStatus(undefined)
        //eslint-disable-next-line
    }, [shipment])

    const onSubmit = async (status: any) => {
        console.log(status)
        try {
            let response;
            if (shipment.shipping_id === "LEGACY") {
                let statusV1 = status;
                if (statusV1 === ShipmentStatus.Active) {
                    statusV1 = ShipmentStatusV1.Active
                }
                response = await fetchDataV1({
                    tracking: shipment.tracking,
                    status: statusV1
                })
            } else {
                response = await fetchData({
                    tracking: shipment.tracking,
                    status
                })
            }
            if (response.data) {
                setNewStatus(status)
                changeEditMode()
                notification.success({
                    message: "Envío actualizado correctamente",
                });
            } else {
                notification.error({
                    message: "Un error ha ocurrido",
                });
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Row style={styles.cardStyle}>
            <Col span={24}>
                <Spin spinning={isloading}>
                    {
                        isBigDesktop && (
                            <CardContainer
                                cardStyle={styles.cardStyle}
                                containerStyle={styles.containerCardStyle}
                                textListStyle={styles.textlistStyle}
                                direction="horizontal"
                                textList={[
                                    "Paquetería",
                                    "Rastreo",
                                    "Usuario",
                                    "Status"
                                ]}
                            />
                        )
                    }
                    <CardContainer
                        cardStyle={styles.cardOutline}
                        textListStyle={styles.secondCardList}
                        direction={isBigDesktop ? "horizontal" : "vertical"}
                        textList={[
                            <div style={styles.listItemStyle}>
                                <div>
                                    {getParcelImage(carrierNames(shipment.carrier.split(' ')[0]) as AllCarriers)}
                                </div>
                                <div>
                                    {
                                        shipment.shipping_id === "LEGACY"
                                            ? shipment.service === "NA" ? t(`Quote.Service.Carrier.${carrierNames(shipment.carrier)}`) : shipment.service
                                            : t(`Quote.Service.Carrier.${carrierNames(shipment.carrier)}`) + ' ' + t(`Quote.Service.${shipment.service.toLowerCase()}`)
                                    }
                                </div>
                                <div>
                                    {t(`Shipment.Datails.ZoneExtens`)}: {`${shipment.metadata?.extended_area === true ? t(`Shipment.Datails.ZoneYes`) : t(`Shipment.Datails.ZoneNo`)}`}
                                </div>
                            </div>
                            ,
                            <p style={styles.listItemStyle}>
                                {limitTextSize(shipment.tracking, 16, true)}
                            </p>,
                            <p style={styles.listItemStyle}>
                                {shipment.profile?.email as string}
                            </p>,
                            <p style={styles.listItemStyle}>
                                $ {formatPrice2(shipment?.price, shipment?.origin?.country)}
                            </p>,
                            <div style={styles.listItemFlexStyles}>
                                {
                                    !editMode
                                        ? (
                                            <>
                                                <CancelStatus status={
                                                    !newStatus
                                                        ? shipment.shipment_status
                                                        : newStatus
                                                } />
                                                <ShipmentsActions
                                                    data={{ shipmentData: shipment } as unknown as any}
                                                    disabledMenu={false}
                                                />
                                            </>
                                        )
                                        : (
                                            <>
                                                <StatusInput
                                                    form={form}
                                                    isLoadingStatus={
                                                        shipment.shipping_id === "LEGACY"
                                                            ? isLoadingStatusV1
                                                            : isLoadingStatus
                                                    }
                                                    onSubmit={onSubmit}
                                                />
                                            </>
                                        )
                                }
                                {
                                    finderAccess.update_status && (
                                        < ModifyAction
                                            editMode={editMode}
                                            changeEditMode={changeEditMode}
                                        />
                                    )
                                }
                            </div>
                        ]}
                    />
                    <br />
                    <FooterMsg editMode={editMode} shipment={shipment} />
                </Spin>
            </Col>
        </Row>
    )
}

export default ShipmentResult