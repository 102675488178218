import { Col, Row } from 'antd'
import  { useEffect, useState } from 'react'
import moment from 'moment'
// import CardContainer from '../../../../components/Containers/CardContainer'
import { getDashboardMkt } from '../../../../services/user_d'
import { usePost } from '../../../../hooks/usePost'
import BarChart from '../../../../components/Common/BarChart'
import NewUsers from './NewUsers'
import MethodsMostUsed from './MethodsMostUsed'
import UserActivity from './UserActivity'

const Marketing = () => {

  const [filteredData, setFilteredData] = useState<any>()
  const [filter] = useState<any>({})
  const [date, setDate] = useState<any>(null)
  const { fetchData, isLoading } = usePost(getDashboardMkt)

  // const PaperPlane = () => {
  //   return (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="20"
  //       height="20"
  //       viewBox="0 0 24 24"
  //       fill="#ffffff"
  //     >
  //       <path d="M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z" />
  //     </svg>
  //   );
  // };

  useEffect(() => {
    setDate([moment().startOf('month'), moment()])
  }, []) //eslint-disable-line

  useEffect(() => {
    const getData = async (filter: any) => {
      const { error, data } = await fetchData(filter)
      if (!error) {
        setFilteredData(data)
      } else {
        setFilteredData({})
      }
    }


    console.log('filter', filter)

    getData(filter)
  }, [filter, date]) //eslint-disable-line

  return (
      <Row gutter={[10,10]} style={{ marginTop: "20px", width: "95%", height: "60%" }}>        
        <Col xs={24} sm={24} md={12} lg={12}>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <BarChart title='Métodos de registro más usados' data={filteredData ? filteredData?.RegMethods :[]} isLoading={isLoading} component={<MethodsMostUsed date={filter?.created_at} />} includeCard={true} />
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12}>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <BarChart title='Análisis de nuevos usuarios' data={filteredData ? filteredData?.UsersRecharges :[]} isLoading={isLoading} component={<NewUsers />} includeCard={true} />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <UserActivity data={filteredData ? filteredData?.UserActivity :[]} isLoading={isLoading} date={'hoy'} />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <UserActivity data={filteredData ? filteredData?.UserActivityMonth :[]} isLoading={isLoading} date={'del mes'}/>
            </Col>
          </Row>
        </Col>

      </Row>
  )
}

export default Marketing