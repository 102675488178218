import { t } from "i18next"
import CardContainer from "../../../../components/Containers/CardContainer"

const styles = {
    fontSize: "1rem", 
    margin: '0.5rem 0'
}

const Instructions = () => {
    return (
        <CardContainer>
            <ul>
                <li style={styles}>{t("Down.Overweight.List.One")}</li>
                <li style={styles}>{t("Down.Overweight.List.Two")}</li>
                <li style={styles}>{t("Down.Overweight.List.Three")}</li>
            </ul>
        </CardContainer>
    )
}

export default Instructions