import { ToolbarProps, ToolbarSlot } from "@react-pdf-viewer/toolbar";
import { ReactElement } from "react";

const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
        {(slots: ToolbarSlot) => {
            const {
                CurrentPageInput,
                Download,
                EnterFullScreen,
                GoToNextPage,
                GoToPreviousPage,
                NumberOfPages,
                Print,
                Zoom,
                ZoomIn,
                ZoomOut,
            } = slots;
            return (
                <div
                    style={{
                        alignItems: "center",
                        display: "flex",
                        width: "100%",
                    }}
                >
                    <div style={{ padding: "0px 2px" }}>
                        <ZoomOut />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                        <Zoom />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                        <ZoomIn />
                    </div>
                    <div style={{ display: "flex" }}>
                        <div>
                            <GoToPreviousPage />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <CurrentPageInput /> / <NumberOfPages />
                        </div>
                        <div>
                            <GoToNextPage />
                        </div>
                    </div>
                    <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                        <EnterFullScreen />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                        <Download />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                        <Print />
                    </div>
                </div>
            );
        }}
    </Toolbar>
);

export default renderToolbar