import { FC } from "react";
import InputContainer from "../Containers/InputContainer";

export const TableZoneFilter: FC<Props> = ({ setZoneSelected }) => {
  const zones = ["Zona 1", "Zona 2", "Zona 4", "Zona 8"];
  return (
    <div>
      <InputContainer
        optionsList={zones}
        placeholder="Zonas"
        type="select"
        valueContainerName="value name"
        onChange={(e) => setZoneSelected(e)}
      />
    </div>
  );
};

type Props = {
  setZoneSelected: React.Dispatch<React.SetStateAction<string | null>>;
};
