import { Form, Row, Col, Typography, Avatar, Spin, notification } from "antd";
import { PricesContext } from "../../context/PricesContext";
import { useState, useEffect, useContext } from "react";
import ModalContainer, {
  modalHeight,
} from "../../components/Containers/ModalContainer";
import CardContainer from "../../components/Containers/CardContainer";
import InputContainer from "../../components/Containers/InputContainer";
import { SearchOutlined } from "@ant-design/icons";
import { useGet } from "../../hooks/useGet";
import { addUserToSpecialPrices, getUsers } from "../../services/services_s";

import { usePost } from "../../hooks/usePost";
import { SpecialPrice } from "../../interfaces/services.interface";
import { FullUserInfoResponse } from "../../interfaces/user.interface";

export const UsersModal = () => {
  const { data, isLoading: isGettingUsers } =
    useGet<FullUserInfoResponse[]>(getUsers);
  const [form] = Form.useForm();
  const { fetchData, isLoading: isAddingUser } = usePost(
    addUserToSpecialPrices
  );
  const {
    selectedServiceId,
    isUsersModalOpen,
    setIsUsersModalOpen,
    setSelectedServiceId,
  } = useContext(PricesContext);

  const [filter, setFilter] = useState("");
  const [usersShown, setUsersShown] = useState<FullUserInfoResponse[]>([]);

  useEffect(() => {
    if (data) {
      const wordList = data.filter(
        (user: FullUserInfoResponse) =>
          user.email && user.email.includes(filter)
      );

      setUsersShown(wordList);
    }
  }, [filter, data]);

  const handleSelectUser = async (user: FullUserInfoResponse) => {
    if (!selectedServiceId) return;

    const resp = await fetchData({
      user: user.user,
      id: selectedServiceId._id,
    });

    if (resp.error)
      return notification.error({
        message: (resp.error as any).msg,
      });

    setSelectedServiceId({
      ...selectedServiceId,
      specialPrices: resp.data as SpecialPrice[],
    });
    setIsUsersModalOpen(false);
  };

  return (
    <ModalContainer
      // onCancel={closeModal}
      width={600}
      height={modalHeight.LARGE}
      style={{ maxWidth: 600, overflow: "scroll", position: "relative" }}
      title={`Users`}
      visible={isUsersModalOpen && !!selectedServiceId}
      footer={<></>}
      headerGutter={[0, 10]}
      onCancel={() => setIsUsersModalOpen(false)}
      customHeader={
        <Form
          form={form}
          onFinish={(props: { email: string }) => {
            setFilter(props.email);
          }}
        >
          <Row style={{ width: "100%" }} align="middle">
            <Col flex={1}>
              <InputContainer
                placeholder="Escribe un correo electronico"
                valueContainerName="email"
                type="text"
                style={{
                  width: "100%",
                  height: "auto",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              />
            </Col>
            <input
              id="button_user_submit"
              type="submit"
              style={{ display: "none" }}
            />
            <Col>
              <InputContainer
                title="Text"
                shape="default"
                iconOrReactElement={<SearchOutlined />}
                type="iconTitleHoverButton"
                onClick={() => {
                  document.getElementById("button_user_submit")?.click();
                }}
                style={{
                  height: "auto",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              />
            </Col>
          </Row>
        </Form>
      }
    >
      {isAddingUser || isGettingUsers ? (
        <Spin />
      ) : (
        usersShown &&
        usersShown.map((user) => (
          <CardContainer
            key={user._id}
            containerStyle={{ margin: "0.5rem 0", cursor: "pointer" }}
            onClick={() => handleSelectUser(user)}
          >
            <Row style={{ width: "100%" }} align="middle">
              <Col style={{ marginRight: 10 }}>
                <Avatar>{user.name.substring(0, 1).toUpperCase()}</Avatar>
              </Col>
              <Col flex={1}>
                <Typography.Text strong style={{ display: "block" }}>
                  {user.name}
                </Typography.Text>
                <Typography.Text style={{ display: "block" }}>
                  {user.email}
                </Typography.Text>
              </Col>
            </Row>
          </CardContainer>
        ))
      )}
    </ModalContainer>
  );
};
