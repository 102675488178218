import { Row } from "antd"
import { UserItemI } from "../Search/SearchItem";
import { FC } from "react";
import SectionLayout from "./SectionLayout";
import MaintenancePage from "./MaintenancePage";
import { dataToShowI, userActionsT } from "../interfaces";

interface props {
    userFound: UserItemI;
    fullUser: dataToShowI | null;
    changeActions: (type: userActionsT) => void;
    showDrawer: () => void;
}

const SpecialPricesPage:FC<props> = ({ userFound, changeActions, showDrawer, fullUser }) => {
    return (
        <SectionLayout 
            title="Precios especiales" 
            userFound={userFound} 
            changeActions={changeActions} 
            showDrawer={showDrawer} 
            roles={fullUser?.roles || []}>
            <Row>
                <MaintenancePage />
            </Row>

        </SectionLayout>
    )
}

export default SpecialPricesPage