import { FileI } from "../Overweight";
import * as XLSX from "xlsx";


export function readData(file: File) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e: any) => {
            const bufferArray = e.target.result;
            const a = XLSX.read(bufferArray, { type: "buffer" });
            const sheetName = a.SheetNames[0];
            const sheet = a.Sheets[sheetName];
            const parsed = XLSX.utils.sheet_to_json(sheet);
            resolve(parsed);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
}

export function findOverWeightsFedex(files: any): FileI[] {
    const overweights: FileI[] = [];
    alert(`Cantidad de filas en el documento: ${files.length}`)
    for (let item of files) {
        overweights.push({
            tracking: item["Número de guía aérea"]?.toString() || item['NÃºmero de guÃ­a aÃ©rea'].toString(),
            amount: item["Monto total guía aérea"] || item['Monto total guÃ­a aÃ©rea'],
            weight: item["Monto de peso estimado"],
            description: item["Etiqueta de cargo de guía aérea"] || item['Etiqueta de cargo de guÃ­a aÃ©rea']
        });
    }
    alert(`Total de Filas Procesadas: ${overweights.length}`)
    return overweights
}

export function findOverWeightsEstafeta(files: any): FileI[] {
    const overweights: FileI[] = [];
    for (let item of files) {
        //console.log(files, "las files", item)
        const numberOfRows = Number.MAX_SAFE_INTEGER - 1;

        if (item["__rowNum__"] <= numberOfRows) {
            overweights.push({
                tracking: item["Número de Guia"]?.toString(),
                amount: item["Costo"],
                weight: item["Sobrepeso"],
                description: item["Factura"]
            });

        }

    }
    //console.log("el total de sobreopesos", overweights)
    return overweights
}

export function findOverWeights99Minutos(files: any): FileI[] {
    const overweights: FileI[] = [];
    alert(`Cantidad de filas en el documento: ${files.length}`)
    for (let item of files) {
        overweights.push({
            tracking: item["Numero 99"]?.toString(),
            amount: item["precio final de guia con iva"],
            weight: item["Tamaño del paquete"],
            description: item["Descripcion del Estatus Actual"]
        });
    }
    alert(`Total de Filas Procesadas: ${overweights.length}`)
    return overweights
}

export function findOverWeightsSendex(files: any): FileI[] {
    const overweights: FileI[] = [];
    alert(`Cantidad de filas en el documento: ${files.length}`)
    for (let item of files) {
        overweights.push({
            tracking: item["Guía"]?.toString() || item['NÃºmero de guÃ­a aÃ©rea'].toString(),
            amount: item["Total"] || item['Monto total guÃ­a aÃ©rea'],
            weight: item["Peso"],
            description: item["noexiste"]
        });
    }
    alert(`Total de Filas Procesadas: ${overweights.length}`)
    return overweights
}

export function findOverWeightsDHL(files: any, provider: 'shipandgo' | 'shipango' | 'shippo' | 'pak2go' | 'directo'): FileI[] {
    const overweights: FileI[] = [];
    alert(`Cantidad de filas en el documento: ${files.length}`)
    for (let item of files) {
        switch (provider) {
            case 'shipandgo':
                overweights.push({
                    tracking: item["GUIA"]?.toString(),
                    amount: item["Costo sobrepeso"],
                    weight: item["SOBREPESO KG"],
                    description: item["FACTURA"]
                });
                break;
            case 'shipango':
                overweights.push({
                    tracking: item["Guia"]?.toString(),
                    amount: item["Total"],
                    weight: item["Peso"],
                    description: "Sobrepeso"
                });
                break;
            case 'shippo':
                overweights.push({
                    tracking: item["Número de Guia"]?.toString(),
                    amount: item["Cobrado"],
                    weight: item["Sobrepeso"],
                    description: item["Factura"]
                });
                break;
            case 'pak2go':
                overweights.push({
                    tracking: item["GUIA"]?.toString(),
                    amount: item["VENTA"],
                    weight: item["SOBREPESO"].split(" ")[0],
                    description: item["PESO ENVIADO"]
                });
                break;
            case 'directo':
                overweights.push({
                    tracking: item["Shipment Number"]?.toString(),
                    amount: item["Total amount (incl. VAT)"],
                    weight: item["Weight (kg)"],
                    description: item["XC2 Name"] || 'Sobrepeso'
                });
                break;
            default:
                overweights.push({
                    tracking: item["Número de Guia"]?.toString(),
                    amount: item["VENTA"],
                    weight: item["Sobrepeso"],
                    description: item["Factura"]
                });
        }


    }
    alert(`Total de Filas Procesadas: ${overweights.length}`)
    return overweights
}

export function findOverWeightsEnvia(files: any): FileI[] {
    const overweights: FileI[] = [];
    alert(`Cantidad de filas en el documento: ${files.length}`)
    for (let item of files) {
        overweights.push({
            tracking: item["Número de Guia"]?.toString(),
            amount: item["Cobrado"],
            weight: item["Sobrepeso"],
            description: item["Comentario"]
        });

    }
    alert(`Total de Filas Procesadas: ${overweights.length}`)
    return overweights
}


export function findOverWeightsJtexpress(files: any): FileI[] {
    const overweights: FileI[] = [];
    alert(`Cantidad de filas en el documento: ${files.length}`)
    for (let item of files) {
        overweights.push({
            tracking: item["guia"]?.toString(),
            amount: item["total"],
            weight: item["peso"],
            description: item["observaciones"]
        });
    }
    alert(`Total de Filas Procesadas: ${overweights.length}`)
    return overweights
}

export function findOverWeightsAmpm(files: any): FileI[] {
    const overweights: FileI[] = [];
    alert(`Cantidad de filas en el documento: ${files.length}`)
    for (let item of files) {
        overweights.push({
            tracking: item["Guía"]?.toString(),
            amount: item["Total"],
            weight: item["Peso"],
            description: item["Volumen"]
        });
    }
    //console.log(overweights)
    alert(`Total de Filas Procesadas: ${overweights.length}`)
    return overweights
}

export function findOverWeightsUPS(files: any): FileI[] {
    const overweights: FileI[] = [];
    alert(`Cantidad de filas en el documento: ${files.length}`)
    for (let item of files) {
        overweights.push({
            tracking: item["GUIA"]?.toString(),
            amount: item["TOTAL_GUIA"],
            weight: item["PESO_DIMENSIONAL"],
            description: item["SECCION"] ?? undefined
        });
    }
    //console.log(overweights)
    alert(`Total de Filas Procesadas: ${overweights.length}`)
    return overweights
}

export function findOverWeightsCoordinadora(files: any): FileI[] {
    const overweights: FileI[] = [];
    alert(`Cantidad de filas en el documento: ${files.length}`)
    for (let item of files) {
        overweights.push({
            tracking: item["remision"]?.toString(),
            amount: item["flete_total"],
            weight: item["peso_total"],
            description: item["observaciones"]
        });
    }
    //console.log(overweights)
    alert(`Total de Filas Procesadas: ${overweights.length}`)
    return overweights  
}

export function findTrackingShipments(files: any): { tracking: string }[] {
    const trackings: { tracking: string }[] = [];
    for (let item of files) {
        //console.log(item, "ver")
        trackings.push({
            tracking: item["Guias"]?.toString(),
        });

    }
    //console.log("trackings", trackings)
    return trackings
}

const filesFilter = (files: FileI[], maxFiles: number, number: number) => {
    if (number === 1) return files.filter((_, index) => (index + 1) <= maxFiles)
    return files.filter((_, index) => (index + 1) <= (maxFiles * number) && (index + 1) > (maxFiles * (number - 1)))
}

export const formatExcelFiles = (overweights: FileI[]) => {
    let filesBy200Items: any = {}
    let maxFiles = 200

    for (let i = 1; i < 12; i++) {
        if (overweights[(maxFiles * (i - 1)) + 1]) {
            filesBy200Items[`overweight_${i}`] = filesFilter(overweights, maxFiles, i)
        }

    }
    return filesBy200Items

}

export const assignCarrierFiles = (carrier: string, files: any) => {
    switch (carrier) {
        case 'fedex':
            return findOverWeightsFedex(files)
        case 'estafeta':
            return findOverWeightsEstafeta(files)
        case '99 Minutos':
            return findOverWeights99Minutos(files)
        case 'sendex':
            return findOverWeightsSendex(files)
        case 'dhl Shipandgo':
            return findOverWeightsDHL(files, 'shipandgo')
        case 'dhl Shipango':
            return findOverWeightsDHL(files, 'shipango')
        case 'dhl Shippo':
            return findOverWeightsDHL(files, 'shippo')
        case 'dhl Directo':
            return findOverWeightsDHL(files, 'directo')
        case 'dhl Pak2go':
            return findOverWeightsDHL(files, 'pak2go')
        case 'jtexpress':
            return findOverWeightsJtexpress(files)
        case 'ampm':
            return findOverWeightsAmpm(files)
        case 'envia':
            return findOverWeightsEnvia(files)
        case 'ups':
            return findOverWeightsUPS(files)
        case 'coordinadora':
            return findOverWeightsCoordinadora(files)
        default:
            //console.log("no hay carrier", carrier)
            return findTrackingShipments(files)
    }
}

export const parseCarrierNames = (carrier: string, noImg = false) => {
    if (noImg) {
        if (carrier.includes('dhl Directo')) return 'dhl directo'
        if (carrier.includes('dhl Shipango')) return 'dhl directo'
        if (carrier.includes('99 Minutos')) return 'noventa9Minutos'
    }

    if (carrier.includes('dhl')) return 'dhl'
    if (carrier.includes('99')) return 'noventa9Minutos'
    return carrier
}
