import { useForm } from 'antd/lib/form/Form'
import { useEffect } from 'react'
import { GuidePayload } from '../../interfaces/guides.interface'
import { usePost } from '../usePost'
import { generateGuide } from '../../services/generate_s'
import { GenerateGuideRes } from '../../interfaces/generate.interface'
import { notification } from 'antd'

export interface RetakeGuideValues {
  DestinyName: string
  DestinyEmail: string
  DestinyPhone: string
  DestinyCompany: string
  DestinyStreet: string
  DestinyNumber: string
  DestinyInt_number: string
  DestinyDistrict: string
  DestinyCity: string
  DestinyPostal_code: string
  DestinyState: string
  DestinyReference: string
  OriginName: string
  OriginEmail: string
  OriginPhone: string
  OriginCompany: string
  OriginStreet: string
  OriginNumber: string
  OriginInt_number: string
  OriginDistrict: string
  OriginCity: string
  OriginPostal_code: string
  OriginState: string
  OriginReference: string
}

export const useRetakeGuide = (guidePayload: GuidePayload | null) => {
  const [form] = useForm<RetakeGuideValues>()

  const { fetchData: fetchGenerateGuide, isLoading: isGeneratingGuide } = usePost<
    GuidePayload,
    GenerateGuideRes
  >(generateGuide)

  const onFinish = async (values: RetakeGuideValues) => {
    if (!guidePayload || isGeneratingGuide) return

    try {
      const dto: GuidePayload = {
        ...guidePayload,
        origin: {
          name: values.OriginName,
          email: values.OriginEmail,
          phone: values.OriginPhone,
          company: values.OriginCompany,
          street: values.OriginStreet,
          number: values.OriginNumber,
          int_number: values.OriginInt_number,
          district: values.OriginDistrict,
          city: values.OriginCity,
          postal_code: values.OriginPostal_code,
          state: values.OriginState,
          reference: values.OriginReference,
          country: guidePayload?.origin.country ?? 'MX',
          title: `${values.OriginPostal_code} - ${values.OriginState} - ${values.OriginCity}`,
        },
        destination: {
          name: values.DestinyName,
          email: values.DestinyEmail,
          phone: values.DestinyPhone,
          company: values.DestinyCompany,
          street: values.DestinyStreet,
          number: values.DestinyNumber,
          int_number: values.DestinyInt_number,
          district: values.DestinyDistrict,
          city: values.DestinyCity,
          postal_code: values.DestinyPostal_code,
          state: values.DestinyState,
          reference: values.DestinyReference,
          country: guidePayload.destination.country ?? 'MX',
          title: `${values.DestinyPostal_code} - ${values.DestinyState} - ${values.DestinyCity}`,
        },
        shipment: guidePayload.shipment,
        insurance: guidePayload.insurance,
        packages: guidePayload.packages,
        service_id: guidePayload.service_id,
        carriers: guidePayload.carriers,
        metadata: guidePayload.metadata,
        vip: guidePayload.vip,
        user: guidePayload.user,
      }

      const res = await fetchGenerateGuide(dto)

      if (!res.data) {
        notification.error({
          message: 'No se pudo realizar la guía con exito',
        })
        return
      }

      notification.success({
        message: 'Guía realizada con exito',
      })
    } catch (error) {
      notification.success({
        message: 'No se pudo realizar la guía con exito',
      })
    }
  }
  const onFinishFailed = () => {}

  useEffect(() => {
    if (!guidePayload) return

    const { destination, origin } = guidePayload

    form.setFieldsValue({
      DestinyName: destination.name,
      DestinyEmail: destination.email,
      DestinyPhone: destination.phone,
      DestinyCompany: destination.company,
      DestinyStreet: destination.street,
      DestinyNumber: destination.number,
      DestinyInt_number: destination.int_number,
      DestinyDistrict: destination.district,
      DestinyCity: destination.city,
      DestinyPostal_code: destination.postal_code,
      DestinyState: destination.state,
      DestinyReference: destination.reference,
      OriginName: origin.name,
      OriginEmail: origin.email,
      OriginPhone: origin.phone,
      OriginCompany: origin.company,
      OriginStreet: origin.street,
      OriginNumber: origin.number,
      OriginInt_number: origin.int_number,
      OriginDistrict: origin.district,
      OriginCity: origin.city,
      OriginPostal_code: origin.postal_code,
      OriginState: origin.state,
      OriginReference: origin.reference,
    })
  }, [form, guidePayload])

  return {
    form,
    isGeneratingGuide,
    onFinish,
    onFinishFailed,
  }
}
