import { apiProps, apiResponse } from "../interfaces/app.interfaces"

export const getResellers = async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: 'GET',
      endpoint: 'admin/data/resellers'
    })
  }

  export const deleteReseller =
  (Id: String) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: 'DELETE',
      endpoint: `admin/data/resellers/${Id}`,
      data: {}
    })
  }

export const getDevicesAnalytics = async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
  return await backendApiCall({
    method: 'GET',
    endpoint: 'device-analytics/analytics/devices'
  })
}