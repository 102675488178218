import { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { usePost } from "../../../../hooks/usePost";
import CardContainer from "../../../../components/Containers/CardContainer";
import { getShipmentsByType } from "../../../../services/admin_s";
import { Col, DatePicker, Row } from "antd";
import { FilterRequest } from "../../../../services/user_d";
import LoadingAnimation from "../../../../components/LoadingAnimation";

type Props = {
  country: string;
};

const ShipmentsByType = ({ country }: Props) => {
  const [ranges, setRanges] = useState<moment.Moment[]>([
    moment().startOf("month"),
    moment().endOf("day"),
  ]);
  const {
    fetchData,
    data: filteredData,
    isLoading,
  } = usePost<FilterRequest, any>(getShipmentsByType);

  const getData = useCallback(async () => {
    await fetchData({
      "origin.country": country,
      created_at: {
        $gte: ranges[0].startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        $lte: ranges[1].endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
    });
  }, [fetchData, country, ranges]);

  useEffect(() => {
    getData();
  }, [ranges]); //eslint-disable-line

  return (
    <CardContainer cardStyle={{ marginTop: "20px" }} titleStyle={{ placeItems: 'center' }} title="Tipos de envíos">
      <DatePicker.RangePicker
        onChange={(e: any) => {
          setRanges([moment(e[0]).startOf("day"), moment(e[1]).endOf("day")]);
        }}
        placeholder={["Fecha inicial", "Fecha final"]}
        value={ranges as any}
        style={{ width: "100%" }}
      />
      <hr />

      <Row>
        <Col span={3} ></Col>
        <Col span={11} style={{ textAlign: 'left', padding: '8px 0', fontWeight: "bold" }}>
          Tipo
        </Col>
        <Col span={10} style={{ textAlign: 'center', padding: '8px 0', fontWeight: "bold" }}>
          Cantidad
        </Col>
      </Row>
      {isLoading ? (
        <LoadingAnimation animationType="small" />
      ) : filteredData === undefined || filteredData?.length === 0 ? (
        <p
          style={{
            fontWeight: "bold",
            margin: "50px auto",
            color: "#0008",
          }}
        >
          No hay datos para mostrar.
        </p>
      ) : (
        filteredData
          ?.sort((a: any, b: any) => b?.count - a?.count)
          .map((item: any, index: any) => {
            return (
              <Row key={index}>
                <Col span={3} ></Col>
                <Col span={11} style={{ textAlign: 'left', padding: '8px 0' }}>{item?._id || "NA"}</Col>
                <Col span={10} style={{ textAlign: 'center', padding: '8px 0', color: "#2D1B6E", fontWeight: "bold" }}>{item?.count.toLocaleString() || 0}</Col>
              </Row>
            );
          })
      )}
    </CardContainer>
  );
};

export default ShipmentsByType;
