import { Button, Col, Form, Row, notification } from "antd"
import InputContainer from "../../components/Containers/InputContainer"
import { CustomerDataI } from "./Sells"
import { ApiContext } from "../../context/apiContext"
import { useContext } from "react"
import axios from "axios"

const AssignCustomer = (
  {
    data,
    setOpenModal,
    openModal
  }:{
    data: CustomerDataI,
    setOpenModal: (data: { type: string, open: boolean, data: object }) => void,
    openModal: { type: string, open: boolean, data: object }
  }) => {

  const { backendURL, userToken } = useContext(ApiContext)
  const [form] = Form.useForm()

  const convertSeller = (email: string) => {
    switch (email) {
      case 'hola@drenvio.com':
        return 'auth0|5e9e15e6c4e8ea0d97ac5992'
      case 'arely.vallejo@drenvio.com':
        return 'auth0|5f6a29130ae09d006fd10a1d'
      case 'valeria181811@gmail.com':
        return 'auth0|608aeee5260f9e00693d50ac'
    }
  }

  const onFinish = async(e: { seller: string, type: string }) => {
    const request = {
      customerId: data?._id ?? data.user,
      sellerId: convertSeller(e.seller),
      type: e.type
    }
    try {
      const response = await axios.post(
        `${backendURL}admin/assignCustomers`,
        request,
        { headers: { Authorization: `Bearer ${ userToken }` } }
      )
      if(response.status === 200){
        form.resetFields()
        setOpenModal({ ...openModal, open: false })
        return notification.success({
          message: "Usuario asignado correctamente"
        })
      }
      return notification.info({
        message: "No se ha podido asignar el usuario, prueba nuevamente"
      })
    } catch (error: any) {
      form.resetFields()
      if(`E11000 duplicate key error collection: Ember-Tests.admincustomers index: customerId_1 dup key: { customerId: "${data._id}" }`){
        return notification.error({
          message: "Este usuario ya ha sido asignado a un vendedor"
        })
      }
      return notification.info({
        message: "No se ha podido asignar el usuario, recarga e intenta nuevamente"
      })
    }
  }

  return (
    <>
    {
      !data ? <p>Usuario no encontrado</p> : (
        <Row>
            <Col span={24} style={{ textAlign:'center' }}>
              <p>{ data?.profile?.email ?? data.email }</p>
            </Col>
            <Col span={24}>
              <Form onFinish={onFinish} form={form}>
                <InputContainer
                  required
                  type="select"
                  placeholder="Seleccionar vendedor"
                  optionsList={[ "arely.vallejo@drenvio.com" ,"valeria181811@gmail.com", "hola@drenvio.com" ]}
                  valueContainerName="seller"
                />
                <InputContainer
                  required
                  type="select"
                  placeholder="Selecciona un tipo"
                  optionsList={[
                    "Revendedor activo con potencial",
                    "Revendedor que dejo la plataforma con potencial",
                    "Usuario normal activo",
                    "Usuario normal que dejo la plataforma con potencial",
                    "Otro"
                  ]}
                  valueContainerName="type"
                />
                <Button htmlType="submit" type="primary" style={{ width:'100%' }}>Asignar</Button>
              </Form>
            </Col>
          </Row>
        
      )
    }
    </>
  )
}

export default AssignCustomer