import { FC } from "react"
import CardContainer from "../../../../components/Containers/CardContainer"
import { getParcelImage } from "../../../../services/records"
import { FileI } from "../Overweight"
import { parseCarrierNames } from "./utils";

interface Props{
    files: FileI[];
    index: number;
    onSelectCard: () => void;
    carrier: string
}

const OverWeightCard:FC<Props> = ({files, index, onSelectCard, carrier}) =>{
    return(
        <CardContainer
        direction='horizontal'
        textList={[
            <div>
                <div>{getParcelImage(parseCarrierNames(carrier as AllCarriers)as AllCarriers)}</div>
            </div>,
            `Sobrepeso por bajar #${index + 1}`,
            `${files.length}`,
            'pendiente'
        ]}
        hoverEffect
        onClick={onSelectCard}
    />
    )
}
export default OverWeightCard