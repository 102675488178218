//Types
export interface AppI {
    statuses: Array<string>;
    errors: object;
}
export interface UserI {
    name: string;
    token: string;
    languaje: string;
    errors: object;
}

//Initial States
export const AppSchema : AppI = Object.freeze({
    statuses: [],
    errors: {}
})
export const UserSchema : UserI = Object.freeze({
    name: '',
    token: '',
    languaje: '',
    errors: {},
})

//General State
export interface StoreI {
    app: AppI;
    user: UserI;
} 