import { Col, Row, Tooltip } from 'antd'
import BarChart from '../../components/Common/BarChart'
import { useGet } from '../../hooks/useGet'
import { getDevicesAnalytics } from '../../services/data_s'
import CardContainer from '../../components/Containers/CardContainer';
import { LaptopOutlined, SmartphoneOutlined } from '@material-ui/icons';
import MostUsedResolutions, { ResolutionUtilizationI } from './MostUsedResolutions';

interface DevicesAnalyticsI {
  DevicesUtilization: [];
  BrowserUtilization: [];
  ResolutionUtilization: ResolutionUtilizationI[];
  OSUtilization: [];
  AverageDevices: number;
}

const DevicesAnalytics = () => {

  const { data, isLoading } = useGet<DevicesAnalyticsI>(getDevicesAnalytics)

  const getDeviceName = (_id: string) => {
    switch (_id) {
      case "Desktop":
        return (
          <Tooltip title="Computador / Laptop">
            <LaptopOutlined style={{ cursor:'pointer' }}/>
          </Tooltip>
        )
      case "Mobile":
        return (
          <Tooltip title="Dispositivo Móvil">
            <SmartphoneOutlined style={{ cursor:'pointer' }}/>
          </Tooltip>
        )
      default:
        return _id
    }
  }

  return (
    <section className='dashboardSection'>
      <Row gutter={[10,10]}>
        <Col sm={24} md={8} lg={8}>
          <CardContainer title='Dispositivos utilizados'>
            <Row>
            {
              isLoading 
              ? 'Cargando...'
              : data?.DevicesUtilization.map((item: any) => {
                return (
                    <Col sm={24} md={12} lg={12}>
                      { getDeviceName(item?._id || "NA")  } { item?.count || 0 }
                    </Col>
                )
              })
            }
            </Row>
          </CardContainer>
        </Col>
        <Col sm={12} md={8} lg={8}>
          <CardContainer title='Uso de dispositivos'>
            {
              isLoading ? 'Cargando...' : `Los usuarios usan en promedio ${data?.AverageDevices} dispositivos`
            }
          </CardContainer>
        </Col>
        <Col sm={24} md={12} lg={12}>
            <BarChart title='Sistemas operativos utilizados' data={data ? data?.OSUtilization as object[] : []} type='bar' isLoading={isLoading} includeCard={true} />
        </Col>
        <Col sm={24} md={12} lg={12}>
            <BarChart title='Navegadores más utilizados' data={data ? data?.BrowserUtilization as object[] : []} type='bar' isLoading={isLoading} includeCard={true} />
        </Col>
        <Col sm={24} md={8} lg={8}>
          <MostUsedResolutions data={data?.ResolutionUtilization as ResolutionUtilizationI[]} isLoading={isLoading} />
        </Col>
      </Row>
    </section>
  )
}

export default DevicesAnalytics