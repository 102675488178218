import React, { useRef, useState } from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { ReferralsColumnsType } from '../../../../interfaces/partner.interface'
import { Tooltip, Typography, notification } from 'antd'
import { CloseSquareOutlined, LoadingOutlined, MoreOutlined } from '@ant-design/icons'
import { t } from 'i18next'

const ReferralActions: React.FC<{ 
    rowData: ReferralsColumnsType, 
    deleteReferral: (id: string) => any,
    activateDeleteReferral: boolean,
    setActivateDeleteReferral: (state: boolean) => void
}> = ({ 
    rowData,
    deleteReferral,
    activateDeleteReferral,
    setActivateDeleteReferral
})=> {
    const [loading, setLoading] = useState<boolean>(false)
    const [toggle, setToggle] = useState<boolean>(false)
    const ref = useRef(null)

    const handleFetching = async ()=> {
        setLoading(true)
        const rta = await deleteReferral(rowData?._id)

        if (rta?.code === 500){
            notification.success({
                message: t('Referrals.Table.Options.DeleteReferral.Notification.Error')
            })
            setLoading(false)
            return 
        }

        if (rta?.code === 200){
            notification.success({
                message: t('Referrals.Table.Options.DeleteReferral.Notification.Success')
            })
            setLoading(false)
            setActivateDeleteReferral(!activateDeleteReferral)
        }
    }

    return (
        <div>
            <Tooltip title={t('Referrals.Table.Options.Tooltip')}>
                <IconButton
                    disabled={false}
                    onClick={()=> setToggle(true)}
                    ref={ref}
                >
                    <MoreOutlined />
                </IconButton>
            </Tooltip>
            <div>
                <Menu
                    open={toggle}
                    anchorEl={ref.current}
                    onClick={()=> setToggle(!toggle)}
                >
                    <MenuItem
                        onClick={async ()=> {
                            setToggle(false)
                            await handleFetching()
                        }}
                    >
                        {loading ? (
                            <LoadingOutlined style={{ marginRight: '5px' }} />
                        ) : (
                            <CloseSquareOutlined style={{ marginRight: '5px' }} />
                        )}
                        <Typography>{t('Referrals.Table.Options.DeleteReferral')}</Typography>
                    </MenuItem>
                </Menu>
            </div>
        </div>
    )
}

export default ReferralActions