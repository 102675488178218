import { Col, Row, Collapse, DatePicker } from "antd";
import React, { useEffect, useState } from "react";

import Fedex from "../../../../assets/images/ParcelDashboard/fedex.webp";
import Ampm from "../../../../assets/images/ParcelDashboard/ampm.webp";
import Borzo from "../../../../assets/images/ParcelDashboard/borzo.png";
import Estafeta from "../../../../assets/images/ParcelDashboard/estafeta.webp";
import Dhl from "../../../../assets/images/ParcelDashboard/dhl.webp";
import Ups from "../../../../assets/images/ParcelDashboard/ups.webp";
import Carssa from "../../../../assets/images/ParcelDashboard/carssa.png";
import Tresguerras from "../../../../assets/images/ParcelDashboard/tresguerras.png";
import Coordinadora from "../../../../assets/images/ParcelDashboard/coordinadora.png";
import Deprisa from "../../../../assets/images/ParcelDashboard/deprisa.png";
import Interrapidisimo from "../../../../assets/images/ParcelDashboard/interrapidisimo.png";
import Jtexpress from "../../../../assets/images/ParcelDashboard/jtexpress.webp";
import Noventa9minutos from "../../../../assets/images/ParcelDashboard/noventa9minutos.webp";
import MensajerosUrbanos from "../../../../assets/images/ParcelDashboard/mensajerosUrbanos.png";
import Paquetexpress from "../../../../assets/images/ParcelDashboard/paquetexpress.webp";
import Pickit from "../../../../assets/images/ParcelDashboard/pickit.png";
import Ivoy from "../../../../assets/images/ParcelDashboard/ivoy.png";
import Quiken from "../../../../assets/images/ParcelDashboard/quiken.webp";
import Scm from "../../../../assets/images/ParcelDashboard/scm.png";
import Servientrega from "../../../../assets/images/ParcelDashboard/servientrega.png";
import Treggo from "../../../../assets/images/ParcelDashboard/treggo.png";
import Tracusa from "../../../../assets/images/ParcelDashboard/tracusa.png";
import Vencedor from "../../../../assets/images/ParcelDashboard/vencedor.png";
import Uber from "../../../../assets/images/ParcelDashboard/uber.png";
import Entrega from "../../../../assets/images/ParcelDashboard/entrega.png";
import Moova from "../../../../assets/images/ParcelDashboard/moova.png";
import TestDrenvio from "../../../../assets/images/ParcelDashboard/testDrenvio.png";
import Sendex from "../../../../assets/images/ParcelDashboard/sendex.webp";

import CardContainer from "../../../../components/Containers/CardContainer";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { t } from "i18next";
import moment from "moment";
import { getShipmentsPerMonth } from "../../../../services/admin_s";
import { useGet } from "../../../../hooks/useGet";

type Props = {
  country: string;
};

const Panel = Collapse.Panel;

const getImageByName = (carrier: string) => {
  switch (carrier.toLowerCase()) {
    case "fedex":
      return Fedex;
    case "ampm":
      return Ampm;
    case "borzo":
      return Borzo;
    case "estafeta":
      return Estafeta;
    case "dhl":
      return Dhl;
    case "ups":
      return Ups;
    case "carssa":
      return Carssa;
    case "tresguerras":
      return Tresguerras;
    case "coordinadora":
      return Coordinadora;
    case "deprisa":
      return Deprisa;
    case "interrapidisimo":
      return Interrapidisimo;
    case "jtexpress":
      return Jtexpress;
    case "noventa9minutos":
      return Noventa9minutos;
    case "mensajerosurbanos":
      return MensajerosUrbanos;
    case "paquetexpress":
      return Paquetexpress;
    case "pickit":
      return Pickit;
    case "ivoy":
      return Ivoy;
    case "quiken":
      return Quiken;
    case "scm":
      return Scm;
    case "servientrega":
      return Servientrega;
    case "treggo":
      return Treggo;
    case "tracusa":
      return Tracusa;
    case "vencedor":
      return Vencedor;
    case "uber":
      return Uber;
    case "entrega":
      return Entrega;
    case "moova":
      return Moova;
    case "sendex":
      return Sendex;
    default:
      return null; // Devuelve null si no se encuentra la imagen
  }
};

const getProviderName = (code: string) => {
  switch (code) {
    // case "D-C01":
    case "directo-drenvio":
    case "directo_drenvio":
    case "directo_drevsto":
    case "ups":
      return "Drenvio"
    case "directo-drevsto":
      return "Drevsto"
    case "directo-web360":
    case "directo_web360":
      return "Web360"
    case "nextshipping":
      return "NS"
    case "globalpaq":
      return "GB"
    case "shippo":
      return "SH"
    case "shipango":
      return "SAG"
    case "t1envios-web360":
      return "T1 Web360"
    case "t1envios-drevsto":
      return "T1 Drevsto"
    case "quiken":
      return "Quiken"
    case "pack2go-drevsto":
      return "P2G Drevsto"
    case "pack2go-web360":
      return "P2G Web360"
    case "envia":
      return "Envia"
    default:
      break;
  }
};

export const ShipmentsMonth = ({ country }: Props) => {
  const [ranges, setRanges] = useState<moment.Moment[]>([
    moment().startOf("month"),
    moment().endOf("day"),
  ]);

  const { refetch, data, isLoading } = useGet<[]>(getShipmentsPerMonth(country, ranges[0].startOf("day").format("YYYY-MM-DD HH:mm:ss"), ranges[1].endOf("day").format("YYYY-MM-DD HH:mm:ss")));

  // const getData = async () => {
  //   // const response = await fetchData({
  //   //   "country": country,
  //   //   created_at: {
  //   //     $gte: ranges[0].startOf("day").format("YYYY-MM-DD HH:mm:ss"),
  //   //     $lte: ranges[0].startOf("day").format("YYYY-MM-DD HH:mm:ss"),
  //   //   },
  //   // });
  //   console.log('response ---------------------------------------------------------------->', response)
  // };

  // console.log('getData -------------->', data)

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ranges, country]);

  return (
    <CardContainer title="Proveedores más usados este Mes" cardStyle={{ marginTop: "20px" }} titleStyle={{ placeItems: 'center' }}>
      <DatePicker.RangePicker
        onChange={(e: any) => {
          setRanges([moment(e[0]).startOf("day"), moment(e[1]).endOf("day")]);
        }}
        placeholder={["Fecha inicial", "Fecha final"]}
        value={ranges as any}
        style={{ width: "100%" }}
      />
      <hr />
      {
        data && data.length > 0 && (
          <Row>
            <Col span={15} style={{ margin: "auto", fontWeight: "bold" }}>
              <p>Paqueteria</p>
            </Col>
            <Col span={9} style={{ margin: "auto", fontWeight: "bold", textAlign: "center" }}>
              <p>Total</p>
            </Col>
          </Row>
        )
      }
      <Row style={{ maxHeight: "36vh", overflowY: "auto" }}>
        {isLoading ? (
          <LoadingAnimation animationType="small" />
        ) : !data || data.length === 0 ? (
          <p
            style={{
              fontWeight: "bold",
              margin: "50px auto",
              color: "#0008",
            }}
          >
            No hay datos para mostrar.
          </p>
        ) : (
          !isLoading && data.length > 0 && data
            .sort((a: any, b: any) => b?.count - a?.count)
            .map((item: any) => {
              return (
                <>
                  <Col
                    span={15}
                    style={{
                      display: "flex",
                    }}
                  >
                    <img
                      style={{ width: "40px", height: "40px" }}
                      alt={item.carrier}
                      src={getImageByName(item.carrier) ?? TestDrenvio}
                    />
                    <Collapse
                      bordered={false}
                      defaultActiveKey={["1"]}
                      style={{ background: "none" }}
                      accordion={true}
                      ghost={true}
                    >
                      <Panel header={<div>{t(`Quote.Service.Carrier.${(item.carrier)}`)}</div>} key={item._id}>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <p style={{ margin: 0, fontWeight: "bolder" }}>
                            Proveedor
                          </p>
                          <p style={{ margin: 0, fontWeight: "bolder", textAlign: "center", paddingLeft: 10 }}>
                            Cantidad
                          </p>
                          {item.suppliers
                            .sort((a: any, b: any) => b.count - a.count)
                            .map((supplier: any) => (
                              <React.Fragment key={getProviderName(supplier.supplier)}>
                              <div style={{ display: "contents" }}>
                                <div style={{ borderBottom: "1px solid #ccc" }}>
                                  <p style={{ margin: 0 }}>
                                    {getProviderName(supplier.supplier)}
                                  </p>
                                </div>
                                <div style={{
                                  borderBottom: "1px solid #ccc",
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}>
                                  <p style={{ margin: 0 }}>
                                    {supplier.count.toLocaleString()}
                                  </p>
                                </div>
                              </div>
                            </React.Fragment>
                            ))}
                        </div>
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col span={9} style={{ color: "#2D1B6E", textAlign: "center", fontWeight: "bold", paddingTop: 10 }} >{item.count.toLocaleString()}</Col>
                </>
              );
            })
        )}
      </Row>
    </CardContainer>
  );
};
