import { Col, Layout, Row, Input, Form, notification } from 'antd'
import CardContainer from '../../components/Containers/CardContainer'
import Text from 'antd/lib/typography/Text'
import InputContainer from '../../components/Containers/InputContainer'
import Title from 'antd/lib/typography/Title'
import { t } from 'i18next'
import { usePost } from '../../hooks/usePost'
import { sendDrEvioLog } from '../../services/information_s'

const ErrorReport = () => {
  const { TextArea } = Input
  const [form] = Form.useForm()

  const { fetchData } = usePost(sendDrEvioLog)

  const onFinish = async(e: any) => {
    const request = {
      message:e.report,
      component:'Error en front-end admin'
    }
    const { error, data } = await fetchData(request)
    console.log(data, error)
    if (!error) {
      notification.success({ message: 'Reporte enviado correctamente.' })
      form.resetFields()
    } else {
      notification.error({ message: 'Ha ocurrido un error, intenta nuevamente' })
    }
  }

  const onFinishFailed = async(e: any) => {
    console.log(e)
    notification.error({ message: 'Ha ocurrido un error, intenta nuevamente' })
  }

  return (
    <Layout style={{ height: '100vh' }} className={'align-items-center justify-content-center'}>
      <Row className="justify-content-center">
        <Col
          xs={20}
          xl={18}
          className={'d-flex flex-column align-items-center'}
          style={{ width: '100%' }}
        >
          <img
            alt="drenvio-logo"
            src="https://firebasestorage.googleapis.com/v0/b/drenvio-8741f.appspot.com/o/logo-drenvio-sweep.gif?alt=media&amp;token=fde47609-bca9-400f-aca1-e26dc6a403b1"
            style={{ maxWidth: '250px', justifyContent: 'center' }}
          />
          <CardContainer>
            <Title level={4}>{t('Report.Title')}</Title>
            <Text type="secondary" style={{ fontSize: '0.7rem', marginTop: '10px' }}>
              {t('Report.advertence')}
            </Text>
            <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Form.Item name='report'>
                <TextArea
                  rows={6}
                  style={{ marginTop: '10px' }}
                  placeholder={t('Report.placeHolder')}
                />
              </Form.Item>
              <InputContainer
                type="submitButton"
                style={{ textAlign: 'center', fontSize: '0.5rem', marginTop: '10px' }}
                title={t('Report.summbitReport')}
              />
            </Form>
          </CardContainer>
        </Col>
      </Row>
    </Layout>
  )
}

export default ErrorReport
