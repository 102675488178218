import { t } from 'i18next'
import moment from 'moment'
import { Dispatch, FC, SetStateAction } from 'react'
import { Button, Calendar, DatePicker, Menu, notification } from 'antd'
import { useFilterByDate } from '../../hooks/useFilterByDate'

export const enum FilterByDateRangeKeys {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  THIS_YEAR = 'THIS_YEAR',
  CUSTOM = 'CUSTOM'
}

export const FilterByDate: FC<Props> = ({ getRangeFromOutside, onApplyFilter, getRangeTag }) => {
  const { rangeInText, setRangeInText, range, setRange } = useFilterByDate()

  const isCustomRange = rangeInText === FilterByDateRangeKeys.CUSTOM
  const { RangePicker } = DatePicker

  const dateFullCellRender = (value: any) => {
    const isStartOfRange = moment(value).isSame(range[0], 'day');
    const isEndOfRange = moment(value).isSame(range[1], 'day');
    const isInRange =
    range.length === 2 &&
      moment(value).isSameOrAfter(range[0], 'day') &&
      moment(value).isSameOrBefore(range[1], 'day');

    const cellStyle = {
      color: isInRange && (isStartOfRange || isEndOfRange) ? 'white' : 'black', 
      background: isStartOfRange || isEndOfRange ? '#1890ff' : isInRange ? '#E6F7FF' : 'white', 
      borderTopLeftRadius: isStartOfRange ? '4px' : '0',
      borderBottomLeftRadius: isStartOfRange ? '4px' : '0',
      borderTopRightRadius: isEndOfRange ? '4px' : '0',
      borderBottomRightRadius: isEndOfRange ? '4px' : '0',
    };

    return (
      <div className="calendar-cell" style={cellStyle}>
        {value.date()}
      </div>
    );
  };

  const handleTableMenu = (menuType: string) => {
    switch (menuType){
        case 'Hoy':
          return t('Shipments.Filters.Tabs.Date.DatePicker.Today')
        case 'Ayer':
          return t('Shipments.Filters.Tabs.Date.DatePicker.Yesterday')
        case 'Últimos 7 días':
          return t('Shipments.Filters.Tabs.Date.DatePicker.Last7Days')
        case 'Últimos 30 días':
          return t('Shipments.Filters.Tabs.Date.DatePicker.Last30Days')
        case 'Este año':
          return t('Shipments.Filters.Tabs.Date.DatePicker.ThisYear')
        case 'Rango personalizado':
          return t('Shipments.Filters.Tabs.Date.DatePicker.CustomRange')
    } 
  }

  return (
    <div>
      <div className='containerFiltersDate'>
        <Menu
          mode="inline"
          defaultSelectedKeys={[FilterByDateRangeKeys.TODAY]}
          className='menuFiltersDate'
        >
          {menu.map((m) => (
            <Menu.Item
              style={{ marginTop: 0 }}
              key={m.range}
              onClick={() => setRangeInText(m.range)}
            >
              {handleTableMenu(m.title)}
            </Menu.Item>
          ))}
        </Menu>
        <div style={{ width: '100%', overflow: 'hidden' }}>
          {isCustomRange ? (
            <div className='rangepicker'>
              {window.innerWidth <= 375 ? (
                <div>
                    <DatePicker
                    className='picker'
                    placeholder={t('Shipments.Filters.Tabs.Date.RangePicker.InitialDate')}
                    style={{ width: '100%' }}
                    onChange={(val) => {
                      if (!val) return
                      setRange((prev) => {
                        const temp = [...prev]
                        temp[0] = val.toDate()
                        return temp as [Date | null, Date | null]
                      })
                    }}
                    format="DD/MM/YYYY"
                  />
                    <DatePicker
                    className='picker'
                    placeholder={t('Shipments.Filters.Tabs.Date.RangePicker.FinalDate')}
                    style={{ width: '100%' }}
                    onChange={(val) => {
                      if (!val) return
                      setRange((prev) => {
                        const temp = [...prev]
                        temp[1] = val.toDate()
                        return temp as [Date | null, Date | null]
                      })
                    }}
                    format="DD/MM/YYYY"
                  />
                </div>
              ) : (
                <RangePicker
                className='picker'
                placeholder={[t('Shipments.Filters.Tabs.Date.RangePicker.InitialDate'), t('Shipments.Filters.Tabs.Date.RangePicker.FinalDate')]}
                style={{ width: '100%' }}
                onChange={(val) => {
                  if (!val?.length) return
                  setRange([val[0]!.toDate(), val[1]!.toDate()])
                }}
                format="DD/MM/YYYY"
              />
              )}
            </div>
          ) : (
            <div style={{ width: '100%', border: `1px solid 1px`, borderRadius: 6 }}>
              <Calendar
                dateFullCellRender={dateFullCellRender}
                headerRender={() => null}
                mode="month"
                fullscreen={false}
                onPanelChange={() => null}
                validRange={[moment(range[0]), moment(range[1])]}
              />
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          textAlign: 'end'
        }}
      >
        {getRangeTag ? (
          <Button
            className='applyDate-button'
            onClick={() => {
              if (!range[0] || !range[1]){
                notification.warning({
                  message: t('Filters.Notifications.Warning')
                })
                return 
              }
              getRangeTag(range)
              getRangeFromOutside(range)
              onApplyFilter()
            }}
          >
            {t('Table.Filters.Apply')}
          </Button>
        ): (
          <Button
          className='applyDate-button'
            onClick={() => {
              getRangeFromOutside(range)
              onApplyFilter()
            }}
          >
            {t('Table.Filters.Apply')}
          </Button>
        )}
      </div>
    </div>
  )
}

type Props = {
  getRangeFromOutside: Dispatch<SetStateAction<[Date | null, Date | null]>>
  onApplyFilter: () => void
  getRangeTag?: (range: [Date | null, Date | null]) => void 
}

class MenuFactory {
  constructor(readonly title: string, readonly range: FilterByDateRangeKeys) {}
}

const menu = [
  new MenuFactory('Hoy', FilterByDateRangeKeys.TODAY),
  new MenuFactory('Ayer', FilterByDateRangeKeys.YESTERDAY),
  new MenuFactory('Últimos 7 días', FilterByDateRangeKeys.LAST_7_DAYS),
  new MenuFactory('Últimos 30 días', FilterByDateRangeKeys.LAST_30_DAYS),
  new MenuFactory('Este año', FilterByDateRangeKeys.THIS_YEAR),
  new MenuFactory('Rango personalizado', FilterByDateRangeKeys.CUSTOM)
]
