import { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { usePost } from "../../../../hooks/usePost";
import CardContainer from "../../../../components/Containers/CardContainer";
import { getShipmentsByWeight } from "../../../../services/admin_s";
import { Col, DatePicker, Row, Select } from "antd";
import { FilterRequest } from "../../../../services/user_d";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { t } from "i18next";

type Props = {
    country: string;
};

const ShipmentsByWeight = ({ country }: Props) => {
    const [ranges, setRanges] = useState<moment.Moment[]>([
        moment().startOf("month"),
        moment().endOf("day"),
    ]);
    const [selectedType, setSelectedType] = useState<string>("general");
    const [selectedService, setSelectedService] = useState<string | null>(null);
    const {
        fetchData,
        data: filteredData,
        isLoading,
    } = usePost<FilterRequest, any>(getShipmentsByWeight);

    const getData = useCallback(async () => {
        await fetchData({
            "origin.country": country,
            created_at: {
                $gte: ranges[0].startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                $lte: ranges[1].endOf("day").format("YYYY-MM-DD HH:mm:ss"),
            },
        });
    }, [fetchData, country, ranges]);

    useEffect(() => {
        getData();
    }, [ranges, country]); //eslint-disable-line

    useEffect(() => {
        setSelectedType("general");
        setSelectedService(null);
    }, [ranges, country]);

    useEffect(() => {
        if (selectedType === "general") {
            setSelectedService(null);
        } else {
            const generalService = filteredData?.carriers?.find((carrier: any) => carrier.carrier === selectedType)?.services[0]?.service;
            setSelectedService(generalService || null);
        }
    }, [selectedType, filteredData]);

    const handleTypeChange = (value: string) => {
        setSelectedType(value);
        setSelectedService(value === "general" ? null : "general");
    };

    const handleServiceChange = (value: string) => {
        setSelectedService(value);
    };

    const renderData = () => {
        if (isLoading) {
            return <LoadingAnimation animationType="small" />;
        }

        const data = selectedType === "general"
            ? filteredData?.general || []
            : filteredData?.carriers?.find((carrier: any) => carrier.carrier === selectedType)?.services.find((service: any) => service.service === selectedService)?.weights || [];

        if (data.length === 0) {
            return (
                <p
                    style={{
                        fontWeight: "bold",
                        margin: "50px auto",
                        color: "#0008",
                    }}
                >
                    No hay datos para mostrar.
                </p>
            );
        }

        return data
            .sort((a: any, b: any) => a?.weight - b?.weight)
            .map((item: any, index: any) => (
                <Row key={index} style={{ display: 'flex', alignItems: 'center' }}>
                    <Col span={10} style={{ textAlign: 'center', borderBottom: "1px solid #ccc" }}>
                        {item?.weight ? `${item.weight} kg` : "NA"}
                    </Col>
                    <Col span={14} style={{ textAlign: 'center', color: "#2D1B6E", fontWeight: "bold", borderBottom: "1px solid #ccc" }}>
                        {item?.count.toLocaleString() || 0}
                    </Col>
                </Row>
            ));
    };

    return (
        <CardContainer cardStyle={{ marginTop: "20px" }} titleStyle={{ placeItems: 'center' }} title="Peso de envíos">
            <DatePicker.RangePicker
                onChange={(e: any) => {
                    setRanges([moment(e[0]).startOf("day"), moment(e[1]).endOf("day")]);
                }}
                placeholder={["Fecha inicial", "Fecha final"]}
                value={ranges as any}
                style={{ width: "100%" }}
            />
            <Select
                value={selectedType}
                style={{ width: selectedType === "general" ? '100%' : '50%', textAlign: 'center' }}
                onChange={handleTypeChange}
            >
                <Select.Option value="general" style={{ textAlign: 'center' }}>General</Select.Option>
                {filteredData?.carriers?.map((carrier: any) => (
                    <Select.Option key={carrier.carrier} value={carrier.carrier} style={{ textAlign: 'center' }}>
                        {t(`Quote.Service.Carrier.${carrier.carrier}`)}
                    </Select.Option>
                ))}
            </Select>
            {selectedType !== "general" && (
                <Select
                    value={selectedService || "general"}
                    style={{ width: '50%', textAlign: 'center' }}
                    onChange={handleServiceChange}
                >
                    {filteredData?.carriers?.find((carrier: any) => carrier.carrier === selectedType)?.services.map((service: any) => (
                        <Select.Option key={service.service} value={service.service} style={{ textAlign: 'center' }}>
                            {t(`Quote.Service.${service.service}`)}
                        </Select.Option>
                    ))}
                </Select>
            )}
            <hr />
            <Row>
                <Col span={10} style={{ textAlign: 'center', paddingBottom: '8px', fontWeight: "bold" }}>
                    Peso
                </Col>
                <Col span={14} style={{ textAlign: 'center', paddingBottom: '8px', fontWeight: "bold" }}>
                    Cantidad
                </Col>
            </Row>
            <div
                style={{
                    maxHeight: "33vh",
                    overflowY: "auto",
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0'
                }}
            >
                {renderData()}
            </div>
        </CardContainer>
    );
};

export default ShipmentsByWeight;
