import { FC } from "react";
import ModalContainer, { modalSizes } from "../../../../../components/Containers/ModalContainer"
import { Row } from "antd";
import SectionsMenu from "../SectionsMenu";
import { prefrencesMenu } from "../../interfaces";

interface props {
    visible: boolean;
    manageModal: () => void;
    handleMenu: (menuItem: prefrencesMenu) => void;
}

const SectionsMenuModal: FC<props> = ({ visible, manageModal, handleMenu }) => {
    return (
        <ModalContainer
            title={"Navegación del menú"}
            visible={visible}
            onCancel={manageModal}
            width={modalSizes.BIG}
            footer={<></>}
        >
            <Row>
                <SectionsMenu handleMenu={handleMenu} changeModal={manageModal}  />
            </Row>
        </ModalContainer>
    )
}

export default SectionsMenuModal