import { Row, Popconfirm, Button, Spin, Col, Steps, notification, Alert } from "antd"
import CardContainer from "../../../components/Containers/CardContainer"
import ModalContainer, { modalSizes, modalHeight } from "../../../components/Containers/ModalContainer"
import ConfigData from "./ConfigData"
import FormData from "./FormData"
import EditForm from "./EditForm"
import InstructionsData from "./InstructionsData"
import { getStoreImg } from "./config"
import {
    DeleteOutlined,
    EditOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons'
import { FC, useEffect, useState } from "react"
import { useDelete } from "../../../hooks/useDelete"
import { deleteConfigIntegrations } from "../../../services/configuration_s"
const { Step } = Steps;

const styles = {
    centerButton: { display: 'flex', alignItems: 'center' },
    popconfirm: { color: 'red' },
    spin: { width: "100%" }
}

interface props {
    isModalOpen: boolean;
    openModal: () => void;
    editMode: boolean;
    currentStore: any;
    currentStep: number;
    refetch: () => void;
    changeStep: (step: number) => void;
    changeEditMode: () => void;
    changeCurrentStore: (store: any) => void;
}

const StoreModal: FC<props> = ({
    editMode,
    currentStore,
    currentStep,
    changeEditMode,
    openModal,
    isModalOpen,
    changeStep,
    changeCurrentStore,
    refetch
}) => {
    const [imgStore, setImgStore] = useState(getStoreImg(currentStore?.type))
    const { fetchDataDelete } = useDelete(deleteConfigIntegrations)

    useEffect(() => {
        if (currentStore?.type) {
            setImgStore(getStoreImg(currentStore?.type))
        }

    }, [currentStore])

    const deleteStore = async () => {
        try {
            await fetchDataDelete(currentStore.type)

            notification.success({
                message:
                    'Integración Eliminada correctamente'
            })
            refetch()
            openModal()
            changeCurrentStore(null)

        } catch (error) {
            console.log(error)
            notification.error({
                message:
                    'La solicitud ha fallado.'
            })
        }
    }

    return (
        <ModalContainer
            size={modalSizes.BIG}
            height={modalHeight.MID}
            title={currentStore?.label}
            visible={isModalOpen}
            onCancel={openModal}
            footer={
                <Row justify="end">
                    <Popconfirm
                        placement="topLeft"
                        cancelButtonProps={{ type: 'primary', ghost: true }}
                        okButtonProps={{ danger: true }}
                        title={'Está seguro de eliminar esta integración?'}
                        onConfirm={deleteStore}
                        icon={<QuestionCircleOutlined style={styles.popconfirm} />}
                        okText="Si"
                        cancelText="No"
                    >
                        <Button
                            style={styles.centerButton}
                            icon={<DeleteOutlined />}
                            danger
                        >
                            {'Eliminar'}
                        </Button>
                    </Popconfirm>
                    <Button
                        onClick={changeEditMode}
                        style={styles.centerButton}
                        icon={<EditOutlined />}
                        type="primary"
                        ghost
                    >
                        {editMode ? 'Finalizar edición' : 'Modificar'}
                    </Button>
                    <Button onClick={openModal}>
                        Cerrar
                    </Button>
                </Row>
            }
        >
            <Spin spinning={false} style={styles.spin}>
                <Row gutter={[20, 20]}>
                    {
                        currentStore?.status === 'INACTIVE' && (
                            <Col span={24}>
                                <Alert
                                    type="warning"
                                    message="Esta integración está deshabilitada, no se verá reflejada a los clientes hasta que se active."
                                    showIcon
                                    style={{ borderRadius: '10px' }}
                                />
                            </Col>
                        )
                    }
                    <Col span={24}>
                        <CardContainer
                            iconOrImg={
                                <img
                                    alt={"logo de paquetería"}
                                    src={imgStore}
                                    style={{ width: "200px" }}
                                />
                            }
                            contrastColor="transparent"
                        />
                    </Col>
                    {
                        !editMode && (
                            <>
                                <Steps
                                    type="navigation"
                                    size="small"
                                    current={currentStep}
                                    onChange={(e) => changeStep(e)}
                                    className="site-navigation-steps"
                                >
                                    <Step
                                        title={'Configuración'}
                                        status="process"
                                    />
                                    <Step
                                        title={'Formulario'}
                                        status="process"
                                    />
                                    <Step
                                        title={'Instrucciones'}
                                        status="process"
                                    />
                                </Steps>
                                {
                                    currentStep === 0 ? (
                                        <ConfigData
                                            currentStore={currentStore}
                                        />
                                    ) : currentStep === 1 ? (
                                        <FormData
                                            currentStore={currentStore}
                                        />
                                    ) : (
                                        <InstructionsData
                                            currentStore={currentStore}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                    {
                        editMode && <EditForm
                            currentStore={currentStore}
                            editMode={editMode}
                            refetch={refetch}
                            changeCurrentStore={changeCurrentStore}
                        />
                    }
                </Row>
            </Spin>
        </ModalContainer>
    )
}

export default StoreModal