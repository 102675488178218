import { PeopleAlt } from '@material-ui/icons';
import { Tag } from 'antd'
import { useState } from 'react'
import ModalContainer from '../../../../components/Containers/ModalContainer';
import Tracing from '../../../MarketingSection/Tracing';

const NewUsers = () => {

    const [openModal, setOpenModal] = useState(false)

  return (
    <>
        <Tag color='blue' onClick={() => setOpenModal(true)} style={{ display:'flex', alignItems:'center', cursor:'pointer', margin:'5px 0' }}>
            <PeopleAlt />
            <small>Ver usuarios</small>
        </Tag>
        <ModalContainer
            visible={openModal}
            title="Análisis de nuevos usuarios"
            footer={[<></>]}
            onCancel={() => setOpenModal(false)}
            customWidth='80%'
        >
            <Tracing />
        </ModalContainer>
    </>
  )
}

export default NewUsers