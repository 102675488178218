// Libraries
import React, { useContext, useEffect, useState } from 'react'

// Contexts
import { AdminDataContext } from '../../../context/adminContext'

// Components
import { Table, TableFilter } from '../../../components/Common/Table'
import { myShipmentsColumn } from '../../../services/records'

// Icons and Styling
import { useGet } from '../../../hooks/useGet'
import { getAllShipments } from '../../../services/shipments_s'
import { ShipmentsTableResponse } from '../../../interfaces/shipment-table.interface'
import { tableShipmentsMapper } from '../../../utils/mappers/tables/shipments-table.mapper'
import { setDebouncedValue } from '../../../utils/string'
import { useToggle } from '../../../hooks/useToggle'
import { TableFilterModal } from '../../../components/Sections/TableFilterModal'
import { ByPriceTypes } from '../../../components/Sections/TablePriceFilter'
import { allCarriers } from '../../../utils/ArrayCarrier'


const ITEMS_PER_PAGES = 100

const Shipments: React.FC = () => {
  const { country } = useContext(AdminDataContext)
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState<TableFilter | null>(null)
  const { state, toggle } = useToggle()
  const [rowsPerPages, setRowPerPages] = useState(5)
  const [currentPage, setCurrentPage] = useState(5)
  const [topLimit, setTopLimit] = useState(ITEMS_PER_PAGES)

  const [rangeDate, setRangeDate] = useState<string[] | null>(null)
  const [priceFilter, setPriceFilter] = useState<ByPriceTypes | null>(null)

  const [carrierSelected, setCarrierSelected] = useState<AllCarriers | null>(null)

  const { data, isLoading, refetch } = useGet<ShipmentsTableResponse>(
    getAllShipments({
      country,
      date: rangeDate?.length ? `${rangeDate[0]}~${rangeDate[1]}` : 'none',
      price: 'none',
      from: '0',
      to: `${topLimit}`,
      word: search ? search : 'none',
    })
  )


  useEffect(() => {
    refetch()
  }, [search, rangeDate, topLimit, country]) //eslint-disable-line

  useEffect(() => {
    if (!data?.length) return

    const totalPages = (data?.length ?? 0) / rowsPerPages

    if (currentPage + 1 < totalPages) return

    setTopLimit((prevState) => prevState + ITEMS_PER_PAGES)
  }, [currentPage]) //eslint-disable-line

  const shipmentsFilteredByCarrier = carrierSelected
    ? data?.filter((s) => s.carrier === carrierSelected)
    : data

  const sortedShipmentsByCarrier = priceFilter
    ? shipmentsFilteredByCarrier?.sort((a, b) =>
        priceFilter === 'highest' ? b.price - a.price : a.price - b.price
      )
    : shipmentsFilteredByCarrier

  const shipmentsMapped =
    sortedShipmentsByCarrier?.map((s) => tableShipmentsMapper(s, country)) ?? []

    console.log('Shipments Mapped: ',shipmentsMapped)
  return (
    <>
      <Table
        data={shipmentsMapped}
        isLoading={isLoading}
        columns={myShipmentsColumn(true)}
        onSearchChange={(e) => setDebouncedValue(e, setSearch)}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(row) => setRowPerPages(row)}
        title='Envios más recientes'
        // onSearchChange={(word) => WordSearcherOptimizer(word)}
        // onChangeRowsPerPage={(rows) => setCurrentRowsPerPage(rows)}
        setFilter={(filter) => {
          setFilter(filter)
          toggle()
        }}
        filters={['date', 'price', 'carrier']}
      />

      <TableFilterModal
        carriers={allCarriers}
        setCarrierSelected={setCarrierSelected}
        setPriceFilter={setPriceFilter}
        setRangeDate={setRangeDate}
        isOpen={state}
        close={toggle}
        filter={filter}
      />
    </>
  )
}

export default Shipments
