import { notification, Form } from "antd";
import InputContainer from "../../../../components/Containers/InputContainer";
import { usePost } from "../../../../hooks/usePost";
import { FormatedShipmentForTables } from "../../../../interfaces/shipments.interface";
import { updateShipmentStatus } from "../../../../services/records";

import { ShipmentStatus } from "../../../../utils/GlobalServices";
import { CancellationColumn } from "./Actions";

type Props = {
  data: CancellationColumn;
  setData?: React.Dispatch<
    React.SetStateAction<FormatedShipmentForTables[] | null>
  >;
  refetch: any;
};

export const UpdateStatusForm = ({ data, setData }: Props) => {
  const { fetchData } = usePost(updateShipmentStatus);

  const [form] = Form.useForm();

  const handleUpdateStatus = async (status: ShipmentStatus) => {
    console.log(data,"es data")
    // const temp = {...data, shipment_status: status };

    const resp = await fetchData({
      status,
      tracking: data.tracking,
    });
    console.log(resp, "response", resp)
    if (!resp.data) {
      form.setFieldsValue({ status: data.shipment_status });
      notification.error({
        message: "No se pudo actualizar el estatus del envio",
      });
      return;
    }

    notification.success({
      message: "La guía se ha actualizado",
    });
    //setData(temp);
  };

  return (
    <Form
      initialValues={{ status: data.shipment_status }}
      onFinish={handleUpdateStatus}
      form={form}
    >
      {" "}
      <InputContainer
        placeholder="Estatus del envio"
        valueContainerName="status"
        onChange={handleUpdateStatus}
        defaultValue={data.shipment_status}
        type="select"
        optionsList={[
          {
            label: ShipmentStatus.Active,
            value: ShipmentStatus.Active,
          },
          {
            label: ShipmentStatus.InTransit,
            value: ShipmentStatus.InTransit,
          },
          {
            label: ShipmentStatus.Delivered,
            value: ShipmentStatus.Delivered,
          },
          {
            label: ShipmentStatus.CancelledButUsed,
            value: ShipmentStatus.CancelledButUsed,
          },
          {
            label: ShipmentStatus.CancelledFromProvider,
            value: ShipmentStatus.CancelledFromProvider,
          },
          {
            label: ShipmentStatus.Refunded,
            value: ShipmentStatus.Refunded,
          },
          {
            label: ShipmentStatus.Solicited,
            value: ShipmentStatus.Solicited,
          },
        ]}
      />
    </Form>
  );
};
