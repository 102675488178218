import { Col, Row } from "antd"
import Table, { ColumnsType } from "antd/lib/table"
import { FC } from "react"
import InputContainer from "../../../../components/Containers/InputContainer"
import ModalContainer from "../../../../components/Containers/ModalContainer"
import { TrackingsI } from "../Overweight"

interface Props {
    openModal: boolean;
    setOpenModal: (state: boolean) => void
    files: TrackingsI[];
    loading: boolean;
    changeShipmentStatus: (files: TrackingsI[]) => void;
    loadingUpdateMassive: boolean;
}

const columnsTracking: ColumnsType<TrackingsI> = [
    {
        title: 'Número de guía',
        dataIndex: 'tracking',
        key: 'tracking',
        render: (text) => <a>{text}</a>, //eslint-disable-line
    },
    {
        title: 'Paqueteria',
        dataIndex: 'carrier',
        key: 'carrier',
        render: (text) => <a>{text}</a>, //eslint-disable-line
    },
    {
        title: 'Cantidad',
        dataIndex: 'amount',
        key: 'amount',
        render: (text) => <a>{text}</a>, //eslint-disable-line
    },
    {
        title: 'Estatus',
        dataIndex: 'status',
        key: 'status',
        render: (text) => <a>{text}</a>, //eslint-disable-line
    },
]


const ModalTracking: FC<Props> = ({ openModal, setOpenModal, files, loading, loadingUpdateMassive, changeShipmentStatus }) => {

    return (
        <ModalContainer
            visible={openModal}
            title="Bajar Status"
            footer={[<></>]}
            onOk={() => setOpenModal(false)}
            onCancel={() => setOpenModal(false)}
        >
            <Row>
                <Col span={24}>
                    <Table
                        columns={columnsTracking}
                        dataSource={files}
                        loading={loading || loadingUpdateMassive}
                        pagination={{ pageSize: 5 }}
                    />

                </Col>
                <br />
                <Col span={24}>
                    <InputContainer
                        style={{ marginTop: "20px" }}
                        type="button"
                        onClick={() => changeShipmentStatus(files)}
                        disabled={loading}
                        title="Cambiar status masivo"
                    />
                </Col>
            </Row>

        </ModalContainer>
    )
}

export default ModalTracking