import { useState, useEffect, useContext } from 'react'
import Chart from "react-apexcharts";
import CardContainer from "../../../../components/Containers/CardContainer";
import LoadingAnimation from '../../../../components/LoadingAnimation';
import { Col, Row, Select } from 'antd';
import { AppContext } from '../../../../context/appContext';
import { t } from "i18next";

type ChartType = "area" | "bar"
type FilterType = 'day' | 'month' | 'year' | 'compare' | 'month int' | "daily users"

type Props = {
  filteredData: any
  isLoading: boolean,
  chartType: ChartType,
  filterType: FilterType
  FooderData?: any
}

type Carrier = {
  carrier: string;
  count: number;
}

type DataByDay = {
  day: string
  count: number
  total: number
  carriers: Carrier[]
}

type DataCompare = {
  day: string
  count: number
}

type DataByYear = {
  year: string
  count: number
}

const Graph = ({ isLoading, filteredData, chartType, filterType, FooderData }: Props) => {
  const [categories, setCategories] = useState<string[]>([])
  const [dataA, setDataA] = useState<number[]>([])
  const [dataB, setDataB] = useState<number[]>([])
  const [data, setData] = useState<number[]>([])
  const [selectedTitle, setSelectedTitle] = useState<string>('')
  const [selectorValue, setSelectorValue] = useState<string>('option1')
  const [uniqueCarriers, setUniqueCarriers] = useState<string[]>([])
  const { userData } = useContext(AppContext);
  const { Option } = Select

  const handleData = async () => {
    switch (filterType) {
      case 'day':
      case 'daily users':
        const days: string[] = filteredData?.map((item: DataByDay) => item.day.toString());

        const carriersSet = new Set<string>();
        filteredData?.forEach((item: DataByDay) => {
          item.carriers.forEach((carrier: Carrier) => carriersSet.add(carrier.carrier));
        });
        setUniqueCarriers(Array.from(carriersSet));

        if (filterType === 'day') {
          if (selectorValue === 'option1') {
            setCategories(days);
            setData((filteredData as DataByDay[]).map((item: DataByDay) => item.total));
          } else {
            const carrierCounts = days.map(day => {
              const dayData = filteredData.find((item: DataByDay) => item.day === day);
              const carrier = dayData?.carriers.find((c: Carrier) => c.carrier === selectorValue);
              return carrier ? carrier.count : 0;
            });
            setCategories(days);
            setData(carrierCounts);
          }
        }
        break;
      case 'month int':
      case 'month':
        filteredData?.sort((a: any, b: any) => {
          const meses = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
          return meses.indexOf(a.mes) - meses.indexOf(b.mes);
        });

        const meses = filteredData?.map((item: any) => item.mes);

        if (selectorValue === 'option1') {
          const conteos = filteredData?.map((item: any) => item.total);
          setCategories(meses);
          setData(conteos);
        } else {
          const carrierCounts = filteredData?.map((item: any) => {
            const carrierData = item.carriers.find((c: Carrier) => c.carrier === selectorValue);
            return carrierData ? carrierData.count : 0;
          });
          setCategories(meses);
          setData(carrierCounts);
        }

        const carriersSetMonth = new Set<string>();
        filteredData?.forEach((item: any) => {
          item.carriers.forEach((carrier: Carrier) => carriersSetMonth.add(carrier.carrier));
        });
        setUniqueCarriers(Array.from(carriersSetMonth));
        break
      case 'compare':
        const daysA: string[] = filteredData?.filterA?.map((item: DataCompare) => item.day.toString());
        const countsDaysA: number[] = filteredData?.filterA?.map((item: DataCompare) => item.count);
        const countsDaysB: number[] = filteredData?.filterB?.map((item: DataCompare) => item.count);

        setCategories(daysA)
        setDataA(countsDaysA)
        setDataB(countsDaysB)
        break;
      case 'year':
        const years: string[] = filteredData?.map((item: DataByYear) => item.year.toString());
        const counts: number[] = filteredData?.map((item: DataByYear) => item.count);
        setCategories(years)
        setData(counts)
        break
    }
  }

  const handleSelectedTitle = async (filterType: FilterType) => {
    if (filterType === 'day') {
      setSelectedTitle('Envios diarios')
    } else if (filterType === 'month') {
      setSelectedTitle('Envios mensuales')
    } else if (filterType === 'month int') {
      setSelectedTitle('Envios internacionales mensuales')
    } else if (filterType === 'year') {
      setSelectedTitle('Envios anuales')
    } else if (filterType === 'compare') {
      setSelectedTitle('Envios Internacionales y Nacionales')
    }
  }

  const handleSelectorChange = (selectedValue: string) => {
    setSelectorValue(selectedValue);
  }

  useEffect(() => {
    handleData()
    handleSelectedTitle(filterType)
  }, [filteredData, filterType, selectorValue]) //eslint-disable-line

  const options = {
    chart: {
      id: `${filterType}-bar`
    },
    xaxis: {
      categories
    }
  }

  const series = [
    {
      name: "series",
      data
    }
  ]

  const compareSeries = [
    {
      name: "Nacional",
      data: dataB
    },
    {
      name: "Internacional",
      data: dataA
    }
  ]

  return (
    <>
      {
        (userData?.roles[0] === "ADMIN" || userData?.roles[0] === "DEV") && (
          <CardContainer cardStyle={{ marginTop: '20px', textAlign: 'center' }} title={selectedTitle} titleStyle={{ placeItems: 'center' }}>
            {
              (filterType === 'day' || filterType === 'month int' || filterType === 'month') && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Select
                    value={selectorValue}
                    onChange={handleSelectorChange}
                    style={{ width: '100%', textAlign: 'center' }}
                  >
                    <Option value="option1" style={{ textAlign: 'center' }}>
                      General
                    </Option>
                    {uniqueCarriers.map(carrier => (
                      <Option key={carrier} value={carrier} style={{ textAlign: 'center' }}>
                        {t(`Quote.Service.Carrier.${carrier}`)}
                      </Option>
                    ))}
                  </Select>
                </div>
              )
            }
            {isLoading ? (
              <LoadingAnimation animationType='small' />
            ) : (
              <Chart
                options={options}
                series={filterType === "compare" ? compareSeries : series}
                type={chartType}
                width="100%"
              />
            )}
            {
              FooderData != null &&
              <Row gutter={[8, 8]} justify="center" align="middle">
                <Col span={4} offset={2}>
                  <b>Totales</b>
                </Col>
                <Col span={4} offset={2}>
                  <b>En espera</b>
                </Col>
                <Col span={4} offset={2}>
                  <b>Agendado</b>
                </Col>
                <Col span={4} offset={2}>
                  <b>Solicitada</b>
                </Col>
                <Col span={4} offset={2}>
                  <p>{FooderData?.total[0]?.totalPickups}</p>
                </Col>
                <Col span={4} offset={2}>
                  <p>{FooderData?.pickups[0]?.count}</p>
                </Col>
                <Col span={4} offset={2}>
                  <p>{FooderData?.pickups[1]?.count}</p>
                </Col>
                <Col span={4} offset={2}>
                  <p>{FooderData?.pickups[2]?.count}</p>
                </Col>
              </Row>
            }
          </CardContainer>
        )
      }
    </>
  )
}

export default Graph
