import { AddressesInterface, PackagesInterface, Paqueteria } from "./shipments.interface";

export interface ReportsMetadataInterface {
	overweight?: {
		amount_kgs: number;
		delivery_type: string;
		receipt: string[];
		evidence: string[];
	},
	recolections?: {
		carrier: Paqueteria;
		service: string;
		origin: AddressesInterface;
		packages: PackagesInterface[];
	},
	expedite_delivery?: {
		carrier: Paqueteria;
		report_number: string;
		origin: AddressesInterface;
		destination: AddressesInterface;
		new_destination: AddressesInterface;
		packages: PackagesInterface[];
		receipt: string[];
		evidence: string[];
	}
}

export interface ReportsInterface {
	name: string;
	email: string;
	subject: string;
	description: string;
	status: StatusType;
	priority: 1 | 2 | 3 | 4;
	created_at: Date;
	metadata: ReportsMetadataInterface
}

export type StatusType = 2 | 3 | 4 | 5;

export enum StatusReport{
    N2 = 'Abierto',
    N3 = 'Pendiente',
	N4 = 'Resuelto',
	N5 = 'Cerrado',
}