import { FC } from "react"
import { assingDashboardLogos } from "../../utils";
import CarrierSwitch from "../CarrierSwitch";
import useDevice from "../../../../../hooks/useDevice";
import { PreferencesCarriersV2 } from "../../interfaces";

interface props {
    carrier: PreferencesCarriersV2;
    isApi?: boolean;
    onChange: (name: string, key: string, value: boolean) => void;
}

interface Styles {
    title: React.CSSProperties;
    titleMobile: React.CSSProperties;
    container: React.CSSProperties;
    containerMobile: React.CSSProperties;
    cardsList: React.CSSProperties;
    cardsListMobile: React.CSSProperties;

}

const styles: Styles = {
    title: {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    titleMobile: {
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1rem',
        padding: '18px 12px',
        margin: '8px 0',
        border: '.1rem solid #DFDCDC',
        borderRadius: '12px',
        width: '14rem'
    },
    containerMobile: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1rem',
        padding: '18px 12px',
        margin: '8px 0',
        border: '.1rem solid #DFDCDC',
        borderRadius: '12px',
        width: '100%'
    },
    cardsList: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '1rem',
        margin: '2rem 0'
    },
    cardsListMobile: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        gap: '1rem',
        margin: '2rem 0'
    }

}

const AdminCarrierCard: FC<props> = ({ carrier, isApi, onChange }) => {
    const { isTablet } = useDevice()

    return (
        <article style={isTablet ? styles.containerMobile : styles.container}>
            <section>
                <img width={52} src={assingDashboardLogos(carrier.name)} alt={carrier.name} />
            </section>
            <section>
                <CarrierSwitch
                    title={carrier.name}
                    active={isApi ? !carrier?.ignore_api : carrier?.active_admin}
                    isApi={isApi || false}
                    onChange={onChange}
                /></section>
        </article>
    )
}

export default AdminCarrierCard
