import { Col, Typography } from "antd"
import { FC } from "react";
import CardContainer from "../../../../components/Containers/CardContainer"
import ReportImage from "./ReportImage";

interface Props{
    report: {
        amount_kgs: number;
		delivery_type: string;
		receipt: string[];
		evidence: string[];
    };
}
const OverWeightInfo: FC<Props> = ({report}) => {
    return (
        <>
            <CardContainer title="Datos del sobrepeso">
                <Col span={24} style={{ padding: '8px' }}>
                    <Typography>
                        {` - Sobrepeso cobrado: `} <strong>{report.amount_kgs}</strong>
                    </Typography>
                </Col>
                <Col span={24} style={{ padding: '8px' }}>
                    <Typography>
                        {` - Tipo de entrega de paquete: `} {<strong>{report.delivery_type}</strong>}
                    </Typography>
                </Col>
                <Col span={24} style={{ padding: '8px' }}>
                    <Typography>
                        - Evidencias de recibo o facturas:
                    </Typography>
                </Col>
                <Col span={24}>
                    {
                        report.receipt.map(url => <ReportImage url={url} />)
                    }
                </Col>
                <Col span={24} style={{ padding: '8px' }}>
                    <Typography>
                        - Evidencias de medidas/peso del paquete:
                    </Typography>
                </Col>
                <Col span={24} style={{ width: '100%' }}>
                    {
                        report.evidence.map(url => <ReportImage url={url} />)
                    }
                </Col>
            </CardContainer>
        </>
    )
}

export default OverWeightInfo