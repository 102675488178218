import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

const LogoutButton = () => {
    const { logout } = useAuth0();
    return (
      <button
        className="btn btn-danger btn-block"
        onClick={() =>
          logout({
            returnTo: window.location.origin,
          })
        }
      >
        Log Out
      </button>
    );
  };

  const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
    return (
      <button
        className="btn btn-primary btn-block"
        onClick={() => loginWithRedirect()}
      >
        Log In
      </button>
    );
  };

  const AuthenticationButton = () => {
    const { isAuthenticated } = useAuth0();
  
    return isAuthenticated ? <LogoutButton /> : <LoginButton />;
  };

const Login =()=> {

  const { user } = useAuth0();

  console.log(user)

    return <AuthenticationButton />;
}

export default withAuthenticationRequired(Login, {
  onRedirecting: () => <div>ssss</div>,
});