import { useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { IconButton} from "@material-ui/core";
import { Pickup } from "../../../../interfaces/pickups.interface";
import { PickupsDetail } from "../../../../components/Common/TableDatails/PickupsDetails";

type Props = {
  data: Pickup;
  refetch: () => void;
};

export const DetailsActions = ({ data, refetch }: Props) => {
  const [openInfo, setOpenInfo] = useState(false);

  return (
    <>
    <div style={{display: 'flex'}}>
    <IconButton
            title="Información"
            style={{ padding: 0 }}
            onClick={() => {
                setOpenInfo(true)
            }}
          >
            <InfoCircleOutlined style={{ cursor: "pointer" }} />
          </IconButton>      
    </div>
          
      <PickupsDetail 
          visible={openInfo}
          setIsVisible={setOpenInfo}
          data={data}
      />
    </>
  );
};
