import { Col } from "antd"
import CardContainer from "../../../components/Containers/CardContainer"
import { FC } from "react"

interface props {
    img: any
}
const StoreHeader: FC<props> = ({ img }) => {
    return (
        <Col span={24}>
            <CardContainer
                iconOrImg={
                    <img
                        alt={"logo de paquetería"}
                        src={img}
                        style={{ height: "50px" }}
                    />
                }
                contrastColor="transparent"
            />
        </Col>
    )
}

export default StoreHeader