import React from 'react'
import ErrorReport from '../../pages/ErrorReport/ErrorReport'

export default class ErrorCatch extends React.Component<{}, { hasError: boolean }> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorReport />
    }
    return this.props.children
  }
}
