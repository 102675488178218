import { Col, Typography } from "antd"
import { FC } from "react";
import CardContainer from "../../../../components/Containers/CardContainer"
import { AddressesInterface, PackagesInterface } from "../../../../interfaces/shipments.interface";
import { AddressReportInfo, PackagesReportInfo } from "./GenericInfo";
import ReportImage from "./ReportImage";

interface Props {
    report: {
        carrier: string;
        report_number: string;
        origin: AddressesInterface;
        destination: AddressesInterface;
        new_destination: AddressesInterface;
        packages: PackagesInterface[];
        receipt: string[];
        evidence: string[];
    };
}
const ExpediteDeliveryInfo: FC<Props> = ({ report }) => {
    return (
        <>
            <CardContainer title="Datos de la paquetería" cardStyle={{ marginBottom: '1.5rem' }}>
                <Col span={24} style={{ padding: '8px' }}>
                    <Typography>
                        {` - Paqueteria: `} <strong>{`${report.carrier}`}</strong>
                    </Typography>
                </Col>
                <Col span={24} style={{ padding: '8px' }}>
                    <Typography>
                        {` - Número de reporte: `} <strong>{`${report.report_number}`}</strong>
                    </Typography>
                </Col>
                <Col span={24} style={{ padding: '8px' }}>
                    <Typography>
                        - Evidencias de recibo o facturas:
                    </Typography>
                </Col>
                <Col span={24}>
                    {
                        report.receipt.map(url => <ReportImage url={url} />)
                    }
                </Col>
                <Col span={24} style={{ padding: '8px' }}>
                    <Typography>
                        - Evidencias de medidas/peso del paquete:
                    </Typography>
                </Col>
                <Col span={24} style={{ width: '100%' }}>
                    {
                        report.evidence.map(url => <ReportImage url={url} />)
                    }
                </Col>
            </CardContainer>
            <AddressReportInfo data={report.origin} title="Dirección de origen" />
            <AddressReportInfo data={report.destination} title="Dirección de destino" />
            <AddressReportInfo data={report.new_destination} title="Nueva dirección de Destino" />
            <PackagesReportInfo data={report.packages} />
            
        </>
    )
}



export default ExpediteDeliveryInfo