export const pickupStatus = [
  "Solicitada",
  "Cancelada de prov",
  "Agendado",
  "En espera",
  'Sin disponibilidad',
] as const;
export type PickupStatus = typeof pickupStatus[number];

interface miniShipmentData {
  origin: {
    postal_code: string;
    user?: string;
    country?: string;
    email?: string;
    title?: string;
    name?: string;
    phone?: string;
    company?: string;
    street?: string;
    number?: string;
    int_number?: string;
    district?: string;
    city?: string;
    postalCode?: string;
    state?: string;
    reference?: string;
  };
}

export interface Pickup {
  _id?: string;
  user: string;
  email: string;
  shipment_id: string;
  tracking: string;
  carrier: AllCarriers;
  creation_date: Date;
  date_from: Date;
  date_to: Date;
  folio: String;
  service_id?: String;
  pickups_status: PickupStatus;
  packages?: any
  address: miniShipmentData[];
  addressShipments?: any
}
