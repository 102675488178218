import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import BlockIcon from '@material-ui/icons/Block'
import { useTranslation } from 'react-i18next'
import { ReferralStatus, WithdrawalStatus } from '../../../../interfaces/partner.interface'

type TableType = 
    | 'referrals' 
    | 'withdrawals'

export const Status = ({
    tableType, 
    status,
    withIcon = true,
}: {
    tableType: TableType
    status:  ReferralStatus | WithdrawalStatus
    withIcon?: boolean
}) => {
    const { t } = useTranslation()
    
    const statusReferrals = (status: ReferralStatus) => {
        switch (status) {
            case 'aprobado':
                return (
                    <div style={{ backgroundColor: '#5FCE8C', ...chipStyle }}>
                        {withIcon && <CheckCircleIcon style={chipStyle.imgStye} />}
                        {t('Referrals.Status.Approved')}
                    </div>
                )
            case 'pendiente':
                return (
                    <div style={{ backgroundColor: '#54C0F9', ...chipStyle }}>
                        {withIcon && <ReportProblemIcon style={chipStyle.imgStye} />}
                        {t('Referrals.Status.Pending')}
                    </div>
                )
            case 'rechazado':
                return (
                    <div style={{ backgroundColor: '#FF413F', ...chipStyle }}>
                        {withIcon && <BlockIcon style={chipStyle.imgStye} />}
                        {t('Referrals.Status.Refused')}
                    </div>
                )
            default:
                return <div></div>
        }
    }

    const statusWithdrawals = (status: WithdrawalStatus) => {
        switch (status) {
            case 'abonado':
                return (
                    <div style={{ backgroundColor: '#5FCE8C', ...chipStyle }}>
                        {withIcon && <CheckCircleIcon style={chipStyle.imgStye} />}
                        {t('Withdrawals.Table.Filters.Status.Paid')}
                    </div>
                )
            case 'pendiente':
                return (
                    <div style={{ backgroundColor: '#54C0F9', ...chipStyle }}>
                        {withIcon && <ReportProblemIcon style={chipStyle.imgStye} />}
                        {t('Withdrawals.Table.Filters.Status.Pending')}
                    </div>
                )
            case 'rechazado':
                return (
                    <div style={{ backgroundColor: '#FF413F', ...chipStyle }}>
                        {withIcon && <BlockIcon style={chipStyle.imgStye} />}
                        {t('Withdrawals.Table.Filters.Status.Refused')}
                    </div>
                )
            default:
                return <div></div>
        }
    }

    if (tableType === 'referrals') return <>{statusReferrals(status as ReferralStatus)}</>
    if (tableType === 'withdrawals') return <>{statusWithdrawals(status as WithdrawalStatus)}</>
    else return <div></div>
}

const chipStyle = {
    display: 'flex',
    fontWeight: '500',
    width: '115px',
    height: '25px',
    borderRadius: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    imgStye: { 
        margin: '5px', 
        width: '17px', 
        color: 'white' 
    }
}
