//Enum with all of platforms of integrations
export enum Platforms {
    Shopify = 'SHOPIFY',
    WooCommerce = 'WOOCOMMERCE',
    Prestashop = 'PRESTASHOP',
    Wix = 'WIX',
    Squarespace = 'SQUARESPACE',
    Mercadolibre = 'MERCADOLIBRE',
    Tiendanube = 'TIENDANUBE',
    PluginWoocomerce = 'PLUGIN_WOOCOMMERCE',
    Magento = 'MAGENTO',
    Ecwid = 'ECWID',
    Amazon = 'AMAZON',
    Zoho = 'ZOHO',
    BigCommerce = 'BIGCOMMERCE',
    OpenCart = 'OPENCART',
    ShipHero = 'SHIPHERO',
    Vtex = 'VTEX',
    Ebay = 'EBAY',
}

interface FieldsI{
    name: string;
    type: 'text' | 'password' | 'number';
    placeholder: {
        en: string;
        es: string;
    };
}

export interface StoreInformationI {
    type?: Platforms;
    label: string;
    is_read_only: boolean;
    show_products: boolean;
    pdf_manual: string;
    media?: {
        url: string | null;
    }
    fields: FieldsI[];
    meta_fields: FieldsI[];
    steps: {
        en: string[];
        es: string[];
    }
}
