import { useContext, useState } from 'react'
import { Button, Input, Tooltip, notification } from 'antd'
import moment from 'moment'
import axios from 'axios'
import { ApiContext } from '../../context/apiContext'
import { CustomerDataI } from './Sells'
import { SearchOutlined } from '@material-ui/icons'

const FindUser = ({ setCustomerData }: { customerData: Array<CustomerDataI>, setCustomerData: (data: Array<CustomerDataI>) => void }) => {

    const [ search, setSearch ] = useState<string>('')
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const startMonth = moment().startOf('month').format('YYYY-MM-DD')
    const { backendURL, userToken } = useContext(ApiContext)

    const onFindUser = async() => {
        setIsLoading(true)
        if(search.trim() === '') {
            setIsLoading(false)
            return notification.error({ message: "Escribe un correo" })
        }
        const response = await axios.get(`${backendURL}admin/findCustomer/${startMonth}/${search.trim()}`, {
            headers: { Authorization: `Bearer ${ userToken }` }
        })
        if(response.status === 200){
            setIsLoading(false)
            if(response.data.length > 0){
                setCustomerData(response.data)
                return
            }
        }
        setIsLoading(false)
        return notification.info({ message: "Este usuario no existe, revisa bien!" })
    }

  return (
        <Tooltip title="Buscar usuario especifico">
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center' }}>
                <Input
                    placeholder='Escriba un correo electrónico'
                    onChange={(e) => setSearch(e.target.value)}
                    />
                <Button loading={isLoading} type='primary' style={{ width:'2.5rem' }} onClick={onFindUser} icon={<SearchOutlined />} />
            </div>
        </Tooltip>
  )
}

export default FindUser