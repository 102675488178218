import React, { createContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import {
  apiResponse,
  BackendApiCall,
  RequestStatus,
} from "../interfaces/app.interfaces";
import { getToken } from "../services/app_s";
import { notification } from "antd";

interface apiData {
  userToken: string;
  backendURL: string;
  backendApiCall: BackendApiCall;
  serviceIsReady: boolean;
}

export const ApiContext = createContext<apiData>({
  userToken: "fetching..",
  backendURL: `${process.env.REACT_APP_NOT_BACKEND_URL}`,
  backendApiCall: () => Promise.resolve({} as apiResponse),
  serviceIsReady: false,
});

export const ApiContextProvider: React.FC = (props) => {
  const backendURL = `${process.env.REACT_APP_NOT_BACKEND_URL}`;
  const { getAccessTokenSilently } = useAuth0();
  const [userToken, setuserToken] = useState("");
  const [serviceIsReady, setserviceIsReady] = useState(false);

  const backendApiCall: BackendApiCall = async (data) => {
    return new Promise(async (resolve) => {
      if (serviceIsReady) {
        const returnData: apiResponse = {
          data: "awaiting",
          status: RequestStatus.Fetching,
        };
        await axios({
          data: data.method === "GET" ? undefined : data.data,
          method: data.method,
          url: `${backendURL}${data.endpoint}`,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          params: data.params,
        })
          .then((res) => {
            resolve({
              status: RequestStatus.Success,
              data: res,
            });
          })
          .catch((err) => {
            console.log(err);
            const e = err as Error;
            returnData.data = err;
            resolve({
              status: RequestStatus.Error,
              data: {
                msg: e.message,
              },
            });
          });
      } else {
        resolve({
          status: RequestStatus.Error,
          data: {
            msg: "Service is not ready yet",
          },
        });
      }
    });
  };

  useEffect(() => {
    const getAppToken = async () => {
      await getToken(getAccessTokenSilently)
        .then((accessToken: string) => {
          setuserToken(accessToken);
          setserviceIsReady(true);
        })
        .catch((e: any) => {
          notification.error({ message: "Error en servicio de Auth0" });
          console.log(e);
          window.location.reload();
        });
    };
    setTimeout(() => {
      getAppToken();
    }, 350);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ApiContext.Provider
      value={{ backendApiCall, userToken, backendURL, serviceIsReady }}
    >
      {props.children}
    </ApiContext.Provider>
  );
};
