import { FC } from 'react'
import ModalContainer from '../Containers/ModalContainer'
import { Col, Form, Row } from 'antd'
import { useRetakeGuide } from '../../hooks/errorLogs/useRetakeGuide'
import InputContainer from '../Containers/InputContainer'
import { GuidePayload } from '../../interfaces/guides.interface'
import { OriginForm } from './OriginForm'
import { DestinyForm } from './DestinyForm'
import LoadingAnimation from '../LoadingAnimation'
import CardContainer from '../Containers/CardContainer'

export const GenerateGuideModal: FC<Props> = ({ isVisible, guide, rowData, onCancel }) => {
  const { form, isGeneratingGuide, onFinish, onFinishFailed } = useRetakeGuide(guide)

  if (!guide) return <LoadingAnimation animationType='big' />

  console.log({
    rowData,
  })

  return (
    <ModalContainer title={`Generar Guía`} visible={isVisible} onCancel={onCancel} footer={false}>
      <Form
        name='GenerateGuideForm'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        autoComplete='off'
        initialValues={{
          Container: 0,
        }}
        style={{ width: '100%', height: '100%' }}
      >
        <Row
          style={{
            paddingTop: '1rem',
            paddingBottom: '2rem',
            height: 'auto',
          }}
          gutter={[20, 20]}
        >
          <Col sm={24} lg={24} style={{ width: '100%' }}>
            <Row style={{ width: '100%' }}>
              <Col span={24}>
                <CardContainer id='infoCard'>
                  <div style={{ display: 'flex', justifyContent: 'space-between', gap: 16 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <strong>Fecha</strong>
                      <p style={{ textAlign: 'center' }}>{rowData.date}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <strong>Usuario</strong>
                      <p style={{ textAlign: 'center' }}>{rowData.email}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <strong>Tipo</strong>
                      <p style={{ textAlign: 'center' }}>{rowData.type}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <strong>Categoría</strong>
                      <p style={{ textAlign: 'center' }}>{rowData.category}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <strong>Componente</strong>
                      <p style={{ textAlign: 'center' }}>{rowData.component}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <strong>Mensaje</strong>
                      <p style={{ textAlign: 'center' }}>{rowData.msg}</p>
                    </div>
                  </div>
                </CardContainer>
              </Col>
            </Row>
          </Col>

          <OriginForm guide={guide} />
          <DestinyForm guide={guide} />

          <Col span={24}>
            <InputContainer
              isButtonLoading={isGeneratingGuide}
              type='submitButton'
              title={'Generar Guía'}
            />
          </Col>
        </Row>
      </Form>
    </ModalContainer>
  )
}

type Props = {
  isVisible: boolean
  onCancel: () => void
  guide: GuidePayload | null
  rowData: any
}
