import { Col, Row } from 'antd'
import { useMemo, Fragment } from 'react'
import { DashboardAnalyticsRes } from '../../../../interfaces/admin.interface'
import CardContainer from '../../../../components/Containers/CardContainer'
import LoadingAnimation from '../../../../components/LoadingAnimation'
import { CancelStatus } from '../../../../components/Common/CancelStatus'


type Props = {
  filteredData: DashboardAnalyticsRes
  isLoading: boolean
}

export const ShipmentsWithStatus = ({ filteredData, isLoading }: Props) => {
  const sumaTotal = filteredData?.ShipmentsWithStatus?.reduce(
    (sum: any, value: any) => sum + value.total,
    0
  )

  const resultData = useMemo(
    () =>
      filteredData?.ShipmentsWithStatus?.filter((item) => item._id === 'Cancelada de prov') || [
        { total: 0 },
      ],
    [filteredData]
  )

  const textMap: Record<string, string> = {
    'Saldo abonado': 'Abonado',
    Solicitada: 'Solicitada',
  }

  return (
    <CardContainer cardStyle={{ marginTop: "20px" }} title="Guías realizadas" titleStyle={{ placeItems: 'center' }}>
      <hr />
      <Row>
        <Col span={12} style={{ margin: "auto", fontWeight: "bold", textAlign: "center" }}>
          <p>Estado</p>
        </Col>
        <Col span={6} style={{ margin: "auto", fontWeight: "bold", textAlign: "center" }}>
          <p>%</p>
        </Col>
        <Col span={6} style={{ margin: "auto", fontWeight: "bold", textAlign: "center" }}>
          <p>Guías</p>
        </Col>
      </Row>
      <Row
        align="middle"
        style={{
          maxHeight: "40vh",
          overflowY: "auto",
        }}
      >
        {isLoading ? (
          <LoadingAnimation animationType="small" />
        ) : filteredData?.ShipmentsWithStatus === undefined ||
          filteredData?.ShipmentsWithStatus?.length === 0 ? (
          <p
            style={{
              fontWeight: "bold",
              margin: "50px auto",
              color: "#0008",
            }}
          >
            No hay datos para mostrar.
          </p>
        ) : (
          filteredData?.ShipmentsWithStatus?.map((item: any, index: number) => {
            if (item._id === "Cancelada de prov") {
              return null;
            }
            const newTotal =
              item._id === "Saldo abonado"
                ? item.total + (resultData[0]?.total || 0)
                : item.total;
            return (
              <Fragment key={index}>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: "5px auto" }}>
                  <CancelStatus
                    customText={textMap[item._id]}
                    status={item._id || ""}
                  />
                </Col>
                <Col
                  span={6}
                  style={{
                    margin: "5px auto",
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                >
                  {`${((item.total * 100) / sumaTotal).toFixed(1)}%`}
                </Col>
                <Col span={6} style={{ margin: "5px auto", textAlign: "center", color: "#2D1B6E", fontWeight: "bold" }}>
                  {newTotal.toLocaleString()}
                </Col>
              </Fragment>
            );
          })
        )}
      </Row>
    </CardContainer>
  );
}
