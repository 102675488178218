import { useContext } from "react";
import { Col, Row, Typography, notification, Spin } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import CardContainer from "../../components/Containers/CardContainer";

import { PricesContext } from "../../context/PricesContext";

import { Price, SpecialPrice } from "../../interfaces/services.interface";

import { usePost } from "../../hooks/usePost";

import { removeSpecialPriceFromUser } from "../../services/services_s";
import usePermission from "../../hooks/usePermission";
import { ActionsPricesSection, PermissionViews } from "../../interfaces/permissions.interfaces";
import AccessMsg from "../../components/Common/AccessMsg";

type Props = {
  price: Price;
  index: number;
  user?: string;
  withDelete?: boolean;
  withEdit?: boolean;
};

export const PricesCard = ({
  price,
  index,
  user,
  withDelete,
  withEdit = true,
}: Props) => {
  const {
    setSelectedPrice,
    setIsPricesModalOpen,
    selectedServiceId,
    setSelectedServiceId,
  } = useContext(PricesContext);

  const { fetchData: removeSpecialPrice, isLoading: isDeletingPrice } = usePost(
    removeSpecialPriceFromUser
  );

  const { hasAccess } = usePermission()

  const onClickEdit = () => {
    setSelectedPrice(() => ({ ...price, index, user }));
    setIsPricesModalOpen(() => true);
  };

  const handleDelete = async () => {
    if (!selectedServiceId || !user)
      return notification.error({ message: "No se selecciono un usuario" });

    const resp = await removeSpecialPrice({
      id: selectedServiceId._id,
      user,
      price,
    });

    if (resp.error) return;

    setSelectedServiceId({
      ...selectedServiceId,
      specialPrices: resp.data as SpecialPrice[],
    });
  };

  return (
    <Col span={24}>
      <CardContainer
        cardStyle={{
          boxShadow: "none",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          textAlign: isDeletingPrice ? "center" : "left",
        }}
      >
        {!isDeletingPrice ? (
          <Row style={{ padding: "0 10px" }}>
            <Col flex={1}>
              <Typography.Text strong>{price.weight}kg - </Typography.Text>
              <Typography.Text strong style={{ color: "#6D71F6" }}>
                {price.price.toLocaleString("es-MX", {
                  currency: "MXN",
                  style: "currency",
                })}
              </Typography.Text>
            </Col>
            <Col>
              <Row align="middle">
                {withEdit && (
                  <Col>
                    <AccessMsg
                      label="Modificar Tarifas"
                      access={hasAccess(ActionsPricesSection.modify_prices, PermissionViews.prices_section)} >
                      <EditOutlined
                        onClick={() => {
                          if (hasAccess(ActionsPricesSection.modify_prices, PermissionViews.prices_section)) {
                            onClickEdit()
                          }

                        }}
                        style={{
                          color: "#6D71F6",
                          fontSize: "1.1rem",
                          cursor: "pointer",
                          marginRight: "0.5rem",
                        }}
                      />
                    </AccessMsg>
                  </Col>
                )}
                {withDelete && (
                  <Col>
                    <AccessMsg
                      label="Modificar P. Especiales"
                      access={hasAccess(ActionsPricesSection.modify_special_prices, PermissionViews.prices_section)} >
                      <DeleteOutlined
                        onClick={() => {
                          if (hasAccess(ActionsPricesSection.modify_special_prices, PermissionViews.prices_section)) {
                            handleDelete()
                          }

                        }}
                        style={{
                          color: "#FF5059",
                          fontSize: "1.1rem",
                          cursor: "pointer",
                        }}
                      />
                    </AccessMsg>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        ) : (
          <Spin />
        )}
      </CardContainer>
    </Col>
  );
};
