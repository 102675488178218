// Generic Imports
import React, { useEffect, useState } from "react"
import TabsContainer from "./../../../../components/Containers/TabsContainer"

// Table Components
import usePermission from "../../../../hooks/usePermission"
import {
  ActionsFulfillment,
  PermissionViews,
} from "../../../../interfaces/permissions.interfaces"
import FulfillProfiles from './FulfillProfiles'

export interface RecordAccessI {
  fulfillmentProfiles: boolean
}

const Fulfillment: React.FC = () => {
  const { hasAccess } = usePermission()
  const [tabContent, setTabContent] = useState({})
  const [tabAccess] = useState<RecordAccessI>({
    fulfillmentProfiles: hasAccess(ActionsFulfillment.view_fulfill_profiles, PermissionViews.fulfillment),
  })

  const assignTabs = () => {
    let tabObject = {}

    if (tabAccess.fulfillmentProfiles) {
      tabObject = {
        ...tabObject,
        Perfiles: {
          component: <FulfillProfiles />,
          description: "Perfiles",
        },
      }
    }
    
    setTabContent(tabObject)
  }

  useEffect(() => {
    assignTabs()
    //eslint-disable-next-line
  }, [tabAccess])

  return (
    <section className="dashboardSection">
      <TabsContainer titlesAndContent={tabContent} />
    </section>
  )
}

export default Fulfillment
