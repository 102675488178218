import MaterialTable, { Column, MaterialTableProps, MTableToolbar } from 'material-table'
import { FC, useState } from 'react'

import AddBox from '@material-ui/icons/AddBox'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import Save from '@material-ui/icons/Save'
import { Button, Dropdown, Menu, RowProps } from 'antd'
import { makeStyles, TablePagination } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import * as XLSX from 'xlsx'
import Title from 'antd/lib/typography/Title'
import { ProfileOutlined } from '@ant-design/icons'
import { Lock, LockOpen } from '@material-ui/icons'

const tableIcons = {
  Save: () => <Save />,
  Add: () => <AddBox />,
  Check: () => <Check />,
  Clear: () => <Clear />,
  Delete: () => <DeleteOutline />,
  DetailPanel: () => <ChevronRight />,
  Edit: () => <Edit />,
  Export: () => <SaveAlt />,
  Filter: () => <FilterList />,
  FirstPage: () => <FirstPage />,
  LastPage: () => <LastPage />,
  NextPage: () => <ChevronRight />,
  PreviousPage: () => <ChevronLeft />,
  ResetSearch: () => <Clear />,
  Search: () => <Search />,
  SortArrow: () => {
    const [up, setUp] = useState(false)

    const handleClick = () => {
      setUp(!up)
    }

    return <div onClick={handleClick}>{up ? <ArrowUpward /> : <ArrowDownward />}</div>
  },
  ThirdStateCheck: () => <Remove />,
  ViewColumn: () => <ViewColumn />,
  lock_open: () => <LockOpen />,
  lock: () => <Lock />,
}

interface DropdownMethods {
  name: string
  description?: string
  key?: string
  action?: any
}

export type TableFilter = 'date' | 'price' | 'carrier' | 'zone' | 'quotes' | 'services'

export type TColumnTable = Column<object>[]
interface TableProps extends MaterialTableProps<RowProps> {
  columns: TColumnTable
  data: object[]
  title?: string
  filters?: TableFilter[]
  setFilter?: (filter: TableFilter) => void
  isButton?: {
    title?: string
    dropdown?: boolean
    icon?: any
    methods?: DropdownMethods[]
    onClick?: any
  }
  config?: {
    exportButton?: boolean
    selection?: boolean
    actionsColumnIndex?: number
    sorting?: boolean
    disableExport?: boolean
  }
  component?: any
}

const usePaginationStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid var(--bs-gray-200)',
  },

  customToolBar: {
    padding: 0,
  },
}))

export const Table: FC<TableProps> = ({
  columns,
  data,
  title,
  config,
  filters,
  setFilter,
  isButton,
  component,
  ...rest
}) => {
  const { t } = useTranslation()

  const paginationStyles = usePaginationStyles()

  const downloadExcel = (type: 'xlsx' | 'csv') => {
    // TODO: Esto provoca un bug que despues de descargar en xlsx o en csv ya no puedes aplicar filtros
    // esto por el delete que hay dentro del map, elimina esas propiedades de todos los objetos provocando valores indefinidos
    // Podríamos dejarlos como undefined en vez de eliminarlos pero el el excel o el csv saldrá el campo vacío.
    const formattedData = data.map((shipment: any) => {
      const formatedShipment = shipment
      delete formatedShipment.shipmentData
      delete formatedShipment.tableData
      return formatedShipment
    })

    const worksheet = XLSX.utils.json_to_sheet(formattedData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, `${title}`)
    XLSX.writeFile(workbook, `${title}.${type}`, { bookType: type })
  }

  const itemsDropdown = (
    <Menu>
      {isButton?.methods?.map((item, index) => {
        return (
          <Menu.Item onClick={item?.action || null} key={index + 1}>
            {item?.name || ''}
          </Menu.Item>
        )
      })}
    </Menu>
  )

  const arrRows = data.length < 500 ? data.length : 500

  return (
    <>
      <MaterialTable
        {...rest}
        localization={{
          toolbar: {
            searchPlaceholder: t('Table.Labels.SearchFilter'),
            nRowsSelected: '{0} movimiento seleccionado',
          },
          pagination: { labelRowsSelect: t('Table.Labels.RowsLabel') },
          body: {
            emptyDataSourceMessage: t('Table.Labels.EmptyDataMessage'),
          },
          header: {
            actions: '',
          },
        }}
        // @ts-ignore
        icons={tableIcons}
        title={<div>{title && <Title level={5}>{title}</Title>}</div>}
        columns={columns}
        data={data}
        options={{
          exportButton: config?.exportButton || false,
          selection: config?.selection || false,
          actionsColumnIndex: config?.actionsColumnIndex || 0,
          sorting: config?.sorting || false,
        }}
        style={{
          boxShadow: 'none',
        }}
        components={{
          Pagination: (props) => (
            <TablePagination
              className={paginationStyles.root}

              {...props}
              rowsPerPageOptions={[5, 10, arrRows]}
            />
          ),
          Toolbar: (props) => (
            <div>
              <MTableToolbar classes={{ root: paginationStyles.customToolBar }} {...props} />
              <div style={{ display: 'flex' }}>
                {!config?.disableExport && (
                  <>
                    <Button
                      onClick={() => downloadExcel('csv')}
                      style={{
                        marginRight: '1rem',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      icon={<ProfileOutlined />}
                    >
                      Descargar CSV
                    </Button>
                    <Button
                      onClick={() => downloadExcel('xlsx')}
                      style={{
                        marginRight: '1rem',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      icon={<ProfileOutlined />}
                    >
                      Descargar XLSX
                    </Button>
                  </>
                )}
                {component}
                {filters?.length && (
                  <Dropdown
                    overlay={
                      <Menu>
                        {filters.map((f) => (
                          <Menu.Item onClick={() => (setFilter ? setFilter(f) : null)}>
                            {parseFilterName(f)}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                  >
                    <Button>Filtros</Button>
                  </Dropdown>
                )}
                {isButton && isButton.dropdown ? (
                  <Dropdown overlay={itemsDropdown}>
                    <Button
                      icon={isButton?.icon || null}
                      style={{
                        marginRight: '1rem',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      {isButton?.title || ''}
                    </Button>
                  </Dropdown>
                ) : (
                  <Button
                    icon={isButton?.icon}
                    style={{
                      marginRight: '1rem',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                    onClick={isButton?.onClick}
                  >
                    {isButton?.title || ''}
                  </Button>
                )}
              </div>
            </div>
          ),
        }}
      />
    </>
  )
}

const parseFilterName = (filter: TableFilter) => {
  if (filter === 'date') return 'Por fecha'
  if (filter === 'price') return 'Por precio'
  if (filter === 'zone') return 'Por zona'
  if (filter === 'quotes') return 'Por Numero de Cotizaciones'
  if (filter === 'services') return 'Por Servicios'

  return 'Por paquetería'
}
