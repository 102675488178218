import { FilterOutlined } from '@ant-design/icons'
import axios from 'axios'
import { createRef, useContext, useEffect, useState } from 'react'
// import ReactJson from "react-json-view";
import { Table, TColumnTable } from '../../components/Common/Table'
import ModalContainer from '../../components/Containers/ModalContainer'
import { ApiContext } from '../../context/apiContext'
import moment from 'moment'
import { RefreshOutlined } from '@material-ui/icons'
import { useSectionFilters } from '../../hooks/sections/useSectionFilters'
import { FilterList, FilterTypes } from '../../components/Sections/FiltersList'

const StpTransactions = () => {
  const {
    state,
    toggle,
    byPrice,
    filterSelected,
    rangeDate,
    setByPrice,
    setFilterSelected,
    setRangeDate,
    resetFilters,
  } = useSectionFilters()
  // const { data: allLogs } = useGet(getDrEnvioLogs)
  const perBatch = 100
  const [visible, setVisible] = useState(false)
  const [pay, setPay] = useState<boolean>(false)
  // const [data, setData] = useState<{} | any>();
  const { backendURL, userToken } = useContext(ApiContext)

  const [batchesFetched, setBatchesFetched] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [monitoredFetched, setMonitoredFetched] = useState<any[]>([])
  const [monitoredFiltered, setMonitoredFiltered] = useState<any[]>([])

  const tableRef = createRef()

  const closeModal = () => {
    setVisible(false)
  }

  const columns: TColumnTable = [
    {
      title: 'Fecha',
      field: 'date',
      render: (rowData: any) => moment(rowData.date).format('lll'),
    },
    {
      title: 'Usuarios',
      field: 'stpId',
      render: (rowData: any) => {
        return rowData.name ? rowData.name : 'No disponible'
      },
    },
    {
      title: 'Email',
      field: 'Email',
      render: (rowData: any) => {
        return rowData.email ? rowData.email : 'No disponible'
      },
    },
    {
      title: 'Tipo',
      field: 'claveRastreo',
      render: (rowData: any) => {
        return rowData.type ? rowData.type : 'No disponible'
      },
    },
    {
      title: 'Monto',
      field: 'monto',
      render: (rowData: any) => {
        return rowData.amount ? rowData.amount : rowData.amount === 0 ? 0 : 'No disponible'
      },
    },
    {
      title: 'Estado',
      field: 'status',
      render: (rowData: any) => {
        const value = rowData.monitored
        return value === true ? 'Monitoreado' : 'No monitoreado'
      },
    },
    {
      title: 'Estado de pago',
      field: 'status pay',
      render: (rowData: any) => {
        const value = rowData.statusPay
        console.log(value)
        return value === true ? 'Pagado' : 'Deuda'
      },
    },
    {
      title: 'Clabe',
      field: 'Clave de rastreo',
      render: (rowData: any) => {
        return rowData.clabe ? rowData.clabe : 'No disponible'
      },
    },
  ]

  const fetchMonitoredInfo = async (initialData?: boolean) => {
    setIsLoading(true)

    const response = await axios.get(`${backendURL}users/monitored`, {
      headers: { Authorization: `Bearer ${userToken}` },
      params: {
        statusPay: pay,
        page: initialData ? 0 : batchesFetched,
        limit: initialData ? perBatch : (batchesFetched + 1) * perBatch,
        search: '',
      },
    })

    // @ts-ignore
    const optimizedData = response.data.response.map((t: object) => {
      return monitoredData(t)
    })

    setMonitoredFetched(optimizedData)
    setMonitoredFiltered(optimizedData)
    setBatchesFetched((prevBatch) => prevBatch + 1)
    setIsLoading(false)
  }

  const monitoredData = (item: any): object => {
    return {
      date: item.created_at,
      user: item.user,
      amount: item.amount,
      monitored: item.monitored,
      statusPay: item.statusPay,
      type: item.type,
      name: item.userData[0]?.name,
      email: item.userData[0]?.email,
      clabe: item.description,
    }
  }

  const handlePageChange = (page: any) => {
    if ((page + 1) * rowsPerPage === perBatch * batchesFetched) {
      fetchMonitoredInfo()
    }
  }

  useEffect(() => {
    fetchMonitoredInfo()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!byPrice && !rangeDate) {
      setMonitoredFiltered(monitoredFetched ?? [])
      return
    }

    let result: any[] = []

    if (rangeDate) {
      const lowerLimit = new Date(rangeDate[0])
      const upperLimit = new Date(rangeDate[1])

      result =
        Array.from(monitoredFetched ?? [])?.filter((c) => {
          console.log(c.date)

          const createdAt = new Date(c.date)
          console.log({
            createdAt,
          })

          return createdAt > lowerLimit && createdAt < upperLimit
        }) ?? []

      setMonitoredFiltered(result)
      return
    }

    if (byPrice) {
      if (byPrice === 'highest') {
        result = Array.from(monitoredFetched ?? [])?.sort((a, b) => {
          const formattedPriceA = Number(a.amount)
          const formattedPriceB = Number(b.amount)

          if (formattedPriceA < formattedPriceB) return 1
          if (formattedPriceA > formattedPriceB) return -1

          return 0
        })
      } else {
        result = Array.from(monitoredFetched ?? [])?.sort((a, b) => {
          const formattedPriceA = Number(a.amount)
          const formattedPriceB = Number(b.amount)

          if (formattedPriceA < formattedPriceB) return -1
          if (formattedPriceA > formattedPriceB) return 1

          return 0
        })
      }
      setMonitoredFiltered(result)
    }
  }, [byPrice, rangeDate]) //eslint-disable-line

  return (
    <>
      <Table
        tableRef={tableRef}
        columns={columns}
        isLoading={isLoading}
        data={monitoredFiltered}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={(rows) => setRowsPerPage(rows)}
        title='Transacciones STP'
        isButton={{
          title: 'Aplicar Filtros',
          onClick: () => {
            toggle()
          },
          icon: <FilterOutlined />,
        }}
        actions={[
          {
            icon: () => <RefreshOutlined />,
            tooltip: 'Filtro de pago',
            isFreeAction: true,
            onClick: () => {
              setPay(!pay)
              setBatchesFetched(0)
              fetchMonitoredInfo(true)
            },
          },
        ]}
      ></Table>
      <ModalContainer
        title={'Seleciona un Filtro'}
        visible={state}
        onCancel={toggle}
        footer={false}
      >
        <div className='JSONViewer'>
          <div style={{ display: 'flex' }}>
            <FilterList
              filterSelected={filterSelected as FilterTypes}
              resetAllFilters={resetFilters}
              setByPrice={setByPrice}
              setFilterSelected={setFilterSelected}
              setIsVisible={toggle}
              setRangeDate={setRangeDate}
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer title='Información' visible={visible} onCancel={closeModal} footer={false}>
        {/* <div className="JSONViewer">
          {data ? <ReactJson src={data} /> : "Data no disponible"}
        </div> */}
      </ModalContainer>
    </>
  )
}

export default StpTransactions
