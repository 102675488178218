import { Row, Col, Avatar, Typography, Switch, Badge, Tooltip } from "antd"
import { FC, useEffect, useState } from "react";
import CardContainer from "../../../components/Containers/CardContainer"
import usePermission from "../../../hooks/usePermission";
import { UserRoles } from "../../../interfaces/app.interfaces";
import { ActionsPermissions, AdminUserI, PermissionViews } from "../../../interfaces/permissions.interfaces";

interface props {
    user: AdminUserI;
    accountType: string;
    onClickUser: (user: any) => void
    updateUserAdmin: (state: boolean, id: string) => void
}
const UserItemList: FC<props> = ({ accountType, onClickUser, updateUserAdmin, user }) => {
    const [admin, setAdmin] = useState(true)
    const { hasAccess } = usePermission()
    const [editAdmin] = useState(hasAccess(ActionsPermissions.modify_admin_permission, PermissionViews.permissions))
    const [editPermissions] = useState(hasAccess(ActionsPermissions.update_permissions, PermissionViews.permissions))
    const { profile, _id: id, roles } = user;
    const { name, email } = profile[0]

    const changeAdmin = (state: boolean) => {
        setAdmin(state)
        updateUserAdmin(state, id)
    }

    useEffect(() => {
        if (roles && !roles.includes(UserRoles.admin)) {
            setAdmin(false)
        }
        //eslint-disable-next-line
    }, [roles])

    return (
        <CardContainer
            cardStyle={{
                margin: '25px 0',
                cursor: editPermissions ? 'pointer' : 'default'
            }}
            key={id}

        >
            <Row>
                <Col
                    span={21}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClick={() => {
                        if (admin && editPermissions) {
                            onClickUser({ algo: '' })
                        }
                    }

                    } >

                    <Col span={4} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Avatar
                            style={{
                                backgroundColor: true ? '#f56a00' : '#7265e6',
                                verticalAlign: 'middle'
                            }}
                            size="large">
                            {name?.substring(0, 1).toUpperCase()}
                        </Avatar>
                    </Col>
                    <Col span={14}>
                        <Typography style={{ fontWeight: 'bold', fontSize: '16px', letterSpacing: '0.3px' }} >
                            {name && name.length > 22 ? name?.substring(0, 23) + '...' : name}
                        </Typography>
                        <Typography style={{ fontSize: '14px', letterSpacing: '0.3px' }}>{email}</Typography>
                    </Col>
                    <Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Badge color="rgb(45, 183, 245)" text={accountType} />
                    </Col>
                </Col>
                <Col span={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'default' }} >
                    <Typography style={{ marginRight: '10px', fontSize: '0.9rem', color: '#6B6363' }}>Admin </Typography>
                    <Tooltip
                        placement="topLeft"
                        title={
                            editAdmin
                                ? admin
                                    ? 'Revocar rol Administrativo'
                                    : 'Otorgar rol administrativo'
                                : 'No tienes permisos para realizar esta acción'
                        }
                    >
                        <Switch
                            disabled={!editAdmin}
                            checked={admin}
                            onChange={(state) => {
                                changeAdmin(state)
                            }}
                        />
                    </Tooltip>
                </Col>


            </Row>
        </CardContainer>
    )
}

export default UserItemList