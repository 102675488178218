import { useState } from 'react'
import { useToggle } from '../useToggle'
import { ByPriceTypes, FilterTypes } from '../../components/Sections/FiltersList'

export const useSectionFilters = () => {
  const { state, toggle } = useToggle()
  const [rangeDate, setRangeDate] = useState<[string, string] | null>(null)
  const [filterSelected, setFilterSelected] = useState<FilterTypes | null>(null)
  const [byPrice, setByPrice] = useState<ByPriceTypes | null>(null)

  const resetFilters = () => {
    setByPrice(null)
    setFilterSelected(null)
    setRangeDate(null)
  }

  return {
    state,
    rangeDate,
    filterSelected,
    byPrice,
    toggle,
    setRangeDate,
    setFilterSelected,
    setByPrice,
    resetFilters,
  }
}
