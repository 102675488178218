import { Skeleton, Switch } from "antd"

const styles = {
    container: {
        border: '1px solid #E8E8E8',
        borderLeft: '1px solid #E8E8E8',
        borderRadius: '12px',
        padding: '20px',
        paddingBottom: '20px',
        boxShadow: '0 0 0',
        marginBottom: '16px'
    }
}
const CarrierCardSkeleton = () =>{
    return(
        <article style={styles.container}>
            <Skeleton loading={false} active>
                <section style={{ display: 'grid', gridTemplateColumns: '5rem 10rem' }}>
                    <div>
                        {/* Usar un Skeleton para la imagen */}
                        <Skeleton.Avatar active size={50} shape="square" />
                    </div>
                    <div>
                        {/* Usar un Skeleton para el texto */}
                        <Skeleton active paragraph={{ rows: 1 }} title={false} />
                        {/* Utilizar un Skeleton y un Switch para el estado "Activar" */}
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <Skeleton.Button active size="small" shape="square" style={{ marginRight: '1rem' }} />
                            <Switch
                                size="small"
                                style={{ width: '40px' }}
                                checked={false}
                                disabled // Cambia a "disabled={true}" si es necesario
                            />
                        </span>
                    </div>
                </section>
            </Skeleton>
        </article>
    )
}

export default CarrierCardSkeleton