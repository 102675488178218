import { getUserDataFromAuth0 } from "../services/user_d";
import Status from "../utils/status";
// import { Write } from "../utils/write";
import { ActionT, ReducerI, UserActionTypeT } from "./ducks";
import { UserSchema } from "./schema";

const initialState = {...UserSchema}

const PREFIX: ReducerI['prefixes'] = 'USER';
const SET_VALUE: UserActionTypeT = 'SET_VALUE';

// const User = Write({reducer: 'user'})

export const getUserData = (userId: string) => (dispatch: Function, getState: Function) =>{
    const getUserData = Status({reducer: 'user', status: 'GET_DATA'});

    dispatch(getUserData.startFetch());

    return getUserDataFromAuth0(userId)
}

export default function reducer(state = initialState, action: ActionT['action']){
    switch(action.type){
        case `${PREFIX}/${SET_VALUE}`:
            return {...state, ...action.payload};
        default: 
            return state;
    }
}