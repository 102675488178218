import { Typography } from "antd"
import CardContainer from "../../components/Containers/CardContainer"

const NoAccessPrices = () =>{
    return(
        <CardContainer
              cardStyle={{
                display: "grid",
                placeItems: "center",
                height: "6rem",
                margin: '1rem',
                boxShadow: "none",
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography.Text 
                style={{ 
                  display: "grid", 
                  placeItems: "center", 
                  height: "inherit",
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                  flex: 1,
                  }}
                >
                No tienes permisos para realizar estra acción.
              </Typography.Text>
            </CardContainer>
    )
}

export default NoAccessPrices