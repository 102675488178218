import moment from "moment";
import { useEffect, useState, useContext } from "react";
import { AdminDataPicker } from "../../components/Common/admin/AdminDataPicker";
import { Table } from "../../components/Common/Table";
import ModalContainer from "../../components/Containers/ModalContainer";
import { useGetParams } from "../../hooks/useGetParams";
import { getTopBuyers } from "../../services/information_s";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { AdminDataContext } from "../../context/adminContext";

const TopClients = () => {
  const { data, fetchData, isLoading } = useGetParams(getTopBuyers);
  const [visible, setVisible] = useState(false);
  const [rangeDate, setRangeDate] = useState<any>(null);
  const { country } = useContext(AdminDataContext);

  const closeModal = () => {
    setVisible(false);
  };

  const deleteFilter = () => {
    setRangeDate(null);
    fetchData();
  };

  const getDataFilter = () => {
    if (rangeDate) {
      fetchData({
        startDate: rangeDate
          ? moment(rangeDate[0]?._d).format("YYYY-MM-DD")
          : "",
        endDate: rangeDate ? moment(rangeDate[1]?._d).format("YYYY-MM-DD") : "",
        page: 0,
        limit: 5,
        country,
      });
    }
    setVisible(false);
    return;
  };

  useEffect(() => {
    fetchData({
      startDate: rangeDate ? moment(rangeDate[0]?._d).format("YYYY-MM-DD") : "",
      endDate: rangeDate ? moment(rangeDate[1]?._d).format("YYYY-MM-DD") : "",
      page: 0,
      limit: 5,
      country,
    });
  }, [fetchData, country, rangeDate]);

  return (
    <>
      <Table
        config={{
          sorting: true,
        }}
        isButton={{
          title: "Filtrar por fechas",
          onClick: () => setVisible(!visible),
        }}
        isLoading={isLoading}
        columns={[
          { title: "Email", field: "email" },
          {
            title: "Remitentes",
            field: "senders",
            render: (rowData: any) => {
              return rowData.senders.length
            },
          },
          {
            title: "Gastado",
            field: "spend",
            render: (rowData: any) => {
              return Number(rowData.spend).toLocaleString("en-US", {
                style: "currency",
                currency: country === "CO" ? "COP" : "MXN",
              });
            },
          },
          {
            title: "Saldo Actual",
            field: "balance",
            render: (rowData: any) => {
              return Number(rowData.balance).toLocaleString("en-US", {
                style: "currency",
                currency: country === "CO" ? "COP" : "MXN",
              });
            },
          },
          { title: "Envios", field: "shipments" },
          { title: "Ultimo Envio", field: "last_shipment" },
          { title: "Fecha de Registro", field: "created_at" },
        ]}
        totalCount={data?.count}
        data={data?.data || []}
        page={data?.page}
        onChangePage={(page, size) => {
          fetchData({
            page: page,
            limit: size,
            startDate: rangeDate
              ? moment(rangeDate[0]?._d).format("YYYY-MM-DD")
              : "",
            endDate: rangeDate
              ? moment(rangeDate[1]?._d).format("YYYY-MM-DD")
              : "",
          });
        }}
      />
      <ModalContainer
        title={"Seleciona un rango de fechas"}
        visible={visible}
        onCancel={closeModal}
        footer={false}
      >
        <div className="JSONViewer">
          <div style={customStyle.container}>
            <AdminDataPicker setValue={setRangeDate}></AdminDataPicker>

            <Button
              type="primary"
              style={customStyle.button}
              icon={<SearchOutlined />}
              onClick={getDataFilter}
              disabled={!rangeDate}
              loading={isLoading}
            >
              {isLoading ? "" : "Filtrar por fechas"}
            </Button>
            <Button
              type="primary"
              style={customStyle.button}
              icon={<DeleteOutlined />}
              onClick={deleteFilter}
              disabled={!rangeDate}
              loading={isLoading}
            ></Button>
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

export default TopClients;
// '(params?: GetTopBuyersParams | undefined) => (backendApiCall: (data: apiProps) => Promise<apiResponse<TopBuyers[]>>) => Promise<apiResponse<TopBuyers[]>>'
// '(backendApiCall: (data: apiProps<any>) => Promise<apiResponse<AxiosResponse<TopBuyers[], any>>>) => Promise<apiResponse<AxiosResponse<TopBuyers[], any>>>'
const customStyle = {
  button: {
    marginLeft: "5px",
  },
  container: {
    display: "flex",
  },
};
