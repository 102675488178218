import axios from 'axios';
import React, { useContext, createRef, useState } from 'react'
import { ApiContext } from '../../context/apiContext';
import { QueryResult } from 'material-table';
import { Col, Divider, Row, RowProps, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { TColumnTable, Table } from '../../components/Common/Table';
import { RefreshOutlined, Visibility } from '@material-ui/icons';
import { MovementStatus } from '../../components/Common/MovementStatus';
import { IconButton } from '@material-ui/core';
import ModalContainer from '../../components/Containers/ModalContainer';
import { formatPrice2 } from '../../utils/prices';

const AdminLogs = () => {

    const [visible, setVisible] = useState<boolean>(false)
    const [modalData, setModalData] = useState<any>()
    const [type, setType] = useState<string>('')
    const { backendURL, userToken } = useContext(ApiContext);

    const tableRef = createRef()

    const dataModal = (data: any, action: string) => {
        setVisible(true)
        setModalData(data)
        setType(action)
        console.log(data)
    }

    const columns: TColumnTable = [
        {
            title: "Fecha",
            field: "date",
        },
        {
            title: "Responsable",
            field: "admin_name",
            render: (rowData: any) => {
                return (
                    <Tooltip title={rowData?.admin_id} style={{ cursor:'pointer' }}>
                        {rowData?.admin_name}
                    </Tooltip>
                )
            },
        },
        {
            title: "Usuario",
            field: "customer_name",
            render: (rowData: any) => {
                return (
                    <Tooltip title={rowData?.customer_id} style={{ cursor:'pointer' }}>
                        {rowData?.customer_name}
                    </Tooltip>
                )
            },
        },
        {
            title: "Descripción",
            field: "description"
        },
        {
            title: "Tipo",
            field: "action",
            render: (rowData: any) => {
                return <MovementStatus status={rowData?.action}></MovementStatus>
            }
        },
        {
            title: "Acciones",
            render: (rowData: any) => {
                return (
                    <Tooltip title="Información del registro">
                        <IconButton disabled={rowData?.metadata === undefined ? true : false} onClick={() => dataModal(rowData, rowData?.action)}>
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                )
            }
        }
      ];

    const fetchData = (query: any): Promise<QueryResult<RowProps>> => {
    
        return new Promise((resolve, reject) => {
          axios
            .get(`${backendURL}admin/actions/logs`, {
              headers: { Authorization: `Bearer ${userToken}` },
              params: {
                page: query.page,
                limit: query.pageSize,
                search: query.search,
              },
            })
            .then((result) => {
              resolve({
                data:
                  result.data.response?.map((item: any) => ({
                    date: moment(item?.created_at).format("lll"),
                    customer_id: item?.customer,
                    customer_name: item?.customerData[0]?.name,
                    customer_email: item?.customerData[0]?.email,
                    customer_phone: item?.customerData[0]?.phone,
                    admin_id: item?.admin,
                    admin_name: item?.adminData[0]?.name,
                    admin_email: item?.adminData[0]?.email,
                    admin_phone: item?.adminData[0]?.phone,
                    action: item?.action,
                    description: item?.description,
                    metadata: item?.metadata
                  })) || [],
                page: query.page,
                totalCount: result.data.total,
              });
            });
        });
      };

      const dataSelector = (action: string) => {

        const usersData = () => {
            return (
                <>
                    <Col span={12}>
                        <span style={{ fontWeight:'600' }}>Datos del responsable</span>
                        <Row gutter={[20,10]} style={{ textAlign:'center' }}>
                            <Col span={24}>
                                <Tag color='blue'>{modalData?.admin_id}</Tag>
                            </Col>
                            <Col span={12}>
                                <span style={{ fontWeight:'600' }}>Nombre</span><br />
                                <span>{modalData?.admin_name}</span>
                            </Col>
                            <Col span={12}>
                                <span style={{ fontWeight:'600' }}>Télefono</span><br />
                                <span>{modalData?.admin_phone}</span>
                            </Col>
                            <Col span={24}>
                                <span style={{ fontWeight:'600' }}>Email</span><br />
                                <span>{modalData?.admin_email}</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <span style={{ fontWeight:'600' }}>Datos del usuario</span>
                        <Row gutter={[20,10]} style={{ textAlign:'center' }}>
                            <Col span={24}>
                                <Tag color='blue'>{modalData?.customer_id}</Tag>
                            </Col>
                            <Col span={12}>
                                <span style={{ fontWeight:'600' }}>Nombre</span><br />
                                <span>{modalData?.customer_name}</span>
                            </Col>
                            <Col span={12}>
                                <span style={{ fontWeight:'600' }}>Télefono</span><br />
                                <span>{modalData?.customer_phone}</span>
                            </Col>
                            <Col span={24}>
                                <span style={{ fontWeight:'600' }}>Email</span><br />
                                <span>{modalData?.customer_email}</span>
                            </Col>
                        </Row>
                    </Col>
                </>
            )
        }

        switch (action) {
            case 'DESCUENTO':
                return (
                  <Row style={{ textAlign: "center" }}>
                        { usersData() }
                        <Divider></Divider>
                        <Col span={24}>
                            <span style={{ fontWeight:'600' }}>Monto Descontado</span>
                            <br />${" "}
                            {formatPrice2(
                                modalData?.metadata?.movementData[0]?.amount || 0,
                                modalData?.metadata?.movementData[0]?.currency
                            )}
                            {modalData?.metadata?.movementData[0]?.currency}
                        </Col>
                        <Col span={12}>
                            <span style={{ fontWeight:'600' }}>Saldo Antes</span>
                            <br />${" "}
                            {formatPrice2(
                                modalData?.metadata?.movementData[0]?.security?.before_mod || 0,
                                modalData?.metadata?.movementData[0]?.currency
                            )}
                            {modalData?.metadata?.movementData[0]?.currency}
                        </Col>
                        <Col span={12}>
                            <span style={{ fontWeight:'600' }}>Saldo Después</span>
                            <br />${" "}
                            {formatPrice2(
                                modalData?.metadata?.movementData[0]?.security?.after_mod || 0,
                                modalData?.metadata?.movementData[0]?.currency
                            )}
                            {modalData?.metadata?.movementData[0]?.currency}
                        </Col>
                  </Row>
                );
            case 'RECARGA':
                return (
                    <Row style={{ textAlign:'center' }}>
                        { usersData() }
                        <Divider></Divider>
                        <Col span={24}>
                            <span style={{ fontWeight:'600' }}>Monto Abonado</span>
                            <br />${" "}
                            {formatPrice2(
                                modalData?.metadata?.movementData[0]?.amount || 0,
                                modalData?.metadata?.movementData[0]?.currency
                            )}
                            {modalData?.metadata?.movementData[0]?.currency}
                        </Col>
                        <Col span={12}>
                            <span style={{ fontWeight:'600' }}>Saldo Antes</span>
                            <br />${" "}
                            {formatPrice2(
                                modalData?.metadata?.movementData[0]?.security?.before_mod || 0,
                                modalData?.metadata?.movementData[0]?.currency
                            )}
                            {modalData?.metadata?.movementData[0]?.currency}
                        </Col>
                        <Col span={12}>
                            <span style={{ fontWeight:'600' }}>Saldo Después</span>
                            <br />${" "}
                            {formatPrice2(
                                modalData?.metadata?.movementData[0]?.security?.after_mod || 0,
                                modalData?.metadata?.movementData[0]?.currency
                            )}
                            {modalData?.metadata?.movementData[0]?.currency}
                        </Col>
                    </Row>
                );
            case 'ACTUALIZACIÓN DE USUARIO':
                return (
                  <Row style={{ textAlign: "center" }}>
                    <Col span={24}>
                      <span style={{ fontWeight: "600" }}>
                        Datos del responsable
                      </span>
                      <Row gutter={[20, 10]} style={{ textAlign: "center" }}>
                        <Col span={24}>
                          <Tag color="blue">{modalData?.admin_id}</Tag>
                        </Col>
                        <Col span={8}>
                          <span style={{ fontWeight: "600" }}>Nombre</span>
                          <br />
                          <span>{modalData?.admin_name}</span>
                        </Col>
                        <Col span={8}>
                          <span style={{ fontWeight: "600" }}>Télefono</span>
                          <br />
                          <span>{modalData?.admin_phone}</span>
                        </Col>
                        <Col span={8}>
                          <span style={{ fontWeight: "600" }}>Email</span>
                          <br />
                          <span>{modalData?.admin_email}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Divider></Divider>
                    <Col span={12}>
                      <span style={{ fontWeight: "600" }}>Perfil antes</span>
                      <Row gutter={[20, 10]} style={{ textAlign: "center" }}>
                        <Col span={12}>
                          <span style={{ fontWeight: "600" }}>Nombre</span>
                          <br />
                          <span
                            style={{
                              background: `${
                                modalData?.metadata?.new_data?.name !==
                                modalData?.metadata?.old_data?.name
                                  ? "#f0ff1c"
                                  : "transparent"
                              }`,
                            }}
                          >
                            {modalData?.metadata?.new_data?.name}
                          </span>
                        </Col>
                        <Col span={12}>
                          <span style={{ fontWeight: "600" }}>Télefono</span>
                          <br />
                          <span
                            style={{
                              background: `${
                                modalData?.metadata?.new_data?.phone !==
                                modalData?.metadata?.old_data?.phone
                                  ? "#f0ff1c"
                                  : "transparent"
                              }`,
                            }}
                          >
                            {modalData?.metadata?.new_data?.phone}
                          </span>
                        </Col>
                        <Col span={24}>
                          <span style={{ fontWeight: "600" }}>Email</span>
                          <br />
                          <span
                            style={{
                              background: `${
                                modalData?.metadata?.new_data?.email !==
                                modalData?.metadata?.old_data?.email
                                  ? "#f0ff1c"
                                  : "transparent"
                              }`,
                            }}
                          >
                            {modalData?.metadata?.new_data?.email}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                        <span style={{ fontWeight: "600" }}>Perfil después</span>
                        <Row gutter={[20, 10]} style={{ textAlign: "center" }}>
                            <Col span={12}>
                            <span style={{ fontWeight: "600" }}>Nombre</span>
                            <br />
                            <span
                                style={{
                                background: `${
                                    modalData?.metadata?.new_data?.name !==
                                    modalData?.metadata?.old_data?.name
                                    ? "#f0ff1c"
                                    : "transparent"
                                }`,
                                }}
                            >
                                {modalData?.metadata?.old_data?.name}
                            </span>
                            </Col>
                            <Col span={12}>
                            <span style={{ fontWeight: "600" }}>Télefono</span>
                            <br />
                            <span
                                style={{
                                background: `${
                                    modalData?.metadata?.new_data?.phone !==
                                    modalData?.metadata?.old_data?.phone
                                    ? "#f0ff1c"
                                    : "transparent"
                                }`,
                                }}
                            >
                                {modalData?.metadata?.old_data?.phone}
                            </span>
                            </Col>
                            <Col span={24}>
                            <span style={{ fontWeight: "600" }}>Email</span>
                            <br />
                            <span
                                style={{
                                background: `${
                                    modalData?.metadata?.new_data?.email !==
                                    modalData?.metadata?.old_data?.email
                                    ? "#f0ff1c"
                                    : "transparent"
                                }`,
                                }}
                            >
                                {modalData?.metadata?.old_data?.email}
                            </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                );
        
            default:
                break;
        }
      }

  return (
    <>
    <Table
        tableRef={tableRef}
        columns={columns}
        //@ts-ignore
        data={(query) => fetchData(query)}
        title="Actividad de administración"
        actions={[
          {
            icon: () => <RefreshOutlined />,
            tooltip: "Refrescar datos",
            isFreeAction: true,
            //@ts-ignore
            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
          },
        ]}
      ></Table>
      <ModalContainer
        footer={<></>}
        title="Información del registro"
        visible={visible}
        onCancel={() => setVisible(false)}
      >
        { dataSelector(type) }
      </ModalContainer>
    </>
  )
}

export default AdminLogs