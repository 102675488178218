import { FC } from 'react';

interface props{
    videoId: any
}
const YouTubeEmbed:FC<props> = ({ videoId }) => {
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="video-container">
      <iframe
        width="560"
        height="315"
        src={embedUrl}
        title="YouTube video player"
        frameBorder={0}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeEmbed;
