import { FC, useState } from "react";
import { ArrowLeftOutlined, MenuOutlined } from '@ant-design/icons';
import UserAvatar from "../Components/UserAvatar";
import { UserItemI } from "../Search/SearchItem";
import { Col, Row, Typography } from "antd";
import MaintenancePage from "./MaintenancePage";
import ProfilePage from "./ProfilePage";
import VerificationsPage from "./VerificationsPage";
import { dataToShowI, ModalTableI, prefrencesMenu, userActionsT } from "../interfaces";
import { useMediaQuery } from "react-responsive";
import SectionsMenu from "../Components/SectionsMenu";
import SectionsMenuModal from "../Components/Modals/SectionsMenuModal";
import PreferencesPage from "./PreferencesPage";
import PreferencesAdminPage from "./PreferencesAdminPage";
import PluginsPage from "./PluginsPage";
import SpecialPricesPage from "./SpecialPricesPage";
import UserActionsModal from "../Components/Modals/UserActionsModal";

interface props {
    userFound: UserItemI | null;
    fullUser: dataToShowI | null;
    prevStep: () => void;
    getFullUser: (user: string) => void;
    newUserFound: (user: UserItemI) => void;
    setModalTable: (value: ModalTableI) => void;
    showDrawer: () => void;
    handleMenu: (item: prefrencesMenu) => void;
    selectedMenu: any;
}

interface Styles {
    topHeader: React.CSSProperties;
    mainRow: React.CSSProperties;
    mainSection: React.CSSProperties;
    avatarSection: React.CSSProperties;
    contentCol: React.CSSProperties;
    contentColMobile: React.CSSProperties;
    backButton: React.CSSProperties;
    menuIcon: React.CSSProperties;
    advancedIcon: React.CSSProperties;
    mobileMainSection: React.CSSProperties;
}

const styles: Styles = {
    topHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    mainRow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '1rem'
    },
    mainSection: {
        width: '100%',
        minHeight: '43rem',
        maxHeight: '43rem',
        overflow: 'hidden',
        padding: '30px',
        borderRadius: '20px',
        backgroundColor: 'white',
        boxShadow: '4px 2px 14px rgba(45, 27, 110, 0.13)'
    },
    mobileMainSection: {
        padding: '30px 16px',
        width: '100%',
        minHeight: '100%',
        height: 'fit-content',
        borderRadius: '20px',
        backgroundColor: 'white',
        boxShadow: '4px 2px 14px rgba(45, 27, 110, 0.13)'
    },
    avatarSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.95rem',
        padding: '8px',
        gap: '0.4rem'
    },
    contentCol: {
        padding: '0 2rem',
        width: '100%',
        overflowY: 'auto',
        maxHeight: '39rem'

    },
    contentColMobile: {
        padding: '1rem 0',
        width: '100%'
    },
    backButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px',
        border: '1px solid black',
        borderRadius: '6px',
        width: '3rem',
        height: '3rem'
    },
    menuIcon: {
        fontSize: '32px',
        color: 'inherit',
    },
    advancedIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px'
    }
}


const UserView: FC<props> = ({ 
    prevStep, 
    userFound, 
    fullUser, 
    getFullUser, 
    newUserFound, 
    setModalTable, 
    showDrawer,
    handleMenu,
    selectedMenu
}) => {
    const [visibleModal, setVisibleMenu] = useState(false)
    const [visibleActionsModal, setVisibleActionsModal] = useState(false)
    const [actionsType, setActionsType] = useState<userActionsT>('update')
    const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1199px)' })
    const breakWordMin = useMediaQuery({ query: '(min-width: 1200px)' })
    const breakWordMax = useMediaQuery({ query: '(max-width: 1550px)' })

    const changeModal = () => {
        setVisibleMenu(!visibleModal)
    }

    const changeModalActions = () => {
        setVisibleActionsModal(!visibleActionsModal)
    }

    const changeActions = (type: userActionsT) => {
        setVisibleActionsModal(!visibleActionsModal)
        setActionsType(type)
    }


    const getContent = () => {
        switch (selectedMenu) {
            case 'profile':
                return <ProfilePage
                    userFound={userFound as UserItemI}
                    fullUser={fullUser}
                    changeActions={changeActions}
                    setModalTable={setModalTable}
                    showDrawer={showDrawer}
                    
                />
            case 'verifications':
                return <VerificationsPage
                    userFound={userFound as UserItemI}
                    fullUser={fullUser}
                    changeActions={changeActions}
                    refetch={getFullUser}
                    showDrawer={showDrawer}
                />
            case 'preferences_block':
                return <PreferencesAdminPage
                    userFound={userFound as UserItemI}
                    fullUser={fullUser}
                    changeActions={changeActions}
                    showDrawer={showDrawer}
                />
            case 'user_preferences':
                return <PreferencesPage
                    userFound={userFound as UserItemI}
                    fullUser={fullUser}
                    changeActions={changeActions}
                    showDrawer={showDrawer}
                />
            case 'plugins':
                return <PluginsPage
                    userFound={userFound as UserItemI}
                    fullUser={fullUser}
                    changeActions={changeActions}
                    showDrawer={showDrawer}
                />
            case 'special_prices':
                return <SpecialPricesPage
                    userFound={userFound as UserItemI}
                    fullUser={fullUser}
                    changeActions={changeActions}
                    showDrawer={showDrawer}
                />

            default:
                return <MaintenancePage />
        }
    }

    return (
        <Row
            className="dashboardSection"
            style={isMobileOrTablet ? { ...styles.mobileMainSection } : styles.mainSection}
        >
            <Col xs={24} sm={24} md={24} lg={24} xl={5} xxl={5}>
                <Row style={styles.mainRow}>
                    <Col span={24} style={styles.topHeader}>
                        <div
                            className="user-search-item"
                            onClick={prevStep}
                            style={styles.backButton}>
                            <ArrowLeftOutlined size={24} />

                        </div>
                        {
                            isMobileOrTablet && (
                                <section className="user-advanced-options-settings">
                                    <MenuOutlined
                                        style={styles.menuIcon}
                                        onClick={changeModal}
                                    />
                                </section>
                            )
                        }

                    </Col>
                    <Col span={24}>
                        <section style={styles.avatarSection}>
                            <UserAvatar userFound={userFound as UserItemI} />
                            <Typography style={{ fontWeight: '600', textAlign: 'center', overflowWrap: "break-word" }}>{userFound?.name} </Typography>
                            <Typography style={{ overflowWrap: "break-word", textAlign: 'center' }}>
                                {
                                    breakWordMin && breakWordMax && userFound?.email &&
                                        (userFound?.email?.split('@')[0]?.length > 20 || userFound?.email?.split('@')[1]?.length > 20) ?
                                        `${userFound?.email?.split('@')[0]} @${userFound?.email?.split('@')[1]}` :
                                        userFound?.email
                                }
                            </Typography>
                        </section>
                    </Col>

                    <Col span={24}>
                        {
                            !isMobileOrTablet && (
                                <SectionsMenu handleMenu={handleMenu} />
                            )
                        }
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={19} xxl={19} style={isMobileOrTablet ? styles.contentColMobile : styles.contentCol}>
                {
                    getContent()
                }
            </Col>

            <SectionsMenuModal
                visible={visibleModal}
                handleMenu={handleMenu}
                manageModal={changeModal}
            />
            <UserActionsModal
                type={actionsType}
                visible={visibleActionsModal}
                userFound={userFound as UserItemI}
                manageModal={changeModalActions}
                getFullUser={getFullUser}
                newUserFound={newUserFound}
            />
        </Row>
    )
}

export default UserView