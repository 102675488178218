import { FC } from "react";
import Moneyback from "../../../src/assets/images/Movements/back_status_ico.png";
import Shipment from "../../../src/assets/images/Movements/send_status_ico.png";
import Pkg from "../../../src/assets/images/Movements/box_status_ico.png";
import Money from "../../../src/assets/images/Movements/recar_status_ico.png";
import UpdateUser from "../../../src/assets/images/Movements/actualizar-signo-de-usuario.png";
import BlockUser from "../../../src/assets/images/Movements/bloquear.png";
import { MovementTypes } from "../../interfaces/shipments.interface";
import { Tooltip } from "antd";

interface Movements {
  status: MovementTypes;
}

function titleCaseWord(word: string) {
  if (!word) return word;
  return word[0].toUpperCase() + word.substr(1).toLowerCase();
}

export const MovementStatus: FC<Movements> = ({ status }) => {
  const statusbadged = (type: MovementTypes) => {
    switch (type) {
      case "RECARGA":
        return (
          <div style={{ backgroundColor: "#5FCE8C", ...chipStyle }}>
            <img style={chipStyle.imgStye} src={Money} alt="money" />
            {titleCaseWord(type)}
          </div>
        );
      case "SOBREPESO":
      case "DESCUENTO":
        return (
          <div style={{ backgroundColor: "#FF413F", ...chipStyle }}>
            <img style={chipStyle.imgStye} src={Pkg} alt="Pkg" />
            {titleCaseWord(type)}
          </div>
        );
      case "ENVIO":
        return (
          <div style={{ backgroundColor: "#54C0F9", ...chipStyle }}>
            <img style={chipStyle.imgStye} src={Shipment} alt="Shipment" />
            {titleCaseWord(type)}
          </div>
        );
      case "REEMBOLSO":
        return (
          <div style={{ backgroundColor: "#F07C54", ...chipStyle }}>
            <img style={chipStyle.imgStye} src={Moneyback} alt="Moneyback" />
            {titleCaseWord(type)}
          </div>
        );
      case "SINCRONIZACION DE SALDO":
        return (
          <div style={{ backgroundColor: "rgb(109, 113, 246)", ...chipStyle }}>
            <img style={chipStyle.imgStye} src={Moneyback} alt="Moneyback" />
            Sync
          </div>
        );
      case "NOTIFICATION":
        return (
          <div style={{ backgroundColor: "rgb(109, 113, 246)", ...chipStyle }}>
            <img style={chipStyle.imgStye} src={Moneyback} alt="Moneyback" />
            {titleCaseWord('Notificacion')}
          </div>
        );
      case "REFERIDO":
        return (
          <div style={{ backgroundColor: "rgb(109, 113, 246)", ...chipStyle }}>
            <img style={chipStyle.imgStye} src={Moneyback} alt="Moneyback" />
            {titleCaseWord(type)}
          </div>
        );
      case "ACTUALIZACIÓN DE USUARIO":
        return (
          <Tooltip title='Actualización de usuario'>
            <div style={{ backgroundColor: "#54C0F9", ...chipStyle }}>
              <img style={chipStyle.imgStye} src={UpdateUser} alt="Update" />
              {titleCaseWord('Actualización')}
            </div>
          </Tooltip>
        );
      case "BLOQUEO DE USUARIO":
        return (
          <Tooltip title='Bloqueo de usuario'>
            <div style={{ backgroundColor: "#FF413F", ...chipStyle }}>
              <img style={chipStyle.imgStye} src={BlockUser} alt="Pkg" />
              {titleCaseWord('Bloqueo')}
            </div>
          </Tooltip>
        );
      case "DESBLOQUEO DE USUARIO":
        return (
          <Tooltip title='Desbloqueo de usuario'>
            <div style={{ backgroundColor: "#5FCE8C", ...chipStyle }}>
              <img style={chipStyle.imgStye} src={BlockUser} alt="Pkg" />
              {titleCaseWord('Desbloqueo')}
            </div>
          </Tooltip>
        );
      default:
        return <div>{type}</div>;
    }
  };

  return (
    <Tooltip title={titleCaseWord(status)}>{statusbadged(status)}</Tooltip>
  );
};

const chipStyle = {
  display: "flex",
  fontWeight: "500",
  width: "120px",
  height: "25px",
  borderRadius: "30px",
  padding: "3%",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  imgStye: { width: "14px", marginRight: "8px" },
};
