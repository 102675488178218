import { Col, Row, Collapse } from "antd";
import React, { Fragment } from "react";

import PrestaShop from "../../../../assets/images/StoreLogos/PrestaShop.png";
import Shopify from "../../../../assets/images/StoreLogos/Shopify.webp";
import SquareSpace from "../../../../assets/images/StoreLogos/SquareSpace.png";
import TiendaNube from "../../../../assets/images/StoreLogos/TiendaNube.png";
import WixStores from "../../../../assets/images/StoreLogos/WixStores.png";
import ECWID from "../../../../assets/images/StoreLogos/Ecwid.png";
import WooCommerce from "../../../../assets/images/StoreLogos/WooCommerce.webp";
import DrenvioPlugin from "../../../../assets/images/StoreLogos/DrenvioPlugin.png";
import MercadoLibre from "../../../../assets/images/StoreLogos/MercadoLibre.webp";
import eBay from "../../../../assets/images/StoreLogos/ebay.png";
import Amazon from "../../../../assets/images/StoreLogos/Amazon.png";
import Magento from "../../../../assets/images/StoreLogos/Magento.png";
import ApiDoc from "../../../../assets/images/StoreLogos/DrenvioAPI.webp";
import { DashboardAnalyticsRes } from "../../../../interfaces/admin.interface";
import CardContainer from "../../../../components/Containers/CardContainer";
import LoadingAnimation from "../../../../components/LoadingAnimation";

type Props = {
  filteredData: DashboardAnalyticsRes;
  isLoading: boolean;
};

const Panel = Collapse.Panel;

const getImageByName = (store: string) => {
  switch (store.toUpperCase()) {
    case "WOOCOMMERCE":
      return WooCommerce;
    case "SHOPIFY":
      return Shopify;
    case "PRESTASHOP":
      return PrestaShop;
    case "WIX":
      return WixStores;
    case "SQUARESPACE":
      return SquareSpace;
    case "DRENVIOPLUGIN":
      return DrenvioPlugin;
    case "MERCADOLIBRE":
      return MercadoLibre;
    case "TIENDANUBE":
      return TiendaNube;
    case "MAGENTO":
      return Magento;
    case "ECWID":
      return ECWID;
    case "AMAZON":
      return Amazon;
    case "EBAY":
      return eBay;
    case "APIDOC":
      return ApiDoc;
    default:
      return store;
  }
};

const IntegrationData = ({ filteredData, isLoading }: Props) => {
  return (
    <CardContainer
      title="Integraciones más usadas"
      titleStyle={{ placeItems: 'center' }}
      cardStyle={{ marginTop: "20px" }}
    >
      <hr />
      <Row>
        {filteredData?.IntegrationsData?.length > 0 && (
          <>
            <Col
              span={11}
              style={{
                margin: "auto",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <p>Integración</p>
            </Col>
            <Col span={5}></Col>
            <Col
              span={8}
              style={{ margin: "auto", fontWeight: "bold", textAlign: "right" }}
            >
              <p>Cantidad</p>
            </Col>
          </>
        )}
        <Col span={24} style={{ maxHeight: "25vh", overflowY: "auto" }}>
          {isLoading ? (
            <LoadingAnimation animationType="small" />
          ) : filteredData?.IntegrationsData?.length === 0 ? (
            <p
              style={{
                fontWeight: "bold",
                margin: "50px auto",
                color: "#0008",
              }}
            >
              No hay datos para mostrar.
            </p>
          ) : (
            filteredData?.IntegrationsData?.map((item: any, index: number) => {
              return (
                <Row key={index}>
                  <Col
                    span={20}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Collapse
                      bordered={false}
                      defaultActiveKey={["1"]}
                      style={{ background: "none", width: "100%" }}
                    >
                      <Panel
                        header={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {item._id === "MAGENTO" ? (
                              item._id
                            ) : (
                              <img
                                style={{
                                  width: "100%",
                                  maxWidth: "100px",
                                  marginRight: "10px",
                                }}
                                alt={item._id.name}
                                loading="lazy"
                                src={getImageByName(item._id)}
                              />
                            )}
                          </div>
                        }
                        key={item._id}
                        style={{ borderBottom: "none", border: "none" }}
                      >
                        <div style={{ paddingLeft: 25 }}>
                          {item.userList.map((userItem: any, idx: number) => {
                            return (
                              <Fragment key={idx}>
                                <p>{userItem}</p>
                              </Fragment>
                            );
                          })}
                        </div>
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col
                    span={4}
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "#2D1B6E",
                      paddingTop: "1vh",
                    }}
                  >
                    {item.count}
                  </Col>
                </Row>
              );
            })
          )}
        </Col>
      </Row>
    </CardContainer>
  );
};

export default IntegrationData;
