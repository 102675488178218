// Libraries
import React, { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";

// Contexts
import { AdminDataContext } from "../../../context/adminContext";
import { ApiContext } from "../../../context/apiContext";

// Components
import { Table } from "../../../components/Common/Table";
import { Button, Col, Row, Select } from "antd";
import { myShipmentsColumn } from "../../../services/records";
import { getParcelImage } from "../../../services/records";
import ModalContainer from "../../../components/Containers/ModalContainer";
import { AdminDataPicker } from "../../../components/Common/admin/AdminDataPicker";

// Icons and Styling
import {
  CloseOutlined,
  CalendarOutlined,
  DollarOutlined,
  FilterOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

// Required Types
type ByPriceTypes = "highest" | "lowest";

type FilterTypes = "ByDate" | "ByPrice";

interface FilterProps {
  name: string;
  component: React.ReactElement | null;
  icon: React.ReactElement;
}

type Filters = {
  [key in FilterTypes]: FilterProps;
};

// Constants
const perShipmentsBatch = 200;
let inputTypingTimer: any;

const AdminShipments: React.FC = () => {
  // Context Variables
  const { country } = useContext(AdminDataContext);
  const { backendApiCall } = useContext(ApiContext);

  // Filters List
  const FilterList: Filters = {
    ByDate: {
      name: "Rango de Fechas",
      component: (
        <AdminDataPicker
          setValue={(e: any) => {
            setRangeDate([
              moment(e[0]._d).format("YYYY-MM-DD"),
              moment(e[1]._d).format("YYYY-MM-DD"),
            ]);
            setVisible(false);
          }}
        />
      ),
      icon: <CalendarOutlined />,
    },
    ByPrice: {
      name: "Orden de Precios",
      component: (
        <Select
          onChange={(e: ByPriceTypes) => {
            setByPrice(e);
            setVisible(false);
          }}
          placeholder="Selecciona una opción"
          options={[
            {
              label: "Ordenar por precios más altos",
              value: "highest",
            },
            {
              label: "Ordernar por precios más bajos",
              value: "lowest",
            },
          ]}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      ),
      icon: <DollarOutlined />,
    },
  };

  // Filter, Modal and Table Control Variables
  let [isLoading, setIsLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [rangeDate, setRangeDate] = useState<[string, string] | null>(null);
  const [filterSelected, setFilterSelected] = useState<FilterTypes | null>(
    null
  );
  const [byPrice, setByPrice] = useState<ByPriceTypes | null>(null);
  const [wordSearched, setWordSearched] = useState<string | undefined>(
    undefined
  );
  const isAnyFilterActive = useMemo((): boolean => {
    if (byPrice || wordSearched || rangeDate) return true;
    else return false;
  }, [byPrice, wordSearched, rangeDate]);

  // Paginas y Filas Actualmente Seleccionadas
  const [currentTablePage, setCurrentTablePage] = useState<number>(0);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState<number>(5);

  // Cantidad de API calls que se han hechos para Envios y Búsquedas
  const [shipmentsBatchesFetched, setShipmentsBatchesFetched] =
    useState<number>(0);
  const [searchesBatchesFetched, setSearchesBatchesFetched] =
    useState<number>(0);

  // Listas de Objetos para Envios y Búsquedas
  const [searchedShipmentsFetched, setSearchedShipmentsFetched] = useState<
    object[]
  >([]);
  const [shipmentsFetched, setShipmentsFetched] = useState<object[]>([]);

  // Función para Obtener y dar Formato a los Envios
  const FetchAndFormatShipments = async () => {
    console.log("Fetching more shipments");
    setIsLoading(true);

    const FormatShipments = (shipment: any) => {
      return {
        metadata: shipment.metadata || {},
        applicationDate: new Date(shipment?.created_at).toLocaleDateString(),
        user: shipment.user.user,
        mail: shipment.profile
          ? shipment.profile?.email
          : "Email no registrado",
        tracking: shipment.tracking
          ? shipment.tracking
          : "Rastreo no registrado",
        addressee: shipment.origin.name,
        destination: shipment.destination.name,
        Parcel: getParcelImage(shipment.carrier as AllCarriers),
        Total: shipment.price.toLocaleString("en-US", {
          style: "currency",
          currency: country === "CO" ? "COP" : "MXN",
        }),
        shipmentData: shipment,
        shipment_status: shipment.shipment_status,
        provider: shipment.service_id
          ? shipment.service_id.split("_")[2] || "-"
          : "none",
      };
    };

    const FetchShipments = async (): Promise<object[]> => {
      let from = shipmentsBatchesFetched * perShipmentsBatch;
      let to = (shipmentsBatchesFetched + 1) * perShipmentsBatch;
      let word = wordSearched ? wordSearched : "none";
      let filter = "none";

      if (isAnyFilterActive) {
        from = searchesBatchesFetched * perShipmentsBatch;
        to = (searchesBatchesFetched + 1) * perShipmentsBatch;
      }

      switch (filterSelected) {
        case "ByDate":
          if (rangeDate) {
            filter = `${rangeDate[0]}~${rangeDate[1]}`;
          }
          break;
        case "ByPrice":
          if (byPrice) {
            filter = byPrice;
          }
          break;
      }

      const response = await backendApiCall({
        method: "GET",
        endpoint: `shipments/api-shipments/${country}/${from}/${to}/${word}/${filter}`,
      });

      // @ts-ignore
      return response.data.data.map((shipment: any) =>
        FormatShipments(shipment)
      );
    };

    const shipments = await FetchShipments();

    if (isAnyFilterActive) {
      setSearchedShipmentsFetched((prevShipments) =>
        prevShipments.concat(shipments)
      );
      setSearchesBatchesFetched((prevBatches) => prevBatches + 1);
    } else {
      setSearchesBatchesFetched(0);
      setShipmentsFetched((prevShipments) => prevShipments.concat(shipments));
      setShipmentsBatchesFetched((prevBatches) => prevBatches + 1);
    }

    setIsLoading(false);
  };

  // Función para Esperar hasta que se escriba un Texto completo en el campo "Search"
  const WordSearcherOptimizer = (word: string) => {
    clearTimeout(inputTypingTimer);
    inputTypingTimer = setTimeout(() => {
      setWordSearched(word);
    }, 1000);
  };

  const ResetAllFilters = () => {
    setByPrice(null);
    setFilterSelected(null);
    setRangeDate(null);
    setSearchedShipmentsFetched([]);
    setSearchesBatchesFetched(0);
    setCurrentTablePage(1);
  };

  // Cada que cambie el Páis buscar Envíos por País
  useEffect(() => {
    FetchAndFormatShipments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  // Cada que cambie algun Filtro aplicarlos y Buscar Envios
  useEffect(() => {
    if (isAnyFilterActive) {
      FetchAndFormatShipments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnyFilterActive]);

  //  Cada que Cambie la Info de la Tabla de Envíos, si esta en la última página Cargar más Envios
  useEffect(() => {
    if (isAnyFilterActive) {
      const searchedPagesTotal =
        searchedShipmentsFetched.length % currentRowsPerPage === 0
          ? Math.floor(searchedShipmentsFetched.length / currentRowsPerPage) ===
            0
            ? 0
            : Math.floor(searchedShipmentsFetched.length / currentRowsPerPage) -
              1
          : Math.floor(searchedShipmentsFetched.length / currentRowsPerPage);

      console.log(searchedPagesTotal);
      console.log(Math.floor(currentTablePage));

      if (
        searchedPagesTotal !== 0 &&
        currentTablePage !== 0 &&
        searchedPagesTotal === currentTablePage
      ) {
        console.log("last page!");
        FetchAndFormatShipments();
      }
    } else {
      const ShipmentsTableIsOnLastPage =
        (currentTablePage + 1) * currentRowsPerPage ===
          perShipmentsBatch * shipmentsBatchesFetched &&
        currentTablePage !== 0 &&
        shipmentsBatchesFetched !== 0;

      if (ShipmentsTableIsOnLastPage) {
        console.log("last page!");
        FetchAndFormatShipments();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentRowsPerPage,
    currentTablePage,
    shipmentsBatchesFetched,
    searchedShipmentsFetched,
    isAnyFilterActive,
  ]);

  return (
    <>
      <Table
        data={isAnyFilterActive ? searchedShipmentsFetched : shipmentsFetched}
        isLoading={isLoading}
        columns={myShipmentsColumn(true)}
        onChangePage={(page) => setCurrentTablePage(page)}
        title="Envios más recientes"
        onSearchChange={(word) => WordSearcherOptimizer(word)}
        onChangeRowsPerPage={(rows) => setCurrentRowsPerPage(rows)}
        isButton={{
          title: isAnyFilterActive ? "Quitar Filtros" : "Aplicar Filtros",
          onClick: () => {
            if (isAnyFilterActive) {
              ResetAllFilters();
            } else {
              setVisible(true);
            }
          },
          icon: isAnyFilterActive ? <CloseOutlined /> : <FilterOutlined />,
        }}
      />

      <ModalContainer
        title={"Seleciona un Filtro"}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={false}
      >
        <div className="JSONViewer">
          <div style={{ display: "flex" }}>
            {filterSelected ? (
              <Row
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <Col span={2}>
                  <Button
                    key={"GoBack"}
                    icon={<ArrowLeftOutlined />}
                    onClick={ResetAllFilters}
                  />
                </Col>

                <Col span={22}>{FilterList[filterSelected].component}</Col>
              </Row>
            ) : (
              <Row
                style={{
                  width: "100%",
                  height: "100%",
                }}
                gutter={[10, 10]}
              >
                {Object.entries(FilterList).map((filterEntry) => {
                  const [key, value] = filterEntry;

                  return (
                    <Col span={12}>
                      <Button
                        icon={value.icon}
                        block
                        // @ts-ignore
                        onClick={() => setFilterSelected(key)}
                      >
                        {value.name}
                      </Button>
                    </Col>
                  );
                })}
              </Row>
            )}
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

export default AdminShipments;
