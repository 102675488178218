import {
  Platforms,
  StoreInformationI,
} from "../../../interfaces/integrations.interface";
import WooCommerce from "../../../assets/images/StoreLogos/WooCommerce.webp";
import Shopify from "../../../assets/images/StoreLogos/Shopify.webp";
import Prestashop from "../../../assets/images/StoreLogos/PrestaShop.png";
import Wix from "../../../assets/images/StoreLogos/WixStores.png";
import Squarespace from "../../../assets/images/StoreLogos/SquareSpace.png";
import MercadoLibre from "../../../assets/images/StoreLogos/MercadoLibre.webp";
import TiendaNube from "../../../assets/images/StoreLogos/TiendaNube.png";
import DrenvioPlugin from "../../../assets/images/StoreLogos/DrenvioPlugin.png";
import Ecwid from "../../../assets/images/StoreLogos/Ecwid.png";
import Magento from "../../../assets/images/StoreLogos/Magento.png";
import Amazon from "../../../assets/images/StoreLogos/Amazon.png";
import Zoho from "../../../assets/images/StoreLogos/Zoho.png";
import BigCommerce from "../../../assets/images/StoreLogos/BigCommerce.webp";
import OpenCart from "../../../assets/images/StoreLogos/OpenCart.png";
import ShipHero from "../../../assets/images/StoreLogos/Shiphero.png";
import Vtex from "../../../assets/images/StoreLogos/Vtex.png";
import Ebay from "../../../assets/images/StoreLogos/ebay.png";

export const getStoreImg = (store: string) => {
  switch (store) {
    case Platforms.WooCommerce:
      return WooCommerce;
    case Platforms.Shopify:
      return Shopify;
    case Platforms.Prestashop:
      return Prestashop;
    case Platforms.Squarespace:
      return Squarespace;
    case Platforms.Wix:
      return Wix;
    case Platforms.Mercadolibre:
      return MercadoLibre;
    case Platforms.Tiendanube:
      return TiendaNube;
    case Platforms.Magento:
      return Magento;
    case Platforms.Ecwid:
      return Ecwid;
    case Platforms.Amazon:
      return Amazon;
    case Platforms.Zoho:
      return Zoho;
    case Platforms.BigCommerce:
      return BigCommerce;
    case Platforms.OpenCart:
      return OpenCart;
    case Platforms.ShipHero:
      return ShipHero;
    case Platforms.Vtex:
      return Vtex;
    case Platforms.Ebay:
      return Ebay;
    default:
      return DrenvioPlugin;
  }
};

export const configJson: StoreInformationI = {
  label: "WooCommerce",
  is_read_only: false,
  show_products: false,
  pdf_manual: "",
  media: {
    url: null,
  },
  fields: [
    {
      name: "",
      type: "text",
      placeholder: {
        es: "",
        en: "",
      },
    },
  ],
  meta_fields: [],
  steps: {
    es: ["Primera instrucción"],
    en: ["First instruction"],
  },
};

export const platformsList = [
  {
    value: Platforms.WooCommerce,
    label: "Woocommerce",
  },
  {
    value: Platforms.Shopify,
    label: "Shopify",
  },
  {
    value: Platforms.Prestashop,
    label: "prestashop",
  },
  {
    value: Platforms.Wix,
    label: "Wix",
  },
  {
    value: Platforms.Squarespace,
    label: "SquareSpace",
  },
  {
    value: Platforms.Tiendanube,
    label: "Tienda Nube",
  },
  {
    value: Platforms.Mercadolibre,
    label: "Mercado Libre",
  },
  {
    value: Platforms.PluginWoocomerce,
    label: "Plugin WooCommerce",
  },
  {
    value: Platforms.Magento,
    label: "Magento 2",
  },
  {
    value: Platforms.Ecwid,
    label: "Ecwid",
  },
  {
    value: Platforms.Amazon,
    label: "Amazon",
  },
  {
    value: Platforms.Zoho,
    label: "Zoho",
  },
  {
    value: Platforms.BigCommerce,
    label: "BigCommerce",
  },
  {
    value: Platforms.OpenCart,
    label: "OpenCart",
  },
  {
    value: Platforms.ShipHero,
    label: "ShipHero",
  },
  {
    value: Platforms.Vtex,
    label: "VTEX",
  },
  {
    value: Platforms.Ebay,
    label: "Ebay",
  },
];

export const adminLogoStyles = (type: Platforms) => {
  switch (type) {
    case Platforms.Prestashop:
    case Platforms.Tiendanube:
    case Platforms.Ecwid:
      return {
        height: "30px",
      };
    case Platforms.Squarespace:
      return {
        height: "35px",
      };
    case Platforms.PluginWoocomerce:
      return {
        height: "60px",
      };
    default:
      return {
        height: "40px",
      };
  }
};
