import { Tooltip } from "antd"
import { Button } from "react-bootstrap";
import {
    SearchOutlined
} from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import ModalView from "./ModalView";
import { ActionsFinderSection } from "../../../interfaces/permissions.interfaces";
import { AppContext } from "../../../context/appContext";
import { userhavePermissions } from "../../../utils/AppLayout";
import AccessMsg from "../AccessMsg";

const styles = {
    centerItems: {
        display: "flex",
        alignItems: "center",
        height: "2.5rem"
    }
}

export interface FinderAccessI {
    show_finder: boolean;
    shipments_finder: boolean;
    update_status: boolean;
}

const FinderComponent = () => {
    const [openModal, setOpenModal] = useState(false)
    const { userData } = useContext(AppContext);
    const [finderAccess, setFinderAccess] = useState<FinderAccessI>({
        show_finder: userData?.metadata?.permissions?.finder?.menu || false,
        shipments_finder: userData?.metadata?.permissions?.finder?.actions.includes(ActionsFinderSection.shipments_finder),
        update_status: userData?.metadata?.permissions?.finder?.actions.includes(ActionsFinderSection.update_status),
    })

    const openCloseModal = () => {
        setOpenModal(!openModal)
    }

    useEffect(() => {
        setFinderAccess({
            show_finder: userData?.metadata?.permissions?.finder?.menu || false,
            shipments_finder: userData?.metadata?.permissions?.finder?.actions.includes(ActionsFinderSection.shipments_finder),
            update_status: userData?.metadata?.permissions?.finder?.actions.includes(ActionsFinderSection.update_status),
        })

    }, [userData])

    return (
        <>
            <AccessMsg label="Buscador" access={finderAccess.show_finder}>
                <Tooltip title={"Buscar envíos"}>
                    <Button
                        variant="outline-light"
                        className="mx-1"
                        style={styles.centerItems}
                        onClick={openCloseModal}
                        disabled={!userhavePermissions("Buscador", userData)}
                    >
                        <SearchOutlined />
                    </Button>
                </Tooltip>
            </AccessMsg>
            <ModalView
                openModal={openModal}
                openCloseModal={openCloseModal}
                finderAccess={finderAccess}
            />
        </>
    )
}

export default FinderComponent