import { useContext, useEffect, useState } from "react"
import { Col, Row, Typography } from "antd"
import { Dispatch, FC, ReactElement, SetStateAction } from "react"
import { PackagesInterface, ShipmentV2 } from "../../../interfaces/shipments.interface"
import ModalContainer from "../../Containers/ModalContainer"
import { Worker, Viewer } from "@react-pdf-viewer/core"
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"
import { ToolbarProps, ToolbarSlot } from "@react-pdf-viewer/toolbar"
import "@react-pdf-viewer/toolbar/lib/styles/index.css"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"
import "@react-pdf-viewer/core/lib/styles/index.css"
// import ZPLLabel from "./ZPLLabel"
import { t } from "i18next";
import moment from "moment"
import { ApiContext } from "../../../context/apiContext"
import axios from "axios"

type Props = {
  visible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
  shipment: { shipmentData: ShipmentV2 }
}

const styles = {
  card: {
    marginRight: "10px",
    borderRadius: "10px",
    marginBottom: "16px",
  },
  textStyle: {
    marginBottom: "5px",
  },
}
export const ShipmentDetail: FC<Props> = ({ visible, setIsVisible, shipment }) => {
  const [shipmentData, setShipmentData] = useState<ShipmentV2 | null>(null)
  const { userToken, backendURL } = useContext(ApiContext)

  const handleFecth = async (id: string) => {
    const { data } = await axios.post(
      `${backendURL}shipments/getShipmentsById`,
      { id },
      { headers: { Authorization: `Bearer ${userToken}` } }
    )

    setShipmentData(data?.shipment)
  }

  useEffect(() => {
    (async () => {
      await handleFecth(shipment.shipmentData._id)
    })()
  }, []) //eslint-disable-line

  // const { shipmentData } = shipment
  console.log("shipmentData:", shipmentData)
  const pdfJsMain = "https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js"
  const [invoiceToRender, setInvoiceToRender] = useState<string>(shipmentData?.label || "")
  console.log("invoiceToRender:", invoiceToRender)
  const [invoiceType, setInvoiceType] = useState<"label" | "comercial_invoice" | "">(
    shipmentData?.label ? "label" : ""
  )
  const renderInvoice = () => {
    if (invoiceType === "label") {
      if (shipmentData?.comercial_innvoice && shipmentData?.comercial_innvoice !== "") {
        setInvoiceToRender(shipmentData?.comercial_innvoice)
        setInvoiceType("comercial_invoice")
      }
    } else if (shipmentData?.label) {
      setInvoiceToRender(shipmentData?.label)
      setInvoiceType("label")
    }
  }
  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          Download,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Print,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <div style={{ padding: "0px 2px" }}>
              <ZoomOut />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Zoom />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomIn />
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <GoToPreviousPage />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CurrentPageInput /> / <NumberOfPages />
              </div>
              <div>
                <GoToNextPage />
              </div>
            </div>
            <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
              <EnterFullScreen />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Download />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Print />
            </div>
          </div>
        )
      }}
    </Toolbar>
  )

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  })

  return (
    <ModalContainer
      footer={<></>}
      title={"Detalles de envio"}
      visible={visible}
      onCancel={() => {
        setIsVisible(false)
      }}
    >
      <Row>
        {/* <Col span={`${shipmentData?.metadata || shipmentData?.shipping_id === "LEGACY" ? 8 : 24}`}> */}
        <Col xs={24} md={24} xl={8} xxl={8}>
          <Row style={{ marginTop: "5px", marginBottom: "10px" }}>
            {shipmentData?.comercial_innvoice && shipmentData?.comercial_innvoice !== "" && (
              <button
                data-testid="invoice-button"
                style={{
                  borderRadius: "6px",
                  background: "#4F31BF",
                  width: "50%",
                  border: "1px solid",
                  color: "white",
                  padding: "5px 10px",
                }}
                onClick={() => renderInvoice()}
              >
                {invoiceType === "label" ? "Ver carta porte" : "Guía"}
              </button>
            )}
          </Row>

          <Typography style={styles.textStyle}>
            <b>{"Provedor"}</b> <br />
            {shipmentData?.service_id?.split("_")[2] || "-"}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Fecha de creación"}</b> <br />
            {/* {shipmentData?.created_at.toLocaleString()} */}
            {moment(shipmentData?.created_at).format('DD/MM/YYYY')}
          </Typography>

          {
            shipmentData?.metadata?.cancel_at ? (
              <Typography style={styles.textStyle}>
                <b>{"Fecha de cancelación"}</b> <br />
                {shipmentData?.metadata?.cancel_at}
              </Typography>
            ) : ''
          }

          <Typography style={styles.textStyle}>
            <b>{t(`Shipment.Datails.ZoneExtens`)}</b> <br />
            {`${shipmentData?.metadata?.extended_area === true ? t(`Shipment.Datails.ZoneYes`) : t(`Shipment.Datails.ZoneNo`)}`}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Guía"}</b> <br />
            {shipmentData?.tracking}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Remitente"}</b> <br />{" "}
            {`${shipmentData?.origin?.name}${shipmentData?.origin?.last_name ? ' ' + shipmentData?.origin?.last_name : ''}, ${shipmentData?.origin?.phone}, ${shipmentData?.origin?.email}, ${shipmentData?.origin?.company}`}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Origen"}</b> <br />{" "}
            {`${shipmentData?.origin?.street} ${shipmentData?.origin?.district} ${shipmentData?.origin?.number} ${shipmentData?.origin?.city} ${shipmentData?.origin?.postal_code}`}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Referencia de origen"}</b> <br /> {shipmentData?.origin?.reference}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Destinatario"}</b> <br />{" "}
            {`${shipmentData?.destination?.name}${shipmentData?.destination?.last_name ? ' ' + shipmentData?.destination?.last_name : ''}, ${shipmentData?.destination?.phone}, ${shipmentData?.destination?.email}, ${shipmentData?.destination?.company}`}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Destino"}</b> <br />{" "}
            {`${shipmentData?.destination?.street} ${shipmentData?.destination?.district} ${shipmentData?.destination?.number} ${shipmentData?.destination?.city} ${shipmentData?.destination?.postal_code}`}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Referencia de destino"}</b> <br /> {shipmentData?.destination?.reference}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Contenido del paquete"}</b> <br /> {shipmentData && shipmentData?.packages?.length > 0 ? shipmentData?.packages[0]?.content : ''}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Kilos"}</b> <br />{" "}
            {
              // shipmentData && shipmentData?.packages?.length > 0 ? shipmentData?.packages[0]?.weight : ""
              shipmentData && shipmentData?.packages?.length > 0 ? (() => {
                const totalWeight = shipmentData.packages.reduce((acc, pckg) => acc + (pckg?.weight ?? 0), 0);
                return (
                  <>
                    {shipmentData?.packages.map((pckg: PackagesInterface, index: number) => (
                      <div key={index}>
                        <li>
                          Caja {index + 1}: {pckg?.weight ?? 0}kg
                        </li>
                      </div>
                    ))}
                    <br />
                    <div>TOTAL: {totalWeight}kg</div>
                  </>
                )
              })() : ''
            }
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Medidas"}</b> <br />{" "}
            {
              // shipmentData && shipmentData?.packages?.length > 0
              // ? `${shipmentData?.packages[0]?.width}x${shipmentData?.packages[0]?.length}x${shipmentData?.packages[0]?.height}`
              // : ""
              shipmentData && shipmentData?.packages?.length > 0 ? shipmentData?.packages.map((pckg: PackagesInterface, index: number) => {
                return (
                  <div key={index}>
                    <li>
                      {pckg?.width ?? 0}x{pckg?.length ?? 0}x{pckg?.height ?? 0}
                    </li>
                  </div>
                )
              }) : ''
            }
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Volumetrico"}</b> <br />
            {
              shipmentData && shipmentData?.packages?.length > 0 ? (() => {
                const totalVolumetric = shipmentData?.packages.reduce((acc, pckg) => acc + (pckg?.volumetric ?? 0), 0);
                return (
                  <>
                    {shipmentData.packages.map((pckg: PackagesInterface, index: number) => (
                      <div key={index}>
                        <li>
                          Caja {index + 1}: {pckg?.volumetric ?? 0}kg
                        </li>
                      </div>
                    ))}
                    <br />
                    <div>TOTAL: {totalVolumetric.toFixed(2)}kg</div>
                  </>
                );
              })() : ''
            }
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Monto del envío:"}</b> <br />
            ${shipmentData?.price.toLocaleString()}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Saldo antes del envio:"}</b> <br />
            ${shipmentData?.movement?.security?.before_mod
              ? shipmentData?.movement?.security?.before_mod.toLocaleString()
              : "-"}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Saldo despues del envio:"}</b> <br />
            ${shipmentData?.movement?.security?.after_mod
              ? shipmentData?.movement?.security?.after_mod.toLocaleString()
              : "-"}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Saldo actual:"}</b> <br />
            ${typeof shipmentData?.user === "object"
              ? shipmentData?.user?.balance?.toLocaleString()
              : shipmentData?.movement?.security?.after_mod
                ? shipmentData.movement.security.after_mod.toLocaleString()
                : "-"}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Monto asegurado:"}</b> <br />
            ${shipmentData?.insurance.toLocaleString()}
          </Typography>
        </Col>
        <Col xs={24} md={24} xl={16} xxl={16}>
          <Worker workerUrl={pdfJsMain}>
            <div>
              {shipmentData?.label ? (
                <>
                  {
                    invoiceType === 'label' ? (
                      <Viewer fileUrl={shipmentData?.label} plugins={[defaultLayoutPluginInstance]} />
                    ) : (
                      <Viewer fileUrl={shipmentData?.comercial_innvoice as string || shipmentData?.label} plugins={[defaultLayoutPluginInstance]} />
                    )
                  }
                </>
              ) : (
                <p> PDF NOT FOUND</p>
              )}
            </div>
          </Worker>
        </Col>
        {/* {shipmentData?.shipping_id === "LEGACY" ||
        (shipmentData?.metadata && !shipmentData?.metadata?.format?.includes("ZPL")) ? (
          <Col span={16}>
            <Worker workerUrl={pdfJsMain}>
              <div>
                {shipmentData?.label ? (
                  <Viewer fileUrl={invoiceToRender} plugins={[defaultLayoutPluginInstance]} />
                ) : (
                  <p> PDF NOT FOUND</p>
                )}
              </div>
            </Worker>
          </Col>
        ) : (
          <Col span={16}>
            <ZPLLabel shipmentData={shipmentData} />
          </Col>
        )} */}
      </Row>
    </ModalContainer>
  )
}

/** 
     * 

              rowData.shipment_status === "Activa" &&
              rowData.metadata &&
              rowData.metadata?.format?.includes("PDF")
                ? false
                : rowData.shipment_status === "Activa" && !rowData.metadata
                ? false
                : true

    * 
    */
