import { Row } from "antd";
import usePermission from "../../hooks/usePermission";
import { ActionsPricesSection, PermissionViews } from "../../interfaces/permissions.interfaces";

import { SpecialPrice } from "../../interfaces/services.interface";
import NoAccessPrices from "./NoAccessPrices";

import { SpecialPriceCard } from "./SpecialPriceCard";

export const SpecialPricesContainer = ({
  specialPrices,
}: {
  specialPrices: SpecialPrice[] | undefined;
}) => {
  const { hasAccess } = usePermission()
  return (
    <Row
      style={{
        height: "100% - 50px",
        maxHeight: "100%",
        overflow: "scroll",
        width: "100%",
      }}
      className="scrollNone"
      gutter={[0, 20]}
    >
      {
        hasAccess(ActionsPricesSection.special_prices, PermissionViews.prices_section)
          ?
          specialPrices?.map((specialPrice) => (
            <SpecialPriceCard key={specialPrice.user} specialPrice={specialPrice} />
          )) : <NoAccessPrices />
      }
    </Row>
  );
};
