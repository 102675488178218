import { Popover } from "antd"
import { TooltipPlacement } from "antd/lib/tooltip"
import { FC, PropsWithChildren } from "react"

interface Props {
    label: string
    access: boolean
    trigger?: 'hover' | 'focus' | 'click'
    placement?: TooltipPlacement

}
const AccessMsg: FC<PropsWithChildren<Props>> = ({ label, access, children, trigger, placement }) => {
    return (
        <>
            {
                access ? (
                    <>{children} </>
                ) : (
                    <Popover
                        placement={placement || "topLeft"}
                        title='No tienes permisos'
                        trigger={trigger || 'hover'}
                        content={
                            <>
                                <p>Tu cuenta requiere los permisos de "<strong>{label}</strong>" activados.</p>
                            </>
                        }
                    >
                        <span></span>
                        {children}
                    </Popover>
                )
            }
        </>

    )
}

export default AccessMsg