import { Col, Row } from "antd";
import { useState } from "react";
import UserList from "./UsersBeta/Search/UserList";
import UserView from "./UsersBeta/Sections/UserView";
import anime from "animejs";
import { UserItemI } from "./UsersBeta/Search/SearchItem";
import { usePost } from "../../hooks/usePost";
import { getFullDataUserSearch } from "../../services/user_d";
import { AdvancedCriteria, dataToShowI, ModalTableI, prefrencesMenu } from "./UsersBeta/interfaces";
import { dataToShowDefault } from "./UsersBeta/utils";
import ModalTable from "../../components/ModalTable/ModalTable";
import SearchDrawer from "./UsersBeta/Search/SearchDrawer";

interface Styles {
  searchContainer: React.CSSProperties;
  searchSection: React.CSSProperties;
}

export type SearchUsersStepsObject = {
  [key in SearchUsersStep]: React.ReactElement
}

export type SearchUsersStep =
  | '1) Search users'
  | '2) User Info'



const styles: Styles = {
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 0 0 12px'
  },
  searchSection: {
    width: '1200px'
  }
}

const UsersBeta = () => {
  const [searchQuery, setSearchQuery] = useState<AdvancedCriteria | null>(null);
  const [searchResults, setSearchResults] = useState<any[] | null>(null);
  const [searchUserSteps, setSearchUserStep] = useState<SearchUsersStep>('1) Search users')
  const [selectedMenu, setSelectedMenu] = useState<any>('profile')
  const [userFound, setUserFound] = useState<UserItemI | null>(null);
  const { fetchData } = usePost(getFullDataUserSearch)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [fullUser, setFullUser] = useState<dataToShowI | null>(dataToShowDefault)
  const [modalTable, setModalTable] = useState<ModalTableI>({
		visible: false,
		currentTable: "",
	});

  const persistQuery = (query: any) => {
    setSearchQuery(query)
  }
  const persistResults = (results: any[] | null) => {
    setSearchResults(results)
  }

  const newUserFound = (user: UserItemI, updateSearch?: boolean) => {
    setUserFound(user)

    if(updateSearch){
      setSearchResults(searchResults?.map(el =>{
        if(el.user === user.user){
          return user
        }
        return el
      }) as UserItemI[])
    }


  }

  const showDrawer = () => {
    setOpenDrawer(true);
};

const onClose = () => {
    setOpenDrawer(false);
};

const handleMenu = (menuItem: prefrencesMenu) => {
  setSelectedMenu(menuItem)
  
}

  const nextStep = () => {
    const tl = anime.timeline({
      duration: 440,
      easing: "easeInOutSine",
    });

    tl.add({
      targets: ".content-layout",
      translateY: "150px",
      opacity: 0,
    });

    tl.add({
      targets: ".content-layout",
      translateY: "0%",
      opacity: 1,
    });

    tl.add(
      {
        targets: ".headerContainer",
        easing: "linear",
        duration: 1000,
        opacity: [1, 0, 1],
      },
      "-=1050"
    );
    setTimeout(() => {
      setSearchUserStep('2) User Info')
    }, 480)


  }
  const prevStep = () => {
    setFullUser(null);
    const tl = anime.timeline({
      duration: 440,
      easing: "easeInOutSine",
    });

    tl.add({
      targets: ".content-layout",
      translateY: "150px",
      opacity: 0,
    });

    tl.add({
      targets: ".content-layout",
      translateY: "0%",
      opacity: 1,
    });

    tl.add(
      {
        targets: ".headerContainer",
        easing: "linear",
        duration: 1000,
        opacity: [1, 0, 1],
      },
      "-=1050"
    );
    setTimeout(() => {
      setSearchUserStep('1) Search users')
    }, 480)

  }

  const getFullUser = async (user: string) => {
    try {
      const response = await fetchData({ user });
      setFullUser(response.data)
    } catch (error) {
      console.log(error)
    }
  }


  const SearchUsersStepComponentsList: SearchUsersStepsObject = {
    '1) Search users':
      <Row gutter={[25, 0]} className="dashboardSection">
        <Col span={24} style={styles.searchContainer}>
          <section style={styles.searchSection}>
            <UserList
              nextStep={nextStep}
              prevStep={prevStep}
              persistResults={persistResults}
              persistQuery={persistQuery}
              searchQuery={searchQuery}
              searchResults={searchResults}
              newUserFound={newUserFound}
              getFullUser={getFullUser}
              handleMenu={handleMenu}             
            />
          </section>
        </Col>
      </Row>,
    '2) User Info':
      <Row gutter={[25, 0]} className="dashboardSection">
        <ModalTable isModalOpen={modalTable} setIsModalOpen={setModalTable} user={userFound?.user || ''} />
        <Col span={24} style={styles.searchContainer}>
          <UserView
            prevStep={prevStep}
            userFound={userFound}
            fullUser={fullUser}
            getFullUser={getFullUser}
            newUserFound={newUserFound}
            setModalTable={setModalTable}
            showDrawer={showDrawer}
            handleMenu={handleMenu}
            selectedMenu={selectedMenu}
          />
        </Col>
        <SearchDrawer 
          onClose={onClose} 
          open={openDrawer} 
          persistQuery={persistQuery}
          persistResults={persistResults}
          searchQuery={searchQuery}
          searchResults={searchResults}
          newUserFound={newUserFound}
          getFullUser={getFullUser}
          handleMenu={handleMenu}

        />
      </Row>
  }

  return (
    <>{SearchUsersStepComponentsList[searchUserSteps]}</>
  )
}

export default UsersBeta