import { Col, Typography } from "antd"
import { DownOutlined } from '@ant-design/icons';
import { FC, PropsWithChildren } from "react";
import ActionsMenu from "./ActionsMenu";
import { UserItemI } from "../Search/SearchItem";
// import { useMediaQuery } from "react-responsive";
import { userActionsT } from "../interfaces";
import { Input } from 'antd';
import { useMediaQuery } from "react-responsive";
const { Search } = Input;

interface props {
    userFound: UserItemI;
    roles: string[];
    changeActions: (type: userActionsT) => void;
    showDrawer: () => void;
}

interface Styles {
    column: React.CSSProperties;
    mobileColumn: React.CSSProperties;
    titleContainer: React.CSSProperties;
    title: React.CSSProperties;
    titleMobile: React.CSSProperties;
    button: React.CSSProperties;
    buttonText: React.CSSProperties;
    icon: React.CSSProperties;
    blockedTag: React.CSSProperties;
    pendingTag: React.CSSProperties;
    adminTag: React.CSSProperties;
    actionsContainer: React.CSSProperties;
    searchContainer: React.CSSProperties;
}

const styles: Styles = {
    column: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
        border: '1px solid #DFDCDC',
        borderWidth: '1px 0 1px 0'
    },
    mobileColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
        border: '1px solid #DFDCDC',
        borderWidth: '1px 0 1px 0',
        gap: '1rem'
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '1rem'
    },
    title: {
        color: '#2D1B6E',
        fontSize: '20px',
        fontWeight: '600'
    },
    titleMobile: {
        color: '#2D1B6E',
        fontSize: '18px',
        fontWeight: '600'
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#6D71F6',
        color: '#fff',
        padding: '6px 12px',
        borderRadius: '6px'
    },
    buttonText: {
        display: 'flex',
        alignItems: 'center',
        color: 'inherit',
        margin: 0,
        padding: 0
    },
    icon: {
        fontWeight: 'bold',
        marginLeft: '4px',
        marginTop: '3px',
        fontSize: '16px'
    },
    blockedTag: {
        color: '#F5222D',
        backgroundColor: '#FFF1F0',
        padding: '4px 16px',
        border: '1px solid #FFA39E',
        borderRadius: '2px',
        fontSize: '14px',
        margin: 0
    },
    pendingTag: {
        color: '#837814',
        backgroundColor: '#FFF6CA',
        padding: '4px 16px',
        border: '1px solid #AD8C10',
        borderRadius: '2px',
        fontSize: '14px',
        margin: 0
    },
    adminTag: {
        color: '#1d6d9e',
        backgroundColor: '#caf9ff',
        padding: '4px 16px',
        border: '1px solid #104fad',
        borderRadius: '2px',
        fontSize: '14px',
        margin: 0
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '1rem'
    }

}
const UserHeader: FC<PropsWithChildren<props>> = ({ children, userFound, changeActions, showDrawer, roles }) => {
    const isMobile = useMediaQuery({query: '(max-width: 700px)'})

    return (
        <Col span={24} style={isMobile ? styles.mobileColumn : styles.column}>
            <span style={styles.titleContainer}>
                <Typography style={isMobile ? styles.titleMobile : styles.title}>{children}</Typography>
                {
                    userFound.security.isBlocked ? (
                        <p style={styles.blockedTag}>Bloqueado</p>
                    ) : userFound.app_country === 'PENDIENTE' ? (
                        <p style={styles.pendingTag}>Pendiente</p>
                    ) : roles.includes('DEV') ? (
                        <p style={styles.adminTag}>Desarrollador</p>
                    ) : roles.includes('ADMIN') ? (
                        <p style={styles.adminTag}>Admin</p>
                    ): ''
                }
            </span>
            <div style={styles.actionsContainer}>
                <span style={styles.searchContainer}>
                    <Search placeholder="Buscar usuarios" onClick={showDrawer} onSearch={() => { }} style={{ width: 200 }} />
                </span>
                <ActionsMenu userFound={userFound} changeActions={changeActions}>
                    <span
                        className="user-actions-button"
                        style={styles.button}>
                        <Typography style={styles.buttonText}>
                            Acciones <DownOutlined style={styles.icon} />
                        </Typography>
                    </span>
                </ActionsMenu>
            </div>
        </Col>
    )
}

export default UserHeader