import { apiProps, apiResponse } from '../interfaces/app.interfaces'
import { GetAllShipmentsDto } from '../interfaces/services.interface'

export const getAllShipments =
  (dto: GetAllShipmentsDto) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    const { country, from, to, word, date, price } = dto
    return await backendApiCall({
      method: 'GET',
      endpoint: `shipments/getAllShipments/${country}/${from}/${to}/${word}/${date}/${price}`,
    })
  }

  export const getAllCancelations =
  (dto: GetAllShipmentsDto) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    const { country, from, to, word, date, price } = dto
    return await backendApiCall({
      method: 'GET',
      endpoint: `shipments/getAllCancellations/admin/${country}/${from}/${to}/${word}/${date}/${price}`,
    })
  }

