import { FC, useState, useEffect } from "react";
import InputContainer from "../Containers/InputContainer";
import { ServicesId_MX, serviceTitles } from "../../utils/GlobalServices";

// Lista de proveedores a extraer
const providersList: { [key: string]: string } = {
  globalpaq: "A-P09",
  envia: "A-P01",
  shipandGo: "A-P04",
  pack2go: "A-P03",
  shippo: "A-P02",
  nextshipping: "A-P07",
};

const extractProvidersAndServices = (carrier: string) => {
  const services = Object.values(ServicesId_MX).filter((service) =>
    service.includes(carrier)
  );
  const providers = new Set<string>();

  services.forEach((service) => {
    Object.entries(providersList).forEach(([provider, label]) => {
      if (service.includes(label)) {
        providers.add(provider);
      }
    });
  });

  return { services, providers: Array.from(providers) };
};

const extractServicesByProvider = (carrier: string, provider: string) => {
  const services = Object.values(ServicesId_MX).filter(
    (service) =>
      service.includes(carrier) &&
      service.includes(providersList[provider])
  );
  return services;
};

export const TableCarrierServicesFilter: FC<Props> = ({
  carriers,
  setCarrierSelected,
  setProviderSelected,
  setServiceSelected,
}) => {
  const [providers, setProviders] = useState<string[]>([]);
  const [services, setServices] = useState<string[]>([]);
  const [selectedCarrier, setSelectedCarrier] = useState<string | undefined>();
  const [selectedProvider, setSelectedProvider] = useState<string | undefined>();

  useEffect(() => {
    if (selectedCarrier) {
      const { services, providers } =
        extractProvidersAndServices(selectedCarrier);
      setProviders(providers);
      setServices(services);
    } else {
      setProviders([]);
      setServices([]);
    }
  }, [selectedCarrier]);

  useEffect(() => {
    if (selectedCarrier && selectedProvider) {
      const services = extractServicesByProvider(
        selectedCarrier,
        selectedProvider
      );
      setServices(services);
    }
  }, [selectedCarrier, selectedProvider]);

  return (
    <div style={{ width: "100%" }}>
      <InputContainer
        optionsList={carriers}
        placeholder="Paqueteria"
        type="select"
        valueContainerName="value name"
        onChange={(e) => {
          setCarrierSelected(e);
          setSelectedCarrier(e);
          setSelectedProvider(undefined);
          setProviders([]);
          setServices([]);
        }}
      />
      <InputContainer
        optionsList={providers}
        placeholder="Proveedores"
        type="select"
        valueContainerName="value name"
        onChange={(e) => {
          setProviderSelected(e);
          setSelectedProvider(e);
          setServices([]);
        }}
      />
      <InputContainer
        optionsList={services.map((service) => ({
          value: service,
          label: serviceTitles[service] || service,
        }))}
        placeholder="Servicios"
        type="select"
        valueContainerName="value name"
        onChange={(e) => setServiceSelected(e)}
      />
    </div>
  );
};
type Props = {
  carriers: string[];
  setCarrierSelected: React.Dispatch<React.SetStateAction<AllCarriers | null>>;
  setProviderSelected: React.Dispatch<React.SetStateAction<string | undefined>>;
  setServiceSelected: React.Dispatch<
    React.SetStateAction<ServicesId_MX | undefined>
  >;
};
