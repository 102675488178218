import React, { CSSProperties } from 'react'
import {
  Input,
  InputNumber,
  Radio,
  Button,
  Tooltip,
  Form,
  Checkbox,
  Select,
} from 'antd'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'


type inputContainerProps =
  | {
      type: 'text' | 'number' | 'email' | 'password' | 'checkbox'
      placeholder: string
      errorMessage?: string
      suffix?: string | React.ReactElement
      prefix?: string | React.ReactElement
      valueContainerName: string
      style?: CSSProperties
      onClick?: (e: any) => void
      onChange?: (e: any) => void
      required?: boolean
      disabled?: boolean
      label?: string
      regexpPattern?: RegExp
      counters?: { min?: number; max?: number }
      allowClear?: boolean
    }
  | {
      type: 'select'
      placeholder: string
      optionsList: Array<{ label: string; value: string } | any>
      canSearch?: boolean
      onSearch?: (value: string) => void
      errorMessage?: string
      valueContainerName: string
      style?: CSSProperties
      onClick?: (e: any) => void
      onChange?: (e: any) => void
      required?: boolean
      disabled?: boolean
      loading?: boolean
      allowClear?: boolean
      multiple?: boolean;
      defaultValue?: any
    }
  | {
      type: 'multipleRadio'
      optionsList: Array<string>
      errorMessage?: string
      valueContainerName: string
      style?: CSSProperties
      onClick?: (e: any) => void
      onChange?: (e: any) => void
      required?: boolean
    }
  | {
      type: 'prePostTab'
      placeholder: string
      errorMessage?: string
      suffix?: string | React.ReactElement
      prefix?: string | React.ReactElement
      valueContainerName: string
      style?: CSSProperties
      onClick?: (e: any) => void
      onChange?: (e: any) => void
      required?: boolean
      disabled?: boolean
      addonBefore?: string | React.ReactElement
      addonAfter?: string | React.ReactElement
      selectOptions?: Array<any>
      uniquePlaceholder?: string
    }
  | {
      type: 'button' | 'submitButton'
      title: string
      name?: string
      style?: CSSProperties
      onClick?: (e: any) => void
      onChange?: (e: any) => void
      isButtonLoading?: boolean
      disabled?: boolean
      danger?: boolean
      ghost?: boolean
      buttonType?: 'link' | 'text' | 'ghost' | 'dashed' | 'default' | 'primary' | undefined
    }
  | {
      type: 'iconTitleHoverButton'
      title: string
      style?: CSSProperties
      shape: 'circle' | 'round' | 'default'
      iconOrReactElement: React.ReactElement
      onClick?: (e: any) => void
      onChange?: (e: any) => void
      isButtonLoading?: boolean
    }

const InputContainer: React.FC<inputContainerProps> = (inputProps) => {
  const { t } = useTranslation()
  const { Option } = Select

  const getComponent = () => {
    switch (inputProps.type) {
      case 'text':
      case 'email':
      case 'number':
      case 'password':
        return (
          <Form.Item
            name={inputProps.valueContainerName}
            hasFeedback={inputProps.type === 'number' ? false : true}
            rules={
              inputProps.type === 'number'
                ? [
                  ]
                : [
                    {
                      required: inputProps.required,
                      type:
                        inputProps.type === 'text' || inputProps.type === 'password'
                          ? 'string'
                          : inputProps.type,
                      message: inputProps.errorMessage
                        ? inputProps.errorMessage
                        : t('InputErrorMessage.default')
                    },
                    {
                      pattern: inputProps.regexpPattern,
                      message: inputProps.errorMessage
                        ? inputProps.errorMessage
                        : t('InputErrorMessage.default'),
                      validator: async (_, inputName) => {
                        if (
                          inputName &&
                          inputName.length <
                            (inputProps.counters?.min ? inputProps.counters?.min : 2)
                        ) {
                          return Promise.reject(
                            new Error('Los campos deben tener al menos 2 carácteres')
                          )
                        }
                      }
                    }
                  ]
            }
          >
            {inputProps.type === 'password' ? (
              <Input.Password
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                suffix={inputProps.suffix}
                prefix={inputProps.prefix}
                disabled={inputProps.disabled}
                placeholder={inputProps.placeholder}
                onClick={inputProps.onClick}
                onChange={inputProps.onChange}
                style={{
                  width: '100%',
                  margin: '10px 0px',
                  ...inputProps.style
                }}
              />
            ) : inputProps.type === 'number' ? (
              <InputNumber
                style={{
                  width: '100%',
                  margin: '10px 0px',
                  ...inputProps.style
                }}
                required={inputProps.required}
                addonAfter={inputProps.suffix}
                prefix={inputProps.prefix}
                controls={inputProps.suffix ? false : true}
                disabled={inputProps.disabled}
                placeholder={inputProps.placeholder}
                onClick={inputProps.onClick}
                onChange={inputProps.onChange}
                maxLength={inputProps?.counters?.max || 25}
              />
            ) : (
              <Input
                suffix={inputProps.suffix}
                prefix={inputProps.prefix}
                disabled={inputProps.disabled}
                placeholder={inputProps.placeholder}
                onClick={inputProps.onClick}
                onChange={inputProps.onChange}
                showCount
                maxLength={inputProps?.counters?.max || 50}
                allowClear={inputProps?.allowClear}
                style={{
                  width: '100%',
                  margin: '10px 0px',
                  ...inputProps.style
                }}
              />
            )}
          </Form.Item>
        )
      case 'checkbox':
        return (
          <Form.Item name={inputProps.valueContainerName} valuePropName="checked" noStyle>
            <Checkbox
              style={{ margin: '20px 5px', fontWeight: 'bolder', ...inputProps.style }}
              onClick={inputProps.onClick}
              onChange={inputProps.onChange}
              disabled={inputProps.disabled}
            >
              {inputProps.placeholder}
            </Checkbox>
          </Form.Item>
        )
      case 'multipleRadio':
        return (
          <Form.Item
            name={inputProps.valueContainerName}
            rules={[
              {
                required: inputProps.required,
                message: inputProps.errorMessage
                  ? inputProps.errorMessage
                  : t('InputErrorMessage.default')
              }
            ]}
            style={{
              height: '100%',
              width: '100%',
              display: 'grid',
              placeItems: 'center'
            }}
          >
            <Radio.Group name="RadioGroup" onChange={inputProps.onChange}>
              {typeof inputProps.optionsList !== 'string'
                ? inputProps.optionsList.map((value, index) => {
                    return (
                      <Radio
                        key={index}
                        onClick={inputProps.onClick}
                        value={index}
                        style={{
                          fontWeight: 'bolder'
                        }}
                      >
                        {value}
                      </Radio>
                    )
                  })
                : null}
            </Radio.Group>
          </Form.Item>
        )
      case 'button':
      case 'submitButton':
        return (
          <Button
            name={inputProps.name}
            disabled={inputProps.disabled}
            onClick={inputProps.onClick}
            style={{ borderRadius: '10px', ...inputProps.style }}
            type={inputProps.buttonType ? inputProps.buttonType : 'primary'}
            block={true}
            danger={inputProps.danger}
            ghost={inputProps.ghost}
            htmlType={inputProps.type === 'submitButton' ? 'submit' : undefined}
            loading={inputProps.isButtonLoading}
          >
            {inputProps.isButtonLoading ? '' : inputProps.title}
          </Button>
        )
      case 'iconTitleHoverButton':
        return (
          <Tooltip title={inputProps.title}>
            <Button
              type="default"
              shape={inputProps.shape}
              icon={inputProps.iconOrReactElement}
              size="large"
              style={inputProps.style}
              onClick={inputProps.onClick}
              loading={inputProps.isButtonLoading}
            />
          </Tooltip>
        )
      case 'select':
        return (
          <Form.Item
            name={inputProps.valueContainerName}
            rules={[
              {
                required: inputProps.required,
                message: inputProps.errorMessage
                  ? inputProps.errorMessage
                  : t('InputErrorMessage.default')
              }
            ]}
          >
            <Select
              showSearch={inputProps.canSearch}
              onSearch={inputProps.onSearch}
              mode={inputProps.multiple ? 'multiple' : undefined}
              style={{
                width: '100%',
                margin: '10px 0px',
                fontWeight: 'bolder',
                ...inputProps.style
              }}
              allowClear={inputProps.allowClear}
              placeholder={inputProps.placeholder}
              onChange={inputProps.onChange}
              disabled={inputProps.disabled}
              loading={inputProps.loading}
              defaultValue={inputProps.defaultValue}
            >
              {inputProps.optionsList !== undefined
                ? inputProps.optionsList.map((value, index) => {
                    if (value.label && value.value) {
                      return (
                        <Option key={index} value={value.value}>
                          {value.label}
                        </Option>
                      )
                    }
                    return (
                      <Option key={index} value={value}>
                        {value}
                      </Option>
                    )
                  })
                : null}
            </Select>
          </Form.Item>
        )
     
      case 'prePostTab':
        return (
          <Form.Item
            name={inputProps.valueContainerName}
            rules={[
              {
                required: inputProps.required,
                message: inputProps.errorMessage
                  ? inputProps.errorMessage
                  : t('InputErrorMessage.default')
              }
            ]}
          >
            <Input
              addonBefore={inputProps.addonBefore}
              addonAfter={inputProps.addonAfter}
              suffix={inputProps.suffix}
              prefix={inputProps.prefix}
              disabled={inputProps.disabled}
              placeholder={inputProps.placeholder}
              onClick={inputProps.onClick}
              onChange={inputProps.onChange}
              style={{
                width: '100%',
                margin: '10px 0px',
                ...inputProps.style
              }}
            />
          </Form.Item>
        )
      default:
        return null
    }
  }

  return <>{getComponent()}</>
}

export default InputContainer
