import { Skeleton } from "antd"

interface Styles {
    container: React.CSSProperties;
}

const styles: Styles = {
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '20px',
        width: '100%',
        padding: '16px',
        borderRadius: '6px',
        margin: '24px 0'
    }
}

const ProfileSkeleton = () => {
    return (
        <div style={styles.container}>
            <div>
                <Skeleton active paragraph={{ rows: 1 }} />
                <Skeleton active paragraph={{ rows: 2 }} />
                <Skeleton active paragraph={{ rows: 2 }} />
                <Skeleton active paragraph={{ rows: 3 }} />
            </div>
            <div>
                <Skeleton active paragraph={{ rows: 1 }} />
                <Skeleton active paragraph={{ rows: 2 }} />
                <Skeleton active paragraph={{ rows: 2 }} />
                <Skeleton active paragraph={{ rows: 3 }} />
            </div>
            <div>
                <Skeleton active paragraph={{ rows: 1 }} />
                <Skeleton active paragraph={{ rows: 2 }} />
                <Skeleton active paragraph={{ rows: 2 }} />
                <Skeleton active paragraph={{ rows: 3 }} />
            </div>
        </div>
    )
}

export default ProfileSkeleton