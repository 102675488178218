import { apiProps, apiResponse } from "../interfaces/app.interfaces";
import { PreferencesCarriersV2, VerificationsI } from "../pages/DashboardSections/UsersBeta/interfaces";

export interface FilterRequest {
	reg_method?: string;
	country?: string;
	"origin.country"?: string;
	"userData.app_country"?: "CO" | "MX";
	enterado?: string;
	created_at?: {
		$gte?: any;
		$lte?: any;
	};
	"userData.metadata.activity.completedRegister"?: boolean;
	"userData.metadata.activity.firstRecharge"?: any;
}

export const getUserDataFromAuth0 = (userid: string) => {
	return {
		name: "Usuario de Prueba",
		token: "aaaaaaaaaaaaaaaaaa",
		languaje: "ES-MX",
		errors: {},
	};
};

export const getAllUsers =
	(page?: number, limit?: number, country?: string) =>
	async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "GET",
			endpoint: `users/users?page=${page}&limit=${limit}&country=${country}`,
		});
	};

export const getProfilesPaginated =
	(page?: number, limit?: number) =>
	async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "GET",
			endpoint: `users/profiles?page=${page}&limit=${limit}`,
		});
	};

export const getUsersBySearch =
	(page?: number, limit?: number, country?: string) =>
	async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "GET",
			endpoint: `users/users-search?page=${page}&limit=${limit}&country=${country}`,
		});
	};

export const getUsersByAdvancedCriteria =
	(dto: { criteria: string; text: string }) =>
	async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `users/users-search/advanced`,
			data: dto,
		});
	};
export const getUsersBySearchCriteria =
	(dto: { page?: number; limit?: number; country?: string; text?: string }) =>
	async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `users/users-search`,
			data: dto,
		});
	};

export const getFullDataUserSearch =
	(dto: { user: string }) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `users/users-search/${dto.user}`,
			data: {},
		});
	};

export const getUserSearchByPasteEmail =
	(dto: { email: string }) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `users/users-search/paste`,
			data: dto,
		});
	};


  export const updateVerifications = 
  (Id: String, dto: VerificationsI) => 
    async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: 'PATCH',
      endpoint: `users/users-search/verifications/${Id}`,
      data: {
        values: dto
      },
    })
  }

  export const getUserPreferencesV2 =
  (userId: string) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "GET",
      endpoint: `users/users-search/preferences/${userId}`,
    });
  };

  export const updatePreferencesV2 = 
  (Id: String, dto: PreferencesCarriersV2[]) => 
    async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: 'PATCH',
      endpoint: `users/users-search/preferences/${Id}`,
      data: dto,
    })
  }


export const getAllUsersByAdvancedOption =
	(dto: { criteria: string; text: string }) =>
	async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `users/users/advanced`,
			data: dto,
		});
	};

export const getUserById =
	(userId: string) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "GET",
			endpoint: `users/users/${userId}`,
		});
	};

export const getSpecialPricesByUserId =
	(userId: string) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "GET",
			endpoint: `users/specialPrices/${userId}`,
		});
	};

export const getUserProfileByEmail =
	(email: string) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "GET",
			endpoint: `users/emailuser/${email}`,
		});
	};

export const getDataForMkt =
	(filter?: FilterRequest) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `marketing/getData`,
			data: filter,
		});
	};

export const getRegisterTypes = async (
	backendApiCall: (data: apiProps) => Promise<apiResponse>
) => {
	return await backendApiCall({
		method: "GET",
		endpoint: "marketing/getRegisterTypes",
	});
};

export const getDashboardMkt =
	(filter?: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `marketing/dashboard`,
			data: filter,
		});
	};
