import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Form } from "antd";
import { t } from "i18next";
import InputContainer from "../../../components/Containers/InputContainer";
import CardContainer from "../../../components/Containers/CardContainer";
import { usePost } from "../../../hooks/usePost";
import { DiscountOverweights, ShippingInfo, TrackingInfo, UpdateMassiveStatus } from "../../../services/shipping_s";
import { notification } from "antd";
import { useDropzone } from "react-dropzone";
import { OverweightActionArray, OverweightCarriersArray } from "../../../interfaces/shipments.interface";
import ModalFiles from "./Overweight/ModalFiles";
import { assignCarrierFiles, formatExcelFiles, readData, parseCarrierNames } from "./Overweight/utils";
import Instructions from "./Overweight/Instructions";
import OverWeightCard from "./Overweight/OverWeightCard";
import DragNDrop from "./Overweight/DragNDrop";
import ChangeStatusCard from "./Overweight/ChangeStatusCard";
import ModalTracking from "./Overweight/ModalTracking";

export interface FileI {
    tracking: any;
    amount: any;
    weight: any;
    description: any | undefined;
}

export interface TrackingsI {
    tracking: string;
    amount: string;
    carrier: string;
    status: string;
}

export interface OverweightI {
    email: string;
    user: string;
    overweight: number;
    amount: number;
    tracking: string;
    description: string;
}

const Overweight: React.FC = () => {
    const [carrier, setCarrier] = useState<string>("");
    const [action, setAction] = useState<string>("");
    const [selectedFiles, setSelectedFiles] = useState<FileI[]>([])
    const [overweights, setOverweights] = useState<OverweightI[]>([])
    const [openModal, setOpenModal] = useState(false)
    const [filesBy200Items, setFilesBy200Items] = useState([])
    const [trackings, setTrackings] = useState<TrackingsI[]>([])
    const onDrop = useCallback(
        async ([acceptedFiles]) => await onDropActions(acceptedFiles),
        // eslint-disable-next-line
        [action, carrier]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: ".xls,.xlsx" as any,
        maxFiles: 1,
    });

    const [form] = Form.useForm();

    const { fetchData: getShippingInfo, isLoading } = usePost(ShippingInfo);
    const { fetchData: trackingInfoFetch, isLoading: isloadingTracking } = usePost(TrackingInfo);
    const { fetchData: fetchDiscounts, isLoading: loadingDiscounts } = usePost(DiscountOverweights);
    const { fetchData: fetchUpdateMassive, isLoading: loadingUpdateMassive } = usePost(UpdateMassiveStatus);

    const getShipmentInfo = async (files: FileI[]) => {
        try {
            const SendCarrier = parseCarrierNames(carrier, true) as string
            const response = await getShippingInfo({
                files,
                carrier: SendCarrier
            })
            if (response.data) {
                console.log(response.data)
                setOverweights(response.data)
            } else {
                onFinishFaild()
            }
            console.log(response)
        } catch (error) {
            console.log(error)
            onFinishFaild()
        }
    }


    const discountOverweights = async (files: OverweightI[]) => {
        try {
            const SendCarrier = parseCarrierNames(carrier, true) as string
            const response = await fetchDiscounts({
                files,
                carrier: SendCarrier
            })
            if (response.data) {
                console.log(response.data)
                setOverweights(response.data)
                notification.success({
                    message: t("Sobrepesos cobrados exitosamente"),
                });
                setOpenModal(false);
            } else {
                onFinishFaild()
            }
            console.log(response)
        } catch (error) {
            console.log(error)
            onFinishFaild()
        }
    }
    const getTrackingInfo = async (files: TrackingsI[]) => {
        try {
            const response = await trackingInfoFetch({
                files
            })
            if (response.data) {
                console.log(response.data)
                setTrackings(response.data)
            } else {
                onFinishFaild()
            }
            console.log(response)
        } catch (error) {
            console.log(error)
            onFinishFaild()
        }
    }

    const changeShipmentStatus = async (files: TrackingsI[]) => {
        try {
            const response = await fetchUpdateMassive({
                files,
            })
            if (response.data) {
                console.log(response.data)
                setOverweights(response.data)
                notification.success({
                    message: t("Estatus actualizados masivamente"),
                });
                setOpenModal(false);
            } else {
                onFinishFaild()
            }
            console.log(response)
        } catch (error) {
            console.log(error)
            onFinishFaild()
        }
    }


    const onFinishFaild = async () => {
        notification.error({
            message: t("Algo ha fallado"),
        });
    };

    const onDropActions = async (acceptedFiles: any) => {
        const files = await readData(acceptedFiles)
        console.log("carrier", carrier)
        const carrierFiles = assignCarrierFiles(carrier, files)
        if (action !== "Cambiar status masivo") {
            const shipmentsBy200 = formatExcelFiles(carrierFiles as FileI[])
            assignFilesBy200(shipmentsBy200)
        } else {
            const shipmentsBy200 = formatExcelFiles(carrierFiles as FileI[])
            assignFilesBy200(shipmentsBy200)
        }

    }

    const assignFilesBy200 = (files: any) => {
        setFilesBy200Items(Object.values(files))
    }

    const onSelectCard = (files: any) => {
        console.log(action, "the actuoibn")
        setSelectedFiles(files)
        setOpenModal(true)
        if (action === "Cambiar status masivo") {
            getTrackingInfo(files as TrackingsI[])
        } else {
            getShipmentInfo(files)
        }
    }

    const resetFiles = () => {
        setSelectedFiles([])
        setCarrier('')
        setOverweights([])
        setAction('')
        setFilesBy200Items([])
        form.resetFields()
    }

    useEffect(() => {
        if (!openModal) {
            setSelectedFiles([])
            setOverweights([])

        }
    }, [openModal])

    useEffect(() => {
    }, [carrier])

    return (
        <>
            <Form form={form} onFinishFailed={onFinishFaild}>
                <Row gutter={[20, 15]} style={{ justifyContent: "center" }}>
                    <Col sm={24} md={12}>
                        <Row gutter={[5, 10]}>
                            <Col span={24}>
                                <CardContainer>

                                    <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>Sobrepesos</p>
                                    <br />

                                    <b>Acción</b>
                                    <InputContainer
                                        type="select"
                                        placeholder={t("Down.Placeholder.Action")}
                                        valueContainerName={"action"}
                                        optionsList={OverweightActionArray || []}
                                        onChange={(value) => setAction(value)}
                                        required
                                    />
                                    <br />
                                    {
                                        action !== "Cambiar status masivo" && (
                                            <>
                                                <b>Paquetería</b>
                                                <InputContainer
                                                    type="select"
                                                    placeholder={t("Down.Placeholder.Carrier")}
                                                    valueContainerName={"carriers"}
                                                    optionsList={OverweightCarriersArray || []}
                                                    onChange={(value) => {
                                                        setCarrier(value)
                                                    }
                                                    }
                                                    required
                                                />
                                                <br />
                                            </>
                                        )
                                    }

                                    {
                                        carrier !== "" && action === "Bajar sobrepesos" && (
                                            <DragNDrop
                                                getRootProps={getRootProps}
                                                getInputProps={getInputProps}
                                                isDragActive={isDragActive}
                                            />
                                        )
                                    }

                                    {
                                        action === "Cambiar status masivo" && (
                                            <DragNDrop
                                                getRootProps={getRootProps}
                                                getInputProps={getInputProps}
                                                isDragActive={isDragActive}
                                            />
                                        )
                                    }
                                    <br />
                                    <InputContainer
                                        style={{ marginTop: "20px" }}
                                        type="button"
                                        onClick={resetFiles}
                                        title="Resetear"
                                    />

                                </CardContainer>
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={24} md={12}>
                        <Row gutter={[5, 10]}>
                            <Col span={24}>
                                <CardContainer
                                    title={t("Down.Overweight.Title.Instruction")}
                                    titleStyle={{ justifyContent: "center", fontSize: "1.3rem" }}
                                />
                                <br />
                                <hr />

                            </Col>

                            <Col span={24}>
                                {
                                    filesBy200Items && filesBy200Items.length > 0 ? (
                                        <>
                                            <CardContainer
                                                direction="horizontal"
                                                textList={
                                                    action === 'Cambiar status masivo' ?
                                                        [
                                                            "Titulo",
                                                            "Cantidad",
                                                            "Status"
                                                        ]
                                                        : [
                                                            "Paquetería",
                                                            "Sobrepeso",
                                                            "Cantidad",
                                                            "Status"
                                                        ]}
                                            />
                                            <br />
                                            {
                                                filesBy200Items.map((files: any[], index) => (
                                                    <>
                                                        {
                                                            action === "Cambiar status masivo" ? (
                                                                <ChangeStatusCard
                                                                    files={files}
                                                                    index={index}
                                                                    onSelectCard={() => onSelectCard(files)}
                                                                />
                                                            ) : (
                                                                <OverWeightCard
                                                                    files={files}
                                                                    index={index}
                                                                    onSelectCard={() => onSelectCard(files)}
                                                                    carrier={carrier}
                                                                />

                                                            )
                                                        }


                                                        <br />
                                                    </>
                                                ))
                                            }
                                        </>
                                    ) : (
                                        <>
                                            {
                                                trackings?.length === 0 ? <Instructions /> : ""
                                            }

                                        </>
                                    )
                                }

                                <div>
                                    {
                                        action === "Cambiar status masivo" ? (
                                            <ModalTracking
                                                openModal={openModal}
                                                setOpenModal={setOpenModal}
                                                files={trackings}
                                                loading={isloadingTracking}
                                                changeShipmentStatus={changeShipmentStatus}
                                                loadingUpdateMassive={loadingUpdateMassive}
                                            />
                                        ) : (
                                            <ModalFiles
                                                openModal={openModal}
                                                setOpenModal={setOpenModal}
                                                files={selectedFiles}
                                                loading={isLoading}
                                                overweights={overweights}
                                                discountOverweights={discountOverweights}
                                                loadingDiscounts={loadingDiscounts}
                                            />
                                        )
                                    }


                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default Overweight;
