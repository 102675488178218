import { FC } from "react";
import ModalContainer from "../../../../../components/Containers/ModalContainer"
import { Row } from "antd";
import SwitchItem from "../SwitchItem";
import { AdvancedCriteria, CriteriaType } from "../../interfaces";

interface props {
    visible: boolean;
    advancedCriteria: AdvancedCriteria;
    manageModal: () => void;
    onChangeSwitchAdvanced: (criteria: CriteriaType) => void;
}

interface Styles {
    text: React.CSSProperties;
    advancedOptionsCard: React.CSSProperties;
}

const styles: Styles = {
    text: {
        color: '#2D1B6E',
        fontSize: '18px',
        fontWeight: '500'
    },
    advancedOptionsCard: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.2rem',
        padding: '20px',
        borderRadius: '12px',
        width: '400px',
        height: '650px',
        maxHeight: '650px'
    }
}

const AdvancedOptionsModal: FC<props> = ({ visible, manageModal, advancedCriteria, onChangeSwitchAdvanced }) => {
    return (
        <ModalContainer
            title={"Criterios de búsqueda"}
            visible={visible}
            onCancel={manageModal}
            footer={<></>}
        >
            <Row>
                <section style={styles.advancedOptionsCard}>
                    <SwitchItem
                        title="Búsqueda"
                        criteria={'search'}
                        advancedData={advancedCriteria}
                        onChange={onChangeSwitchAdvanced}
                    />
                    <SwitchItem
                        title="Correo de usuario"
                        criteria={'email'}
                        advancedData={advancedCriteria}
                        onChange={onChangeSwitchAdvanced}
                    />
                    <SwitchItem
                        title="User ID"
                        criteria={'user_id'}
                        advancedData={advancedCriteria}
                        onChange={onChangeSwitchAdvanced}
                    />
                    <SwitchItem
                        title="Teléfono"
                        criteria={'phone'}
                        advancedData={advancedCriteria}
                        onChange={onChangeSwitchAdvanced}
                    />
                    <SwitchItem
                        title="Numero de guias"
                        criteria={'tracking'}
                        advancedData={advancedCriteria}
                        onChange={onChangeSwitchAdvanced}
                    />
                    <SwitchItem
                        title="Cuenta STP"
                        criteria={'stp'}
                        advancedData={advancedCriteria}
                        onChange={onChangeSwitchAdvanced}
                    />
                    <SwitchItem
                        title="Clabe de rastreo"
                        criteria={'movement'}
                        advancedData={advancedCriteria}
                        onChange={onChangeSwitchAdvanced}
                    />
                </section>
            </Row>
        </ModalContainer>
    )
}

export default AdvancedOptionsModal