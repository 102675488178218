import { Typography } from "antd";
import CardContainer from "../../../../components/Containers/CardContainer"
import SwitchItem from "./SwitchItem"
import { FC } from "react";
import { AdvancedCriteria, CriteriaType } from "../interfaces";
const { Text } = Typography;

interface Styles {
    text: React.CSSProperties;
    sticky: React.CSSProperties;
    advancedOptionsCard: React.CSSProperties;
    drawer: React.CSSProperties;
}

const styles: Styles = {
    text: {
        color: '#2D1B6E',
        fontSize: '16px',
        fontWeight: '500'
    },
    advancedOptionsCard: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.2rem',
        padding: '20px',
        borderRadius: '12px',
        height: '650px'
    },
    sticky: {
        position: 'sticky',
        top: '10px'
    },
    drawer: {
        boxShadow: 'none'
    }
}

interface props {
    advancedCriteria: AdvancedCriteria;
    onChangeSwitchAdvanced: (criteria: CriteriaType) => void;
    isDrawer?: boolean;
}

const AdvancedOptions: FC<props> = ({ advancedCriteria, onChangeSwitchAdvanced, isDrawer = false }) => {
    return (
        <CardContainer 
            containerStyle={isDrawer ? styles.drawer : styles.sticky} 
            cardStyle={isDrawer ? styles.drawer: {}}>
            <section style={styles.advancedOptionsCard}>
                {
                    !isDrawer &&
                    <Text style={styles.text}>Criterio de búsqueda</Text>
                }

                <SwitchItem
                    title="Búsqueda"
                    criteria={'search'}
                    advancedData={advancedCriteria}
                    onChange={onChangeSwitchAdvanced}
                />
                <SwitchItem
                    title="Correo de usuario"
                    criteria={'email'}
                    advancedData={advancedCriteria}
                    onChange={onChangeSwitchAdvanced}
                />
                <SwitchItem
                    title="User ID"
                    criteria={'user_id'}
                    advancedData={advancedCriteria}
                    onChange={onChangeSwitchAdvanced}
                />
                <SwitchItem
                    title="Teléfono"
                    criteria={'phone'}
                    advancedData={advancedCriteria}
                    onChange={onChangeSwitchAdvanced}
                />
                <SwitchItem
                    title="Numero de guias"
                    criteria={'tracking'}
                    advancedData={advancedCriteria}
                    onChange={onChangeSwitchAdvanced}
                />
                <SwitchItem
                    title="Cuenta STP"
                    criteria={'stp'}
                    advancedData={advancedCriteria}
                    onChange={onChangeSwitchAdvanced}
                />
                <SwitchItem
                    title="Clabe de rastreo"
                    criteria={'movement'}
                    advancedData={advancedCriteria}
                    onChange={onChangeSwitchAdvanced}
                />
            </section>
        </CardContainer>)
}

export default AdvancedOptions