import { GetTokenSilentlyOptions } from '@auth0/auth0-react'
import { apiProps, apiResponse, UserData } from '../interfaces/app.interfaces'
const audience = process.env.REACT_APP_AUTH0_AUDIENCE || ''
const scope = process.env.REACT_APP_AUTH0_SCOPE || ''

//API METHODS
export const getToken = async (
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>
) => {
  return await getAccessTokenSilently({ audience, scope })
}

export const updateUserData = async (
  backendApiCall: (data: apiProps) => Promise<apiResponse>,
  newData: { userId: string; info: UserData }
) => {
  return await backendApiCall({
    method: 'PUT',
    endpoint: 'users/update',
    data: newData
  })
}

//OTP METHODS
export const sendCode = async (
  backendApiCall: (data: apiProps) => Promise<apiResponse>,
  phoneNumber: string
) => {
  return await backendApiCall({
    method: 'POST',
    endpoint: 'otp/sendcode',
    data: { phone_number: phoneNumber }
  })
}

export const resendCode = async (
  backendApiCall: (data: apiProps) => Promise<apiResponse>,
  phoneNumber: string
) => {
  return await backendApiCall({
    method: 'POST',
    endpoint: 'otp/resendcode',
    data: { phone_number: phoneNumber.trim().replace('+', '') }
  })
}

export const validateCode = async (
  backendApiCall: (data: apiProps) => Promise<apiResponse>,
  phoneNumber: string,
  code: string
) => {
  return await backendApiCall({
    method: 'POST',
    endpoint: 'otp/validateCode',
    data: {
      phone_number: phoneNumber.trim(),
      code: code
    }
  })
}
