import { Typography } from "antd"

interface Styles {
    container: React.CSSProperties;
    text: React.CSSProperties;
    textBold: React.CSSProperties;
}

const styles: Styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: '#F6F6F6',
        borderRadius: '12px'
    },
    text: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    textBold: {
        fontWeight: 'bold'
    }
}

const ResultsNotFound = () =>{
    return(
        <section style={styles.container}>
            <Typography style={styles.textBold}>No hay resultados para mostrar</Typography>
            <Typography style={styles.text}>Prueba utilizando otro criterio de búsqueda</Typography>
        </section>
    )
}

export default ResultsNotFound