import {
	apiProps,
	apiResponse,
	BackendApiCall,
	TopBuyers,
	UseGet,
	UseGetParams,
} from '../interfaces/app.interfaces';
import { DEFAULT_ADMIN_ROUTE } from './admin_s';
import moment from 'moment';

type CreateCpDto = {
	cp: number;
	mail: string;
	user: string;
	blockReason: string;
};
export type UpdateCpDto = {
	banned: {
		cp: number;
	};
};

export type GetTopBuyersParams = {
	startDate?: string;
	endDate?: string;
	page?: number;
	limit?: number;
	country?:string;
};

export const getTopBuyers: UseGetParams<
	{ count: number; page: number; data: TopBuyers[] },
	GetTopBuyersParams
> = (params) => async (backendApiCall) => {
	return await backendApiCall({
		method: 'GET',
		endpoint: `${DEFAULT_ADMIN_ROUTE}/data/topbuyers`,
		params: {
			startDate: params?.startDate || '2020-01-01',
			endDate: params?.endDate || moment().endOf('month').format('YYYY-MM-DD'),
			page: params?.page || 0,
			limit: params?.limit || 5,
			...(params?.country && {country: params?.country})
		},
	});
};
export const getBlockedCp: UseGet = async (backendApiCall) => {
	return await backendApiCall({
		method: 'GET',
		endpoint: `${DEFAULT_ADMIN_ROUTE}/cp`,
	});
};

export const blockCp =
	(dto: UpdateCpDto) => async (backendApiCall: BackendApiCall) => {
		return await backendApiCall({
			method: 'POST',
			endpoint: `${DEFAULT_ADMIN_ROUTE}/cp/block`,
			data: dto,
		});
	};

export const unblockCp =
	(dto: UpdateCpDto) => async (backendApiCall: BackendApiCall) => {
		return await backendApiCall({
			method: 'POST',
			endpoint: `${DEFAULT_ADMIN_ROUTE}/cp/unblock`,
			data: dto,
		});
	};

export const createBlockedCp =
	(dto: CreateCpDto) => async (backendApiCall: BackendApiCall) => {
		return await backendApiCall({
			method: 'POST',
			endpoint: `${DEFAULT_ADMIN_ROUTE}/cp/create`,
			data: {
				data: dto,
			},
		});
	};

	export const sendDrEvioLog =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
	  return await backendApiCall({
		method: 'POST',
		endpoint: 'configurations/sendLog',
		data: dto
	  })
	}

	export const getBlockedUsers: UseGet = async (backendApiCall) => {
		return await backendApiCall({
			method: 'GET',
			endpoint: `${DEFAULT_ADMIN_ROUTE}/data/user-blacklist`,
		});
	};

	
	export const getSendersUsedByBlockedUsers =
	(dto: {user: string}) => async (backendApiCall: BackendApiCall) => {
		return await backendApiCall({
			method: 'POST',
			endpoint: `${DEFAULT_ADMIN_ROUTE}/data/senders-count`,
			data: dto,
		});
	};

	export const getMultipleAccountsByUser =
	(dto: {user_id: string}) => async (backendApiCall: BackendApiCall) => {
		return await backendApiCall({
			method: 'POST',
			endpoint: `${DEFAULT_ADMIN_ROUTE}/blacklist/restricted-values`,
			data: dto,
		});
	};

	export const getMAccounts =
	(dto: {users: string[]}) => async (backendApiCall: BackendApiCall) => {
		return await backendApiCall({
			method: 'POST',
			endpoint: `${DEFAULT_ADMIN_ROUTE}/blacklist/multiple-accounts`,
			data: dto,
		});
	};


	export const blockSenderByBlockedUser =
	(dto: {
		data:{
			postal_code: string, 
			user: string, 
			block_reason: string, 
			email: string
		}
		
	}) => async (backendApiCall: BackendApiCall) => {
		return await backendApiCall({
			method: 'POST',
			endpoint: `${DEFAULT_ADMIN_ROUTE}/data/block-sender`,
			data: dto,
		});
	};


