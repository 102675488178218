import TabsContainer from "../../components/Containers/TabsContainer";
import TokenUsers from "./TokenUsers";
import TopClients from "./TopClients";
import TransferTab from "./TransferTab";
import usePermission from "../../hooks/usePermission";
import { useEffect, useState, useContext } from "react";
import {
  ActionsRegisters,
  PermissionViews,
} from "../../interfaces/permissions.interfaces";
import { AdminDataContext } from "../../context/adminContext";
import StpTransactions from "./StpTransactions";
import MonitoredInfo from "./MonitoredInfo";
import AdminLogs from "./AdminLogs";
export interface DataAccessI {
  api_clients: boolean;
  top_users: boolean;
  col_recharges?: boolean;
  resellers: boolean;
  admin_logs: boolean;
  stp_transactions?: boolean
  monitored_info?: boolean
}

const Data = () => {
  const { hasAccess } = usePermission();
  const { country } = useContext(AdminDataContext);
  const [tabContent, setTabContent] = useState({});
  const [tabAccess, setTabAccess] = useState<DataAccessI>({
    api_clients: hasAccess(
      ActionsRegisters.api_clients,
      PermissionViews.registers
    ),
    top_users: hasAccess(ActionsRegisters.top_users, PermissionViews.registers),
    resellers: hasAccess(ActionsRegisters.resellers, PermissionViews.registers),
    admin_logs: hasAccess(ActionsRegisters.admin_logs, PermissionViews.registers),
  });

  useEffect(() => {
    let updateTabAccess = { ...tabAccess };
    if (country === "CO") {
      updateTabAccess = {
        ...tabAccess,
        col_recharges: hasAccess(
          ActionsRegisters.col_recharges,
          PermissionViews.registers
        ),
      };
      delete updateTabAccess.monitored_info
      delete updateTabAccess.stp_transactions
      setTabAccess(updateTabAccess);
    }
    if (country === "MX") {
      updateTabAccess = {
        ...tabAccess,
        stp_transactions: hasAccess(
          ActionsRegisters.stp_transactions,
          PermissionViews.registers
        ),
        monitored_info: hasAccess(
          ActionsRegisters.monitored_info,
          PermissionViews.registers
        ),
      };
      delete updateTabAccess.col_recharges;
      setTabAccess(updateTabAccess);
    }
  }, [country]); //eslint-disable-line

  const assignTabs = () => {
    let tabObject = {};

    if (tabAccess.api_clients) {
      tabObject = {
        ...tabObject,
        "Token Users": {
          component: <TokenUsers />,
        },
      };
    }

    if (tabAccess.top_users) {
      tabObject = {
        ...tabObject,
        "Top Clientes": {
          component: <TopClients />,
        },
      };
    }

    if (tabAccess.col_recharges) {
      tabObject = {
        ...tabObject,
        "Recargas Colombia": {
          component: <TransferTab />,
        },
      };
    }

    // if (tabAccess.resellers) {
    //   tabObject = {
    //     ...tabObject,
    //     Revendedores: {
    //       component: <ResellerUsers />,
    //     },
    //   };
    // }

		if (tabAccess.stp_transactions) {
			tabObject = {
				...tabObject,
				'Transacciones STP': {
					component: <StpTransactions />,
				}
			}
		}

    if(tabAccess.monitored_info) {
      tabObject = {
        ...tabObject,
        Monitored: {
          component: <MonitoredInfo />,
        },
      };
    }

		if (tabAccess.admin_logs) {
			tabObject = {
				...tabObject,
				'Admin Logs': {
					component: <AdminLogs />,
				}
			}
		}

  setTabContent(tabObject);
}

  useEffect(() => {
    assignTabs();
    //eslint-disable-next-line
  }, [tabAccess]);

  return <TabsContainer titlesAndContent={tabContent} />;
};

export default Data;
