import { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { t } from "i18next";
import { Col, DatePicker, Collapse, Row } from "antd";
import { usePost } from "../../../../hooks/usePost";
import CardContainer from "../../../../components/Containers/CardContainer";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { getMostUsedParcels } from "../../../../services/admin_s";
import {
  carrierNames,
  getParcelImageForDashboard,
} from "../../../../services/records";
import { FilterRequest } from "../../../../services/user_d";
const { Panel } = Collapse;

type Props = {
  country: string;
};

interface DataMostUsedParcels {
  data: {
    parcels: {
      _id: string;
      total: number;
      services: {
        service: {
          name: string;
          total: number;
        };
      }[];
    }[];
  };
}
export const MostUsedPackages = ({ country }: Props) => {
  const [ranges, setRanges] = useState<moment.Moment[]>([
    moment().startOf("month"),
    moment().endOf("day"),
  ]);
  const {
    fetchData,
    data: filteredData,
    isLoading,
  } = usePost<FilterRequest, DataMostUsedParcels>(getMostUsedParcels);

  const getData = useCallback(async () => {
    await fetchData({
      "origin.country": country,
      created_at: {
        $gte: ranges[0].startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        $lte: ranges[1].endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
    });
  }, [fetchData, country, ranges]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ranges, country]);

  return (
    <CardContainer
      cardStyle={{
        marginTop: "20px",
        overflow: "hidden",
        padding: "20px",
        borderBottom: "1px solid #ccc",
      }}
      titleStyle={{ placeItems: "center" }}
      title="Paqueterías mas usadas este Mes"
    >
      <DatePicker.RangePicker
        onChange={(e: any) => {
          setRanges([moment(e[0]).startOf("day"), moment(e[1]).endOf("day")]);
        }}
        placeholder={["Fecha inicial", "Fecha final"]}
        value={ranges as any}
        style={{ width: "100%" }}
      />
      <hr />
      <Row>
        <Col span={11} style={{ fontWeight: "bold", textAlign: "center" }}>
          <p>Paquetería</p>
        </Col>
        <Col span={6}></Col>
        <Col span={7} style={{ fontWeight: "bold", textAlign: "center" }}>
          <p>Total</p>
        </Col>
      </Row>
      <Row
        align="middle"
        style={{
          maxHeight: "36vh",
          paddingRight: "20px",
          overflowY: `${filteredData?.data?.parcels !== undefined &&
              filteredData?.data.parcels.length > 5
              ? "scroll"
              : "hidden" || "scroll"
            }`,
        }}
      >
        {isLoading ? (
          <LoadingAnimation animationType="small" />
        ) : filteredData?.data?.parcels === undefined ||
          filteredData?.data.parcels.length === 0 ? (
          <p
            style={{
              fontWeight: "bold",
              margin: "50px auto",
              color: "#0008",
            }}
          >
            No hay datos para mostrar.
          </p>
        ) : (
          <Collapse
            bordered={false}
            style={{ background: "transparent", width: "100%" }}
          >
            {filteredData.data.parcels.map((item: any) => {
              let carrier =
                item._id === "noventa9minutos" ? "noventa9Minutos" : item._id;
              return (
                <Panel
                  key={item._id}
                  header={
                    <Row
                      justify="space-between"
                      style={{ alignItems: "center", width: "100%" }}
                    >
                      <Col
                        span={16}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {getParcelImageForDashboard(
                          carrierNames(carrier) as AllCarriers,
                          "30px"
                        )}
                        <span
                          style={{ marginLeft: "10px", whiteSpace: "nowrap" }}
                        >
                          {t(`Quote.Service.Carrier.${carrierNames(carrier)}`)}
                        </span>
                      </Col>
                      <Col
                        span={8}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "right",
                          fontWeight: "bold",
                          color: "#2D1B6E",
                        }}
                      >
                        {item.total.toLocaleString() || 0}
                      </Col>
                    </Row>
                  }
                  style={{ background: "transparent", border: "none" }}
                >
                  {item.services
                    .sort((a: any, b: any) => b.service.total - a.service.total)
                    .map(({ service }: any) => (
                      <Row
                        key={service.name}
                        justify="space-between"
                        style={{
                          margin: "5px 0",
                          flexWrap: "wrap",
                          width: "65%",
                        }}
                      >
                        <Col
                          span={24}
                          style={{
                            paddingLeft: 25,
                            overflowWrap: "break-word",
                            whiteSpace: "normal",
                            maxWidth: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              borderBottom: "1px solid #ccc",
                            }}
                          >
                            <div>
                              {`${t(
                                `Quote.Service.Carrier.${carrierNames(carrier)}`
                              )} ${t(`Quote.Service.${service.name}`)}`}
                            </div>
                            <div
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {service.total.toLocaleString()}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ))}
                </Panel>
              );
            })}
          </Collapse>
        )}
      </Row>
    </CardContainer>
  );
};
