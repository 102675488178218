// Auth0 Configuration by Carlos Leon - 10/01/22
import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';

const AuthWrapper = ({ children }:any) => {
  const history = useNavigate();
  
  const onRedirectCallback = (appState:any) => {
    history(appState?.returnTo || window.location.pathname);
  };
  
  return (
    <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE || ''}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    cacheLocation='localstorage'
    scope={process.env.REACT_APP_AUTH0_SCOPE || ''}
    >
      {children}
    </Auth0Provider>
  );
};
  
export default AuthWrapper;
