import { Row, Col, Form, Spin, message } from "antd";
import { FC, useContext, useEffect, useState } from "react";
import CardContainer from "../../../components/Containers/CardContainer";
import { ArrowLeftOutlined } from "@ant-design/icons";
import InputContainer from "../../../components/Containers/InputContainer";
import { AdminDataContext } from "../../../context/adminContext";
import {
  ActionsDevSection,
  ActionsDownloads,
  ActionsFinderSection,
  ActionsMarketingSection,
  ActionsPermissions,
  ActionsPricesSection,
  ActionsReferrals,
  ActionsFulfillment,
  ActionsRegisters,
  ActionsSeachUsers,
  ActionsSections,
  ActionsSells,
  ActionsStadistics,
  AdminUserI,
  PermissionFormDataI,
  PermissionViews,
  UpdateUserPermissionsI,
  UserPermissionsI,
} from "../../../interfaces/permissions.interfaces";
import { usePatch } from "../../../hooks/usePatch";
import { editUserPermissions } from "../../../services/permission_s";
import { UserInterface } from "../../../interfaces/app.interfaces";
import { defaultFormValues, stylesPermissions } from "./utils";
import { AppContext } from "../../../context/appContext";
import PermissionCard from "./PermissionCard";
import usePermission from "../../../hooks/usePermission";

const permissionsData = {
  search_users: false,
  registers: false,
  sections: false,
  referrals: false,
  fulfillment: false,
  sells: false,
  downloads: false,
  permissions: false,
  prices_section: false,
  stadistics: false,
  marketing: false,
  developers: false,
  finder: false,
};

interface props {
  data: AdminUserI | undefined;
  updatePermissions: (id: string, permissions: UserPermissionsI) => void;
  refetchUsers: () => any;
}

const PermissionsScreen: FC<props> = ({
  data,
  updatePermissions,
  refetchUsers,
}) => {
  const [form] = Form.useForm();
  const [permissions, setPermissions] = useState(permissionsData);
  const { hasUserAccess } = usePermission();
  const { previousPermissionStep, setPermissionStepTransition } =
    useContext(AdminDataContext);
  const { updatePermissionsData, updateUserAccount, userData } =
    useContext(AppContext);
  const { fetchDataPatch, isLoadingPatch, dataPatch } = usePatch<
    UpdateUserPermissionsI,
    UserInterface
  >(editUserPermissions);

  const changePermissions = (index: string, e: any) => {
    const value = e?.target.checked;
    console.log(e.target.checked, { [index]: value });
    setPermissions({
      ...permissions,
      [index]: value,
    });
  };

  const assignPermissionView = (view: PermissionViews) => {
    switch (view) {
      case PermissionViews.search_users:
        return data?.metadata.permissions?.search_users?.menu || false;

      case PermissionViews.sections:
        return data?.metadata.permissions?.sections?.menu || false;

      case PermissionViews.referrals:
        return data?.metadata.permissions?.referrals?.menu || false;

      case PermissionViews.fulfillment:
        return data?.metadata.permissions?.fulfillment?.menu || false;

      case PermissionViews.sells:
        return data?.metadata?.permissions?.sells?.menu || false;

      case PermissionViews.registers:
        return data?.metadata.permissions?.registers?.menu || false;

      case PermissionViews.prices_section:
        return data?.metadata.permissions?.prices_section?.menu || false;

      case PermissionViews.permissions:
        return data?.metadata.permissions?.permissions?.menu || false;

      case PermissionViews.downloads:
        return data?.metadata.permissions?.downloads?.menu || false;

      case PermissionViews.developers:
        return data?.metadata.permissions?.developers?.menu || false;

      case PermissionViews.marketing:
        return data?.metadata.permissions?.marketing?.menu || false;

      case PermissionViews.stadistics:
        return data?.metadata.permissions?.stadistics?.menu || false;

      case PermissionViews.finder:
        return data?.metadata.permissions?.finder?.menu || false;

      default:
        return false;
    }
  };

  useEffect(() => {
    if (dataPatch !== null) {
      updatePermissions(
        data?._id as string,
        dataPatch?.metadata?.permissions as UserPermissionsI
      );

      if (userData._id === dataPatch._id) {
        updatePermissionsData(
          dataPatch.metadata.permissions as UserPermissionsI
        );
        updateUserAccount(dataPatch);
      }
    }
    //eslint-disable-next-line
  }, [dataPatch, data]);

  const previousPage = () => {
    setPermissionStepTransition(previousPermissionStep);
  };

  const onFinish = async (formData: PermissionFormDataI) => {
    try {
      console.log(formData);
      await fetchDataPatch(data?._id as string, {
        permissions: {
          users: {
            verify: formData.verify,
            search_users: formData.search_users,
            recharge: formData.recharge,
            update: formData.update,
            block_users: formData.block_users,
            assign_admin: formData.assign_admin,
            discount: formData.discount,
            user_special_prices: formData.user_special_prices,
          },
          sections: {
            sections: formData.sections,
            shipments: formData.shipments,
            movements: formData.movements,
            reports: formData.reports,
            recolections: formData.recolections,
            cancelations: formData.cancelations,
            users: formData.users,
            balances: formData.balances,
            api_shipments: formData.api_shipments,
            personalAccounts: formData.personalAccounts,
          },
          referrals: {
            referrals: formData.referrals,
            view_profiles: formData.view_profiles,
            view_referrals: formData.view_referrals,
            assign_referrals: formData.assign_referrals,
            view_withdrawals: formData.view_withdrawals,
            view_commissions: formData.view_commissions,
            view_pending_withdrawal_by_platform: formData.view_pending_withdrawal_by_platform,
          },
          fulfillment: {
            fulfillment: formData.fulfillment,
            view_fulfill_profiles: formData.view_fulfill_profiles,
          },
          sells: {
            sells: formData.sells,
            view_seller_assign: formData.view_seller_assign,
            assign_customers: formData.assign_customers,
            view_all_sells: formData.view_all_sells,
          },
          registers: {
            registers: formData.registers,
            resellers: formData.resellers,
            top_users: formData.top_users,
            modify_api_clients: formData.modify_api_clients,
            col_recharges: formData.col_recharges,
            stp_transactions: formData.stp_transactions,
            api_clients: formData.api_clients,
            admin_logs: formData.admin_logs,
            monitored_info: formData.monitored_info,
            provider_info: formData.provider_info,
          },
          prices: {
            prices: formData.prices,
            prices_section: formData.prices_section,
            modify_prices: formData.modify_prices,
            modify_special_prices: formData.modify_special_prices,
            special_prices: formData.special_prices,
          },
          downloads: {
            downloads: formData.downloads,
            download_movements: formData.download_movements,
            download_recolections: formData.download_recolections,
            download_shipments: formData.download_shipments,
            download_users: formData.download_users,
            excess_weight: formData.excess_weight,
          },
          permissions: {
            permissions: formData.permissions,
            update_permissions: formData.update_permissions,
            modify_admin_permission: formData.modify_admin_permission,
          },
          devs: {
            developers: formData.developers,
            cp_blacklist: formData.cp_blacklist,
            error_logs: formData.error_logs,
            user_blacklist: formData.user_blacklist,
            modify_cps: formData.modify_cps,
          },
          marketing: {
            marketing: formData.marketing,
            marketing_stadistics: formData.marketing_stadistics,
            user_tracking: formData.user_tracking,
          },
          stadistics: {
            stadistics: formData.stadistics,
            stats: formData.stats,
          },
          finder: {
            finder: formData.finder,
            shipments_finder: formData.shipments_finder,
            update_status: formData.update_status,
          },
        },
      });
      message.success("El estatus ha sido actualizado", 10);
      refetchUsers();
    } catch (error) {
      message.error("El estatus ha sido actualizado", 10);
      console.log(error);
    }
  };

  useEffect(() => {
    try {
    } catch (error) {
      console.log(error);
    }
  }, [permissions]);

  useEffect(() => {
    if (data !== undefined && !data?.metadata?.permissions) {
      form.setFieldsValue(defaultFormValues);
    } else {
      setPermissions({
        search_users: assignPermissionView(PermissionViews.search_users),
        registers: assignPermissionView(PermissionViews.registers),
        sections: assignPermissionView(PermissionViews.sections),
        referrals: assignPermissionView(PermissionViews.referrals),
        fulfillment: assignPermissionView(PermissionViews.fulfillment),
        downloads: assignPermissionView(PermissionViews.downloads),
        permissions: assignPermissionView(PermissionViews.permissions),
        prices_section: assignPermissionView(PermissionViews.prices_section),
        stadistics: assignPermissionView(PermissionViews.stadistics),
        marketing: assignPermissionView(PermissionViews.marketing),
        developers: assignPermissionView(PermissionViews.developers),
        finder: assignPermissionView(PermissionViews.finder),
        sells: assignPermissionView(PermissionViews.sells)
      });
      const permissions = data?.metadata.permissions as UserPermissionsI;

      form.setFieldsValue({
        //users_section
        search_users: permissions?.search_users?.menu || false,
        verify: hasUserAccess(
          permissions,
          ActionsSeachUsers.verify,
          PermissionViews.search_users
        ),
        recharge: hasUserAccess(
          permissions,
          ActionsSeachUsers.recharge,
          PermissionViews.search_users
        ),
        update: hasUserAccess(
          permissions,
          ActionsSeachUsers.update,
          PermissionViews.search_users
        ),
        block_users: hasUserAccess(
          permissions,
          ActionsSeachUsers.block_users,
          PermissionViews.search_users
        ),
        assign_admin: hasUserAccess(
          permissions,
          ActionsSeachUsers.assign_admin,
          PermissionViews.search_users
        ),
        discount: hasUserAccess(
          permissions,
          ActionsSeachUsers.discount,
          PermissionViews.search_users
        ),
        user_special_prices: hasUserAccess(
          permissions,
          ActionsSeachUsers.user_special_prices,
          PermissionViews.search_users
        ),

        //sections
        sections: permissions?.sections?.menu || false,
        shipments: hasUserAccess(
          permissions,
          ActionsSections.shipments,
          PermissionViews.sections
        ),
        movements: hasUserAccess(
          permissions,
          ActionsSections.movements,
          PermissionViews.sections
        ),
        reports: hasUserAccess(
          permissions,
          ActionsSections.reports,
          PermissionViews.sections
        ),
        recolections: hasUserAccess(
          permissions,
          ActionsSections.recolections,
          PermissionViews.sections
        ),
        cancelations: hasUserAccess(
          permissions,
          ActionsSections.cancelations,
          PermissionViews.sections
        ),
        users: hasUserAccess(
          permissions,
          ActionsSections.users,
          PermissionViews.sections
        ),
        balances: hasUserAccess(
          permissions,
          ActionsSections.balances,
          PermissionViews.sections
        ),
        api_shipments: hasUserAccess(
          permissions,
          ActionsSections.api_shipments,
          PermissionViews.sections
        ),
        personalAccounts: hasUserAccess(
          permissions,
          ActionsSections.personalAccounts,
          PermissionViews.sections
        ),
        
        //referrals
        referrals: permissions?.referrals?.menu || false,
        view_profiles: hasUserAccess(
          permissions,
          ActionsReferrals.view_profiles,
          PermissionViews.referrals
        ),
        view_referrals: hasUserAccess(
          permissions,
          ActionsReferrals.view_referrals,
          PermissionViews.referrals
        ),
        assign_referrals: hasUserAccess(
          permissions,
          ActionsReferrals.assign_referrals,
          PermissionViews.referrals
        ),
        view_withdrawals: hasUserAccess(
          permissions,
          ActionsReferrals.view_withdrawals,
          PermissionViews.referrals
        ),
        view_commissions: hasUserAccess(
          permissions,
          ActionsReferrals.view_commissions,
          PermissionViews.referrals
        ),
        view_pending_withdrawal_by_platform: hasUserAccess(
          permissions,
          ActionsReferrals.view_pending_withdrawal_by_platform,
          PermissionViews.referrals
        ),
        
        //fulfillment
        fulfillment: permissions?.fulfillment?.menu || false,
        view_fulfill_profiles: hasUserAccess(
          permissions,
          ActionsFulfillment.view_fulfill_profiles,
          PermissionViews.fulfillment
        ),

        //sells
        sells: permissions?.sells?.menu || false,
        view_seller_assign: hasUserAccess(
          permissions,
          ActionsSells.view_seller_assign,
          PermissionViews.sells
        ),
        assign_customers: hasUserAccess(
          permissions,
          ActionsSells.assign_customers,
          PermissionViews.sells
        ),
        view_all_sells: hasUserAccess(
          permissions,
          ActionsSells.view_all_sells,
          PermissionViews.sells
        ),

        //registers
        registers: permissions?.registers?.menu || false,
        resellers: hasUserAccess(
          permissions,
          ActionsRegisters.resellers,
          PermissionViews.registers
        ),
        top_users: hasUserAccess(
          permissions,
          ActionsRegisters.top_users,
          PermissionViews.registers
        ),
        modify_api_clients: hasUserAccess(
          permissions,
          ActionsRegisters.modify_api_clients,
          PermissionViews.registers
        ),
        col_recharges: hasUserAccess(
          permissions,
          ActionsRegisters.col_recharges,
          PermissionViews.registers
        ),
        stp_transactions: hasUserAccess(
          permissions,
          ActionsRegisters.stp_transactions,
          PermissionViews.registers
        ),
        admin_logs: hasUserAccess(
          permissions,
          ActionsRegisters.admin_logs,
          PermissionViews.registers
        ),
        monitored_info: hasUserAccess(
          permissions,
          ActionsRegisters.monitored_info,
          PermissionViews.registers
        ),
        api_clients: hasUserAccess(
          permissions,
          ActionsRegisters.api_clients,
          PermissionViews.registers
        ),
        provider_info: hasUserAccess(
          permissions,
          ActionsRegisters.provider_info,
          PermissionViews.registers
        ),
        //prices
        prices_section: permissions?.prices_section?.menu || false,
        prices: hasUserAccess(
          permissions,
          ActionsPricesSection.prices,
          PermissionViews.prices_section
        ),
        modify_prices: hasUserAccess(
          permissions,
          ActionsPricesSection.modify_prices,
          PermissionViews.prices_section
        ),
        modify_special_prices: hasUserAccess(
          permissions,
          ActionsPricesSection.modify_special_prices,
          PermissionViews.prices_section
        ),
        special_prices: hasUserAccess(
          permissions,
          ActionsPricesSection.special_prices,
          PermissionViews.prices_section
        ),

        //downloads
        downloads: permissions?.downloads?.menu || false,
        download_movements: hasUserAccess(
          permissions,
          ActionsDownloads.download_movements,
          PermissionViews.downloads
        ),
        download_recolections: hasUserAccess(
          permissions,
          ActionsDownloads.download_recolections,
          PermissionViews.downloads
        ),
        download_shipments: hasUserAccess(
          permissions,
          ActionsDownloads.download_shipments,
          PermissionViews.downloads
        ),
        download_users: hasUserAccess(
          permissions,
          ActionsDownloads.download_users,
          PermissionViews.downloads
        ),
        excess_weight: hasUserAccess(
          permissions,
          ActionsDownloads.excess_weight,
          PermissionViews.downloads
        ),

        //permissions
        permissions: permissions?.permissions?.menu || false,
        update_permissions: hasUserAccess(
          permissions,
          ActionsPermissions.update_permissions,
          PermissionViews.permissions
        ),
        modify_admin_permission: hasUserAccess(
          permissions,
          ActionsPermissions.modify_admin_permission,
          PermissionViews.permissions
        ),

        //developers
        developers: permissions?.developers?.menu || false,
        cp_blacklist: hasUserAccess(
          permissions,
          ActionsDevSection.cp_blacklist,
          PermissionViews.developers
        ),
        error_logs: hasUserAccess(
          permissions,
          ActionsDevSection.error_logs,
          PermissionViews.developers
        ),
        user_blacklist: hasUserAccess(
          permissions,
          ActionsDevSection.user_blacklist,
          PermissionViews.developers
        ),
        modify_cps: hasUserAccess(
          permissions,
          ActionsDevSection.modify_cps,
          PermissionViews.developers
        ),

        //marketing
        marketing: permissions?.marketing?.menu || false,
        marketing_stadistics: hasUserAccess(
          permissions,
          ActionsMarketingSection.marketing_stadistics,
          PermissionViews.marketing
        ),
        user_tracking: hasUserAccess(
          permissions,
          ActionsMarketingSection.user_tracking,
          PermissionViews.marketing
        ),

        //stadistics
        stadistics: permissions?.stadistics?.menu || false,
        stats: hasUserAccess(
          permissions,
          ActionsStadistics.stats,
          PermissionViews.stadistics
        ),

        //general
        finder: permissions?.finder?.menu || false,
        shipments_finder: hasUserAccess(
          permissions,
          ActionsFinderSection.shipments_finder,
          PermissionViews.finder
        ),
        update_status: hasUserAccess(
          permissions,
          ActionsFinderSection.update_status,
          PermissionViews.finder
        ),
      });
    }

    //eslint-disable-next-line
  }, [data]);

  return (
    <Spin spinning={isLoadingPatch}>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={[50, 10]}>
          <InputContainer
            type="iconTitleHoverButton"
            shape={"circle"}
            title="Regresar"
            iconOrReactElement={<ArrowLeftOutlined />}
            style={{
              position: "absolute",
              top: "10px",
              left: "-10px",
              zIndex: "5",
            }}
            onClick={previousPage}
          />

          <Col span={22} style={stylesPermissions.permissionContainer}>
            <PermissionCard
              title="Usuarios"
              menu="search_users"
              permissions={permissions}
              changePermissions={changePermissions}
              actions={[
                { value: "recharge", label: "Recargar" },
                { value: "discount", label: "Descontar" },
                {
                  value: "user_special_prices",
                  label: "Ver precios especiales",
                },
                { value: "update", label: "Actualizar" },
                { value: "verify", label: "Verificar" },
                { value: "block_users", label: "Bloquear Usuarios" },
                { value: "assign_admin", label: "Asignar admin" },
              ]}
            />
            <PermissionCard
              title="Registros"
              menu="registers"
              permissions={permissions}
              changePermissions={changePermissions}
              actions={[
                { value: "api_clients", label: "Token clientes" },
                {
                  value: "modify_api_clients",
                  label: "Modificar token clientes",
                },
                { value: "resellers", label: "Revendedores" },
                { value: "top_users", label: "Usuarios Top" },
                { value: "col_recharges", label: "Recargas Col" },
                { value: "stp_transactions", label: "Transacciones STP" },
                { value: "admin_logs", label: "Logs de admin" },
                { value: "monitored_info", label: "Información de Monitoreo" },
                { value: "provider_info", label: "Informacion de proveedor" },
              ]}
            />
          </Col>

          <Col span={22} style={stylesPermissions.permissionContainer}>
            <PermissionCard
              title="Secciones"
              menu="sections"
              permissions={permissions}
              changePermissions={changePermissions}
              actions={[
                { value: "shipments", label: "Envíos" },
                { value: "users", label: "Usuarios" },
                { value: "recolections", label: "Recolecciones" },
                { value: "cancelations", label: "Cancelaciones" },
                { value: "balances", label: "Saldos" },
                { value: "movements", label: "Movimientos" },
                { value: "reports", label: "Reportes" },
                { value: "api_shipments", label: "Envíos del API" },
                { value: "personalAccounts", label: "Cuentas Personales" },
              ]}
            />

            <PermissionCard
              title="Descargas"
              menu="downloads"
              permissions={permissions}
              changePermissions={changePermissions}
              actions={[
                { value: "download_shipments", label: "Descargar Envíos" },
                { value: "download_users", label: "Descargar usuarios" },
                { value: "download_movements", label: "Descargar movimientos" },
                { value: "excess_weight", label: "Bajar sobrepesos" },
                {
                  value: "download_recolections",
                  label: "Descargar recolecciones",
                },
              ]}
            />
          </Col>

          <Col span={22} style={stylesPermissions.permissionContainer}>
            <PermissionCard
              title="Permisos"
              menu="permissions"
              permissions={permissions}
              changePermissions={changePermissions}
              actions={[
                {
                  value: "modify_admin_permission",
                  label: "Modificar rol admin",
                },
                { value: "update_permissions", label: "modificar permisos" },
              ]}
            />

            <PermissionCard
              title="Precios"
              menu="prices_section"
              permissions={permissions}
              changePermissions={changePermissions}
              actions={[
                { value: "prices", label: "Ver tarifas" },
                { value: "special_prices", label: "Ver P. especiales" },
                { value: "modify_prices", label: "Modificar tarifas" },
                {
                  value: "modify_special_prices",
                  label: "Modificar P. Especiales",
                },
              ]}
            />
          </Col>

          <Col span={22} style={stylesPermissions.permissionContainer}>
            <PermissionCard
              title="Marketing"
              menu="marketing"
              permissions={permissions}
              changePermissions={changePermissions}
              actions={[
                { value: "marketing_stadistics", label: "Estadísticas" },
                { value: "user_tracking", label: "Seguimiento de usuarios" },
              ]}
            />
            <PermissionCard
              title="Desarrolladores"
              menu="developers"
              permissions={permissions}
              changePermissions={changePermissions}
              actions={[
                { value: "cp_blacklist", label: "Lista negra de cp" },
                { value: "error_logs", label: "Logs de errores" },
                { value: "user_blacklist", label: "Lista negra de usuarios" },
                { value: "modify_cps", label: "modificar lista negra Cps" },
              ]}
            />
          </Col>

          <Col span={22} style={stylesPermissions.permissionContainer}>
            <PermissionCard
              title="Estadísticas"
              menu="stadistics"
              permissions={permissions}
              changePermissions={changePermissions}
              actions={[{ value: "stats", label: "Ver Estadísticas" }]}
            />

            <PermissionCard
              title="Buscador"
              menu="finder"
              permissions={permissions}
              changePermissions={changePermissions}
              actions={[
                { value: "shipments_finder", label: "Buscar envíos" },
                { value: "update_status", label: "Cambiar status" },
              ]}
            />
          </Col>

          <Col span={22} style={stylesPermissions.permissionContainer}>
            <PermissionCard
              title="Referidos"
              menu="referrals"
              permissions={permissions}
              changePermissions={changePermissions}
              actions={[
                { value: "view_profiles", label: "Ver perfiles" },
                { value: "view_referrals", label: "Ver referidos" },
                { value: "assign_referrals", label: "Asignar referidos" },
                { value: "view_withdrawals", label: "Ver retiros" },
                { value: "view_commissions", label: "Ver comisiones" },
                { value: "view_pending_withdrawal_by_platform", label: "Ver retiros por plataforma pendientes" },
              ]}
            />
            <PermissionCard
              title="Fulfillment"
              menu="fulfillment"
              permissions={permissions}
              changePermissions={changePermissions}
              actions={[
                { value: "view_fulfill_profiles", label: "Ver perfiles" },
              ]}
            />
          </Col>
          
          <Col span={22} style={stylesPermissions.permissionContainer}>
            <PermissionCard
              title="Ventas"
              menu="sells"
              permissions={permissions}
              changePermissions={changePermissions}
              actions={[
                { value: "view_all_sells", label: "Ver lista general de clientes" },
                { value: "view_seller_assign", label: "Ver clientes asignados" },
              ]}
            />
          </Col>

          <Col span={22}>
            <CardContainer>
              <InputContainer type="submitButton" title="Guardar Permisos" />
            </CardContainer>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default PermissionsScreen;
