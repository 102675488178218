import { AxiosResponse } from 'axios';
import { useContext, useState } from 'react';
import { ApiContext } from '../context/apiContext';
import { apiProps, apiResponse } from '../interfaces/app.interfaces';

export const useDelete = <T, U>(
	fn: (
		Id: String,
		dto?: T
	) => (
		backendApiCall: (data: apiProps) => Promise<apiResponse<AxiosResponse<U>>>
	) => Promise<apiResponse<AxiosResponse<U>>>
) => {
	const { backendApiCall, serviceIsReady } = useContext(ApiContext);
	const [dataDelete, setDataDelete] = useState<U | null>(null);
	const [isLoadingDelete, setIsLoadingDelete] = useState(false);

	const fetchDataDelete = async (Id: String, dto?: T) => {
		if (!serviceIsReady) return;

		setIsLoadingDelete(true);
		const data = await fn(Id, dto)(backendApiCall);
		// @ts-ignore
		setDataDelete(data.data.data);
		setIsLoadingDelete(false);
	};

	return {
		dataDelete,
		isLoadingDelete,
		fetchDataDelete,
	};
};
