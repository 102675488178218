import { Menu } from "antd"
import { UserOutlined, FileDoneOutlined, ReconciliationOutlined, GlobalOutlined } from '@ant-design/icons';
import { InboxOutlined } from "@material-ui/icons";
import { FC } from "react";
import { prefrencesMenu } from "../interfaces";
import { useMediaQuery } from "react-responsive";

interface props {
    handleMenu: (menu: prefrencesMenu) => void;
    changeModal?: () => void;
}

const SectionsMenu: FC<props> = ({ handleMenu, changeModal }) => {
    const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1600px)' })

    const onClick = (menu: prefrencesMenu) =>{
        handleMenu(menu)
        if(changeModal){
             changeModal()
        }
       
    }

    return (
        <section style={isMobileOrTablet ? { padding: '16px 0', width: '100%' } :{ padding: '16px 0' } }>
            <Menu mode="inline" defaultSelectedKeys={['profile']}>
                {/* Aquí agregar los items del menú */}
                <Menu.Item icon={<UserOutlined />} id="drenvio-searchv2-profile-default" onClick={() => onClick('profile')} key="profile">
                    Perfil
                </Menu.Item>

                <Menu.Item icon={<FileDoneOutlined />} onClick={() => onClick('verifications')} key="verifications">
                    Verificaciones
                </Menu.Item>
                <Menu.Item icon={<GlobalOutlined />} onClick={() => onClick('preferences_block')} key="preferences_block">
                    Bloqueo de preferencias
                </Menu.Item>
                <Menu.Item icon={<ReconciliationOutlined />} onClick={() => onClick('user_preferences')} key="user_preferences">
                    Preferencias de usuario
                </Menu.Item>
                <Menu.Item icon={<InboxOutlined />} onClick={() => onClick('plugins')} key="plugins">
                    Plugins
                </Menu.Item>
                <Menu.Item icon={<InboxOutlined />} onClick={() => onClick('special_prices')} key="special_prices">
                    Precios especiales
                </Menu.Item>

            </Menu>
        </section>
    )
}

export default SectionsMenu