import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { t } from "i18next";
import { TableV3 } from "../../../components/Common/TableV3";
import { ApiContext } from "../../../context/apiContext";
import { shipmentsColumnV3, shipmentsDownloads } from "./ShipmentsV2";
interface Props {
	user: string;
}

export type BodyColumn = {
	id: string;
	find: boolean;
	filters?: {
		sort: boolean;
		sorted?: number;
		filter: boolean;
		filter_values?: any[];
	};
};

export type GetAllFilteredShipmentsQuery = {
	range: {
		from: number;
		to: number;
	};
	user?: string;
	searched?: string;
	columns: BodyColumn[];
};

const ITEMS_PER_PAGES = 150;

const ShipmentsByUser: React.FC<Props> = ({ user }) => {
	const { userToken, backendURL } = useContext(ApiContext);
	const [lastUser, setLastUser] = useState<string>("");
	const [rowsPerPages, setRowPerPages] = useState(5);
	const [currentPage, setCurrentPage] = useState(1);
	const [topLimit, setTopLimit] = useState(ITEMS_PER_PAGES);
	const [refetching, setIsRefetching] = useState(false);
	const [searchText, setSearchText] = useState<string>("");
	const [dataColumns, setDataColumns] = useState<any>(null);
	const [downloadColumns] = useState<GetAllFilteredShipmentsQuery>({
		columns: [
			{
				id: "created_at",
				find: false,
			},
			{
				id: "tracking",
				find: false,
			},
			{
				id: "origin",
				find: false,
			},
			{
				id: "destination",
				find: false,
			},
			{
				id: "carrier",
				find: false,
			},
			{
				id: "price",
				find: false,
			},
			{
				id: "shipment_status",
				find: true,
				filters: {
					sort: false,
					filter: false,
					filter_values: [
						"Activa",
						"Entregado",
						"Cancelada y usada",
						"Saldo abonado",
						"En transito",
						"Excepcion",
						"Cancelado",
						"Solicitada",
						"Cancelada y usada",
					],
				},
			},
		],
		range: {
			from: 0,
			to: topLimit,
		},
		searched: searchText,
		user: user,
	});
	const [filterByColumns, setFilterByColumns] = useState<GetAllFilteredShipmentsQuery>({
		...downloadColumns,
	});

	const handleFecthShipments = async (
		filterByColumns: GetAllFilteredShipmentsQuery,
		saveState = true
	) => {
		const { data } = await axios.post(`${backendURL}shipments/labelsV3`, filterByColumns, {
			headers: {
				Authorization: `Bearer ${userToken}`,
			},
		});
		if (saveState) {
			setDataColumns(data?.shipments);
		} else {
			return data?.shipments;
		}
	};

	useEffect(() => {
		if (!dataColumns?.length) return;
		const totalPages = (dataColumns?.length ?? 0) / rowsPerPages;

		if (currentPage + 1 < Math.round(totalPages)) return;
		setTopLimit((prevState) => prevState + ITEMS_PER_PAGES);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	useEffect(() => {
		(async () => {
			if (topLimit === ITEMS_PER_PAGES) return;
			setFilterByColumns({ ...filterByColumns, range: { ...filterByColumns.range, to: topLimit } });
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [topLimit]);

	useEffect(() => {
		(async () => {
			setIsRefetching(true);
			await handleFecthShipments(filterByColumns);
			setIsRefetching(false);
		})();
	}, [filterByColumns]); //eslint-disable-line

	useEffect(() => {
		if (lastUser === user) return;
		setLastUser(user);
		setFilterByColumns({ ...filterByColumns, user: user });
	}, [user]); //eslint-disable-line

	return (
		<TableV3
			title={t("Shipments.Table.Title")}
			tableType="shipments"
			isShipments
			data={dataColumns ?? []}
			isLoading={refetching}
			onChangePage={(page) => setCurrentPage(page)}
			onChangeRowsPerPage={(row) => setRowPerPages(row)}
			schemaDownload={shipmentsDownloads}
			columns={shipmentsColumnV3(dataColumns ?? []) as any}
			noFormattedDate={true}
			stateValues={{
				currentPage,
				rowsPerPages,
				topLimit,
				searchText,
				filterByColumns,
				downloadColumns,
			}}
			filters={{
				filterBySearchText(text: string) {
					setSearchText(text);
				},
				filterByColumns(filter: GetAllFilteredShipmentsQuery) {
					setFilterByColumns(filter);
				},
				filterDownloads: async (filter: GetAllFilteredShipmentsQuery) => {
					return await handleFecthShipments(filter, false);
				},
			}}
		/>
	);
};

export default ShipmentsByUser;
