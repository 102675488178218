import React from "react";
import TabsContainer from "../../../../components/Containers/TabsContainer";
import { ShipmentsAnalytics } from "./ShipmentsAnalytics";
import { UsersAnalytics } from "./UsersAnalytics";

const Principal: React.FC = () => {
  const tabObject = {
    "Seccion 1": {
      component: <UsersAnalytics />,
    },
    "Seccion 2": {
      component: <ShipmentsAnalytics />,
    },
  };

  return <TabsContainer titlesAndContent={tabObject} />;
};

export default Principal;
