import { getUserBlackListColumns } from '../../assets/data/userBlackListColumn'
import { Table } from '../../components/Common/Table'
import { useGet } from '../../hooks/useGet'
import { blockSenderByBlockedUser, getBlockedUsers, getMAccounts, getMultipleAccountsByUser, getSendersUsedByBlockedUsers } from '../../services/information_s'
import { UserInterface } from '../../interfaces/app.interfaces'
import { InfoCircleFilled, StopOutlined } from "@ant-design/icons";
import { useEffect, useState } from 'react'
import ModalContainer, { modalHeight, modalSizes } from '../../components/Containers/ModalContainer'
import { Button, Col, Popconfirm, Row, Spin, Tag, Typography, notification } from 'antd';
import CardContainer from '../../components/Containers/CardContainer'
import moment from 'moment'
import {
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { blockUserService } from '../../services/userbalance_d'
import { usePatch } from '../../hooks/usePatch'
import { usePost } from '../../hooks/usePost'
import SearchItem from '../DashboardSections/UsersBeta/Search/SearchItem'

const styles = {
  itemSpace: {
    marginLeft: "0.5rem"
  }
}

const UserBlackList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSendersOpen, setIsSendersOpen] = useState(false)
  const [isMultipleAccountOpen, setIsMultipleAccountOpen] = useState(false)
  const [currentUser, setCurrentUser] = useState<any>(null)
  const [sendersList, setSendersList] = useState<any[]>([])
  const [multipleAccounts, setMultipleAccounts] = useState<any[]>([])
  const [accounts, setAccounts] = useState<any[]>([])
  const { data, refetch } = useGet<UserInterface[]>(getBlockedUsers)
  const { fetchData: sendersFetch, isLoading: isLoadingSenders } = usePost(getSendersUsedByBlockedUsers)
  const { fetchData: multipleAccountsFetch, isLoading: isLoadingMultipleAccounts } = usePost(getMultipleAccountsByUser)
  const { fetchData: getMAccountsFetch } = usePost(getMAccounts)
  const { fetchData: blockSenderFetch } = usePost(blockSenderByBlockedUser)
  const { isLoadingPatch, fetchDataPatch } = usePatch(blockUserService)

  useEffect(() => {
    console.log(data)
  }, [data])
  const openModal = () => {
    if(isModalOpen){
      resetValues()
    }else{
      setIsModalOpen(!isModalOpen);
    }
   
  };

  useEffect(() =>{
    if(currentUser !== null){
      getListMAccounts()
    }
  },[currentUser]) //eslint-disable-line

  const openSenders = () => {
    getSendersCount(currentUser.user)
    setIsSendersOpen(!isSendersOpen)
  }

  const openMultipleAccounts = () => {
    getMultipleAccounts(currentUser.user)
    setIsMultipleAccountOpen(!isMultipleAccountOpen)
  }

  const resetValues = () => {
    setIsModalOpen(false)
    setIsMultipleAccountOpen(false)
    setIsSendersOpen(false)
    setSendersList([])
    setAccounts([])
    setCurrentUser(null)
    setMultipleAccounts([])
  }

  const deleteBlock = async () => {
    try {
      const resp = await fetchDataPatch(currentUser.user, {
        blocked: false
      });
      if (!resp?.success) {
        notification.error({ message: 'Ha ocurrido un error, intente de nuevo.' })
        return
      }
      notification.success({ message: 'El usuario ha sido desbloqueado.' })
      refetch()
      setIsModalOpen(false)
      setCurrentUser(null)

    } catch (error) {
      console.log(error)
    }
  }

  const getSendersCount = async (user: string) => {
    try {
      const response = await sendersFetch({
        user
      })
      if (!response.error) {
        setSendersList(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getMultipleAccounts = async (user: string) => {
    try {
      const response = await multipleAccountsFetch({
        user_id: user
      })
      if (!response.error) {
        setMultipleAccounts(Object.values(response.data.multiple_accounts))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getListMAccounts = async () => {
    try {
      if (
        currentUser?.metadata?.multiple_accounts &&
        currentUser?.metadata?.multiple_accounts.length > 0) {
        const response = await getMAccountsFetch({
          users: currentUser?.metadata?.multiple_accounts
        })
        if (!response.error) {
          setAccounts(response.data)
        }
      }

    } catch (error) {
      console.log(error)
    }
  }


  const blockPostalCode = async (postalCode: string) => {
    try {
      const response = await blockSenderFetch({
        data: {
          postal_code: postalCode,
          user: currentUser.user,
          block_reason: currentUser.block_reason,
          email: currentUser.email
        }
      })
      if (!response.error) {
        setSendersList([
          ...sendersList.map(el => {
            if (el.sender === postalCode) {
              return {
                ...el,
                status: response.data.status === 'BANNED' ? 'BANNED' : 'ALREADY_BANNED'
              }
            }
            return el
          })
        ])
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Table
        columns={getUserBlackListColumns(refetch)}
        data={data ?? []}
        title="User Blacklist"
        config={{
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: () => <InfoCircleFilled />,
            tooltip: "Obtener información",
            onClick: (event, rowData: any) => {
              setCurrentUser(rowData)
              openModal();
            },
          },
        ]}
      />
      <ModalContainer
        footer={
          <Row justify="end">
            <Popconfirm
              placement="topLeft"
              cancelButtonProps={{ type: 'primary', ghost: true }}
              okButtonProps={{ danger: true }}
              title={'Está seguro de desbloquear a este usuario?'}
              onConfirm={() => deleteBlock()}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              okText="Si"
              cancelText="No"
            >
              <Button
                style={{ display: 'flex', alignItems: 'center' }}
                icon={<DeleteOutlined />}
                danger
              >
                {'Desbloquear'}
              </Button>
            </Popconfirm>
            <Button onClick={openModal}>
              Cerrar
            </Button>
          </Row>
        }
        size={modalSizes.BIG}
        height={modalHeight.BIG}
        title="Información del Usuario"
        visible={isModalOpen}
        onCancel={openModal}
      >
        <Spin spinning={isLoadingPatch} style={{ width: "100%" }}>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <CardContainer title={'Datos del usuario'}>
                <hr />
                <Typography.Paragraph>
                  <strong>Nombre:</strong> {currentUser?.profile[0].name}
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <strong>Teléfono:</strong> {currentUser?.profile[0].phone}
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <strong>Saldo:</strong>
                  {' $' + currentUser?.user_data[0]?.balance} {
                    currentUser?.user_data[0]?.app_country === 'MX'
                      ? 'mxn'
                      : 'cop'
                  }
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <strong>País:</strong> {currentUser?.app_country}
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <strong>Método de registro:</strong> {currentUser?.profile[0].reg_method}
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <strong>Fecha de registro:</strong> {
                    moment(currentUser?.user_data[0].created_at).format('YYYY-MM-DD')
                  }
                </Typography.Paragraph>
              </CardContainer>
            </Col>

            <Col span={24}>
              <CardContainer title="Verificaciones">
                <hr />
                <Typography.Paragraph>
                  <strong>Verificación:</strong> {
                    currentUser?.user_data[0].security.identity_verified
                      ? <Tag style={styles.itemSpace} color='cyan'>Activado</Tag>
                      : <Tag style={styles.itemSpace} color='red'>Desactivado</Tag>
                  }
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <strong>Remitentes:</strong> {
                    currentUser?.user_data[0].security.senders_verified
                      ? <Tag style={styles.itemSpace} color='cyan'>Activado</Tag>
                      : <Tag style={styles.itemSpace} color='red'>Desactivado</Tag>
                  }
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <strong>Envios inter:</strong> {
                    currentUser?.user_data[0].security?.international_senders
                      ? <Tag style={styles.itemSpace} color='cyan'>Activado</Tag>
                      : <Tag style={styles.itemSpace} color='red'>Desactivado</Tag>
                  }
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <strong>Validación OTP:</strong> {
                    currentUser?.user_data[0].metadata?.activity?.OTP
                      ? <Tag style={styles.itemSpace} color='cyan'>Verificada</Tag>
                      : <Tag style={styles.itemSpace} color='red'>No verificada</Tag>
                  }
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <strong>Vip:</strong> {
                    currentUser?.user_data[0]?.security?.vip
                      ? <Tag style={styles.itemSpace} color='cyan'>Activado</Tag>
                      : <Tag style={styles.itemSpace} color='red'>Desactivado</Tag>
                  }
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <strong>Dirección IP:</strong> {currentUser?.metadata.last_ip}
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <strong>Primera recarga:</strong> {
                    moment(currentUser?.user_data[0]?.metadata?.activity?.firstRecharge).format('YYYY-MM-DD')
                  }
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <strong>Última recarga:</strong> {
                    moment(currentUser?.user_data[0]?.metadata?.activity?.lastRecharge).format('YYYY-MM-DD')
                  }
                </Typography.Paragraph>
              </CardContainer>
            </Col>

            <Col span={24}>
              <CardContainer title="Remitentes">
                <hr />
                <Typography.Paragraph>
                  <strong>Verifica cuantos remitentes ha usado para sus envios y la cantidad de veces:</strong>
                </Typography.Paragraph>
                <Button onClick={openSenders}>Verificar</Button>

              </CardContainer>
            </Col>

            <Col span={24}>
              <CardContainer title="Multicuentas">
                <Spin spinning={false} style={{ width: '100%' }} >
                  <hr />
                  {
                    accounts && accounts.length > 0  ? (
                      <>
                        <Typography.Paragraph>
                          <strong>Multicuentas que se han encontrado de este usuario:</strong>
                        </Typography.Paragraph>
                        <div style={{ margin: 0, display: 'flex', flexDirection: 'column', gap: '12px' }}>
                          {
                            accounts.map(account => (
                              <Col span={24}>
                                <SearchItem
                                  user={{
                                    user: account.user,
                                    name: account.profile.name,
                                    email: account.profile.email,
                                    balance: account.balance,
                                    app_country: account.app_country,
                                    security: { isBlocked: account.security.isBlocked }
                                  }}
                                  clickable={false}
                                  nextStep={() => { }}
                                  persist={() => { }}
                                  addUser={() => { }}
                                  getFullUser={() => { }}
                                />
                              </Col>
                            ))
                          }

                        </div>
                      </>
                    ) : (
                      <>
                        <Typography.Paragraph>
                          <strong>Verifica la cantidad de cuentas que tiene el cliente:</strong>
                        </Typography.Paragraph>
                        <Button onClick={openMultipleAccounts}>Verificar</Button>
                      </>
                    )
                  }
                </Spin>

              </CardContainer>
            </Col>
          </Row>
        </Spin>
      </ModalContainer>
      <ModalContainer
        title=''
        size={modalSizes.BIG}
        height={modalHeight.BIG}
        visible={isSendersOpen}
        onOk={openSenders}
        onCancel={openSenders}
        footer={[
          <Row justify="end">
            <Button onClick={openSenders}>Cerrar</Button>
          </Row>
        ]}
      >
        <Row gutter={[20, 20]} style={{ width: '100%' }}>
          <Col span={24}>
            <Spin spinning={isLoadingSenders} style={{ width: '100%' }} >
              <Typography
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                  marginBottom: '1rem'
                }}
              >
                {sendersList && sendersList.length > 0
                  ? `Remitentes encontrados del usuario ${currentUser.email}`
                  : 'Buscando remitentes'
                }
              </Typography>
              <CardContainer
                cardStyle={{ width: '100%' }}
                textListDecoration='circles'
                textList={
                  sendersList?.length > 0 ? sendersList.map(el =>
                    <Row gutter={[20, 20]}>
                      <Col span={18}>
                        <p>
                          <strong
                            style={{ fontSize: '1.1rem', textDecoration: 'underline' }}>
                            {el?.sender}
                          </strong> : <span style={{ color: '#6d71f6' }}>{el?.count}</span> envíos realizados.</p>
                      </Col>
                      <Col span={6}>
                        {
                          !el?.status ? (
                            <Popconfirm
                              placement="topLeft"
                              cancelButtonProps={{ type: 'primary', ghost: true }}
                              okButtonProps={{ danger: true }}
                              title={'Está seguro de bloquear este Código postal?'}
                              onConfirm={() => blockPostalCode(el?.sender)}
                              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                              okText="Si"
                              cancelText="No"
                            >
                              <Button
                                style={{ display: 'flex', alignItems: 'center' }}
                                icon={<StopOutlined />}
                                danger
                              >
                                Bloquear
                              </Button>
                            </Popconfirm>
                          ) : (
                            <Typography>
                              {el?.status === 'BANNED' ? 'Bloqueado' : 'Anteriormente bloqueado'}
                            </Typography>
                          )
                        }


                      </Col>
                    </Row>
                  ) : [
                    <Typography>El usuario aún no tiene envíos realizados.</Typography>
                  ]
                }
              />
            </Spin>
          </Col>
        </Row>
      </ModalContainer>
      <ModalContainer
        title=''
        size={modalSizes.BIG}
        height={modalHeight.BIG}
        visible={isMultipleAccountOpen}
        onOk={openMultipleAccounts}
        onCancel={openMultipleAccounts}
        footer={[
          <Row justify="end">
            <Button onClick={openMultipleAccounts}>Cerrar</Button>
          </Row>
        ]}
      >
        <Row gutter={[20, 20]} style={{ width: '100%' }}>
          <Col span={24}>
            <Spin spinning={isLoadingMultipleAccounts} style={{ width: '100%' }} >
              <Typography
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                  marginBottom: '1rem'
                }}
              >
                {multipleAccounts && multipleAccounts.length > 0
                  ? `Multicuentas encontradas ${currentUser.email}`
                  : 'Buscando multicuentas'
                }
              </Typography>
              <CardContainer>
                <Col span={24} style={{ margin: 0, display: 'flex', flexDirection: 'column', gap: '12px' }}>
                  {
                    multipleAccounts?.length > 0 ? multipleAccounts.map(account => (
                      <>
                        <SearchItem
                          user={account}
                          clickable={false}
                          nextStep={() => { }}
                          persist={() => { }}
                          addUser={() => { }}
                          getFullUser={() => { }}
                        />
                      </>
                    )) : (
                      <Typography>El usuario aún no cuenta con multicuentas.</Typography>
                    )
                }

                </Col>
              </CardContainer>
              <Col>
                <br />
                <Typography style={{ textAlign: 'center' }}>Los usuarios mostrados se han bloqueado en automático.</Typography>
              </Col>
            </Spin>
          </Col>
        </Row>
      </ModalContainer>
    </>
  )
}

export default UserBlackList
