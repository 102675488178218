import { Col, Typography } from "antd"
import { FC } from "react"
import CardContainer from "../../../components/Containers/CardContainer"
import InputContainer from "../../../components/Containers/InputContainer"
import { stylesPermissions } from "./utils"

interface ActionsCardsI {
    label: string;
    value: string;
}

interface Props {
    title: string;
    menu: string;
    permissions: any;
    actions: ActionsCardsI[]
    changePermissions: (index: string, e: any) => void;
}

const PermissionCard: FC<Props> = ({ title, menu, permissions, actions, changePermissions }) => {
    return (
        <CardContainer cardStyle={stylesPermissions.singleCard}>
            <Typography.Text
                style={stylesPermissions.titleCard} strong>
                {title}
                <InputContainer
                    valueContainerName={menu}
                    placeholder=""
                    type="checkbox"
                    style={stylesPermissions.mainCheckBox}
                    onChange={(e) => changePermissions(menu, e)}
                />
            </Typography.Text>
            <hr style={{marginBottom: "1.5rem"}}/>
            <Col span={24} style={stylesPermissions.checkboxMainContainer}>

                {
                    actions.length > 0 && actions.map((el: ActionsCardsI) => (
                        <InputContainer
                            key={el.value}
                            valueContainerName={el.value}
                            placeholder={el.label}
                            type="checkbox"
                            style={stylesPermissions.checkboxItem}
                            disabled={!permissions[`${menu}`]}
                        />
                    ))
                }

            </Col>
        </CardContainer>
    )
}

export default PermissionCard;