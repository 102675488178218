import BlackList from "./BlackList";
import ErrorLogs from "./ErrorLogs";
import UserBlackList from "./UserBlackList";
import usePermission from "../../hooks/usePermission";
import { useEffect, useState } from "react";
import {
  ActionsDevSection,
  PermissionViews,
} from "../../interfaces/permissions.interfaces";
import TabsContainer from "../../components/Containers/TabsContainer";
import Integrations from "./Integrations";
import Features from "./Features";

export interface DataAccessI {
  cp_blacklist: boolean;
  user_blacklist: boolean;
  error_logs: boolean;
  integrations: boolean;
  features: boolean;
}

const DevSection = () => {
  const { hasAccess } = usePermission();
  const [tabContent, setTabContent] = useState({});
  const [tabAccess] = useState<DataAccessI>({
    cp_blacklist: hasAccess(
      ActionsDevSection.cp_blacklist,
      PermissionViews.developers
    ),
    user_blacklist: hasAccess(
      ActionsDevSection.user_blacklist,
      PermissionViews.developers
    ),
    error_logs: hasAccess(
      ActionsDevSection.error_logs,
      PermissionViews.developers
    ),
    integrations: hasAccess(
      ActionsDevSection.error_logs,
      PermissionViews.developers
      ),
    features: hasAccess(
      ActionsDevSection.error_logs,
      PermissionViews.developers
    )
  });

  const assignTabs = () => {
    let tabObject = {};

    if (tabAccess.error_logs) {
      tabObject = {
        ...tabObject,
        "Error Logs": {
          component: <ErrorLogs />,
        },
      };
    }

    if (tabAccess.cp_blacklist) {
      tabObject = {
        ...tabObject,
        "CP Blacklist": {
          component: <BlackList />,
        },
      };
    }

    if (tabAccess.user_blacklist) {
      tabObject = {
        ...tabObject,
        "User BlackList": {
          component: <UserBlackList />,
        },
      };
    }

    if (tabAccess.integrations) {
      tabObject = {
        ...tabObject,
        "Integraciones": {
          component: <Integrations />,
        },
      };
    }

    if (tabAccess.features) {
      tabObject = {
        ...tabObject,
        "Features": {
          component: <Features />,
        },
      };
    }

    setTabContent(tabObject);
  };

  useEffect(() => {
    assignTabs();
    //eslint-disable-next-line
  }, [tabAccess]);

  return <TabsContainer titlesAndContent={tabContent} />;
};

export default DevSection;
