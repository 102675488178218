import { useContext } from "react";
import { Col, Row, Typography } from "antd";
import CardContainer from "../../components/Containers/CardContainer";

import { allParcelsObject } from "../../constants/parcels";

import { PricesContext } from "../../context/PricesContext";
import { AdminDataContext } from "../../context/adminContext";

export const SelectParcel = () => {
  const { setParcelSelected } = useContext(PricesContext);
  const { country } = useContext(AdminDataContext);
  return (
    <Col style={{ overflow: "hidden" }} xs={24} lg={10}>
      <Row gutter={[0, 20]} style={{ width: "100%" }}>
        <Col span={24}>
          <CardContainer>
            <Typography.Title
              level={3}
              style={{
                marginBottom: "0",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Seleccione Paqueteria
            </Typography.Title>
          </CardContainer>
        </Col>

        <Col
          span={24}
          style={{ height: "550px", overflow: "scroll", overflowX: "hidden" }}
          className="scrollNone"
        >
          {" "}
          <Row
            gutter={[0, 20]}
            style={{
              width: "100%",
              padding: " 20px",
            }}
          >
            {Object.entries(allParcelsObject).map(([key, value]) => {
              let imgStyle: any = { height: "3rem"}
              switch(value.key){
                case 'treggo':
                  imgStyle = {...imgStyle, width: '34%'}
                  break 
                // aqui podemos añadir otros casos con estilos personalizados para cada paqueteria  
                default:
                  break  
              }
              if (value.country.includes(country)) {
                return (
                  <Col key={key} span={24}>
                    <CardContainer
                      onClick={() => setParcelSelected(value)}
                      cardStyle={{ cursor: "pointer" }}
                      iconOrImg={
                        <img
                          alt={value.key}
                          src={value.imgSrc}
                          style={imgStyle}
                        />
                      }
                    ></CardContainer>
                  </Col>
                );
              } else {
                console.log("Error en los precios");
                return null;
              }
            })}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
