import { Visibility, MonetizationOn } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'
import { IconButton } from '@material-ui/core'
import moment from 'moment'
import "moment/locale/es"
import React, { useRef, useState } from 'react'
import { CancelStatus } from '../../components/Common/CancelStatus'
import { Table } from '../../components/Common/Table'
import { useGet } from '../../hooks/useGet'
import { addBalance, getAllTransfers } from '../../services/userbalance_d'
import ModalContainer from '../../components/Containers/ModalContainer'
import InputContainer from '../../components/Containers/InputContainer'
import { Col, Modal, notification, Row } from 'antd'
import styled from 'styled-components'
import { usePatch } from '../../hooks/usePatch'

type FetchObject = {
  data: string | object[] | null;
  isLoading: boolean;
  refetch: () => void
}

type DataModal = {
  type: string,
  comprobanteUrl?: string,
  data?: {
    amount?: any,
    date?: any,
    email?: string,
    confirmationNumber?:any,
  }
  user?: string,
  request?: {
    balance?: {
      amount: number,
      currency: string,
    },
    app_country?: string,
    comprobanteId?:string,
  }
  isActivate?: boolean
}

export const Title = styled.p`
  color: #2d1b6e;
  font-size: 1.2em;
  font-weight:600
`

const TransferTab = () => {

  const elRef = useRef(null)
  const { data, isLoading, refetch }: FetchObject = useGet(getAllTransfers)
  const { fetchDataPatch } = usePatch(addBalance)
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ dataModal, setDataModal ] = useState<DataModal>({type:"", comprobanteUrl:"", data:{}, request:{}})

  const statusBadge = (rowData: boolean) => {
    switch (rowData) {
      case true:
        return 'Pendiente'
      case false:
        return 'Resuelto'
      default:
        return 'No disponible'
    }
  }

  //Función para saber cuál es el país del usuario
  const appCountry = (data: any) => {
    switch (data) {
      case 'COP':
        return 'CO'
      case 'MXN':
        return 'MX'
      default:
        return 'CO'
    }
  }

  const comprobanteModal = (data: any) => {
    setIsModalOpen(!isModalOpen)
    setDataModal({
      type:"viewTransfer",
      comprobanteUrl:data?.comprobanteUrl
    })
  }

  const addAmountModal = (data: any) => {
    setIsModalOpen(!isModalOpen)
    setDataModal({
      type:"addAmount",
      data:{
        amount: data.amount !== undefined ? `${data?.amount?.toLocaleString("en-US")} ${data?.currency}` : "No disponible",
        date: data?.created_at,
        email: data?.email,
        confirmationNumber: data?.confirmationNumber
      },
      user: data?.user,
      request: {
        balance: {
          amount: data?.amount || 0,
          currency: data?.currency || "No disponible",
        },
        app_country: appCountry(data?.currency),
        comprobanteId:data?._id,
      },
      isActivate: data?.isActivate
    })
  }

  const addUserAmount = () => {
    setIsModalOpen(false)
    Modal.confirm({
      title: "Confirmar",
      content: "¿Estás seguro de abonar saldo a este usuario?",
      cancelText: "Cancelar",
      async onOk() {
        if(!dataModal.user) return notification.error({message:'Usuario no encontrado'})
        if(dataModal.isActivate !== true) return notification.error({message:'Ya has realizado esta transferencia'})
          await fetchDataPatch(dataModal.user as string, dataModal.request)
          notification.success({message:'Monto abonado correctamente'})
          refetch()
      }
    })
  }

  const actions = (disabledMenu: boolean, rowData: any) => {
    return (
      <div style={{display:'flex'}}>
        <Tooltip title="Ver comprobante">
          <IconButton disabled={disabledMenu} onClick={() => comprobanteModal(rowData)} ref={elRef}>
            <Visibility />
          </IconButton>
        </Tooltip>
        <Tooltip title="Abonar saldo">
          <IconButton disabled={disabledMenu} onClick={() => addAmountModal(rowData)} ref={elRef}>
            <MonetizationOn />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  return (
    <div>
      <Table
        columns={[
          {
            title: "Fecha",
            field: "created_at",
            render: (rowData: any) => {
              return (
                rowData.created_at && moment(rowData.created_at).format("LLLL")
              );
            },
          },
          { title: "Email", field: "email" },
          {
            title: "Usuario",
            field: "user",
            render: (rowData: any) => {
              return rowData.user ? rowData.user : "No disponible";
            },
          },
          {
            title: "Monto",
            field: "amount",
            render: (rowData: any) => {
              return rowData.amount
                ? `$ ${rowData.amount.toLocaleString("en-US")} ${
                    rowData.currency
                  }`
                : "No disponible";
            },
          },
          {
            title: "Número de confirmación",
            field: "confirmationNumber",
            render: (rowData: any) => {
              return rowData.confirmationNumber
                ? rowData.confirmationNumber
                : "No disponible";
            },
          },
          {
            title: "Estado",
            field: "isActivate",
            render: (rowData: any) => {
              return <CancelStatus status={statusBadge(rowData.isActivate)} />;
            },
          },
          {
            title: "Acciones",
            render: (rowData: any) => {
              return actions(false, rowData);
            },
          },
        ]}
        isLoading={isLoading}
        data={data ? (data as object[]) : []}
      ></Table>
      <ModalContainer
        title={dataModal.type === "viewTransfer" ? "Comprobante de pago" : "Abonar Saldo"}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(!isModalOpen)}
        onOk={() => setIsModalOpen(!isModalOpen)}
        footer={false}
      >
        {dataModal.type === "viewTransfer" ? (
          <img
            src={dataModal.comprobanteUrl}
            alt="Comprobante"
            style={{ maxHeight: "550px", width: "100%", marginTop: "20px" }}
          />
        ) : (
          <>
            <Row gutter={[10,10]}>
            <Col xs={24} sm={12} md={12} lg={12} style={{ margin: 'auto' }}>
              <Title>Email</Title>
              <p>{dataModal.data?.email || "No disponible"}</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} style={{ margin: 'auto' }}>
              <Title>Monto</Title>
              <p>{dataModal.data?.amount}</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} style={{ margin: 'auto' }}>
              <Title>Fecha de transferencia</Title>
              <p>{moment(dataModal.data?.date).format("LLLL") || "No disponible"}</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} style={{ margin: 'auto' }}>
              <Title>Número de confirmación</Title>
              <p>{dataModal.data?.confirmationNumber || "No disponible"}</p>
            </Col>
            </Row>
            <InputContainer type="submitButton" title="Abonar Saldo" onClick={addUserAmount} />
          </>
        )}
      </ModalContainer>
    </div>
  );
}

export default TransferTab