import { Col, Typography } from "antd"
import { FC } from "react"
import CardContainer from "../../../../components/Containers/CardContainer"
import { AddressesInterface, PackagesInterface } from "../../../../interfaces/shipments.interface"

interface AddressReportInfoProps {
    data: AddressesInterface;
    title: string;
}

export const AddressReportInfo: FC<AddressReportInfoProps> = ({ data, title }) => {
    return (
        <CardContainer title={title} cardStyle={{ marginBottom: '1.5rem' }}>
            <Col span={24} style={{ padding: '8px' }}>
                <Typography>
                    {` - Nombre: `} <strong>{`${data.name}`}</strong>
                </Typography>
            </Col>
            <Col span={24} style={{ padding: '8px' }}>
                <Typography>
                    {` - Correo: `} <strong>{`${data.email}`}</strong>
                </Typography>
            </Col>
            <Col span={24} style={{ padding: '8px' }}>
                <Typography>
                    {` - Teléfono: `} <strong>{`${data.phone}`}</strong>
                </Typography>
            </Col>
            <Col span={24} style={{ padding: '8px' }}>
                <Typography>
                    {` - Empresa: `} <strong>{`${data.company}`}</strong>
                </Typography>
            </Col>
            <Col span={24} style={{ padding: '8px' }}>
                <Typography>
                    {` - Calle: `} <strong>{`${data.street}`}</strong>
                </Typography>
            </Col>
            <Col span={24} style={{ padding: '8px' }}>
                <Typography>
                    {` - Número: `} <strong>{`${data.number}`}</strong>
                </Typography>
            </Col>

            <Col span={24} style={{ padding: '8px' }}>
                <Typography>
                    {` - Número Interior: `} <strong>{`${data.int_number}`}</strong>
                </Typography>
            </Col>

            <Col span={24} style={{ padding: '8px' }}>
                <Typography>
                    {` - Colonia: `} <strong>{`${data.district}`}</strong>
                </Typography>
            </Col>

            <Col span={24} style={{ padding: '8px' }}>
                <Typography>
                    {` - Código Postal: `} <strong>{`${data.postal_code}`}</strong>
                </Typography>
            </Col>

            <Col span={24} style={{ padding: '8px' }}>
                <Typography>
                    {` - Ciudad: `} <strong>{`${data.city}`}</strong>
                </Typography>
            </Col>
            <Col span={24} style={{ padding: '8px' }}>
                <Typography>
                    {` - Referencia: `} <strong>{`${data.reference}`}</strong>
                </Typography>
            </Col>

        </CardContainer>
    )
}

interface PackagesReportInfoProps {
    data: PackagesInterface[];
}

export const PackagesReportInfo: FC<PackagesReportInfoProps> = ({ data }) => {
    return (
        <>
            {
                data.map((el, index) => (
                    <CardContainer title={`Paquete ${index + 1}`}>
                        <Col span={24} style={{ padding: '8px' }}>
                            <Typography>
                                {` - Peso: `} <strong>{`${el.weight}`}</strong>
                            </Typography>
                        </Col>
                        <Col span={24} style={{ padding: '8px' }}>
                            <Typography>
                                {` - Altura: `} <strong>{`${el.height}`}</strong>
                            </Typography>
                        </Col>
                        <Col span={24} style={{ padding: '8px' }}>
                            <Typography>
                                {` - Ancho: `} <strong>{`${el.width}`}</strong>
                            </Typography>
                        </Col>
                        <Col span={24} style={{ padding: '8px' }}>
                            <Typography>
                                {` - Alto: `} <strong>{`${el.length}`}</strong>
                            </Typography>
                        </Col>
                        <Col span={24} style={{ padding: '8px' }}>
                            <Typography>
                                {` - Tipo de empaque: `} <strong>{`${el.type}`}</strong>
                            </Typography>
                        </Col>
                        <Col span={24} style={{ padding: '8px' }}>
                            <Typography>
                                {` - Valor declarado: `} <strong>{`${el.declared_value}`}</strong>
                            </Typography>
                        </Col>

                        <Col span={24} style={{ padding: '8px' }}>
                            <Typography>
                                {` - Volumétrico: `} <strong>{`${el.volumetric}`}</strong>
                            </Typography>
                        </Col>

                        <Col span={24} style={{ padding: '8px' }}>
                            <Typography>
                                {` - Contenido: `} <strong>{`${el.content}`}</strong>
                            </Typography>
                        </Col>
                    </CardContainer>
                ))
            }
        </>
    )
}