import { FC } from "react"
import CardContainer from "../../../../components/Containers/CardContainer"
import { TrackingsI } from "../Overweight";

interface Props{
    files: TrackingsI[];
    index: number;
    onSelectCard: () => void;
}

const ChangeStatus:FC<Props> = ({files, index, onSelectCard}) =>{
    return(
        <CardContainer
        direction='horizontal'
        textList={[
            `Guías por cambiar status #${index + 1}`,
            `${files.length}`,
            'pendiente'
        ]}
        hoverEffect
        onClick={onSelectCard}
    />
    )
}
export default ChangeStatus