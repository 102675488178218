import { Switch, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { AdvancedCriteria, CriteriaType } from "../interfaces";

interface props {
    title: string;
    criteria: CriteriaType;
    advancedData: AdvancedCriteria; 
    onChange: (criteria: CriteriaType) => void;
}

interface Styles {
    textBold: React.CSSProperties;
    switchItem: React.CSSProperties;
}

const styles: Styles = {
    textBold: {
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 'bold',
        fontSize: '0.8rem'
    },
    switchItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '1rem'
    }
}

const SwitchItem: FC<props> = ({ title, criteria, onChange, advancedData }) => {
    const [active, setActive] = useState(false)

    useEffect(() =>{
        if(criteria === 'search'){
            setActive(true)
        }
        //eslint-disable-next-line
    },[])

    useEffect(() =>{
        if(advancedData.criteria !== criteria){
            setActive(false)
        }else{
            setActive(true)
        }
        //eslint-disable-next-line
    },[advancedData])

    return (
        <div style={styles.switchItem}>
            <Typography style={styles.textBold}>
                {title}
            </Typography>
            <Switch 
                checked={active} 
                onChange={(e) =>{
                    setActive(e)
                    onChange(criteria)
                }}
            />
        </div>
    )
}

export default SwitchItem