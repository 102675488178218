import React from "react";
import CardContainer from "../../../../components/Containers/CardContainer";
import SearchItem, { UserItemI } from "./SearchItem";
import { Col } from "antd";
import { useMediaQuery } from 'react-responsive'
import CleanResults from "../Components/CleanResults";
import UserSkeleton from "../Mocks/UserSkeleton";
import ResultsNotFound from "../Components/ResultsNotFound";
import { AdvancedCriteria } from "../interfaces";

interface props {
    searchQuery: AdvancedCriteria | null;
    searchResults: any[] | null;
    userListSearch: any;
    loadingAdvanced: boolean;
    loadingSearch: boolean;
    loadingPaste: boolean;
    nextStep: () => void;
    persistData: () => void;
    cleanResults: () => void;
    newUserFound: (user: UserItemI) => void;
    getFullUser: (user: string) => void;
    isDrawer?: boolean;
}

interface Styles {
    sectionList: React.CSSProperties;
    sectionListMobile: React.CSSProperties;
    listSeparator: React.CSSProperties;
    cardStyle: React.CSSProperties;
    cardStyleDrawer: React.CSSProperties;
}

export const styles: Styles = {
    sectionList: {
        minWidth: '100%',
        minHeight: '650px',
        overflow: 'auto'
    },
    sectionListMobile: {
        minWidth: '100%',
        minHeight: '650px',
        overflow: 'auto'
    },
    listSeparator: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
    },
    cardStyle:{
        padding: '16px'
    },
    cardStyleDrawer:{
        padding: '16px',
        boxShadow: 'none',

    }
}

const SearchResults: React.FC<props> = ({
    searchQuery,
    searchResults,
    loadingAdvanced,
    loadingSearch,
    loadingPaste,
    nextStep,
    persistData,
    cleanResults,
    newUserFound,
    getFullUser,
    userListSearch,
    isDrawer
}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
    return (
        <CardContainer 
        cardStyle={
            isMobile ? 
                isDrawer ? styles.cardStyleDrawer : styles.cardStyle :
                isDrawer ? styles.cardStyleDrawer : {}}>
            <section style={isMobile ? styles.sectionListMobile : styles.sectionList}>
                {
                    searchQuery && searchResults ? (
                        <div style={styles.listSeparator}>
                            {searchResults?.map(function (actualUser: any) {
                                return (
                                    <SearchItem
                                        user={actualUser}
                                        nextStep={nextStep}
                                        persist={persistData}
                                        addUser={newUserFound}
                                        getFullUser={getFullUser}
                                    />
                                );
                            })}
                            <Col span={24} style={{ padding: 0 }}>
                                <CleanResults clean={cleanResults} />
                            </Col>
                        </div>
                    ) : (
                        <>
                            {
                                searchResults === null && (loadingAdvanced || loadingSearch || loadingPaste) ? (
                                    <section style={styles.listSeparator}>
                                        <UserSkeleton />
                                        <UserSkeleton />
                                        <UserSkeleton />
                                        <UserSkeleton />
                                        <UserSkeleton />
                                    </section>
                                ) : (
                                    <div style={styles.listSeparator}>
                                        {
                                            (userListSearch) ? (
                                                <>
                                                    {
                                                        userListSearch?.length === 0 || !userListSearch ? (
                                                            <ResultsNotFound />
                                                        ) : (
                                                            <>
                                                                {userListSearch?.map(function (actualUser: any) {
                                                                    return (
                                                                        <SearchItem
                                                                            user={actualUser}
                                                                            nextStep={nextStep}
                                                                            persist={persistData}
                                                                            addUser={newUserFound}
                                                                            getFullUser={getFullUser}
                                                                        />
                                                                    );
                                                                })}
                                                                <Col span={24} style={{ padding: 0 }}>
                                                                    <CleanResults clean={cleanResults} />
                                                                </Col>
                                                            </>
                                                        )
                                                    }

                                                </>
                                            ) : (
                                                <ResultsNotFound />
                                            )}
                                    </div>
                                )
                            }
                        </>
                    )
                }

            </section>
        </CardContainer>
    )
}

export default SearchResults