import { getBlackListColumns } from '../../assets/data/blacklistColumn'
import { Table } from '../../components/Common/Table'
import { useGet } from '../../hooks/useGet'
import { usePost } from '../../hooks/usePost'
import { ICPBlacklistRes } from '../../interfaces/admin.interface'
import { createBlockedCp, getBlockedCp } from '../../services/information_s'

const BlackList = () => {
  const { data, refetch } = useGet<ICPBlacklistRes>(getBlockedCp)
  const { fetchData } = usePost(createBlockedCp)

  const addCP = async (row: any) => {
    const data = {
      cp: row.cp,
      mail: row.mail,
      user: row.user,
      blockReason: row.blockReason,
    }

    await fetchData(data)
    refetch()
  }

  return (
    <Table
      columns={getBlackListColumns(refetch)}
      data={data?.cp ?? []}
      title="CP Blacklist"
      options={{
        actionsColumnIndex: -1,
      }}
      editable={{
        async onRowAdd(newData) {
          addCP(newData)
        },
      }}
    />
  )
}

export default BlackList
