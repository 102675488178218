import { useState, useEffect, useContext } from "react";
import Chart from "react-apexcharts";
import CardContainer from "../../../../components/Containers/CardContainer";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { Col, Row } from "antd";
import { AppContext } from "../../../../context/appContext";
import InputContainer from "../../../../components/Containers/InputContainer";

type Props = {
  filteredData: any;
  isLoading: boolean;
  onUserChange: (isVerified: string) => void;
  FooderData?: any;
};

type DataByDay = {
  day: string;
  count: number;
};

const GraphUsersNew = ({
  isLoading,
  filteredData,
  onUserChange,
  FooderData,
}: Props) => {
  const [categories, setCategories] = useState<string[]>([]);
  const [data, setData] = useState<number[]>([]);
  const [selectedTitle, setSelectedTitle] = useState<string>("Total");
  const { userData } = useContext(AppContext);

  const handleData = async () => {
    const days: string[] = filteredData?.map((item: DataByDay) =>
      item.day.toString()
    );
    const countsDays: number[] = filteredData?.map(
      (item: DataByDay) => item.count
    );
    setCategories(days);
    setData(countsDays);
  };

  const handleIntegrationChange = (event: any) => {
      if(!event) onUserChange('');
      onUserChange(event);
      setSelectedTitle(event);
  };

  useEffect(() => {
    handleData();
  }, [filteredData]); //eslint-disable-line

  const options = {
    chart: {
      id: `users-bar`,
    },
    xaxis: {
      categories,
    },
  };

  const series = [
    {
      name: "series",
      data,
    },
  ];

  return (
    <>
      {(userData?.roles[0] === "ADMIN" || userData?.roles[0] === "DEV") && (
        <CardContainer
          cardStyle={{ marginTop: "20px" }}
          title={`Usuarios registrados hoy (${selectedTitle})`}
          responsiveText={true}
        >
          {isLoading ? (
            <LoadingAnimation animationType="small" />
          ) : (
            <>
              {filteredData && (
                <InputContainer
                  optionsList={["Verificado", "No verificado", "Total"]}
                  placeholder="Tipo de Usuarios"
                  type="select"
                  valueContainerName="radio users"
                  onChange={(e) => handleIntegrationChange(e)}
                  style={{ width: "50%" }}
                />
              )}
              <Chart
                options={options}
                series={series}
                type="bar"
                width="100%"
              />
            </>
          )}
          {FooderData != null && (
            <Row gutter={[8, 8]} justify="center" align="middle">
              <Col span={4} offset={2}>
                <b>Totales</b>
              </Col>
              <Col span={4} offset={2}>
                <b>En espera</b>
              </Col>

              <Col span={4} offset={2}>
                <b>Agendado</b>
              </Col>

              <Col span={4} offset={2}>
                <b>Solicitada</b>
              </Col>

              <Col span={4} offset={2}>
                <p>{FooderData?.total[0]?.totalPickups}</p>
              </Col>

              <Col span={4} offset={2}>
                <p>{FooderData?.pickups[0]?.count}</p>
              </Col>

              <Col span={4} offset={2}>
                <p>{FooderData?.pickups[1]?.count}</p>
              </Col>

              <Col span={4} offset={2}>
                <p>{FooderData?.pickups[2]?.count}</p>
              </Col>
            </Row>
          )}
        </CardContainer>
      )}
    </>
  );
};

export default GraphUsersNew;
