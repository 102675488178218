import { useEffect, useMemo, useState } from "react";
import { Table } from "../../../components/Common/Table";
import LoadingAnimation from "../../../components/LoadingAnimation";
import { useGet } from "../../../hooks/useGet";
import { getAllMovementsByUser } from "../../../services/records";
import { UserMovementsColumn } from "../../../services/records";
import { Modal, Select } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import moment from "moment";

const MovementsByUser = ({ user }: any) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { data, isLoading, refetch } = useGet<any>(getAllMovementsByUser(user));
  const { Option } = Select;
  const handleModalVisible = (visible: boolean) => {
    setModalVisible(visible);
  };
  const [filterType, setFilterType] = useState("Movimientos");
  const handleFilterChange = (value: string) => {
    setFilterType(value);
  };

  const fetchMoments = (item: {
    created_at: string | number | Date;
    amount: any;
    currency: any;
    description: any;
    type: any;
    user: any;
    payment_id: any;
    made_by: any;
    profile: any;
    security: { after_mod: any; before_mod: any };
  }) => {
    return {
      date: new Date(item?.created_at).toLocaleDateString(),
      minutes: moment(item.created_at).format('HH:mm:ss'),
      quantity: `${item.amount} ${item.currency}`,
      description: item.description,
      type: item.type,
      email: item.user,
      payment_id: item.payment_id,
      made_by: item.made_by ? (item.profile[0]?.email || 'Automático') : '' || "Automatico",
      postBalance: `${item.security.after_mod} ${item.currency}`,
      prevBalance: `${item.security.before_mod} ${item.currency}`,
    };
  };

  const movementsData = useMemo(() => {
    if (!data?.userMovements) {
      return [];
    }
    const filteredMovements = data.userMovements.res.filter((movement: any) => {
      if (filterType === "Movimientos") {
        return true;
      }
      if (filterType === "Envio") {
        return movement.type === "ENVIO";
      }
      if (filterType === "Recarga") {
        return movement.type === "RECARGA";
      }
      if (filterType === "Reembolso") {
        return movement.type === "REEMBOLSO";
      }
      if (filterType === "Descuento") {
        return movement.type === "DESCUENTO";
      }
      if (filterType === "Facturables") {
        return movement.type === "FACTURABLE";
      }
      if (filterType === "Sincronizacion") {
        return movement.type === "SINCRONIZACION DE SALDO";
      }
      if (filterType === "Sobrepeso") {
        return movement.type === "SOBREPESO";
      }
      else {
        return movement.type === filterType;
      }
    });
    return filteredMovements.map((item: any) => fetchMoments(item));
  }, [data, filterType]);

  useEffect(() => {
    refetch();
  }, [user]); // eslint-disable-line

  return isLoading ? (
    <LoadingAnimation
      loadingText="Cargando los Envíos..."
      animationType="small"
    />
  ) : (
    <>
      <Modal
        visible={modalVisible}
        onCancel={() => handleModalVisible(false)}
        footer={null}
      >
        <h1 style={{ color: "#2D1B6E", textAlign: "center" }}>Filtros</h1>
        <br />
        <h2 style={{ fontSize: "20px" }}>Filtrar tipo de movimiento</h2>
        <span>
          Se filtrara el tipo de movimiento por; recarga, sobrepeso, descuento, envío, reembolso, facturables, sincronización de saldo{" "}
        </span>
        <Select
          style={{ width: "30%" }}
          defaultValue="Recarga"
          onChange={handleFilterChange} // agregar manejador de cambio
        >
          <Option value="Movimientos">Movimientos</Option>
          <Option value="Envio">Envío</Option>
          <Option value="Recarga">Recarga</Option>
          <Option value="Sobrepeso">Sobrepeso</Option>
          <Option value="Descuento">Descuento</Option>
          <Option value="Reembolso">Reembolso</Option>
          <Option value="Facturables">Facturables</Option>
          <Option value="Sincronizacion">Sincronización de saldo</Option>
        </Select>
        <hr />
      </Modal>
      <Table
        data={movementsData ? movementsData : []}
        columns={UserMovementsColumn}
        title="Movimientos"
        isButton={{
          icon: <FilterOutlined />,
          title: "Filtrar",
          onClick: () => handleModalVisible(true),
        }}
      />
    </>
  );
};

export default MovementsByUser;
