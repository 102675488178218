import { Typography } from "antd"
import { balanceFixed } from "../utils"
import { FC } from "react"
import { useMediaQuery } from 'react-responsive'
import { moneyISO } from "../../../../constants/moneyISO";
import UserAvatar from "../Components/UserAvatar";

export interface UserItemI {
    user: string;
    name?: string;
    email?: string;
    phone?: string;
    balance: number;
    app_country: string;
    security: { isBlocked: boolean }

}
interface props {
    user: UserItemI;
    clickable?: boolean;
    nextStep: () => void;
    persist: () => void;
    addUser: (user: UserItemI) => void;
    getFullUser: (user: string) => void;
}

interface Styles {
    mainContainer: React.CSSProperties;
    mobileContainer: React.CSSProperties;
    currencyContainer: React.CSSProperties;
    currencyBlockedContainer: React.CSSProperties;
    currencyText: React.CSSProperties;
    currencySpan: React.CSSProperties;
    negativeCurrency: React.CSSProperties;
    textBold: React.CSSProperties;
    textBoldMobile: React.CSSProperties;
    textNormal: React.CSSProperties;
    textNormalMobile:  React.CSSProperties;
    nameContent: React.CSSProperties;
    blockedTag: React.CSSProperties;
    pendingTag: React.CSSProperties;
}

const styles: Styles = {
    mainContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '5.5rem',
        padding: '20px',
        border: '1px solid #D9D9DB',
        borderRadius: '6px',
        gap: '2rem'
    },
    mobileContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '5rem',
        padding: '8px 16px',
        border: '1px solid #D9D9DB',
        borderRadius: '4px',
        gap: '1.5rem'
    },
    currencyContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 'inherit',
        height: 'inherit',
    },
    currencyBlockedContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        width: 'inherit',
        height: 'inherit',
    },
    currencyText: {
        fontSize: '20px',
        fontWeight: 'bold'
    },
    currencySpan: {
        fontSize: '0.9rem',
        fontWeight: 'normal'
    },
    negativeCurrency: {
        color: '#F5222D'
    },
    textBold: {
        display: 'flex',
        justifyContent: 'flex-start',
        fontWeight: 'bold',
        fontSize: '0.9rem'
    },
    textNormal: {
        display: 'flex',
        justifyContent: 'flex-start',
        fontSize: '0.8rem'
    },
    textBoldMobile: {
        display: 'flex',
        justifyContent: 'flex-start',
        fontWeight: 'bold',
        fontSize: '0.8rem'
    },
    textNormalMobile: {
        display: 'flex',
        justifyContent: 'flex-start',
        fontSize: '0.8rem'
    },
    nameContent: {
        color: '#2D1B6E',
        fontSize: '20px',
        fontWeight: '500'
    },
    blockedTag: {
        color: '#F5222D',
        backgroundColor: '#FFF1F0',
        padding: '2px 16px',
        border: '1px solid #FFA39E',
        borderRadius: '2px',
        fontSize: '12px'
    },
    pendingTag: {
        color: '#837814',
        backgroundColor: '#FFF6CA',
        padding: '2px 16px',
        border: '1px solid #AD8C10',
        borderRadius: '2px',
        fontSize: '12px'
    }

}

const SearchItem: FC<props> = ({ user, nextStep, persist, addUser, getFullUser, clickable = true }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

    return (
        <section style={isMobile ? styles.mobileContainer : styles.mainContainer} className={clickable ? "user-search-item" : ""} onClick={async () => {
            persist()
            addUser(user)
            nextStep()
            await getFullUser(user.user)
        }}>
            <UserAvatar userFound={user} />
            <div style={styles.nameContent}>
                <Typography style={isMobile ? styles.textBoldMobile : styles.textBold} className="user-search-item__title">
                    {user.name}
                </Typography>
                <Typography style={isMobile ? styles.textNormalMobile : styles.textNormal}>
                    {user.email}
                </Typography>
            </div>
            {
                isMobile ? '' : (
                    <>
                        {
                            user.security.isBlocked ? (
                                <div style={styles.currencyBlockedContainer}>
                                    <Typography style={Math.sign(user.balance) === -1 ? { ...styles.currencyText, ...styles.negativeCurrency } : { ...styles.currencyText }}>
                                        {`$${balanceFixed(user)}`} <span style={styles.currencySpan}>{`${moneyISO[user?.app_country] || "N/A"}`}</span>
                                    </Typography>
                                    <p style={styles.blockedTag}>Bloqueado</p>

                                </div>

                            ) : user.app_country === 'PENDIENTE' ? (
                                <div style={styles.currencyBlockedContainer}>
                                    <Typography style={Math.sign(user.balance) === -1 ? { ...styles.currencyText, ...styles.negativeCurrency } : { ...styles.currencyText }}>
                                        {`$${balanceFixed(user)}`} <span style={styles.currencySpan}>{`${moneyISO[user?.app_country] || "N/A"}`}</span>
                                    </Typography>
                                    <p style={styles.pendingTag}>Pendiente</p>

                                </div>

                            ) : (
                                <div style={styles.currencyContainer}>
                                    <Typography style={Math.sign(user.balance) === -1 ? { ...styles.currencyText, ...styles.negativeCurrency } : { ...styles.currencyText }}>
                                        {`$${balanceFixed(user)}`} <span style={styles.currencySpan}>{`${moneyISO[user?.app_country] || "N/A"}`}</span>
                                    </Typography>
                                </div>
                            )
                        }
                    </>
                )
            }


        </section>
    )
}

export default SearchItem