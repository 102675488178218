export const states: Record<any, any> = {
  MX: {
    AG: "Aguascalientes",
    BC: "Baja California",
    BS: "Baja California Sur",
    CM: "Campeche",
    CS: "Chiapas",
    CH: "Chihuahua",
    CX: "Ciudad de México",
    CO: "Coahuila",
    CL: "Colima",
    DG: "Durango",
    EM: "Estado de México",
    GT: "Guanajuato",
    GR: "Guerrero",
    HG: "Hidalgo",
    JA: "Jalisco",
    MI: "Michoacán",
    MO: "Morelos",
    NA: "Nayarit",
    NL: "Nuevo León",
    OA: "Oaxaca",
    PU: "Puebla",
    QT: "Querétaro",
    QR: "Quintana Roo",
    SL: "San Luis Potosí",
    SI: "Sinaloa",
    SO: "Sonora",
    TB: "Tabasco",
    TM: "Tamaulipas",
    TL: "Tlaxcala",
    VE: "Veracruz",
    YU: "Yucatán",
    ZA: "Zacatecas",
    _: "N/A",
    " ": "N/A",
    "  ": "N/A",
    "": "N/A",
  },
  US: {
    AL: "Alabama",
    AK: "Alaska",
    AS: "AmericanSamoa",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "DistrictofColumbia",
    FM: "FederatedStatesofMicronesia",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MH: "MarshallIslands",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "NewHampshire",
    NJ: "NewJersey",
    NM: "NewMexico",
    NY: "NewYork",
    NC: "NorthCarolina",
    ND: "NorthDakota",
    MP: "NorthernMarianaIslands",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PW: "Palau",
    PA: "Pennsylvania",
    PR: "PuertoRico",
    RI: "RhodeIsland",
    SC: "SouthCarolina",
    SD: "SouthDakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VI: "VirginIslands",
    VA: "Virginia",
    WA: "Washington",
    WV: "WestVirginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  },
  CO: {
    AM: "Amazonas",
    AN: "Antioquia",
    AR: "Arauca",
    AT: "Atlántico",
    BO: "Bolívar",
    BY: "Boyacá",
    CL: "Caldas",
    CA: "Caquetá",
    CS: "Casanare",
    CU: "Cauca",
    CE: "Cesar",
    CH: "Chocó",
    CO: "Córdoba",
    CN: "Cundinamarca",
    DC: "Distrito Capital de Bogotá",
    GU: "Guainía",
    GA: "Guaviare",
    HU: "Huila",
    LG: "La Guajira",
    MA: "Magdalena",
    ME: "Meta",
    NA: "Nariño",
    NS: "Norte de Santander",
    PU: "Putumayo",
    QU: "Quindío",
    RI: "Risaralda",
    SA: "San Andrés y Providencia",
    SN: "Santander",
    ST: "Sur de Santander",
    SU: "Sucre",
    TO: "Tolima",
    VC: "Valle del Cauca",
    VA: "Vaupés",
    VI: "Vichada",
  },
  PE: {
    AM: "Amazonas",
    AN: "Ancash",
    AP: "Apurímac",
    AR: "Arequipa",
    AY: "Ayacucho",
    CJ: "Cajamarca",
    CU: "Cusco",
    CL: "Callao",
    HV: "Huancavelica",
    HC: "Huánuco",
    IC: "Ica",
    JU: "Junín",
    LL: "La Libertad",
    LB: "Lambayeque",
    LI: "Lima",
    LO: "Loreto",
    MD: "Madre de Dios",
    MO: "Moquegua",
    PA: "Pasco",
    PI: "Piura",
    PU: "Puno",
    SA: "San Martín",
    TA: "Tacna",
    TU: "Tumbes",
    UC: "Ucayali",
    LM: "Municipalidad Metropolitana de Lima",
  },
  CA: {
    AB: "Alberta",
    BC: "British Columbia",
    MB: "Manitoba",
    NB: "New Brunswick",
    NL: "Newfoundland and Labrador",
    NS: "Nova Scotia",
    NT: "Northwest Territories",
    NU: "Nunavut",
    ON: "Ontario",
    PE: "Prince Edward Island",
    QC: "Quebec",
    SK: "Saskatchewan",
    YT: "Yukon",
  },
  ES: {
    CT: "Catalunya",
  },
  CR: {
    A: "Alajuela",
    C: "Cartago",
    G: "Guanacaste",
    H: "Heredia",
    L: "Limón",
    P: "Puntarenas",
    SJ: "San José",
  },
};
