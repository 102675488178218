let timeOut: NodeJS.Timeout

export const setDebouncedValue = (value: any, updateState: (value: any) => void, timer = 350) => {
  clearTimeout(timeOut)
  timeOut = setTimeout(() => {
    updateState(value)
  }, timer)
}

export const formatDate = (date: Date) => {
  const _date = new Intl.DateTimeFormat('es-MX', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(date)

  return _date
}
