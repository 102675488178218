import { FC } from "react";
import { Col, Row } from "antd";
import ModalContainer, { modalSizes } from "../../../../../components/Containers/ModalContainer"
import { dataToShowI } from "../../interfaces";

interface props {
    visible: boolean;
    userFound: dataToShowI;
    manageModal: () => void;
}

interface Styles {
    container: React.CSSProperties;
}

const styles: Styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '2rem',
        width: '100%',
    }
}


const SendersModal: FC<props> = ({ visible, manageModal,userFound }) => {
    
    return (
        <ModalContainer
            title={'Remitentes del cliente'}
            visible={visible}
            onCancel={manageModal}
            width={modalSizes.BIG}
            footer={<></>}
        >
            <Row style={styles.container}>
                <Col span={24}>
                    {userFound?.security?.senders.map((el: any, index: number) => (
                        <span>
                            {el}{(index + 1 )=== userFound?.security?.senders.length ? '' : ', ' } 
                            </span>
                    ))}
                </Col>


            </Row>
        </ModalContainer>
    )
}

export default SendersModal