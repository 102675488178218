import { Col, Drawer, Row } from "antd";
import { FC, useEffect, useState } from "react";
import InputSearch from "../Components/InputSearch";
import { useUserSearch } from '../../../../hooks/useUserSearch';
import AdvancedOptions from "../Components/AdvancedOptions";
import SearchResults from "./SearchResults";
import { UserItemI } from "./SearchItem";
import { prefrencesMenu } from "../interfaces";

interface props {
    open: boolean;
    onClose: () => void;
    persistQuery: (query: any) => void;
    persistResults: (results: any[] | null) => void;
    searchQuery: any;
    searchResults: any[] | null;
    newUserFound: (user: UserItemI) => void;
    getFullUser: (user: string) => void;
    handleMenu: (item: prefrencesMenu) => void;
}
const SearchDrawer: FC<props> = ({
    onClose,
    open,
    persistQuery,
    persistResults,
    searchQuery,
    searchResults,
    newUserFound,
    getFullUser,
    handleMenu
}) => {
    const [childrenDrawer, setChildrenDrawer] = useState(false);

    useEffect(() => {
        if (!searchResults) {
            getSearchCriteria(true)
        }
        //eslint-disable-next-line
    }, [])

    const {
        userListSearch,
        inputValue,
        advancedCriteria,
        setAdvancedCriteria,
        isMobile,
        loadingAdvanced,
        loadingSearch,
        loadingPaste,
        handlePaste,
        onChangeSwitchAdvanced,
        searchAdvancedOption,
        getSearchCriteria,
        cleanResults,
        handleChangeInput,
        persistData,
        manageAdvancedModal,
    } = useUserSearch(searchQuery, searchResults, persistQuery, persistResults);

    const showChildrenDrawer = () => {
        setChildrenDrawer(true);
    };

    const onChildrenDrawerClose = () => {
        setChildrenDrawer(false);
    };

    const onSelectUserInDrawer = () =>{
        handleMenu('profile')
        document.getElementById('drenvio-searchv2-profile-default')?.click()
        onChildrenDrawerClose()
        onClose()
    }


    return (
        <Drawer title="Buscar usuarios" onClose={onClose} visible={open} width={isMobile ? 400 : 700}>
            <InputSearch
                inputValue={inputValue}
                advancedCriteria={advancedCriteria}
                setAdvancedCriteria={setAdvancedCriteria}
                persistData={persistData}
                searchAdvancedOption={searchAdvancedOption}
                getSearchCriteria={getSearchCriteria}
                handlePaste={handlePaste}
                handleChangeInput={handleChangeInput}
                manageModal={manageAdvancedModal}
                isDrawer={true}
                isOpenDrawer={showChildrenDrawer}
            />
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <SearchResults
                        searchQuery={searchQuery}
                        searchResults={searchResults}
                        loadingAdvanced={loadingAdvanced}
                        loadingSearch={loadingSearch}
                        loadingPaste={loadingPaste}
                        nextStep={onSelectUserInDrawer}
                        persistData={persistData}
                        cleanResults={cleanResults}
                        newUserFound={newUserFound}
                        getFullUser={getFullUser}
                        userListSearch={userListSearch}
                        isDrawer={true}
                    />
                </Col>
            </Row>
            <Drawer
                title="opciones avanzadas"
                width={isMobile ? 250 : 320}
                closable={false}
                onClose={onChildrenDrawerClose}
                visible={childrenDrawer}
            >
                <AdvancedOptions
                    advancedCriteria={advancedCriteria}
                    onChangeSwitchAdvanced={onChangeSwitchAdvanced}
                    isDrawer={true}
                />
            </Drawer>
        </Drawer>
    )
}

export default SearchDrawer