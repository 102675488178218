import { Col, Switch, Tag, Typography } from "antd";
import AccessMsg from "../../../../../components/Common/AccessMsg";
import { FC } from "react";
import useDevice from "../../../../../hooks/useDevice";
const { Text } = Typography;

interface props {
    access: boolean;
    checked: boolean;
    label: string;
    viewOnly?: boolean;
    optional?: boolean;
    text?: string;
    onChange: (e: any) => void;
}

interface Styles {
    text: React.CSSProperties;
    textMobile: React.CSSProperties;
    switchContainer: React.CSSProperties;
    list: React.CSSProperties;

}

const styles: Styles = {
    text: {
        color: 'black',
        fontSize: '14px',
    },
    textMobile: {
        color: 'black',
        fontSize: '14px',
    },
    switchContainer: {
        marginTop: 22,
    },
    list: {
        textAlign: "center",
        border: 'solid .1rem #DFDCDC',
        padding: '1rem',
        margin: '10px 0',
        borderRadius: '.5rem',
    }

}

const VerificationCard: FC<props> = ({ access, checked, onChange, label, viewOnly = false, optional = false, text }) => {
    const { isTablet } = useDevice()

    return (
        <Col
            style={styles.list}
        >
            <Col>
                <Text style={isTablet ? styles.textMobile : styles.text}>
                    {label}
                </Text>
            </Col>
            <Col style={styles.switchContainer}>
                {
                    viewOnly ? (
                        <>
                            <Tag color={
                                !checked ?
                                    optional ? "blue"
                                        : "red" : "cyan"
                            }>
                                {
                                    !checked ?
                                        text ? text : optional ? 'No requerido' : 'Pendiente' :
                                        'Completo'
                                }
                            </Tag>
                        </>
                    ) : (
                        <AccessMsg
                            label={label}
                            access={access}
                        >
                            <Switch
                                checked={checked}
                                disabled={!access}
                                onChange={(e) => onChange(e)}
                            />
                        </AccessMsg>
                    )
                }

            </Col>
        </Col>
    )
}

export default VerificationCard