import { Typography } from "antd";
import CardContainer from "../../../../components/Containers/CardContainer";
import { FC } from "react";
import { CloseCircleOutlined } from '@ant-design/icons';

interface props {
    clean: () => void;
}

interface Styles {
    cardStyle: React.CSSProperties;
    typography: React.CSSProperties;
    iconStyle: React.CSSProperties;
}

const styles: Styles = {
    cardStyle: {
        display: "flex",
        justifyContent: "center",
        marginTop: "1.5rem",
        width: "100%",
        cursor: "pointer",
        boxShadow: 'none',
        border: '1px solid red',
        padding: '16px'
    },
    typography: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: "0.9rem",
        color: "#FF413F",
    },
    iconStyle: { 
        marginLeft: "0.5rem" 
    }
}

const CleanResults: FC<props> = ({ clean }) => {
    return (
        <CardContainer
            hoverEffect
            onClick={clean}
            cardStyle={styles.cardStyle}
        >
            <Typography
                style={styles.typography}
            >
                Deshacer búsqueda{" "}
                <CloseCircleOutlined style={styles.iconStyle} />
            </Typography>
        </CardContainer>)
}

export default CleanResults