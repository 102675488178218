import { TFunction } from "react-i18next";

/**
 * Function to format any number into a price format with commas
 * @param price price to format
 * @returns formatted price with commas
 */
export const formatPrice = (price: number): string => {
  const formattedPrice = Intl.NumberFormat().format(Math.ceil(price));
  return formattedPrice.replace(".", ",");
};

export const formatPrice2 = (price: number, locale: string) => {
  console.log('price', locale, price)
  if( locale === "mxn" || locale === "MXN" || locale === "MX")
  return Intl.NumberFormat('es-MX').format(price)
  if(locale === "cop" || locale === "COP" || locale === "CO")
  return  Intl.NumberFormat('es-CO').format(price)
  else if( locale === "" || locale || 'US') 
  return Intl.NumberFormat('es-US').format(price)
}

export const formPricesTitle = (t: TFunction<"translation", undefined>) => ({
  Precios: t("Precios.Form.Prices.Title"),
  "Precios Especiales": t("Precios.Form.SpecialPrices.Title"),
  "Agregar Precio Especial": t("Precios.Form.AddSpecialPrices.Title"),
});
