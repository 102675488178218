import React, { useState } from "react";
import { Row, Col, Form } from "antd";
import { t } from "i18next";
import InputContainer from "../../../components/Containers/InputContainer";
import CardContainer from "../../../components/Containers/CardContainer";
import { usePost } from "../../../hooks/usePost";
import { shippingData } from "../../../services/shipping_s";
import * as XLSX from "xlsx";
import { notification } from "antd";
import { AdminDataPicker } from "../../../components/Common/admin/AdminDataPicker";
import { carrierArray, shipmentStatusArray, serviceIdArray, versionArray } from "../../../interfaces/shipments.interface";

const styles = {
  fontSize: "1rem", 
  margin: '0.5rem 0'
}


const Shipping: React.FC = () => {
  const [servicesId, setServicesId] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [carries, setCarries] = useState<string>("");
  const [rangeDate, setRangeDate] = useState<any>()
  const [version, setVersion] = useState<string>("");

  const [form] = Form.useForm();

  const { fetchData: getData, isLoading } = usePost(shippingData);


  const downloadExcel = async (type: "xlsx" | "csv") => {
    console.log(servicesId)
    const resp = await getData({
      dateStart: new Date(rangeDate[0]._d),
      endDate: new Date(rangeDate[1]._d),
      status: status === 'Filtrar todos' ? '' : status === 'Todas las Canceladas' ? "cancelled" : status,
      service_id: servicesId === 'Filtrar todos' ? '' : servicesId,
      carrier: carries === 'Filtrar todos' ? '' : carries,
      version: version === "Versión 1" ? "v1" : "v2"
    });
    if (resp.error) {
      notification.error({
        message: t("Se ha encontrado un problema en la busqueda, intente de nuevo"),
      });
      return
    };
    if (resp.data.res.length === 0) {
      notification.error({
        message: t("No se han encontrado resultados"),
      });
      return
    } else {
      const worksheet = XLSX.utils.json_to_sheet(resp?.data?.res);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, `${"book"}`);
      await XLSX.writeFile(workbook, `${"book"}.${type}`, { bookType: type });
      notification.success({
        message: t(type + ' generado'),
      });
    }
  };

  const validate = (type: "xlsx" | "csv") => {
    form
      .validateFields()
      .then((values: any) => {
        if (values) {
          downloadExcel(type);
        }
      })
      .catch((errorInfo: any) => {
        notification.error({
          message: t("Algo ha fallado"),
        });
      });
  };

  const onFinishFaild = async () => {
    notification.error({
      message: t("Algo ha fallado"),
    });
  };

  return (
    <>
      <Form form={form} onFinishFailed={onFinishFaild}>
        <Row gutter={[20, 15]} style={{ justifyContent: "center" }}>
          <Col sm={24} md={12}>
            <Row gutter={[5, 10]}>
              <Col span={24}>
                <CardContainer>
                  <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>Envíos</p>
                  <br />
                  <b>Filtrar por fecha de registro</b>
                  <br />
                  <AdminDataPicker
                    setValue={setRangeDate}
                  />
                  <br />
                  <b>Filtrar por versión</b>
                  <InputContainer
                    type="select"
                    placeholder={t("Down.Placeholder.Version")}
                    valueContainerName={"version"}
                    optionsList={versionArray || []}
                    required
                    onChange={(version) => {
                      setVersion(version);
                    }}
                  />
                  <br />
                  <b>Filtrar por estatus de envio</b>
                  <InputContainer
                    type="select"
                    placeholder={t("Down.Placeholder.Status")}
                    valueContainerName={"parcel"}
                    optionsList={shipmentStatusArray || []}
                    required
                    onChange={(status) => {
                      setStatus(status);
                    }}
                  />
                  <br />
                  <b>Filtrar por ID de servicio</b>
                  <InputContainer
                    type="select"
                    placeholder={t("Down.Placeholder.service")}
                    valueContainerName={"serviceId"}
                    optionsList={serviceIdArray || []}
                    required
                    onChange={(value) => {
                      console.log(value)
                      setServicesId(value);
                    }}
                  />
                  <br />
                  <b>Filtrar por nombre de paqueteria</b>
                  <InputContainer
                    type="select"
                    placeholder={t("Down.Placeholder.carrier")}
                    valueContainerName={"carrier"}
                    optionsList={carrierArray || []}
                    required
                    onChange={(value) => {
                      setCarries(value);
                    }}
                  />
                  <br />
                  <InputContainer
                    style={{ marginTop: "20px" }}
                    type="button"
                    onClick={() => validate("xlsx")}
                    title="Generar Excel"
                    isButtonLoading={isLoading}
                    disabled={!carries && !servicesId}
                  />

                  <InputContainer
                    style={{ marginTop: "20px" }}
                    type="button"
                    onClick={() => validate("csv")}
                    isButtonLoading={isLoading}
                    title="Generar CSV"
                    disabled={!carries && !servicesId}
                  />
                </CardContainer>
              </Col>
            </Row>
          </Col>

          <Col sm={24} md={12}>
            <Row gutter={[5, 10]}>
              <Col span={24}>
                <CardContainer
                  title={t("Down.Shipping.Title.Instruction")}
                  titleStyle={{ justifyContent: "center" }}
                />
              </Col>
              <Col span={24}>
                <CardContainer>
                  <ul>
                    <li style={styles}>{t("Down.Shipping.List.Data")}</li>
                    <li style={styles}>{t("Down.Shipping.List.Filter")}</li>
                    <li style={styles}>{t("Down.Shipping.List.Package")}</li>
                    <li style={styles}>{t("Down.Shipping.List.Cvs")}</li>
                  </ul>
                </CardContainer>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Shipping;
