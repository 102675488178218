import { AppSchema } from "./schema";
import { ActionT, AppActionTypeT } from "./ducks";

const SET_VALUE : AppActionTypeT = 'APP/SET_VALUE'
const initialState = AppSchema;

//Reducer
export default function reducer(state = initialState, action: ActionT['action']){
    switch(action.type){
        case SET_VALUE:
            return {
                ...state, 
                statuses: action.payload,
                errors: action?.error ? action?.error : state.errors
            };
            
        default: return state;
    }
}