import React, { createContext, useEffect, useState } from "react";
import anime from "animejs";

interface ContextData {
  quoteData: QuoteData;
  userBalance: number;
  setUserBalance: (data: number) => void;
  currentLangCurrency: LanguageCurrencies | string;
  setCurrentLangCurrency: (data: LanguageCurrencies | string) => void;
  setQuoteData: (data: QuoteData) => void;
  shipmentTypeSelected: ShipmentTypes;
  setShipmentTypeSelected: (data: ShipmentTypes) => void;
  quoteStep: QuoteSteps;
  setQuoteStepTransition: (data: QuoteSteps) => void;
  transformedUserBalance: number;
  OptimizedSelectedCurrency: string;
  transformCurrencyToSelectedCurrency: (data: number) => number;
}

interface QuoteData {
  ParcelData: ParcelData;
  OriginData: OriginQuoteForm;
  DestinyData: DestinyQuoteForm;
}

interface ParcelData {
  Name?: string | undefined;
  Image?: React.ReactElement | string | undefined;
  Price?: number | string | undefined;
  Time?: string | undefined;
  Type?: string | undefined;
  Height?: number | undefined;
  Width?: number | undefined;
  Length?: number | undefined;
  Weight?: number | undefined;
}

interface OriginQuoteForm {
  Name?: string | undefined;
  Email?: string | undefined;
  Phone?: number | undefined;
  Enterprise?: string | undefined;
  State?: string | undefined;
  StreetName?: string | undefined;
  StreetNumber?: number | undefined;
  Suburb?: string | undefined;
  Delegation?: string | undefined;
  PostalCode?: number | undefined;
  Reference?: string | undefined;
}

interface DestinyQuoteForm extends OriginQuoteForm {
  PackageContent?: string | undefined;
}

export const QuoteDataContext = createContext<ContextData>({
  quoteData: {
    ParcelData: {
      Name: undefined,
      Image: undefined,
      Price: undefined,
      Time: undefined,
      Type: undefined,
      Height: undefined,
      Width: undefined,
      Length: undefined,
      Weight: undefined,
    },
    OriginData: {
      Name: undefined,
      Email: undefined,
      Phone: undefined,
      Enterprise: undefined,
      State: undefined,
      StreetName: undefined,
      StreetNumber: undefined,
      Suburb: undefined,
      Delegation: undefined,
      PostalCode: undefined,
      Reference: undefined,
    },
    DestinyData: {
      Name: undefined,
      Email: undefined,
      Phone: undefined,
      Enterprise: undefined,
      State: undefined,
      StreetName: undefined,
      StreetNumber: undefined,
      Suburb: undefined,
      Delegation: undefined,
      PostalCode: undefined,
      Reference: undefined,
      PackageContent: undefined,
    },
  },
  userBalance: 15245,
  setUserBalance: () => {},
  currentLangCurrency: "español - mxn",
  setCurrentLangCurrency: () => {},
  setQuoteData: () => {},
  shipmentTypeSelected: "National",
  setShipmentTypeSelected: () => {},
  quoteStep: "1) Select Shipment Type",
  setQuoteStepTransition: () => {},
  transformedUserBalance: 0,
  OptimizedSelectedCurrency: "awaiting...",
  transformCurrencyToSelectedCurrency: () => 0,
});

export const QuoteDataContextProvider: React.FC = (props) => {
  const [quoteData, setQuoteData] = useState<QuoteData>({
    ParcelData: {
      Name: undefined,
      Image: undefined,
      Price: undefined,
      Time: undefined,
      Type: undefined,
      Height: undefined,
      Width: undefined,
      Length: undefined,
      Weight: undefined,
    },
    OriginData: {
      Name: undefined,
      Email: undefined,
      Phone: undefined,
      Enterprise: undefined,
      State: undefined,
      StreetName: undefined,
      StreetNumber: undefined,
      Suburb: undefined,
      Delegation: undefined,
      PostalCode: undefined,
      Reference: undefined,
    },
    DestinyData: {
      Name: undefined,
      Email: undefined,
      Phone: undefined,
      Enterprise: undefined,
      State: undefined,
      StreetName: undefined,
      StreetNumber: undefined,
      Suburb: undefined,
      Delegation: undefined,
      PostalCode: undefined,
      Reference: undefined,
      PackageContent: undefined,
    },
  });
  const [shipmentTypeSelected, setShipmentTypeSelected] =
    useState<ShipmentTypes>("National");

  const [quoteStep, setQuoteStep] = useState<QuoteSteps>(
    "1) Select Shipment Type"
  );

  const [currentLangCurrency, setCurrentLangCurrency] = useState<
    LanguageCurrencies | string
  >("español - mxn");

  const [userBalance, setUserBalance] = useState<number>(15245);

  const [transformedUserBalance, setTransformedUserBalance] =
    useState<number>(userBalance);

  const OptimizedSelectedCurrency = currentLangCurrency.split("-")[1].trim();

  const setQuoteStepTransition = (stepSelected: QuoteSteps) => {
    setTimeout(() => {
      setQuoteStep(stepSelected);
    }, 650);
    const tl = anime.timeline({
      targets: ".dashboardSection",
      duration: 500,
      easing: "linear",
    });

    tl.add({
      opacity: 0,
    });
    tl.add(
      {
        opacity: 1,
      },
      "+=400"
    );
  };

  const locationCurrencyValues: CurrenciesOptions = {
    cop: 193.75,
    mxn: 1,
    usd: 0.049,
  };

  const transformCurrencyToSelectedCurrency = (data: number): number => {
    return Math.round(
      // @ts-ignore
      data * locationCurrencyValues[OptimizedSelectedCurrency]
    );
  };

  useEffect(() => {
    setTransformedUserBalance(
      Math.round(
        // @ts-ignore
        userBalance * locationCurrencyValues[OptimizedSelectedCurrency]
      )
    );
  }, [currentLangCurrency]); //eslint-disable-line

  return (
    <QuoteDataContext.Provider
      value={{
        // Quote Data (Origin and Destiny quote info) value and setState function
        quoteData,
        setQuoteData,
        // user Balance (base money in mxn) value and setState function
        userBalance,
        setUserBalance,
        // shipment Type Selected (national, international and Pallet) value and setState function
        shipmentTypeSelected,
        setShipmentTypeSelected,
        // quote Step Selected (1,2,3,4,5) value and setState function
        quoteStep,
        setQuoteStepTransition,
        // user Language and Currency types values and setState function
        currentLangCurrency,
        setCurrentLangCurrency,
        // user Currency values and transformed Balance value and transforming function
        transformedUserBalance,
        transformCurrencyToSelectedCurrency,
        OptimizedSelectedCurrency,
      }}
    >
      {props.children}
    </QuoteDataContext.Provider>
  );
};
