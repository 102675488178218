import { FC } from 'react'
import { PRIMARY_COLOR } from '../../constants/colors'

interface PolicyTitleProps {
  title: string
}

const PolicyTitle: FC<PolicyTitleProps> = ({ title }) => {
  return (
    <h1 style={{ color: PRIMARY_COLOR }} className='text-center fw-bold my-5'>
      {title}
    </h1>
  )
}

export default PolicyTitle
