import { useState, useEffect } from 'react'
import { TColumnTable, Table } from '../../components/Common/Table'
import * as Icon from '@ant-design/icons'
import ModalContainer from '../../components/Containers/ModalContainer'
import { Col, DatePicker, Form, Popconfirm, Row, Tooltip, notification } from 'antd'
import InputContainer from '../../components/Containers/InputContainer'
import moment from 'moment'
import { usePost } from '../../hooks/usePost'
import { addNewFeature, getAllFeatures } from '../../services/admin_s'
import { FeatureTypes } from '../../types'
import { useGet } from '../../hooks/useGet'
import { IconButton } from '@material-ui/core'
import { DeleteOutline, EditOutlined } from '@material-ui/icons'

type Feature = {
    user: string,
    title: string,
    description: string,
    type: string,
    created_at?: any,
    edit?: boolean,
    _id?: any
}

const Features = () => {

    const [openModal, setOpenModal] = useState<boolean>(false)
    const [editMode, setEditMode] = useState<boolean>(false)
    const [deleteMode, setDeleteMode] = useState<any>({ ok: false, data: {} })

    const { fetchData } = usePost(addNewFeature)
    const [form] = Form.useForm()
    const { data: allFeatures, refetch, isLoading } = useGet(getAllFeatures)

    const onFinish = async(e: Feature) => {
        const request = {
            user: e.user,
            title: e.title,
            description: e.description,
            type: e.type as FeatureTypes,
            ...(e.created_at && {
                created_at: new Date(e.created_at)
            }),
            ...(editMode && e._id && { edit: true, _id: e._id }),
            ...(deleteMode && e._id && { delete: true, _id: e._id })
        }
        const { data } = await fetchData(request)
        if(!data){
            return notification.error({ message: "Error al trabajar en las features" })
        }else{
            refetch()
            setOpenModal(false)
            return notification.success({ message: data })
        }
    }

    const onEdit = async(e: Feature) => {
        const { user, title, description, created_at, type, _id } = e
        form.setFieldsValue({
            _id,
            user,
            title,
            description,
            type,
            created_at: moment(created_at)
        })
        setOpenModal(true)
        setEditMode(true)
        setDeleteMode(false)
    }

    const onDelete = async(e: Feature) => {
        setDeleteMode({ ok: true, data: e })
        setEditMode(false)
        setOpenModal(false)
    }

    const columns: TColumnTable = [
        { title: 'Fecha', field: 'created_at', render: (rowData: any) => { return moment(rowData.created_at).format('LLLL') } },
        { title: 'Título', field: 'title' },
        { title: 'Responsable', field: 'user' },
        { title: 'Descripción', field: 'description' },
        { title: 'Tipo', field: 'type' },
        { title: 'Acciones', render: (rowData:any) => { return <ColumnActions rowData={rowData} /> } }
    ]

    const ColumnActions = ({ rowData }:any) => {
        return (
            <div style={{ display:'flex' }}>
                <Tooltip title="Editar">
                    <IconButton onClick={() => onEdit(rowData)}>
                        <EditOutlined />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Eliminar">
                    <Popconfirm title="¿Quieres eliminar esta feature?" onConfirm={() => onDelete(rowData)}>
                        <IconButton>
                            <DeleteOutline />
                        </IconButton>
                    </Popconfirm>
                </Tooltip>
            </div>
        )
    }

    const onNewFeature = () => {
        form.resetFields()
        setOpenModal(!openModal)
        setEditMode(false)
        setDeleteMode(false)
    }

    useEffect(() => {
        if(deleteMode.ok && deleteMode.data){
            onFinish(deleteMode.data)
        }
    }, [deleteMode]) //eslint-disable-line

  return (
    <>
        <Table
            columns={columns}
            data={allFeatures ? allFeatures as [] : []}
            isLoading={isLoading}
            isButton={{
                icon: <Icon.FileAddOutlined />,
                title: 'Añadir Feature',
                onClick: () => onNewFeature()
            }}
        />
        <ModalContainer
            title='Añade un feature'
            onCancel={() => setOpenModal(!openModal)}
            visible={openModal}
            footer={<></>}
            customWidth='30rem'
        >
            <Form onFinish={onFinish} form={form}>
                <Row gutter={[10, 10]}>
                    {
                        editMode && (
                            <Col span={24}>
                                <InputContainer valueContainerName='_id' type='text' placeholder='ID del feature' disabled={true} />
                            </Col>
                            )
                    }
                    <Col span={24}>
                        <InputContainer valueContainerName='user' type='select' placeholder='Selecciona el responsable' required optionsList={Developers} allowClear />
                    </Col>
                    <Col span={24}>
                        <InputContainer valueContainerName='title' type='text' placeholder='Titulo' counters={{ max:30 }} required allowClear />
                    </Col>
                    <Col span={24}>
                        <InputContainer valueContainerName='description' type='text' placeholder='Breve descripción' required allowClear/>
                    </Col>
                    <Col span={24}>
                        <InputContainer valueContainerName='type' type='select' placeholder='Selecciona el tipo' required optionsList={ListTypes} allowClear />
                    </Col>
                    <Col span={24}>
                        <Form.Item name="created_at">
                            <DatePicker placeholder='Fecha de implementación ( opcional )' style={{ width:'100%' }} format={"YYYY-MM-DD"} allowClear/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <InputContainer type='submitButton' title={editMode ? 'Editar Feature' : 'Guardar Feature'} />
                    </Col>
                </Row>
            </Form>
        </ModalContainer>
    </>
  )
}

export default Features

const Developers = [
    "SIN ASIGNAR",
    "Jesús Ronquillo",
    "Jesús Saldaña", 
    "Carlos León", 
    "William Daza", 
    "Jhossua Campos", 
    "Brian Garcia",
    "Rogelio Samuel",
    "Jorge Correa",
    "Jesus Martinez",
    "Carlos Curiel",
    "Felipe Saldaña",
    "Eliud",
]

const ListTypes = [
    "Frontend",
    "Backend",
    "General",
    "Marketing",
    "Otros"
]