import CardContainer from "../../../../components/Containers/CardContainer";
import { Col, Collapse, Row } from "antd";
import LoadingAnimation from "../../../../components/LoadingAnimation";

type Props = {
  data: any[];
  isLoading: boolean;
  date: string;
};

const Panel = Collapse.Panel;

const getStatusColor = (status: boolean) => {
  return status ? "rgb(7, 228, 20)" : "rgb(255, 65, 63)";
};

const UserActivity = ({ data, isLoading, date }: Props) => {
  return (
    <CardContainer
      title={`Actividad de usuarios ${date}`}
      cardStyle={{ marginTop: "20px" }}
    >
      <hr />
      <Row style={{overflow: 'scroll',
      overflowX: 'hidden',
      height: '350px',}}>
        <Col span={16} style={{ margin: "auto", fontWeight: "bold" }}>
          <p>Usuario</p>
        </Col>
        {isLoading ? (
          <LoadingAnimation animationType="small" />
        ) : data.length === 0 ? (
          <p
            style={{
              fontWeight: "bold",
              margin: "50px auto",
              color: "#0008",
            }}
          >
            No hay datos para mostrar.
          </p>
        ) : (
          data.map((item, index) => (
            <Col span={24} key={index}>
              <Collapse
                bordered={false}
                defaultActiveKey={["1"]}
                style={{ background: "none" }}
                accordion={true}
                ghost={true}
              >
                <Panel header={item.user} key={item._id}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "30%",
                      }}
                    >
                      <p> Registro completado</p>
                      <div
                        style={{
                          backgroundColor: getStatusColor(
                            item.completedRegister
                          ),
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                        }}
                      ></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "30%",
                      }}
                    >
                      <p>Perfil completado</p>
                      <div
                        style={{
                          backgroundColor: getStatusColor(
                            item.completedProfile
                          ),
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                        }}
                      ></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "30%",
                      }}
                    >
                      <p>Primera Recarga</p>
                      <div
                        style={{
                          backgroundColor: getStatusColor(
                            item.firstRecharge ? true : false
                          ),
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                        }}
                      ></div>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </Col>
          ))
        )}
      </Row>
    </CardContainer>
  );
};

export default UserActivity;
