import { Row, Button, Spin, Col, Typography, notification } from "antd"
import CardContainer from "../../../components/Containers/CardContainer"
import ModalContainer, { modalSizes, modalHeight } from "../../../components/Containers/ModalContainer"
import { getStoreImg } from "./config"
import { FC, useEffect, useState } from "react"
import JsonEditor from "./JsonEditor"
import { configJson, platformsList } from "./config"
import InputContainer from "../../../components/Containers/InputContainer"
import { usePost } from "../../../hooks/usePost"
import { addConfigIntegrations } from "../../../services/configuration_s"
import StoreHeader from "./StoreHeader"

const styles = {
    centerButton: { display: 'flex', alignItems: 'center' },
    popconfirm: { color: 'red' },
    spin: { width: "100%" }
}

interface props {
    isAddModalOpen: boolean;
    openAddModal: () => void;
    refetch: () => void;
}

const AddModal: FC<props> = ({
    openAddModal,
    isAddModalOpen,
    refetch,
}) => {
    const [imgStore, setImgStore] = useState(getStoreImg('WOOCOMMERCE'))
    const [type, setType] = useState('WOOCOMMERCE')
    const [json, setJson] = useState(configJson)
    const { fetchData: fetchCreate, isLoading: isLoadingCreate } = usePost(addConfigIntegrations)

    const updateJson = (data: any) => {
        delete data.type
        setJson(data)
    }

    const onFinish = async () => {
        try {
            const response = await fetchCreate({
                config: json,
                type
            })
            if (!response.error) {
                notification.success({
                    message:
                        'Configuración creada correctamente'
                })
                openAddModal()
                refetch()
            } else {
                notification.error({
                    message:
                        'Configuración fallida'
                })
            }
        } catch (error) {
            console.log(error)
            notification.error({
                message:
                    'Configuración fallida'
            })
        }
    }

    useEffect(() => {
        if (type) {
            setImgStore(getStoreImg(type))
        }

    }, [type])

    return (
        <ModalContainer
            size={modalSizes.BIG}
            height={modalHeight.MID}
            title={'Crear Nueva integración'}
            visible={isAddModalOpen}
            onCancel={openAddModal}
            footer={
                <Row justify="end">
                    <Button onClick={openAddModal}>
                        Cerrar
                    </Button>
                </Row>
            }
        >
            <Spin spinning={isLoadingCreate} style={styles.spin}>
                <Row gutter={[20, 20]} style={{ width: '100%' }}>
                    <StoreHeader img={imgStore} />
                    <Col span={24}>
                        <CardContainer>
                            <Col span={24} style={{ margin: '0 1rem' }}>
                                <Typography style={{ fontSize: '1rem', fontWeight: "bold" }}>Selecciona una integración</Typography>
                                <InputContainer
                                    type="select"
                                    placeholder="Selecciona el tipo de integración"
                                    valueContainerName="type"
                                    onChange={(e) => setType(e)}
                                    optionsList={platformsList}
                                />
                            </Col>
                            <br />
                            <Col span={24} style={{ padding: '0 1rem' }}>
                                <Typography style={{ fontSize: '1rem', fontWeight: "bold" }}>Configura la integración</Typography>
                            </Col>

                            <br />
                            <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
                                <JsonEditor
                                    currentJson={json}
                                    updateJson={updateJson}
                                    currentStore={null}
                                    editMode={false}
                                />
                            </Col>
                            <br />
                            <InputContainer
                                type="submitButton"
                                title="Guardar cambios"
                                onClick={onFinish}
                            />



                        </CardContainer>
                    </Col>


                </Row>
            </Spin>
        </ModalContainer>
    )
}

export default AddModal