import React, { useContext, useEffect, useState } from "react";
import { FilterOutlined } from "@ant-design/icons";
import { Table, TColumnTable } from "../../../components/Common/Table";
import ModalContainer, {
  modalHeight,
  modalSizes,
} from "../../../components/Containers/ModalContainer";
import { InfoCircleFilled } from "@ant-design/icons";
import {
  ReportsInterface,
  StatusReport,
} from "../../../interfaces/reports.interfaces";
import { Col, Row } from "antd";
import CardContainer from "../../../components/Containers/CardContainer";
import { ReportStatus } from "../../../components/Common/ReportStatus";
import OverWeightInfo from "./Reports/OverWeightInfo";
import RecolectionsInfo from "./Reports/RecolectionsInfo";
import ExpediteDeliveryInfo from "./Reports/ExpediteDeliveryInfo";
import { AdminDataContext } from "../../../context/adminContext";
import { ApiContext } from "../../../context/apiContext";
import { useSectionFilters } from "../../../hooks/sections/useSectionFilters";
import {
  FilterList,
  FilterTypes,
} from "../../../components/Sections/FiltersList";

const Reports: React.FC = () => {
  const {
    state,
    toggle,
    byPrice,
    filterSelected,
    rangeDate,
    setByPrice,
    setFilterSelected,
    setRangeDate,
    resetFilters,
  } = useSectionFilters();

  const [visible, setVisible] = useState<boolean>(false);
  const [report, setReport] = useState<ReportsInterface>();

  const perBatch = 100;
  const { country } = useContext(AdminDataContext);
  const { backendApiCall } = useContext(ApiContext);
  const [batchesFetched, setBatchesFetched] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reportsFetched, setReportsFetched] = useState<ReportsInterface[]>([]);
  const [reportsFiltered, setReportsFiltered] = useState<ReportsInterface[]>(
    []
  );
  console.log({
    reportsFetched,
  });

  const closeModal = () => {
    setVisible(false);
  };

  const openModal = (report: ReportsInterface) => {
    setReport(report);
    setVisible(true);
  };

  const foundReport = (rowData: any) => {
    return reportsFetched?.find((el, index) => rowData.tableData.id === index);
  };

  const myReportsColumn = [
    {
      title: "Fecha de reporte",
      field: "created_at",
    },
    {
      title: "Usuario",
      field: "email",
    },
    {
      title: "Descripción",
      field: "description",
    },
    {
      title: "Asunto",
      field: "subject",
    },
    {
      title: "Estatus",
      field: "status",
      render: (rowData: ReportsInterface) => {
        return <ReportStatus status={StatusReport[`N${rowData.status}`]} />;
      },
    },
    {
      title: "Prioridad",
      field: "priority",
    },
  ];

  const reportsData = (item: any): object => {
    return {
      created_at: new Date(item.created_at).toLocaleDateString(),
      email: item.email,
      description: item.description,
      subject: item.subject,
      status: item.status,
      priority: item.priority,
      metadata: item.metadata,
    };
  };

  const fetchReports = async () => {
    setIsLoading(true);

    const response = await backendApiCall({
      method: "GET",
      endpoint: `users/reports/admin/${batchesFetched * perBatch}/${
        (batchesFetched + 1) * perBatch
      }/${""}`,
    });

    // @ts-ignore
    const optimizedData = response.data.data.map((shipment: object) => {
      return reportsData(shipment);
    });

    setReportsFetched(reportsFetched.concat(optimizedData));
    setReportsFiltered(reportsFetched.concat(optimizedData));
    setBatchesFetched((prevBatch) => prevBatch + 1);
    setIsLoading(false);
  };

  const handlePageChange = (page: any) => {
    if ((page + 1) * rowsPerPage === perBatch * batchesFetched) {
      fetchReports();
    }
  };

  useEffect(() => {
    fetchReports();
  }, [country]); //eslint-disable-line

  useEffect(() => {
    if (!byPrice && !rangeDate) {
      setReportsFiltered(reportsFetched ?? []);
      return;
    }

    let result: ReportsInterface[] = [];

    if (rangeDate) {
      const lowerLimit = new Date(rangeDate[0]);
      const upperLimit = new Date(rangeDate[1]);

      result =
        Array.from(reportsFetched ?? [])?.filter((c) => {
          const createdAt = new Date(c.created_at);
          console.log(createdAt);

          return createdAt > lowerLimit && createdAt < upperLimit;
        }) ?? [];

      setReportsFiltered(result);
      return;
    }
  }, [byPrice, rangeDate, reportsFetched]);
  console.log(reportsFiltered);
  return (
    <>
      <Table
        isLoading={isLoading}
        data={reportsFiltered}
        columns={myReportsColumn as TColumnTable}
        title="Reportes más recientes"
        onChangePage={handlePageChange}
        onChangeRowsPerPage={(rows) => setRowsPerPage(rows)}
        isButton={{
          title: "Aplicar Filtros",
          onClick: () => {
            toggle();
          },
          icon: <FilterOutlined />,
        }}
        config={{
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: () => <InfoCircleFilled />,
            tooltip: "Obtener información",
            onClick: (event, rowData: any) => {
              openModal(foundReport(rowData) as ReportsInterface);
            },
          },
        ]}
      />
      <ModalContainer
        title={"Seleciona un Filtro"}
        visible={state}
        onCancel={toggle}
        footer={false}
      >
        <div className="JSONViewer">
          <div style={{ display: "flex" }}>
            <FilterList
              filterSelected={filterSelected as FilterTypes}
              resetAllFilters={resetFilters}
              setByPrice={setByPrice}
              setFilterSelected={setFilterSelected}
              setIsVisible={toggle}
              setRangeDate={setRangeDate}
              skipPriceFilter
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        footer={<></>}
        size={modalSizes.LARGE}
        height={modalHeight.BIG}
        title="Información del Reporte"
        visible={visible}
        onCancel={closeModal}
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <CardContainer title={report?.subject?.split(":")[0].trim()}>
              Envío: {report?.subject?.split(":")[1].trim()}
            </CardContainer>
          </Col>

          <Col span={24}>
            <CardContainer title="Descripción del problema">
              {report?.description}
            </CardContainer>
          </Col>

          <Col span={24}>
            {report?.metadata?.overweight && (
              <OverWeightInfo report={report?.metadata?.overweight} />
            )}
            {report?.metadata?.recolections && (
              <RecolectionsInfo report={report?.metadata?.recolections} />
            )}
            {report?.metadata?.expedite_delivery && (
              <ExpediteDeliveryInfo
                report={report?.metadata?.expedite_delivery}
              />
            )}
          </Col>
        </Row>
      </ModalContainer>
    </>
  );
};

export default Reports;
