import { Avatar, Badge } from "antd"
import { getAvatarName, getLoginAvatarColor, switchCountry } from "../utils"
import { UserItemI } from "../Search/SearchItem";
import { useMediaQuery } from 'react-responsive'
import { FC } from "react";

interface props {
    userFound: UserItemI;
}

const UserAvatar: FC<props> = ({ userFound }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

    return (
        <Badge
            count={
                <img
                    alt='count'
                    src={switchCountry(userFound.app_country)}
                    width={isMobile ? 20 : 22} height={isMobile ? 20 : 22} />
            }
            offset={[-10, isMobile ? 35 : 40]}
        >
            <Avatar size={isMobile ? 42 : 48} style={{ backgroundColor: getLoginAvatarColor(userFound.user) }}>
                {getAvatarName(userFound)}
            </Avatar>
        </Badge>
    )
}

export default UserAvatar