import axios, { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import { TableV3 } from '../../../../components/Common/TableV3'
import { AllColumnsType, GetAllFilteredShipmentsQuery, PendingCommissions as PendingCommission } from '../../../../interfaces/partner.interface'
import { PendingCommissionsDownloads } from './schemaDownloads'
import { PendingCommissionsColumn } from './schemaColumns'

const ITEMS_PER_PAGES = 150
const backendUrlPartner = process.env.REACT_APP_PARTNERS_BACKEND_URL

const PendingCommissions = () => {
  const { t } = useTranslation()
  const { getAccessTokenSilently } = useAuth0()
  const [itemPerPages, setItemPerPages] = useState(ITEMS_PER_PAGES)
  const [rowsPerPages, setRowPerPages] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)
  const [topLimit, setTopLimit] = useState(ITEMS_PER_PAGES)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [dataColumns, setDataColumns] = useState<any>(null)
  const [downloadColumns] = useState<GetAllFilteredShipmentsQuery>({
    range: {
      from: 0,
      to: topLimit
    },
    searched: searchText,
    columns: [
      {
        id: 'createdAt',
        find: false
      },
      {
        id: 'owner',
        find: false
      },
      {
        id: 'email',
        find: false
      },
      {
        id: 'shipments',
        find: false
      },
      {
        id: 'amount',
        find: false
      },
    ]
  })
  const [filterByColumns, setFilterByColumns] = useState<GetAllFilteredShipmentsQuery>({ ...downloadColumns })

  const handleFecthShipments = async (filterByColumns: GetAllFilteredShipmentsQuery, saveState = true) => {
    const userToken = await getAccessTokenSilently()
    const { data }: AxiosResponse<PendingCommission[]> = await axios.post(`${backendUrlPartner}/admin/withdrawals/table`, filterByColumns, {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })

    const formatedData = data
      .filter((item) => item.referral.shipments.length !== 0)
      .map((data) => {
        const amountBase = data.referral.shipments.reduce((total: Number, shipment: any)=> {
          return total + shipment.price
        }, 0)

        const amountTotal = ((amountBase as number) * 3) / 100
        
        return {
            createdAt: data.createdAt,
            referral: {
                nickName: data.referral.nickName,
                email: data.referral.email,
                amount: amountTotal,
                shipments: data.referral.shipments,
            },
            profile: {
                name: data.profile.name,
                email: data.profile.email
            }
        }
      })
      .sort((item, item2) => item2.referral.amount - item.referral.amount)

    if (saveState){
      setDataColumns(formatedData)
    } else {
      return formatedData ?? []
    }
  }

  useEffect(() => {
    if (!dataColumns?.length) return
    const totalPages = (dataColumns?.length ?? 0) / rowsPerPages

    if (currentPage + 1 < Math.round(totalPages)) return
    setTopLimit((prevState) => prevState + itemPerPages)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]) 

  useEffect(() => {
    (async () => {
      if (topLimit === ITEMS_PER_PAGES) return
      setFilterByColumns({ ...filterByColumns, range: { ...filterByColumns.range, to: topLimit } })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topLimit]) 

  useEffect(() => {
    (async () => {
      setIsRefetching(true)
      await handleFecthShipments(filterByColumns)
      setIsRefetching(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByColumns]) 
  

  return (
    <div>
      {' '}
      <TableV3
        title={t('PendingCommissions.Table.Title')}
        tableType="commissions"
        data={dataColumns ?? []}
        isLoading={refetching}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(row) => setRowPerPages(row)}
        schemaDownload={PendingCommissionsDownloads}
        columns={PendingCommissionsColumn(dataColumns ?? []) as ColumnsType<AllColumnsType>}
        config={{ disableFilters: true }}
        stateValues={{
          currentPage,
          rowsPerPages,
          topLimit,
          searchText,
          filterByColumns,
          downloadColumns,
        }}
        filters={{
          changeTopLimit(limit: number){
            setItemPerPages(limit)
            setTopLimit((limit * 2))
          },
          filterBySearchText(text: string) {
            setSearchText(text)
          },
          filterByColumns(filter: GetAllFilteredShipmentsQuery) {
            setFilterByColumns(filter)
          },
          filterDownloads: async (filter: GetAllFilteredShipmentsQuery) => {
            return await handleFecthShipments(filter, false)
          },
        }}
      />
    </div>
  )
}

export default PendingCommissions