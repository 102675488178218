import { apiProps, apiResponse } from "../interfaces/app.interfaces";
import { Price } from "../interfaces/services.interface";

export const getUsers = async (
  backendApiCall: (data: apiProps) => Promise<apiResponse>
) => {
  return await backendApiCall({
    method: "GET",
    endpoint: `users/users`,
  });
};

export const getServicesByParcel =
  (parcel: AllCarriers | undefined) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "GET",
      endpoint: `services/${parcel}`,
    });
  };

export const updateServiceMode =
  (dto: { mode: "percentage" | "static"; id: string }) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `services/mode/${dto.id}`,
      data: dto,
    });
  };

export const updateServicePrices =
  (dto: { oldPrice: Price; newPrice: Price; id: string }) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `services/edit/prices/${dto.id}`,
      data: { oldPrice: dto.oldPrice, newPrice: dto.newPrice },
    });
  };

export const updateUserSpecialPrice =
  (dto: { oldPrice: Price; newPrice: Price; id: string; user: string }) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `services/specialprice/user/price/edit/${dto.id}/${dto.user}`,
      data: { oldPrice: dto.oldPrice, newPrice: dto.newPrice },
    });
  };

export const addSpecialPriceToUser =
  ({
    price: { price, weight },
    id,
    user,
  }: {
    price: Price;
    id: string;
    user: string;
  }) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `services/specialprice/user/price/add/${id}/${user}`,
      data: { price, weight },
    });
  };

export const addUserToSpecialPrices =
  ({ id, user }: { id: string; user: string }) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `services/specialprice/user/add/${id}/${user}`,
    });
  };

export const removeUserFromSpecialPrices =
  ({ id, user }: { id: string; user: string }) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `services/specialprice/user/delete/${id}/${user}`,
    });
  };

export const removeSpecialPriceFromUser =
  ({ id, user, price }: { id: string; user: string; price: Price }) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `services/specialprice/user/price/delete/${id}/${user}`,
      data: { price },
    });
  };
