import { FC } from 'react'
import { DatePicker } from 'antd'
import { useTranslation } from 'react-i18next'

export const DataPicker: FC<Props> = ({ setValue }: any) => {
  const { t } = useTranslation()
  const { RangePicker } = DatePicker
  const dateFormat = 'DD/MM/YYYY'

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
      }}
    >
      <RangePicker
        placeholder={[
          t("Table.RangeDateModal.DataPicker.StartDate.Placeholder"), 
          t("Table.RangeDateModal.DataPicker.EndDate.Placeholder"), 
        ]}
        style={{ width: '100%' }}
        onChange={(val) => setValue(val)}
        format={dateFormat}
      />
    </div>
  )
}

type Props = {
  setValue: React.Dispatch<React.SetStateAction<Date>>
}
