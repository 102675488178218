import { FC } from 'react'
import InputContainer from '../Containers/InputContainer'

export const TableCarrierFilter: FC<Props> = ({ carriers, setCarrierSelected }) => {
  return (
    <div>
      <InputContainer
        optionsList={carriers}
        placeholder='Paquetería'
        type='select'
        valueContainerName='value name'
        onChange={(e) => setCarrierSelected(e)}
      />
    </div>
  )
}

type Props = {
  carriers: AllCarriers[]
  setCarrierSelected: React.Dispatch<React.SetStateAction<AllCarriers | null>>
}
