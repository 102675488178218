import React, { useContext, useEffect, useState } from 'react'
import { Table } from '../../../components/Common/Table'
import { FilterOutlined } from '@ant-design/icons'
import { myUsersColumn } from '../../../services/records'
import { AdminDataContext } from '../../../context/adminContext'
import { ApiContext } from '../../../context/apiContext'
import { useSectionFilters } from '../../../hooks/sections/useSectionFilters'
import { FilterList, FilterTypes } from '../../../components/Sections/FiltersList'
import ModalContainer from '../../../components/Containers/ModalContainer'

const Users: React.FC = () => {
  const {
    state,
    toggle,
    byPrice,
    filterSelected,
    rangeDate,
    setByPrice,
    setFilterSelected,
    setRangeDate,
    resetFilters,
  } = useSectionFilters()

  const perBatch = 100
  const { country } = useContext(AdminDataContext)
  const { backendApiCall } = useContext(ApiContext)
  const [batchesFetched, setBatchesFetched] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [usersFetched, setUsersFetched] = useState<User[]>([])
  const [userFiltered, setUserFiltered] = useState<User[]>([])
  console.log({
    usersFetched,
  })

  const usersData = (item: any): object => {
    return {
      user: item.user,
      country: item.app_country,
      balance: item.balance,
      created_at: new Date(item?.created_at).toLocaleDateString(),
      email: item.profile[0]?.email ? item.profile[0]?.email : 'El usuario no tiene correo',
      phone: item.profile[0]?.phone ? item.profile[0]?.phone : 'El usuario no tiene teléfono',
      verified: item.security?.identity_verified ? 'Si' : 'No',
    }
  }

  const fetchUsers = async () => {
    setIsLoading(true)

    const response = await backendApiCall({
      method: 'GET',
      endpoint: `users/getAllUsers${country && '/' + country}/${batchesFetched * perBatch}/${
        (batchesFetched + 1) * perBatch
      }`,
    })

    // @ts-ignore
    const optimizedData = response.data.data.map((user: object) => {
      return usersData(user)
    })

    setUsersFetched(usersFetched.concat(optimizedData))
    setUserFiltered(usersFetched.concat(optimizedData))
    setBatchesFetched((prevBatch) => prevBatch + 1)
    setIsLoading(false)
  }

  const handlePageChange = (page: any) => {
    if ((page + 1) * rowsPerPage === perBatch * batchesFetched) {
      fetchUsers()
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [country]) //eslint-disable-line

  useEffect(() => {
    if (!byPrice && !rangeDate) {
      setUserFiltered(usersFetched ?? [])
      return
    }

    let result: User[] = []

    if (rangeDate) {
      const lowerLimit = new Date(rangeDate[0])
      const upperLimit = new Date(rangeDate[1])

      result =
        Array.from(usersFetched ?? [])?.filter((c) => {
          const createdAt = new Date(c.created_at)

          return createdAt > lowerLimit && createdAt < upperLimit
        }) ?? []

      setUserFiltered(result)
      return
    }
  }, [byPrice, rangeDate]) //eslint-disable-line

  return (
    <>
      <Table
        data={userFiltered}
        isLoading={isLoading}
        columns={myUsersColumn}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={(rows) => setRowsPerPage(rows)}
        title='Usuarios más recientes'
        isButton={{
          title: 'Aplicar Filtros',
          onClick: () => {
            toggle()
          },
          icon: <FilterOutlined />,
        }}
      />
      <ModalContainer
        title={'Seleciona un Filtro'}
        visible={state}
        onCancel={toggle}
        footer={false}
      >
        <div className='JSONViewer'>
          <div style={{ display: 'flex' }}>
            <FilterList
              filterSelected={filterSelected as FilterTypes}
              resetAllFilters={resetFilters}
              setByPrice={setByPrice}
              setFilterSelected={setFilterSelected}
              setIsVisible={toggle}
              setRangeDate={setRangeDate}
              skipPriceFilter
            />
          </div>
        </div>
      </ModalContainer>
    </>
  )
}

export default Users

type User = {
  user: string
  country: string
  balance: number
  created_at: string
  email: string
  phone: string
  verified: string
  tableData: {
    id: string
  }
}
