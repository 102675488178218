// import { FC } from "react";
// import { dataToShowI } from "../interfaces";
import { Col, Row, Typography } from "antd";
// import moment from "moment";

interface Styles {
    dataRow: React.CSSProperties;
    pendingContainer: React.CSSProperties;
    sectionTitle: React.CSSProperties;
}

const styles: Styles = {
    dataRow: {
        width: '100%'
    },
    pendingContainer: {
        backgroundColor: '#FFF6CA',
        width: '100%',
        padding: '16px',
        border: '1px solid #AD8C10',
        marginBottom: '12px'
    },
    sectionTitle: {
        color: '#000000',
        fontSize: '15px'
    },
}


const PendingAlert = () => {
    return (
        <Row style={styles.dataRow}>
            <Col xs={24} md={24} lg={24}>
                <section style={styles.pendingContainer}>
                    <section>
                        <Typography style={styles.sectionTitle}>
                            Datos del perfil faltantes, ve a <strong>Acciones &gt; Actualizar perfil</strong> para rellenar la información que falta.
                        </Typography>
                    </section>
                </section>
            </Col>
        </Row>
    )
}

export default PendingAlert
