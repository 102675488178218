import { Row, Typography } from "antd";
import usePermission from "../../hooks/usePermission";
import { ActionsPricesSection, PermissionViews } from "../../interfaces/permissions.interfaces";

import { Price } from "../../interfaces/services.interface";
import NoAccessPrices from "./NoAccessPrices";

import { PricesCard } from "./PricesCard";

export const PricesContainer = ({
  prices,
}: {
  prices: Price[] | undefined;
}) => {
  const { hasAccess } = usePermission()

  return (
    <Row
      style={{
        height: "100% - 50px",
        maxHeight: "100%",
        overflow: "scroll",
        width: "100%",
      }}
      className="scrollNone"
      gutter={[0, 20]}
    >
      {
        hasAccess(ActionsPricesSection.prices, PermissionViews.prices_section)
          ?
          prices && prices.length > 0 ? (
            prices.map((price, i) => (
              <PricesCard key={price._id || i} price={price} index={i} />
            ))
          ) : (
            <Typography.Text style={{ textAlign: "center", flex: 1 }}>
              Sin precios
            </Typography.Text>
          ) : (
            <NoAccessPrices />

          )

      }
    </Row>
  );
};
