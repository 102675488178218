import CardContainer from "../../../../components/Containers/CardContainer";
import { Col, Row, Collapse } from "antd";
import { useGet } from "../../../../hooks/useGet";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import {
  carrierNames,
  getParcelImageForDashboard,
} from "../../../../services/records";
import { getMostUsedPackagesByDay } from "../../../../services/admin_s";
import { t } from "i18next";

const { Panel } = Collapse;

type Props = {
  country: string;
};

interface Service {
  name: string;
  total: number;
}

interface Services {
  service: Service;
}

interface CarrierShipments {
  carrier: string;
  total: number;
  services: Services[];
}

const MostUsedPackagesDaily = ({ country }: Props) => {
  const { data: mostUsedParcelsByDay, isLoading } = useGet(
    getMostUsedPackagesByDay(country)
  ) as {
    data: {
      data: {
        parcels: CarrierShipments[];
      };
    };
    isLoading: boolean;
  };

  if (mostUsedParcelsByDay?.data?.parcels)
    mostUsedParcelsByDay.data.parcels.sort((a, b) => b.total - a.total);

  return (
    <CardContainer
      cardStyle={{
        marginTop: "20px",
        overflow: "hidden",
        padding: "20px",
        borderBottom: "1px solid #ccc", // Agregar la línea divisoria
      }}
      titleStyle={{ placeItems: "center" }}
      title="Paqueterías más usadas Hoy"
    >
      <div
        style={{
          //overflowY: "scroll",//
          maxHeight: "400px",
          paddingRight: "20px",
        }}
      >
        <hr />
        {isLoading ? (
          <LoadingAnimation animationType="small" />
        ) : !mostUsedParcelsByDay?.data?.parcels ||
          mostUsedParcelsByDay.data.parcels.length === 0 ? (
          <p
            style={{
              fontWeight: "bold",
              margin: "50px auto",
              color: "#0008",
            }}
          >
            No hay datos para mostrar.
          </p>
        ) : (
          <>
            <Row justify="space-between">
              <Col
                span={11}
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                <p>Paquetería</p>
              </Col>
              <Col span={5}></Col>
              <Col
                span={6}
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  paddingRight: "1px",
                }}
              >
                <p>Total</p>
              </Col>
            </Row>
            <Row
              align="middle"
              style={{
                maxHeight: "40vh",
                paddingRight: 10,
                overflowY: "auto",
              }}
            >
              {mostUsedParcelsByDay.data.parcels.map((item) => (
                <Col span={24} style={{ textAlign: "left" }} key={item.carrier}>
                  <Collapse
                    bordered={false}
                    style={{ background: "transparent" }}
                  >
                    <Panel
                      key={item.carrier}
                      header={
                        <Row
                          justify="space-between"
                          style={{
                            alignItems: "center",
                            borderColor: "black",
                            width: "100%",
                          }}
                        >
                          <Col
                            span={16}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {getParcelImageForDashboard(
                              carrierNames(item.carrier) as AllCarriers,
                              "30px"
                            )}
                            <span
                              style={{
                                marginLeft: "10px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {t(
                                `Quote.Service.Carrier.${carrierNames(
                                  item.carrier
                                )}`
                              )}
                            </span>
                          </Col>
                          <Col
                            span={8}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "right",
                              fontWeight: "bold",
                              color: "#2D1B6E",
                            }}
                          >
                            {item.total || 0}
                          </Col>
                        </Row>
                      }
                      style={{ background: "transparent", border: "none" }}
                    >
                      {item.services
                        .sort((a: any, b: any) => b.service.total - a.service.total)
                        .map(({ service }) => (
                          <div
                            key={service.name}
                            style={{
                              marginBottom: "5px",
                              width: "65%",
                              paddingLeft: 25,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderBottom: "1px solid #ccc",
                              }}
                            >
                              <div>
                                <p style={{ margin: 0 }}>
                                  {`${t(
                                    `Quote.Service.Carrier.${carrierNames(item.carrier)}`
                                  )} ${t(`Quote.Service.${service.name}`)}`}
                                </p>
                              </div>
                              <div>
                                <p style={{ margin: 0, fontWeight: "bold" }}>
                                  {service.total}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </Panel>
                  </Collapse>
                </Col>
              ))}
            </Row>
          </>
        )}
      </div>
    </CardContainer>
  );
};

export default MostUsedPackagesDaily;
