import { t } from "i18next";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/lib/table";
import { GetAllFilteredShipmentsQuery as GetAllFilteredQuery } from "../../../interfaces/partner.interface";
import { ApiContext } from "../../../context/apiContext";
import { TableV3 } from "../../../components/Common/TableV3";
import { ShipmentStatus, ShipmentV2 } from "../../../interfaces/shipments.interface";
import { getParcelImage, linkRastreo } from "../../../services/records";
import { CancelStatus } from "../../../components/Common/CancelStatus";
import { ShipmentsActions } from "../../../components/Common/ShipmentsActions";
import { AdminDataContext } from "../../../context/adminContext";

interface ShipmentColumnsType {
	key: React.Key;
	created_at: string;
	profile: {
		email: string;
	};
	shipment_status: string;
	tracking: string;
	origin: {
		name: string;
	};
	destination: {
		name: string;
	};
	carrier: string;
	price: number;
	service_id: string;
}

const ITEMS_PER_PAGES = 150;

const ShipmentsV2 = () => {
	const { t } = useTranslation();
	const { userToken, backendURL } = useContext(ApiContext);
	const { country } = useContext(AdminDataContext);
	const [itemPerPages, setItemPerPages] = useState(ITEMS_PER_PAGES);
	const [rowsPerPages, setRowPerPages] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [topLimit, setTopLimit] = useState(ITEMS_PER_PAGES);
	const [refetching, setIsRefetching] = useState(false);
	const [searchText, setSearchText] = useState<string>("");
	const [dataColumns, setDataColumns] = useState<any>(null);
	const [columnsDefaultValue] = useState<GetAllFilteredQuery>({
		country,
		range: {
			from: 0,
			to: topLimit,
		},
		searched: searchText,
		columns: [
			{
				id: "created_at",
				find: false,
			},
			{
				id: "user",
				find: false,
			},
			{
				id: "tracking",
				find: false,
			},
			{
				id: "origin",
				find: false,
			},
			{
				id: "destination",
				find: false,
			},
			{
				id: "carrier",
				find: false,
			},
			{
				id: "price",
				find: false,
			},
			{
				id: "shipment_status",
				find: true,
				filters: {
					sort: false,
					filter: true,
					filter_values: ["Activa", "Entregado", "En transito", "Excepcion"],
				},
			},
		],
	});
	const [downloadColumns] = useState<GetAllFilteredQuery>({ ...columnsDefaultValue });
	const [filterByColumns, setFilterByColumns] = useState<GetAllFilteredQuery>({
		...downloadColumns,
	});

	const handleFecth = async (filterByColumns: GetAllFilteredQuery, saveState = true) => {
		const { data } = await axios.post(`${backendURL}shipments/getAllShipmentsV2`, filterByColumns, {
			headers: { Authorization: `Bearer ${userToken}` },
		});

		if (saveState) {
			setDataColumns(data?.shipments);
		} else {
			return data?.shipments;
		}
	};

	useEffect(() => {
		if (!dataColumns?.length) return;
		const totalPages = (dataColumns?.length ?? 0) / rowsPerPages;

		if (currentPage + 1 < Math.round(totalPages)) return;
		setTopLimit((prevState) => prevState + itemPerPages);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	useEffect(() => {
		(async () => {
			if (topLimit === ITEMS_PER_PAGES) return;
			setFilterByColumns({ ...filterByColumns, range: { ...filterByColumns.range, to: topLimit } });
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [topLimit]);

	useEffect(() => {
		(async () => {
			setIsRefetching(true);
			await handleFecth(filterByColumns);
			setIsRefetching(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterByColumns]);

	useEffect(() => {
		(async () => {
			setIsRefetching(true);
			columnsDefaultValue.country = country;
			await handleFecth(columnsDefaultValue);
			setIsRefetching(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [country]);

	return (
		<div>
			{" "}
			<TableV3
				title={t("Shipments.Table.Title")}
				tableType="shipments"
				isShipments
				data={dataColumns ?? []}
				isLoading={refetching}
				onChangePage={(page) => setCurrentPage(page)}
				onChangeRowsPerPage={(row) => setRowPerPages(row)}
				schemaDownload={shipmentsDownloads}
				columns={shipmentsColumnV2(dataColumns ?? []) as ColumnsType<ShipmentColumnsType>}
				stateValues={{
					currentPage,
					rowsPerPages,
					topLimit,
					searchText,
					filterByColumns,
					downloadColumns,
				}}
				filters={{
					changeTopLimit(limit: number) {
						setItemPerPages(limit);
						setTopLimit(limit * 2);
					},
					filterBySearchText(text: string) {
						setSearchText(text);
					},
					filterByColumns(filter: GetAllFilteredQuery) {
						setFilterByColumns(filter);
					},
					filterDownloads: async (filter: GetAllFilteredQuery) => {
						filter.isDownload = true;
						return await handleFecth(filter, false);
					},
				}}
			/>
		</div>
	);
};

export const shipmentsColumnV2 = (data: ShipmentV2[]): ColumnsType<ShipmentColumnsType> => {
	const uniqueCarriers = Array.from(new Set(data.map((item) => item.carrier)));
	const uniqueShipmentStatus = Array.from(
		new Set(data.map((item) => item.shipment_status))
	) as ShipmentStatus[];

	const statusMapUI = {
		Activa: t("Shipments.Table.Columns.Status.Active"),
		Entregada: t("Shipments.Table.Columns.Status.Delivered"),
		"En transito": t("Shipments.Table.Columns.Status.InTransit"),
		Excepcion: t("Shipments.Table.Columns.Status.Exception"),
		Solicitada: t("Cancellations.Table.Columns.Status.Solicited"),
		"Cancelada y usada": t("Cancellations.Table.Columns.Status.CancelledButUsed"), // C.y usada/C. but used
		"Saldo abonado": t("Cancellations.Table.Columns.Status.Refunded"), // Abonada/Refunded
		"Cancelada de prov": t("Cancellations.Table.Columns.Status.CancelledFromProvider"),
	};

	return [
		{
			title: (
				<Tooltip title={t("Table.Tooltip.Sort.Ascending")}>
					{t("Shipments.Table.Columns.Date")}
				</Tooltip>
			),
			dataIndex: "created_at",
			align: "center",
			showSorterTooltip: false,
			sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
			render: (createdAt: string) => new Date(createdAt).toLocaleString(),
		},
		{
			title: t("Shipments.Table.Columns.User"),
			dataIndex: ["profile", "email"],
			align: "center",
		},
		{
			title: t("Shipments.Table.Columns.Tracking"),
			dataIndex: "",
			align: "center",
			render: (rowData: any) => (
				<a
					href={linkRastreo(rowData?.carrier, rowData?.tracking)}
					target="_blank"
					rel="noopener noreferrer"
					style={{ color: "black" }}
				>
					{rowData?.tracking}
				</a>
			),
		},
		{
			title: t("Shipments.Table.Columns.Origin"),
			dataIndex: ["origin", "name"],
			align: "center",
			render: (name, rowData: any) => {
				return `${name} ${rowData.origin.last_name || ""}`;
			},
		},
		{
			title: t("Shipments.Table.Columns.Destination"),
			dataIndex: ["destination", "name"],
			align: "center",
			render: (name, rowData: any) => {
				return `${name} ${rowData.destination.last_name || ""}`;
			},
		},
		{
			title: t("Shipments.Table.Columns.Carrier"),
			dataIndex: "carrier",
			align: "center",
			filters: uniqueCarriers.map((carrier) => ({ text: carrier, value: carrier })),
			onFilter: (value, record) => record.carrier.indexOf(value as string) === 0,
			render: (carrier) => getParcelImage(carrier),
		},
		{
			title: (
				<Tooltip title={t("Table.Tooltip.Sort.Ascending")}>
					{t("Shipments.Table.Columns.Total")}
				</Tooltip>
			),
			dataIndex: "price",
			align: "center",
			showSorterTooltip: false,
			sorter: (a, b) => a.price - b.price,
			render: (price: any) => `$ ${price}`,
		},
		{
			title: t("Shipments.Table.Columns.Supplier"),
			dataIndex: "",
			align: "center",
			render: (rowData: any) =>
				rowData.service_id ? rowData.service_id.split("_")[2] || "-" : "none",
		},
		{
			title: (
				<Tooltip title={t("Table.Tooltip.Sort.Ascending")}>
					{t("Shipments.Table.Columns.Status")}
				</Tooltip>
			),
			dataIndex: "shipment_status",
			align: "center",
			showSorterTooltip: false,
			sorter: (a, b) => a.shipment_status.localeCompare(b.shipment_status),
			filters: uniqueShipmentStatus.map((status) => ({ text: statusMapUI[status], value: status })),
			onFilter: (value, record) => record.shipment_status.indexOf(value as string) === 0,
			render: (status) => (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
					<CancelStatus status={status} />
				</div>
			),
		},
		{
			title: t("Shipments.Table.Columns.PDF"),
			dataIndex: "",
			align: "center",
			render: (rowData: any) => (
				<ShipmentsActions data={{ shipmentData: rowData } as unknown as any} disabledMenu={false} />
			),
		},
	];
};

export const shipmentsColumnV3 = (data: ShipmentV2[]): ColumnsType<ShipmentColumnsType> => {
	const uniqueCarriers = Array.from(new Set(data.map((item) => item.carrier)));
	const uniqueShipmentStatus = Array.from(
		new Set(data.map((item) => item.shipment_status))
	) as ShipmentStatus[];

	const statusMapUI = {
		Activa: t("Shipments.Table.Columns.Status.Active"),
		Entregada: t("Shipments.Table.Columns.Status.Delivered"),
		"En transito": t("Shipments.Table.Columns.Status.InTransit"),
		Excepcion: t("Shipments.Table.Columns.Status.Exception"),
		Solicitada: t("Cancellations.Table.Columns.Status.Solicited"),
		"Cancelada y usada": t("Cancellations.Table.Columns.Status.CancelledButUsed"), // C.y usada/C. but used
		"Saldo abonado": t("Cancellations.Table.Columns.Status.Refunded"), // Abonada/Refunded
		"Cancelada de prov": t("Cancellations.Table.Columns.Status.CancelledFromProvider"),
	};

	return [
		{
			title: (
				<Tooltip title={t("Table.Tooltip.Sort.Ascending")}>
					{t("Shipments.Table.Columns.Date")}
				</Tooltip>
			),
			dataIndex: "created_at",
			align: "center",
			showSorterTooltip: false,
			sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
			render: (createdAt: string) => createdAt,
		},

		{
			title: t("Shipments.Table.Columns.Tracking"),
			dataIndex: "",
			align: "center",
			render: (rowData: any) => (
				<a
					href={linkRastreo(rowData?.carrier, rowData?.tracking)}
					target="_blank"
					rel="noopener noreferrer"
					style={{ color: "black" }}
				>
					{rowData?.tracking}
				</a>
			),
		},
		{
			title: t("Shipments.Table.Columns.Origin"),
			dataIndex: ["origin", "name"],
			align: "center",
			render: (name, rowData: any) => {
				return `${name} ${rowData.origin.last_name || ""}`;
			},
		},
		{
			title: t("Shipments.Table.Columns.Destination"),
			dataIndex: ["destination", "name"],
			align: "center",
			render: (name, rowData: any) => {
				return `${name} ${rowData.destination.last_name || ""}`;
			},
		},
		{
			title: t("Shipments.Table.Columns.Carrier"),
			dataIndex: "carrier",
			align: "center",
			filters: uniqueCarriers.map((carrier) => ({ text: carrier, value: carrier })),
			onFilter: (value, record) => record.carrier.indexOf(value as string) === 0,
			render: (carrier) => getParcelImage(carrier),
		},
		{
			title: (
				<Tooltip title={t("Table.Tooltip.Sort.Ascending")}>
					{t("Shipments.Table.Columns.Total")}
				</Tooltip>
			),
			dataIndex: "price",
			align: "center",
			showSorterTooltip: false,
			sorter: (a, b) => a.price - b.price,
			render: (price: any) => `$ ${price}`,
		},
		{
			title: t("Shipments.Table.Columns.Supplier"),
			dataIndex: "",
			align: "center",
			render: (rowData: any) =>
				rowData.service_id ? rowData.service_id.split("_")[2] || "-" : "none",
		},
		{
			title: (
				<Tooltip title={t("Table.Tooltip.Sort.Ascending")}>
					{t("Shipments.Table.Columns.Status")}
				</Tooltip>
			),
			dataIndex: "shipment_status",
			align: "center",
			showSorterTooltip: false,
			sorter: (a, b) => a.shipment_status.localeCompare(b.shipment_status),
			filters: uniqueShipmentStatus.map((status) => ({ text: statusMapUI[status], value: status })),
			onFilter: (value, record) => record.shipment_status.indexOf(value as string) === 0,
			render: (status) => (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
					<CancelStatus status={status} />
				</div>
			),
		},
		{
			title: t("Shipments.Table.Columns.PDF"),
			dataIndex: "",
			align: "center",
			render: (rowData: any) => (
				<ShipmentsActions data={{ shipmentData: rowData } as unknown as any} disabledMenu={false} />
			),
		},
	];
};

export const shipmentsDownloads = (data: any[], noFormattedDate?: boolean) => {
	return data.map((item) => ({
		"Fecha de creacion": noFormattedDate ? item?.created_at : new Date(item?.created_at).toLocaleDateString(),
		Tracking: item.tracking,
		"Origen/Nombre": item.origin.name,
		"Origen/Compañia": item.origin.company,
		"Origen/Email": item.origin.email,
		"Origen/Telefono": item.origin.phone,
		"Origen/Calle": item.origin.street,
		"Origen/Numero de calle": item.origin.number,
		"Origen/Pais": item.origin.country,
		"Origen/Ciudad": item.origin.city,
		"Origen/Estado": item.origin.state,
		"Origen/Distrito": item.origin.district,
		"Origen/Codigo postal": item.origin.postal_code,
		"Origen/Referencia": item.origin.reference,
		"Destino/Nombre": item.destination.name,
		"Destino/Compañia": item.destination.company,
		"Destino/Email": item.destination.email,
		"Destino/Telefono": item.destination.phone,
		"Destino/Calle": item.destination.street,
		"Destino/Numero de calle": item.destination.number,
		"Destino/Pais": item.destination.country,
		"Destino/Ciudad": item.destination.city,
		"Destino/Estado": item.destination.state,
		"Destino/Distrito": item.destination.district,
		"Destino/Codigo postal": item.destination.postal_code,
		"Destino/Referencia": item.destination.reference,
		Paqueteria: item.carrier,
		Total: Number(item.price),
		Estado: item.shipment_status,
	}));
};

export default ShipmentsV2;
