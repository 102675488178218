import { FC, useContext, useEffect, useState } from "react"
import PermissionsScreen from "./Permissions/PermissionsScreen"
import AdminUsersScreen from './Permissions/AdminUsersScreen'
import { AdminDataContext } from './../../context/adminContext'
import { useGet } from "../../hooks/useGet"
import { getAdminUsers } from "../../services/permission_s"
import { AdminUserI, PermissionStepsObject, UserPermissionsI } from "../../interfaces/permissions.interfaces"

const Permissions: FC = () => {
  const { permissionStep, resetPermissionStepTransition } = useContext(AdminDataContext)
  const { data, isLoading, refetch: refetchUsers } = useGet(getAdminUsers)
  const [permissionsData, setPermissionsData] = useState<AdminUserI | undefined>(data as AdminUserI | undefined)
  const [usersData, setUsersData ] = useState<AdminUserI[] | undefined>(data as undefined | AdminUserI[])

  useEffect(() => {
  }, [permissionStep])

  useEffect(() => {
    changeUsers(data)
  }, [data])

  useEffect(() =>{
    return () =>{
      resetPermissionStepTransition('1) Admin users')
    }
  },[]) //eslint-disable-line

  const changeUserPermissions = (data: any) => {
    setPermissionsData(data)
  }

  const changeUsers = (data: AdminUserI | unknown) => {
    setUsersData(data as AdminUserI[])
  }

  const updatePermissions = (id: string, permissions: UserPermissionsI) => {
    //@ts-ignore
    const updatedUsers = usersData.map((el: AdminUserI) => {

      if (el._id === id) {
        return {
          ...el,
          metadata: {
            ...el.metadata,
            permissions: permissions
          }
        }
      }

      return el

    })
    setUsersData(updatedUsers)
  }

  const PermissionStepComponentsList: PermissionStepsObject = {
    '1) Admin users':
      <AdminUsersScreen
        users={usersData}
        loading={isLoading}
        changeUsers={changeUsers}
        changeUserPermissions={changeUserPermissions}
      />,
    '2) Permissions':
      <PermissionsScreen
        data={permissionsData}
        updatePermissions={updatePermissions}
        refetchUsers={refetchUsers}
      />,
  }

  return (
    <section className="dashboardSection">{PermissionStepComponentsList[permissionStep]}</section>
  )
}

export default Permissions