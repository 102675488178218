// https://github.com/drenvio/api-clientes/commit/7aab9a6b25ae5a218e6e302a3ca7b6791233d449
// https://github.com/drenvio/drenvio-frontend-admin/commit/0a3c89a799374f15ecf2f7718fa022b556a3d051
import { notification } from 'antd';
import { t } from 'i18next';
// import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useContext, useEffect, useState } from 'react';
import {
	RequestStatus,
	UserData,
	UserInterface,
	UserRoles,
} from '../interfaces/app.interfaces';
import {
	resendCode,
	sendCode,
	updateUserData,
	validateCode,
} from '../services/app_s';
import { ApiContext } from './apiContext';
import anime from 'animejs';
import { UserPermissionsI } from '../interfaces/permissions.interfaces';

const userDataInit: UserData = {
	user: 'none',
	balance: 0,
	roles: [UserRoles.common],
	security: {
		senders: [''],
		senders_verified: false,
		identity_verified: true,
	},
	app_country: 'MX',
	metadata: {},
	app_version: 1,
	currency: '',
};

const userPermissionsData: UserPermissionsI = {
	search_users: {
		menu: false,
		actions: [],
	},
	registers: {
		menu: false,
		actions: [],
	},
	sections: {
		menu: false,
		actions: [],
	},
	referrals: {
		menu: false,
		actions: [],
	},
	fulfillment: {
		menu: false,
		actions: [],
	},
	sells: {
		menu: false,
		actions: []
	},
	downloads: {
		menu: false,
		actions: [],
	},
	permissions: {
		menu: false,
		actions: [],
	},
	prices_section: {
		menu: false,
		actions: [],
	},
	stadistics: {
		menu: false,
		actions: [],
	},
	marketing: {
		menu: false,
		actions: [],
	},
	developers: {
		menu: false,
		actions: [],
	},
	finder: {
		menu: false,
		actions: [],
	},
};

interface menuTextProps {
	title: string | undefined;
	description: string | undefined;
}

interface apiData {
	// Indicates the loading state of the User Information Fetch
	appContextIsFetching: boolean;
	// All User Information
	userData: UserData;
	permissions: UserPermissionsI | null;
	// User Information Functions (update/refetch)
	updateAppUserData: (newData: {
		userId: string;
		info: UserData;
	}) => Promise<boolean>;
	updateUserAccount: (newData: UserInterface) => void;
	updatePermissionsData: (newData: UserPermissionsI) => void;
	refetchUserData: (data: boolean) => void;
	// App Code Functions
	sendAppCode: (phone: string) => Promise<boolean>;
	validateAppCode: (phone: string, code: string) => Promise<boolean>;
	resendAppCode: (phone: string) => Promise<boolean>;
	// App State Components Management Functions
	modalIsOpen: boolean;
	toggleModal: () => void;
	sectionSelected: Sections;
	pageTransition: (data: Sections) => void;
	menuSelectedText: menuTextProps;
	setMenuSelectedText: (data: menuTextProps) => void;
	toggleDashboard: boolean;
	setToggleDashboard: any
}

// Empty Context Placeholder Variables
export const AppContext = createContext<apiData>({
	userData: userDataInit,
	permissions: null,
	modalIsOpen: false,
	appContextIsFetching: true,
	updateAppUserData: () => {
		return new Promise(() => {});
	},
	updatePermissionsData: (newData: UserPermissionsI) => {},
	updateUserAccount: (newData: UserInterface) => {},
	sendAppCode: () => new Promise(() => {}),
	validateAppCode: () => new Promise(() => {}),
	resendAppCode: () => new Promise(() => {}),
	toggleModal: () => {},
	pageTransition: () => {},
	sectionSelected: 'Inicio',
	menuSelectedText: {
		title: '',
		description: '',
	},
	setMenuSelectedText: () => {},
	refetchUserData: () => null,
	toggleDashboard: false,
	setToggleDashboard: () => false
});

export const AppContextProvider = (props: any) => {
	// const { logout } = useAuth0();
	const { backendApiCall, serviceIsReady } = useContext(ApiContext);

	// Storage User Information Variables
	const [userData, setUserData] = useState(userDataInit);
	const [permissions, setPermissions] = useState(userPermissionsData);
	const [userDataRefetch, refetchUserData] = useState<boolean>(true);
	const [appContextIsFetching, setappContextIsFetching] = useState(true);

	// App State Mangement Variables
	const [modalIsOpen, setmodalIsOpen] = useState(false);
	const [sectionSelected, setSectionSelected] = useState<Sections>('Inicio');
	const [toggleDashboard, setToggleDashboard] = useState<boolean>(true)
	const [menuSelectedText, setMenuSelectedText] = useState<menuTextProps>({
		title: '',
		description: '',
	});

	useEffect(() => {
		const customInitUserFetch = async () => {
			setappContextIsFetching(true);

			const { data: fetchedUserData } = await backendApiCall({
				method: 'GET',
				endpoint: 'users/info',
			});
			// @ts-ignore
			setUserData(fetchedUserData?.data?.res);
			// @ts-ignore
			setPermissions(fetchedUserData.data.res.metadata.permissions);
			refetchUserData(false);
			setappContextIsFetching(false);
		};

		if (serviceIsReady && userDataRefetch) {
			customInitUserFetch();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serviceIsReady, userDataRefetch]);

	// useEffect(() => {
	//   // Cada que se cargue la información del usuario, hay que revisar si no marco error, verificando si el usuario existe
	//   // Si uno de estos falla hay que hacer log-out, debido a que esta información es necesaria para que la app funcione

	//   if (!appContextIsFetching) {
	//     if (
	//       userData.user === 'none'
	//     ) {
	//       logout({
	//         returnTo: window.location.origin
	//       })
	//     }

	//   }

	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [appContextIsFetching])

	// useEffect(() => {

	//   if (userData.user !== "none") {
	//     if (!userData.roles.includes(UserRoles.admin)) {
	//       logout({
	//         returnTo: window.location.origin
	//       })
	//     }
	//   }
	//   //eslint-disable-next-line
	// }, [userData, appContextIsFetching])

	const updatePermissionsData = (newData: UserPermissionsI) => {
		setPermissions(newData);
	};
	const updateUserAccount = (newData: UserInterface) => {
		setUserData(newData as UserInterface);
	};

	const updateAppUserData = async (newData: {
		userId: string;
		info: UserData;
	}) => {
		setappContextIsFetching(true);
		let returnValue = false;
		await updateUserData(backendApiCall, newData)
			.then((data: any) => {
				if (data.status === 'success') {
					notification.success({
						message: t('User.Services.dataCorrect'),
					});
					refetchUserData(true);
					returnValue = true;
				} else if (data.status === 'error') {
					notification.error({
						message: t('User.Services.dataincorrect'),
					});
					returnValue = false;
				}
				// setappContextIsFetching(false)
			})
			.catch((e: any) => {
				notification.error({
					message: t('User.Services.dataincorrect'),
				});
				console.log(e);
				returnValue = false;
				setappContextIsFetching(false);
			});
		return returnValue;
	};

	//OTP METHODS
	const sendAppCode = async (phone: string) => {
		setappContextIsFetching(true);
		let returnValue = false;
		await sendCode(backendApiCall, phone)
			.then((data) => {
				if (data.status === RequestStatus.Success) {
					notification.success({
						message: t('Sms.Errors.sendCorrect'),
					});
					returnValue = true;
				} else if (data.status === 'error') {
					notification.error({
						message: t('Sms.Errors.send'),
					});
					returnValue = false;
				}
			})
			.catch((e: any) => {
				setappContextIsFetching(false);
				notification.error({
					message: t('Sms.Errors.send'),
				});
				console.log(e);
				return false;
			});

		setappContextIsFetching(false);
		return returnValue;
	};

	const resendAppCode = async (phone: string) => {
		setappContextIsFetching(true);
		let returnValue = false;
		await resendCode(backendApiCall, phone)
			.then((data) => {
				if (data.status === RequestStatus.Success) {
					notification.success({
						message: t('Sms.Errors.sendCorrect'),
					});
					returnValue = true;
				} else if (data.status === 'error') {
					notification.error({
						message: t('Sms.Errors.send'),
					});
					returnValue = false;
				}
			})
			.catch((e: any) => {
				setappContextIsFetching(false);
				notification.error({
					message: t('Sms.Errors.send'),
				});
				console.log(e);
				return false;
			});

		setappContextIsFetching(false);
		return returnValue;
	};

	const validateAppCode = async (phone: string, code: string) => {
		setappContextIsFetching(true);
		let returnValue = false;
		await validateCode(backendApiCall, phone, code)
			.then((data) => {
				if (data.status === RequestStatus.Success) {
					notification.success({
						message: t('Sms.Errors.sendCorrect'),
					});
					returnValue = true;
				} else if (data.status === 'error') {
					notification.error({
						message: t('Sms.Errors.send'),
					});
					returnValue = false;
				}
			})
			.catch((e: any) => {
				setappContextIsFetching(false);
				notification.error({
					message: t('Sms.Errors.Invalid'),
				});
				console.log(e);
				return false;
			});

		setappContextIsFetching(false);
		return returnValue;
	};

	//UI METHODS
	const toggleModal = async () => {
		setmodalIsOpen(!modalIsOpen);
	};

	const pageTransition = (pageToTransition: Sections) => {
		if (pageToTransition === sectionSelected) {
		} else {
			const tl = anime.timeline({
				duration: 1000,
			});

			tl.add({
				targets: '.content-layout',
				translateY: '150px',
				opacity: 0,
			});

			tl.add({
				targets: '.content-layout',
				translateY: '0%',
				opacity: 1,
			});

			tl.add(
				{
					targets: '.headerContainer',
					easing: 'linear',
					duration: 1000,
					opacity: [1, 0, 1],
				},
				'-=2000'
			);

			setTimeout(() => {
				setSectionSelected(pageToTransition);
			}, 500);
		}
	};

	return (
		<AppContext.Provider
			value={{
				userData,
				permissions,
				appContextIsFetching,
				modalIsOpen,
				toggleModal,
				updateAppUserData,
				updateUserAccount,
				updatePermissionsData,
				sendAppCode,
				resendAppCode,
				validateAppCode,
				pageTransition,
				sectionSelected,
				menuSelectedText,
				setMenuSelectedText,
				refetchUserData,
				toggleDashboard,
				setToggleDashboard
			}}
		>
			{props.children}
		</AppContext.Provider>
	);
};
