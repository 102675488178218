import { useContext, useState } from "react";
import { Col, Spin, Typography } from "antd";

import CardContainer from "../../components/Containers/CardContainer";
import TabsContainer from "../../components/Containers/TabsContainer";
import { PricesContainer } from "./PricesContainer";
import { CardPricesTitle } from "./CardPricesTitle";
import { SpecialPricesContainer } from "./SpecialPricesContainer";

import { PricesContext } from "../../context/PricesContext";

const tabStyles = {
  boxShadow: "none",
  overflow: "scroll",
  width: "100%",
  height: "550px",
  paddingRight: 0,
  paddingLeft: 0,
  paddingTop: 0,
};

const cardContainerStyles = {
  maxHeight: "100%",
  overflow: "scroll",
  width: "100%",
};

export const SelectPrice = () => {
  const {
    parcelSelected,
    fetchedServices,
    selectedServiceId,
    setStrategy,
    isParcelDataLoading,
  } = useContext(PricesContext);
  const [isPricesLoading] = useState(false);

  return (
    <Col
      style={{ maxHeight: "100%", overflow: "hidden", paddingBottom: "10px" }}
      xs={24}
      lg={12}
    >
      <CardContainer
        containerStyle={{ ...cardContainerStyles, scrollbarWidth: "none" }}
        cardStyle={{
          width: "100%",
          padding: "40px 60px",
          textAlign: isParcelDataLoading ? "center" : "left",
        }}
      >
        {isParcelDataLoading ? (
          <Spin style={{ textAlign: "center" }} />
        ) : parcelSelected && fetchedServices ? (
          <>
            <CardPricesTitle />
            {isPricesLoading ? (
              <p>Cargando precios</p>
            ) : selectedServiceId ? (
              <TabsContainer
                onChange={setStrategy}
                titlesAndContent={{
                  Precios: {
                    component: (
                      <PricesContainer
                        prices={selectedServiceId?.prices || undefined}
                      />
                    ),
                  },
                  "Precios Especiales": {
                    component: (
                      <SpecialPricesContainer
                        specialPrices={
                          selectedServiceId?.specialPrices || undefined
                        }
                      />
                    ),
                  },
                }}
                style={tabStyles}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <Typography.Text>Sin paqueteria seleccionada</Typography.Text>
        )}
      </CardContainer>
    </Col>
  );
};
