import { Form, Row } from "antd";
import { FC, useEffect, useState } from "react";
import ModalContainer, { modalHeight } from "../../Containers/ModalContainer"
import CardContainer from "../../Containers/CardContainer";
import { shippingFinder } from "../../../services/shipping_s";
import { usePost } from "../../../hooks/usePost";
import { ShipmentV2 } from "../../../interfaces/shipments.interface";
import SearchCard from "./SearchCard";
import SearchInput from "./SearchInput";
import VersionRadio from "./VersionRadio";
import { FinderAccessI } from ".";
import useDevice from "../../../hooks/useDevice";

interface FormData {
    version: string;
    tracking: string;
}

interface Props {
    openModal: boolean;
    openCloseModal: () => void;
    finderAccess: FinderAccessI;
}

const styles = {
    modal: {
        padding: "2rem",
    },
    modalResponsive: {
        padding: "0.2rem",
    },
    rowContainer: {
        justifyContent: "center",
        padding: '0 2rem 2rem 2rem',
    },
    rowContainerMobile:{
        justifyContent: "center",
        padding: '0',
    },
    maxWidth: {
        width: "100%",
    },
    cardStyleOutline:{
        boxShadow: "none",
        border: "1px solid #fff",
        padding: 0,
    }
}

const ModalView: FC<Props> = ({ openModal, openCloseModal, finderAccess }) => {
    const [form] = Form.useForm();
    const { isLoading, fetchData } = usePost(shippingFinder)
    const [firstSearch, setFirstSearch] = useState(false)
    const [isSuccessSearch, setIsSuccessSearch] = useState<null | boolean>(null)
    const [shipment, setShipment] = useState<null | ShipmentV2>(null)
    const { isMobile } = useDevice()

    const onFinish = async (data: FormData) => {
        setFirstSearch(true)
        try {
            const response = await fetchData({
                version: data.version,
                tracking: data.tracking
            })

            //@ts-ignore
            if (!response.data) {
                setIsSuccessSearch(false)
            } else {
                setShipment(response.data)
                setIsSuccessSearch(true)
            }

        } catch (error) {
            console.log(error)
            setIsSuccessSearch(false)
        }
    }

    useEffect(() => {
        form.setFieldsValue({
            version: "v2"
        })
        //eslint-disable-next-line
    }, [])

    useEffect(() => {

    }, [shipment])

    useEffect(() => {
        if (!openModal) {
            setFirstSearch(false)
            setIsSuccessSearch(null)
            setShipment(null)
            form.setFieldsValue({
                version: "v2",
                tracking: ""
            })
        }
        //eslint-disable-next-line
    }, [openModal])

    return (
        <ModalContainer
            visible={openModal}
            title="Buscar Envíos"
            customWidth={"70vw"}
            height={modalHeight.EXTRABIG}
            onCancel={openCloseModal}
            footer={[]}
            style={!isMobile ? styles.modal : styles.modalResponsive}
        >
            <Row gutter={[20, 20]} style={!isMobile ? styles.rowContainer : styles.rowContainerMobile}>
                <CardContainer containerStyle={styles.maxWidth} cardStyle={styles.cardStyleOutline} >
                    <Form
                        form={form}
                        onFinish={onFinish}
                        style={{width: "100%", margin: 0, padding: 0}}
                    >
                        <VersionRadio />
                        <SearchInput finderAccess={finderAccess} />
                    </Form>
                </CardContainer>

                {
                    firstSearch && (
                        <SearchCard
                            isLoading={isLoading}
                            openModal={openModal}
                            isSuccessSearch={isSuccessSearch as boolean}
                            shipment={shipment as ShipmentV2}
                            finderAccess={finderAccess}
                        />

                    )
                }
            </Row>
        </ModalContainer >
    )
}

export default ModalView