import { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { Col, DatePicker, Row } from "antd";
import { Fragment } from "react";
import { usePost } from "../../../../hooks/usePost";
import { getMostUsedDestinations } from "../../../../services/admin_s";
import CardContainer from "../../../../components/Containers/CardContainer";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { CountriesFlag } from "../../../../components/CountriesFlag";
import { states } from "../../../../constants/statesMx";

export interface FilterRequest {
  "origin.country"?: string;
  created_at?: {
    $gte: string;
    $lte: string;
  };
}
interface DataMostUsedDestinations {
  data: {
    states: {
      country: string;
      estado: string;
      total: number;
    }[];
  };
}

export const MostUsedDestination = ({ country }: { country: string }) => {
  const [ranges, setRanges] = useState<moment.Moment[]>([
    moment().startOf("month"),
    moment().endOf("day"),
  ]);
  const {
    fetchData,
    data: filteredData,
    isLoading: isLoadingMostUsedDestinations,
  } = usePost<FilterRequest, DataMostUsedDestinations>(getMostUsedDestinations);

  const getData = useCallback(async () => {
    await fetchData({
      "origin.country": country,
      created_at: {
        $gte: ranges[0].startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        $lte: ranges[1].endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
    });
  }, [fetchData, country, ranges]);

  useEffect(() => {
    getData();
  }, [ranges]); //eslint-disable-line 

  const columnLabel = (country: string) => {
    return country === "CO" || country === "PE" ? "Departamento" : "Estado";
  };

  return (
    <CardContainer
      cardStyle={{ marginTop: "20px" }}
      titleStyle={{ placeItems: 'center' }}
      title="Destinos más usados"
    >
      <DatePicker.RangePicker
        onChange={(e: any) => {
          setRanges([moment(e[0]).startOf("day"), moment(e[1]).endOf("day")]);
        }}
        placeholder={["Fecha inicial", "Fecha final"]}
        value={ranges as any}
        style={{ width: "100%" }}
      />
      <hr />
      <Row>
        <Col
          span={5}
          style={{ margin: "auto", fontWeight: "bold", textAlign: "center" }}
        >
          <p>País</p>
        </Col>
        <Col span={10} style={{ margin: "auto", fontWeight: "bold", textAlign: "center" }}>
          <p>
            {filteredData?.data.states[0]?.country
              ? columnLabel(filteredData.data.states[0]?.country)
              : ""}
          </p>
        </Col>
        <Col
          span={5}
          style={{ margin: "auto", fontWeight: "bold", textAlign: "center" }}
        >
          <p>Total</p>
        </Col>
      </Row>
      <Row
        align="middle"
        style={{
          maxHeight: "36vh",
          overflowY: "auto",
        }}
      >
        {isLoadingMostUsedDestinations ? (
          <LoadingAnimation animationType="small" />
        ) : filteredData?.data.states === undefined ||
          filteredData?.data.states?.length === 0 ? (
          <p
            style={{
              fontWeight: "bold",
              margin: "50px auto",
              color: "#0008",
            }}
          >
            No hay datos para mostrar.
          </p>
        ) : (
          filteredData?.data.states?.map((item: any) => {
            return (
              <Fragment key={`${item.estado}${item.country}`}>
                <Col
                  span={5}
                  style={{ margin: "5px auto", textAlign: "center" }}
                >
                  <CountriesFlag country={item.country || "MX"} />
                </Col>
                <Col
                  span={10}
                  style={{ margin: "5px auto", textAlign: "center" }}
                >
                  {states[item.country][item.estado] || ""}
                </Col>
                <Col
                  span={5}
                  style={{
                    margin: "5px auto",
                    textAlign: "center",
                    color: "#2D1B6E",
                    fontWeight: "bold",
                  }}
                >
                  {item.total.toLocaleString() || ""}
                </Col>
              </Fragment>
            );
          })
        )}
      </Row>
    </CardContainer>
  );
};
