import { Switch, Typography } from "antd";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";

interface props {
    title: string;
    active: boolean;
    isApi: boolean;
    onChange: (name: string, key: string, value: boolean) => void;
}

interface Styles {
    textBold: React.CSSProperties;
    switchItem: React.CSSProperties;
}

const styles: Styles = {
    textBold: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '0.9rem',
        textAlign: 'end'
    },
    switchItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        gap: '1rem'
    }
}

const CarrierSwitch: FC<props> = ({ title, active, isApi, onChange }) => {
    const [actived, setActived] = useState(false)

    useEffect(() =>{
        setActived(active)
    },[active])

    return (
        <div style={styles.switchItem}>
            <Typography style={styles.textBold}>
                {t(`Quote.Service.Carrier.${title}`)}
            </Typography>
            <Switch 
                checked={actived} 
                onChange={async (e) =>{
                    setActived(e)
                    await onChange(title, isApi ? 'ignore_api': 'active_admin', isApi ? !e : e)
                }}
            />
        </div>
    )
}

export default CarrierSwitch