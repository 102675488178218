import { Modal, Table } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { shipmentColumn } from "./schemaColumns"
import { Shipment } from "../../../../interfaces/partner.interface"

const ShipmentDetailsModal: React.FC<{ 
    shipments: any[],
    shipmentDetailModal: boolean, 
    toggleShipmentDetailModal: () => void 
}>= ({ 
    shipments,
    shipmentDetailModal,
    toggleShipmentDetailModal
})=> {  
    const { t } = useTranslation()
    const [isMobile, setIsMobile] = useState(false)
    const [rowsPerPages, setRowPerPages] = useState(20)
    const [currentPage, setCurrentPage] = useState(1)

    const handleTableChange = (pagination: { page: number, pageSize: number }) => {
        const { page, pageSize } = pagination
        setCurrentPage(page)
        setRowPerPages(pageSize)
    }

    useEffect(() => {
        const trackingScreen = ()=> {
            if (window.innerWidth <= 1045) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        }

        trackingScreen()
        window.addEventListener('resize', trackingScreen)

        return () => {
            window.removeEventListener('resize', trackingScreen)
        }
    }, [])

    const sortedShipments = [...shipments].sort((a: Shipment, b: Shipment): number => {
        const dateA = new Date(a.created_at).getTime() 
        const dateB = new Date(b.created_at).getTime()
        return dateB - dateA
    })

    return (
        <Modal
            title={t("Withdrawals.Table.ShipmentsDetailsModal.Title")}
            width={isMobile ? '95%' : '90%'}
            visible={shipmentDetailModal}
            onCancel={toggleShipmentDetailModal}        
            bodyStyle={{ padding: '0 20px' }}
            footer={false}
        >
            <Table 
                size="small"
                columns={shipmentColumn(shipments)}
                dataSource={sortedShipments}
                scroll={{ x: true }}
                style={{ padding: '20px 0'}}
                title={() => (
                    <div style={{ paddingBottom: '10px'}}>
                        <p>{t('Withdrawals.Table.ShipmentsDetailsModal.SubTitle')}</p>
                    </div>
                )}
                pagination={{
                    current: currentPage,
                    pageSize: rowsPerPages,
                    total: shipments?.length,
                    onChange: (page, pageSize) => handleTableChange({ page, pageSize })
                }}
            />
        </Modal>
    )
}

export default ShipmentDetailsModal