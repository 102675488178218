// Generic Imports
import React, { useEffect, useState } from "react"
import TabsContainer from "./../../../../components/Containers/TabsContainer"

// Table Components
import Profiles from "./Profiles"
import Referrals from "./Referrals"
import usePermission from "../../../../hooks/usePermission"
import {
  ActionsReferrals,
  PermissionViews,
} from "../../../../interfaces/permissions.interfaces"
import Withdrawals from "./Withdrawals"
import PendingCommissions from "./PendingCommissions"
import PendingWithdrawalsByPlatform from "./PendingWithdrawalsByPlatform"

export interface RecordAccessI {
  referralProfiles: boolean
  referrals: boolean
  withdrawals: boolean
  commissions: boolean
  pendingWithdrawalByPlatform: boolean 
}

const Partners: React.FC = () => {
  const { hasAccess } = usePermission()
  const [tabContent, setTabContent] = useState({})
  const [tabAccess] = useState<RecordAccessI>({
    referralProfiles: hasAccess(ActionsReferrals.view_profiles, PermissionViews.referrals),
    referrals: hasAccess(ActionsReferrals.view_referrals, PermissionViews.referrals),
    withdrawals: hasAccess(ActionsReferrals.view_withdrawals, PermissionViews.referrals),
    commissions: hasAccess(ActionsReferrals.view_commissions, PermissionViews.referrals),
    pendingWithdrawalByPlatform: hasAccess(ActionsReferrals.view_pending_withdrawal_by_platform, PermissionViews.referrals),
  })

  const assignTabs = () => {
    let tabObject = {}

    if (tabAccess.referralProfiles) {
      tabObject = {
        ...tabObject,
        Perfiles: {
          component: <Profiles />,
          description: "Perfiles",
        },
      }
    }
    
    if (tabAccess.referrals) {
      tabObject = {
        ...tabObject,
        Referidos: {
            component: <Referrals />,
            description: "Referidos",
        },
      }
    }
    
    if (tabAccess.withdrawals) {
      tabObject = {
        ...tabObject,
        Retiros: {
            component: <Withdrawals />,
            description: "Retiros",
        },
      }
    }

    if (tabAccess.commissions){
      tabObject = {
        ...tabObject,
        Comisiones: {
            component: <PendingCommissions />,
            description: "Comisiones",
        },
      }
    }  
    
    if (tabAccess.pendingWithdrawalByPlatform){
      tabObject = {
        ...tabObject,
        'Retiros por aprobar': {
            component: <PendingWithdrawalsByPlatform />,
            description: "Retiros por aprobar",
        },
      }
    }  

    setTabContent(tabObject)
  }

  useEffect(() => {
    assignTabs()
    //eslint-disable-next-line
  }, [tabAccess])

  return (
    <section className="dashboardSection">
      <TabsContainer titlesAndContent={tabContent} />
    </section>
  )
}

export default Partners
