import { Skeleton } from "antd";

interface Styles {
    container: React.CSSProperties;
    paragraph:  React.CSSProperties;
    input: React.CSSProperties;
}

const styles: Styles = {
    container: { 
        display: 'flex', 
        alignItems: 'center', 
        width: '100%', 
        height: '5rem', 
        padding: '20px', 
        border: '1px solid #E6E6E7', 
        borderRadius: '6px', 
        gap: '2rem' 
    },
    paragraph: { 
        display: 'flex',
        alignItems: 'center', 
        flexDirection: 'column', 
        flex: 1 
    },
    input: { 
        width: 120 
    }
}


const UserSkeleton = () => (
    <section style={styles.container}>
        <Skeleton.Avatar size={52} active={true} />
        <div style={styles.paragraph}>
            <Skeleton active paragraph={{ rows: 1 }} title={true} />
        </div>
        <Skeleton.Input style={styles.input} active={true} />
    </section>
)
export default UserSkeleton