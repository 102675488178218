import { Layout, Menu } from "antd";
import { FC, Fragment, useContext } from "react";
import { GlobalOutlined } from "@ant-design/icons";
import ResponsiveLogo from "../ResponsiveLogo";
import { UserMenu } from "../../../utils/Arrays";
import { QuoteDataContext } from "../../../utils/hooks/quoteContext";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/appContext";
import { userhaveMarketingPermissions, userhavePermissions } from "../../../utils/AppLayout";
const { Sider } = Layout;

interface MobileSideNavProps {
	setPage: (pageToTransition: Sections) => void;
	collapsedState: boolean;
	setCollapsedState: (data: boolean) => void;
}

const MobileSideNav: FC<MobileSideNavProps> = ({ setPage, collapsedState, setCollapsedState }) => {
	const { setCurrentLangCurrency, currentLangCurrency } = useContext(QuoteDataContext);
	const { userData } = useContext(AppContext);
	const { i18n } = useTranslation();

	const createLangOption = (langAndCurrency: LanguageCurrencies, flagEmojiString: string) => {
		return (
			<option style={{ color: "black" }} value={langAndCurrency}>
				{langAndCurrency} {flagEmojiString}
			</option>
		);
	};

	return (
		<>
			{collapsedState && (
				<div className="mobile-sidebar-overlay" onClick={() => setCollapsedState(false)}></div>
			)}
			<Sider className={`${collapsedState ? "active" : ""} mobile-sidebar`} width={260}>
				<ResponsiveLogo collapse={false} collapseAction={() => null} />
				<Menu
					style={{
						display: "flex",
						flexDirection: "column",
						flex: 1,
					}}
					mode="inline"
					defaultSelectedKeys={["Inicio"]}
				>
					{Object.values(UserMenu).map((section, index: number) => {
						if (userhavePermissions(section.identifier, userData)) {
							return (
								<Fragment key={index}>
									{!section.type && (
										<Menu.Item
											key={section.identifier}
											icon={section.icon}
											onClick={() => {
												setPage(section.identifier);
											}}
										>
											{section.title}
										</Menu.Item>
									)}
									{section.type === "subMenu" && section.children && (
										<Menu.SubMenu
											key={section.identifier}
											title={section.title}
											icon={section.icon}
										>
											{section.children.map((item: any, idx: number) => {
												if (userhaveMarketingPermissions(item.identifier, userData)) {
													return (
														<Menu.Item
															key={idx + Math.random()}
															icon={item.icon}
															onClick={() => {
																setPage(item.identifier);
															}}
														>
															{item.title}
														</Menu.Item>
													);
												} else {
													return null;
												}
											})}
										</Menu.SubMenu>
									)}
								</Fragment>
							);
						} else {
							return null;
						}
					})}
					<Menu.Item style={{ marginTop: "auto" }} key="languages" icon={<GlobalOutlined />}>
						<select
							onChange={(e) => {
								const lang = e.currentTarget.value.split("-")[0].trim();
								setCurrentLangCurrency(e.currentTarget.value);
								console.log(currentLangCurrency);
								i18n.changeLanguage(lang);
							}}
							defaultValue="español - mxn"
							style={{
								backgroundColor: "transparent",
								width: "100%",
								height: "100%",
								outline: "none",
								border: "none",
							}}
						>
							{createLangOption("english - usd", "🇺🇸")}
							{createLangOption("español - mxn", "🇲🇽")}
							{createLangOption("español - cop", "🇨🇴")}
						</select>
					</Menu.Item>
				</Menu>
			</Sider>
		</>
	);
};

export default MobileSideNav;
