import { Button, Col, Row } from "antd"
import CardContainer from "../../Containers/CardContainer"
import InputContainer from "../../Containers/InputContainer"
import { SearchOutlined } from '@ant-design/icons';
import { FC, useEffect } from "react";
import { FinderAccessI } from ".";

const styles = {
    trackingInput: {
        display: "inline-flex",
        flex: "1",
        height: '3rem',
        borderRadius: "12px"
    },
    buttonContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        marginBottom: "0.8rem",
        padding: 0
    },
    button: {
        backgroundColor: "#6D71F6",
        display: 'grid',
        placeItems: 'center',
        marginTop: "0.65rem",
        height: '3rem',
        marginLeft: "0.5rem",
        borderRadius: "6px",
        fontSize: "1.1rem"
    },
    cardStyleContainer: {
        margin: "1rem",
        padding: "16px"
    },
    cardStyleOutline: {
        boxShadow: "none",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        backgroundcolor: "red"
    }
}

interface Props {
    finderAccess: FinderAccessI;
}

const SearchInput: FC<Props> = ({ finderAccess }) => {
    useEffect(() => {
        console.log(finderAccess)
    }, [finderAccess])
    return (
        <>
            {
                finderAccess.shipments_finder ? (
                    <CardContainer cardStyle={styles.cardStyleContainer} >
                        <Row>
                            <Col span={24} style={{ display: "flex", placeItems: "center", flexDirection: "row" }} >
                                <InputContainer
                                    type="text"
                                    valueContainerName="tracking"
                                    placeholder="Escriba un número de rastreo"
                                    style={styles.trackingInput}
                                />
                                <div style={styles.buttonContent}>
                                    <Button
                                        style={styles.button}
                                        htmlType='submit'
                                    >
                                        <SearchOutlined style={{ color: "#FFF" }} />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </CardContainer>
                ) : (
                    <CardContainer>
                        <p style={{ fontWeight: "bold", textAlign: "center", margin: 0 }}>
                            No tienes los permisos suficientes para buscar usuarios, contacte con un usuario administrador.
                        </p>
                    </CardContainer>
                )

            }
        </>

    )
}

export default SearchInput