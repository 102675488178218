import { FC } from "react";
import { Col, Row } from "antd";
import ModalContainer, { modalSizes } from "../../../../../components/Containers/ModalContainer"
import { DataTypeT, dataToShowI } from "../../interfaces";
import ShipmentsByUser from "../../../Records/ShipmentsByUser";
import { modalHeight } from "../../../Data/Partners/ModalContainerPicker";
import MovementsByUser from "../../../Records/MovementsByUser";
import { dataTypeModalTitle } from "../../utils";

interface props {
    type: DataTypeT;
    visible: boolean;
    userFound: dataToShowI;
    manageModal: () => void;
}

interface Styles {
    container: React.CSSProperties;
}

const styles: Styles = {
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '2rem',
        width: '100% !important',
        overflow: 'auto'
    }
}


const TablesModal: FC<props> = ({ visible, manageModal, userFound, type }) => {

    return (
        <ModalContainer
            title={dataTypeModalTitle(type)}
            visible={visible}
            onCancel={manageModal}
            width={modalSizes.BIG}
            height={modalHeight.EXTRABIG}
            customWidth={'90%'}
            footer={<></>}
        >
            <Row style={styles.container}>
                <Col span={24} style={{overflow: "auto"}}>
                    {
                        type === 'shipments' ? (
                            <ShipmentsByUser user={userFound?.user} />
                        ) :  type === 'movements' ? (
                            <MovementsByUser user={userFound?.user} />
                        ) : ''
                    }

                </Col>

            </Row>
        </ModalContainer>
    )
}

export default TablesModal