import ErrorCatch from '../utils/errorCatch/ErrorCatch'
import { ApiContextProvider } from './apiContext'
import { AppContextProvider } from './appContext'

const GlobalContextProvider = (props: any) => {
  return (
    <ApiContextProvider>
      <AppContextProvider>
          <ErrorCatch>
            {props.children}
          </ErrorCatch>
      </AppContextProvider>
    </ApiContextProvider>
  )
}

export default GlobalContextProvider
