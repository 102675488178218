import { Row, Button, Spin, Col, Typography, notification, Form } from "antd"
import CardContainer from "../../../components/Containers/CardContainer"
import ModalContainer, { modalSizes, modalHeight } from "../../../components/Containers/ModalContainer"
import { FC, useEffect, useState } from "react"
import InputContainer from "../../../components/Containers/InputContainer"
import { usePost } from "../../../hooks/usePost"
import { uploadPDFUrl } from "../../../services/configuration_s"
import { getStoreImg } from "./config"
import { platformsList } from './config'
import TextArea from "antd/lib/input/TextArea"
import StoreHeader from "./StoreHeader"
import { Platforms } from "../../../interfaces/integrations.interface"

const styles = {
    centerButton: { display: 'flex', alignItems: 'center' },
    popconfirm: { color: 'red' },
    spin: { width: "100%" }
}

interface props {
    isPDFModalOpen: boolean;
    openPDFModal: () => void;
}

const PDFModal: FC<props> = ({
    openPDFModal,
    isPDFModalOpen,
}) => {
    const [type, setType] = useState(Platforms.WooCommerce)
    const [imgStore, setImgStore] = useState(getStoreImg(Platforms.WooCommerce))
    const [oldPDF, setOldPDF] = useState<null | string>(null)
    const [PDFUrl, setPDFUrl] = useState<null | string>(null)
    const { fetchData: fetchCreate, isLoading: isLoadingCreate } = usePost(uploadPDFUrl)
    const [form] = Form.useForm();

    const resetForm = () => {
        form.resetFields()
        setType(Platforms.WooCommerce)
        setOldPDF(null)
        setPDFUrl(null)
        form.setFieldsValue({
            type: Platforms.WooCommerce
        })
    }

    const onFinish = async () => {
        try {
            const response = await fetchCreate({
                config: {
                    url: oldPDF,
                    type
                }
            })
            if (!response.error) {
                //@ts-ignore
                setPDFUrl(response.data.url)
                notification.success({
                    message:
                        'Manual guardado exitosamente'
                })
            } else {
                notification.error({
                    message:
                        'Error al guardar el manual'
                })
            }
        } catch (error) {
            console.log(error)
            notification.error({
                message:
                    'Error en guardar el manual'
            })
        }
    }


    useEffect(() => {
        if (type) {
            setImgStore(getStoreImg(type))
        }
        //eslint-disable-next-line
    }, [type])

    useEffect(() => {
        form.setFieldsValue({
            type: Platforms.WooCommerce
        })
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (PDFUrl !== null) {
            form.setFieldsValue({
                result_pdf: PDFUrl
            })
        }
        //eslint-disable-next-line
    }, [PDFUrl])

    return (
        <ModalContainer
            size={modalSizes.BIG}
            height={modalHeight.MID}
            title={'Sube tu PDF'}
            visible={isPDFModalOpen}
            onCancel={openPDFModal}
            footer={
                <Row justify="end">
                    <Button onClick={openPDFModal}>
                        Cerrar
                    </Button>
                </Row>
            }
        >
            <Spin spinning={isLoadingCreate} style={styles.spin}>
                <Row gutter={[20, 20]} style={{ width: '100%' }}>
                    <Form form={form} style={{ width: '100%' }}>
                        <StoreHeader img={imgStore} />
                        <br />
                        <Col span={24}>
                            <CardContainer>
                                {
                                    PDFUrl !== null ? (
                                        <Col span={24} style={{ margin: '0 1rem' }}>
                                            <Typography style={{ fontSize: '1.2rem', fontWeight: "bold" }}>
                                                Proceso terminado
                                            </Typography>
                                            <Typography style={{ fontSize: '1rem' }}>
                                                Copia y pega la nueva dirección url en la configuración de la integración solicitada.
                                            </Typography>
                                            <br />
                                            <Form.Item name='result_pdf'>
                                                <TextArea
                                                    rows={5}
                                                    style={{ marginTop: '10px' }}
                                                    placeholder='copia y pega la nueva dirección URL'
                                                />
                                            </Form.Item>
                                            <br />
                                            <InputContainer
                                                type="submitButton"
                                                title="Reiniciar Formulario"
                                                onClick={resetForm}
                                            />
                                        </Col>
                                    ) : (
                                        <Col span={24} style={{ margin: '0 1rem' }}>
                                            <Typography style={{ fontSize: '1rem', fontWeight: "bold" }}>Selecciona una integración</Typography>
                                            <InputContainer
                                                type="select"
                                                placeholder="Selecciona el tipo de integración"
                                                valueContainerName="type"
                                                onChange={(e) => setType(e)}
                                                optionsList={platformsList}
                                            />
                                            <br />
                                            <Typography style={{ fontSize: '1rem', fontWeight: "bold" }}>
                                                Sube tu PDF y obten tu URL
                                            </Typography>
                                            <br />
                                            <Form.Item name='pdf_initial'>
                                                <TextArea
                                                    rows={5}
                                                    style={{ marginTop: '10px' }}
                                                    onChange={(e) => setOldPDF(e.target.value)}
                                                    placeholder='Escribe la url del manual de integración'
                                                />
                                            </Form.Item>
                                            <br />
                                            <InputContainer
                                                type="submitButton"
                                                title="Generar URL"
                                                onClick={onFinish}
                                            />
                                        </Col>
                                    )
                                }

                            </CardContainer>
                        </Col>
                    </Form>
                </Row>
            </Spin>
        </ModalContainer>
    )
}

export default PDFModal