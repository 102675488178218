import { useGet } from '../../hooks/useGet'
import { FileAddOutlined } from "@ant-design/icons";
import { useEffect, useState } from 'react'
import { Col, Row, Typography } from 'antd'
import CardContainer from '../../components/Containers/CardContainer'
import { getConfigIntegrations } from '../../services/configuration_s'
import { adminLogoStyles, getStoreImg } from './Integrations/config';
import StoreModal from './Integrations/StoreModal';
import AddModal from './Integrations/AddModal';
import PDFModal from './Integrations/PDFModal';


const styles = {
    title: {
        fontWeight: 'bold',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center'
    },
    cardContainer: {
        height: "7rem",
        padding: '1rem 0',
        cursor: 'pointer'
    },
    col: {
        display: 'grid',
        placeItems: 'center',
        marginTop: '0.4rem'
    }
}

const Integrations = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isAddModalOpen, setIsAddModalOpen] = useState(false)
    const [isPDFModalOpen, setIsPDFModalOpen] = useState(false)
    const [currentStore, setCurrentStore] = useState<any>(null)
    const [currentStep, setcurrentStep] = useState(0)
    const [editMode, setEditMode] = useState(false)
    const { data, refetch } = useGet<any[]>(getConfigIntegrations)

    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const openAddModal = () => {
        setIsAddModalOpen(!isAddModalOpen);
    };

    const openPDFModal = () => {
        setIsPDFModalOpen(!isPDFModalOpen);
    };

    const changeEditMode = () => {
        setEditMode(!editMode)
        setcurrentStep(0)
    }

    const changeCurrentStore = (store: any) => {
        setCurrentStore(store)
    }

    const changeStep = (step: any) => {
        setcurrentStep(step)
    }

    useEffect(() => {
        console.log(data)
    }, [data])

    return (
        <>
            <Row gutter={[20, 40]}>
                <Col span={24}>
                    <CardContainer
                        title={'Configura la información de las integraciones que serán visibles en la plataforma.'}
                        cardStyle={{ margin: '1.5rem 0' }}
                    />
                </Col>
            </Row>
            <Row gutter={[20, 40]}>
                <Col sm={24} md={12} lg={8} xl={6} xxl={4} >
                    <CardContainer
                        onClick={openAddModal}
                        hoverEffect>
                        <Col span={24} style={{ height: '4.5rem' }}>
                            <Typography style={{
                                fontWeight: 'bold',
                                display: 'flex',
                                textAlign: 'center',
                                justifyContent: 'center'
                            }}>
                                Agregar integración
                            </Typography>
                            <Col
                                span={24}
                                style={styles.col}>
                                <FileAddOutlined
                                    style={{ fontSize: '2rem' }} />
                            </Col>

                        </Col>
                    </CardContainer>
                </Col>
                <Col sm={24} md={12} lg={8} xl={6} xxl={4} >
                    <CardContainer
                        onClick={openPDFModal}
                        hoverEffect>
                        <Col span={24} style={{ height: '4.5rem' }}>
                            <Typography style={{
                                fontWeight: 'bold',
                                display: 'flex',
                                textAlign: 'center',
                                justifyContent: 'center'
                            }}>
                                Obtener URL PDF
                            </Typography>
                            <Col
                                span={24}
                                style={styles.col}>
                                <FileAddOutlined
                                    style={{ fontSize: '2rem' }} />
                            </Col>

                        </Col>
                    </CardContainer>
                </Col>
                {
                    data && data.length > 0 && data.map(store => (
                        <Col sm={24} md={12} lg={8} xl={6} xxl={4} >
                            <CardContainer
                                cardStyle={styles.cardContainer}
                                onClick={() => {
                                    setCurrentStore(store)
                                    openModal()
                                }}
                                iconOrImg={
                                    <img
                                        alt={store.label}
                                        src={getStoreImg(store.type)}
                                        style={adminLogoStyles(store.type)}
                                    />
                                }
                            />
                        </Col>
                    ))
                }
            </Row>
            <StoreModal
                isModalOpen={isModalOpen}
                openModal={openModal}
                editMode={editMode}
                currentStore={currentStore}
                currentStep={currentStep}
                changeStep={changeStep}
                refetch={refetch}
                changeEditMode={changeEditMode}
                changeCurrentStore={changeCurrentStore}
            />
            <AddModal
                openAddModal={openAddModal}
                isAddModalOpen={isAddModalOpen}
                refetch={refetch}
            />
            <PDFModal
                openPDFModal={openPDFModal}
                isPDFModalOpen={isPDFModalOpen}
            />
        </>
    )
}

export default Integrations
