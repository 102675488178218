import { Form, Modal, notification, Spin, Tooltip, Typography } from "antd";
import { useState, useContext, useEffect } from "react";
import ReactJson from "react-json-view";
import { Table, TColumnTable } from "../../components/Common/Table";
import InputContainer from "../../components/Containers/InputContainer";
import ModalContainer from "../../components/Containers/ModalContainer";
// import { ApiContext } from "../../context/apiContext";
import moment from "moment";
import {
  AddCircleOutline,
  DeleteOutline,
  InfoOutlined,
  RefreshOutlined,
} from "@material-ui/icons";
import { usePost } from "../../hooks/usePost";
import {
  customerTokenAdd,
  customerTokenUpdate,
  deleteApiClient,
  getUserTokens,
  updateApiClientAccess,
} from "../../services/admin_s";
import { createRef } from "react";
import { useGet } from "../../hooks/useGet";
import { AdminDataContext } from "../../context/adminContext";

const TokenUsers = () => {
  // const { data: allLogs } = useGet(getDrEnvioLogs)
  const [visible, setVisible] = useState(false);
  const { country } = useContext(AdminDataContext)
  const [logs, setLogs] = useState<{} | any>();
  const [type, setType] = useState<"prod" | "sand" | "add" | "update">("prod");
  const [currentUserId, setCurrentUserId] = useState<string>('')
  // const { backendURL, userToken } = useContext(ApiContext);
  const { fetchData: deleteRequest } = usePost(deleteApiClient);
  const { fetchData: addApiClient } = usePost(customerTokenAdd);
  const { fetchData: addCustomerToken } = usePost(customerTokenUpdate);
  const { fetchData: updateAccess, isLoading: isLoadingAccess } = usePost(updateApiClientAccess);
  const { data, isLoading, refetch } = useGet(getUserTokens(country));
  const [form] = Form.useForm();

  const tableRef = createRef();

  const ViewPayload = (e: any, type: "prod" | "sand" | "add" | "update") => {
    setVisible(true);
    setLogs(e);
    setType(type);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const refreshTable = () => {
    //@ts-ignore
    refetch();
  };

  const selectModalType = (type: "prod" | "sand" | "add" | "update") => {
    switch (type) {
      case "prod":
        return {
          title: "Token de producción",
          component: logs ? <ReactJson src={logs} /> : "Data no disponible",
        };
      case "sand":
        return {
          title: "Token de prueba",
          component: logs ? <ReactJson src={logs} /> : "Data no disponible",
        };
      case "add":
        return {
          title: "Añadir cliente",
          component: <AddNewClient />,
        };
      case "update":
        return {
          title: "Agregar token",
          component: <UpdateClient user={logs} />,
        };
      default:
        break;
    }
  };

  const AddNewClient = () => {
    const [form] = Form.useForm();

    const onFinish = async (e: object) => {
      console.log(e);
      const response = await addApiClient(e);
      console.log(response);
      if (response.data) {
        notification.success({ message: "Cliente api añadido" });
        refreshTable();
        setVisible(false);
        form.resetFields();
      } else {
        notification.error({
          message: "El cliente no ha podido ser agregado.",
        });
      }
    };

    return (
      <Form form={form} onFinish={onFinish}>
        <InputContainer
          required
          type="email"
          valueContainerName="email"
          placeholder="Correo eléctronico"
        />
        <InputContainer
          required
          type="text"
          valueContainerName="site"
          placeholder="Sitio Web"
        />
        <InputContainer type="submitButton" title="Añadir Cliente" />
      </Form>
    );
  };

  const UpdateClient = ({ user }: any) => {
    const [form] = Form.useForm();

    const onFinish = async (e: any) => {
      const request = {
        user: user,
        type: e?.type,
      };
      const response = await addCustomerToken(request);
      console.log(response);
      console.log(request);
      if (response.data) {
        notification.success({ message: "Token añadido" });
        refreshTable();
        setVisible(false);
        form.resetFields();
      } else {
        notification.error({ message: "El token no ha podido ser agregado." });
      }
    };

    return (
      <Form form={form} onFinish={onFinish}>
        <InputContainer
          required
          type="select"
          valueContainerName="type"
          placeholder="Tipo de token"
          optionsList={[
            { label: "Producción", value: "PRODUCTION" },
            { label: "Sandbox", value: "SANDBOX" },
          ]}
        />
        <InputContainer type="submitButton" title="Agregar Token" />
      </Form>
    );
  };

  const DeleteApiClient = async (userId: string) => {
    Modal.confirm({
      title: "Confirmar",
      content:
        "Seguro que desea eliminar a este usuario? los tokens de prueba y producción se perderán de nuestro registro, pero seguirán funcionando.",
      cancelText: "Cancelar",
      async onOk() {
        const { error } = await deleteRequest({ user: userId });
        if (!error) {
          refreshTable();
          notification.success({ message: "Cliente api eliminado" });
        } else {
          notification.error({
            message: "No se ha eliminado, intenta nuevamente",
          });
        }
      },
    });
  };

  const columns: TColumnTable = [
    {
      title: "Fecha",
      field: "created_at",
      render: (rowData: any) => {
        return rowData.created_at && moment(rowData.created_at).format("LL");
      },
    },
    {
      title: "Usuario",
      field: "name",
      render: (rowData: any) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <Tooltip title={rowData?.user || "No disponible - auth"}>
              {rowData.name ? rowData.name : "No disponible"}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Sitio",
      field: "site",
    },
    {
      title: "Estado",
      field: "status",
      render: (rowData: any) => {
        return (
          <div
            style={{
              ...badgeStyle,
              textAlign: "center",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <InfoOutlined style={{ color: "#008fbf" }} />
            {rowData?.status || "NO_TIENE_TOKEN"}
          </div>
        );
      },
    },
    {
      title: "Token producción",
      field: "prod_token",
      render: (rowData: any) => {
        return (
          <InputContainer
            disabled={!rowData.prod_token ? true : false}
            type="button"
            title="Ver Token"
            onClick={() => {
              setCurrentUserId(rowData?.user)
              ViewPayload(rowData?.prod_token || {}, "prod")
            }}
          />
        );
      },
    },
    {
      title: "Token sandbox",
      field: "sandbox_token",
      render: (rowData: any) => {
        return (
          <InputContainer
            disabled={!rowData.sandbox_token ? true : false}
            type="button"
            title="Ver Token"
            onClick={() =>{
              setCurrentUserId(rowData?.user)
              ViewPayload(rowData?.sandbox_token || {}, "sand")
            }}
          />
        );
      },
    },
    {
      title: "Acciones",
      render: (rowData: any) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Tooltip title="Agregar Token">
              <AddCircleOutline
                style={{ cursor: "pointer" }}
                onClick={() => ViewPayload(rowData.user, "update")}
              />
            </Tooltip>
            <Tooltip title="Borrar Cliente">
              <DeleteOutline
                style={{ cursor: "pointer" }}
                onClick={() => DeleteApiClient(rowData.user)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    refetch()
    //eslint-disable-next-line
  }, [country])

  useEffect(() => {
    form.setFieldsValue({
      access: logs?.access || undefined
    })
  }, [currentUserId]) //eslint-disable-line

  const updateTokenData = async () => {
    try {
      const newData = form.getFieldValue('access')
      await updateAccess({
        type,
        access: newData,
        user: currentUserId
      })
      notification.success({
        message: 'Token actualizado correctamente'
      })
    } catch (error) {
      console.log(error)
      notification.error({
        message: 'Ha ocurrido un error'
      })
    }
  }

  return (
    <>
      <Table
        tableRef={tableRef}
        columns={columns}
        //@ts-ignore
        data={data ? data : []}
        title="Tokens de usuarios"
        isLoading={isLoading}
        actions={[
          {
            icon: () => <RefreshOutlined />,
            tooltip: "Refrescar datos",
            isFreeAction: true,
            onClick: () => refreshTable(),
          },
        ]}
        options={{
          filtering: true,
        }}
        isButton={{
          title: "Añadir Cliente",
          onClick: () => ViewPayload({}, "add"),
        }}
      ></Table>
      <ModalContainer
        title={`${selectModalType(type)?.title || ""}`}
        visible={visible}
        onCancel={closeModal}
        footer={false}
      >
        <div className="JSONViewer" style={{ overflow: 'scroll' }}>
          {
            selectModalType(type)?.component
          }
        </div>
        <div>
          <br />
          <Form form={form}>
            <Spin spinning={isLoadingAccess}>
            <Typography style={{ marginLeft: '0.3rem' }}><strong>Acceso del token</strong></Typography>
            <InputContainer
              type="select"
              valueContainerName="access"
              placeholder="Acceso"
              multiple={true}
              style={{
                padding: '0.2rem'
              }}
              optionsList={[
                { label: "Plugin WooCommerce", value: "WORDPRESS" },
                { label: "Api", value: "API" },
              ]}
            />
            <InputContainer
              disabled={false}
              type="button"
              title="Actualizar"
              onClick={updateTokenData}
            /></Spin>
          </Form>
          <br />
        </div>
      </ModalContainer>
    </>
  );
};

export default TokenUsers;

const badgeStyle = {
  display: "flex",
  padding: "5px 10px",
  background: "#25fee54c",
  borderRadius: "10px",
};
