import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import CancelIcon from "@material-ui/icons/Cancel";

export enum UserBlacklistTypes {
	manyShipments = 'MULTIPLES_ENVIOS',
  manyGroundShipments = 'MULTIPLES_ENVIOS_TERRESTRES',
  manyMultipackShipments = 'MULTIPLES_ENVIOS_MULTIPAQUETE',
	overweight = 'SOBREPESOS',
	suspicious = 'SOSPECHA',
	reseller = 'REVENDEDOR',
	multipleAccounts = 'MULTICUENTAS'
}

export const UserBlacklistStatus = ({
  status,
  withIcon = true,
  customText,
}: {
  status: string;
  withIcon?: boolean;
  customText?: string;
}) => {
  const statusbadged = (status: UserBlacklistTypes | any) => {
    switch (status) {
      case UserBlacklistTypes.manyShipments:
      case UserBlacklistTypes.manyGroundShipments:
      case UserBlacklistTypes.manyMultipackShipments:
        return (
          <div style={{ backgroundColor: "#F07C54", ...chipStyle, height: 'fit-height' }}>
            {withIcon && <ReportProblemIcon style={chipStyle.imgStye} />}
            {customText || 'Múltiples Envíos'}
          </div>
        );
        case UserBlacklistTypes.overweight:
        return (
          <div style={{ backgroundColor: "#FF413F", ...chipStyle, height: 'fit-height' }}>
            {withIcon && <CancelIcon style={chipStyle.imgStye} />}
            {customText || 'Sobrepesos'}
          </div>
        );
        case UserBlacklistTypes.suspicious:
        return (
          <div style={{ backgroundColor: "#F07C54", ...chipStyle, height: 'fit-height' }}>
            {withIcon && <ReportProblemIcon style={chipStyle.imgStye} />}
            {customText || 'Sospechoso'}
          </div>
        );
        case UserBlacklistTypes.reseller:
        return (
          <div style={{ backgroundColor: "#FF413F", ...chipStyle, height: 'fit-height' }}>
            {withIcon && <CancelIcon style={chipStyle.imgStye} />}
            {customText || 'Revendedor'}
          </div>
        );
        case UserBlacklistTypes.multipleAccounts:
        return (
          <div style={{ backgroundColor: "#54C0F9", ...chipStyle, height: 'fit-height' }}>
            {withIcon && <ReportProblemIcon style={chipStyle.imgStye} />}
            {customText || 'Multicuentas'}
          </div>
        );
      default:
        return <div></div>;
    }
  };

  return <>{statusbadged(status)}</>;
};

const chipStyle = {
  display: "flex",
  fontWeight: "500",
  width: "155px",
  height: "25px",
  borderRadius: "30px",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  imgStye: { margin: "5px", width: "17px", color: "white" },
};
