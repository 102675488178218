import moment from 'moment'
import { TColumnTable } from '../../components/Common/Table'
import { UserBlacklistStatus } from '../../components/Common/UserBlacklistStatus'

export const getUserBlackListColumns = (refetchFn: () => void): TColumnTable => {
  return [
    {
      title: 'Fecha de bloqueo',
      field: 'ban_date',
      render: (rowData: any) => {
        return (
          <>{moment(rowData.ban_date).format('YYYY-MM-DD') || "N/A"}
          </>
        )
      }
    },
    {
      title: 'Correo',
      field: 'email',
    },
    {
      title: 'User ID',
      field: 'user',
    },
    {
      title: "Tipo de Bloqueo",
      field: "type",
      render: (rowData: any) => <UserBlacklistStatus status={rowData.type} />,
    },
    {
      title: 'Motivo',
      field: 'block_reason'
    },
    {
      title: 'Bloqueado por',
      field: 'made_by',
      render: (rowData: any) =>{
        return rowData.made_by ? rowData?.madeBy[0]?.email || rowData.made_by : "Automático"
      }
    }
  ]
}