import { apiProps, apiResponse } from '../interfaces/app.interfaces'

export const getAllNotifications = async (
  backendApiCall: (data: apiProps) => Promise<apiResponse>
) => {
  return await backendApiCall({
    method: 'GET',
    endpoint: `notifications`,
  })
}
