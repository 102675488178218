import Header from './Header'
import BlogText from '../../components/Common/BlogText'
import PolicyTitle from './PolicyTile'
import PolicyLayout from './PolicyLayout'
import { Fragment } from 'react'

const DataPolicy = () => {
  return (
    <Fragment>
      <Header />
      <PolicyLayout>
        <PolicyTitle title='Politica de datos' />
        <BlogText>
          El Reglamento General de Protección de Datos (GDPR) exige que los desarrolladores
          proporcionan una forma para que las personas puedan solicitar que se eliminen sus datos,
          es por ello que Dr. Envio comprometido con estas normas te brinda las instrucciones para
          que si es el caso, tus datos queden totalmente eliminados.
        </BlogText>
        <ol>
          <li>
            <BlogText>
              Envía un mensaje a nuestro chat de soporte en https://drenvio.com/ con tu correo y el
              motivo por el cual solicitan la eliminación.
            </BlogText>
          </li>
          <li>
            <BlogText>
              Nosotros te confirmaremos con un correo cuando el proceso haya concluido.
            </BlogText>
          </li>
        </ol>
      </PolicyLayout>
    </Fragment>
  )
}

export default DataPolicy
