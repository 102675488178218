import { Tooltip } from "antd"
import ShipmentDetailsModal from "./ShipmentDetailsModal"
import { useToggle } from "../../../../hooks/useToggle"
import { useTranslation } from 'react-i18next'
import { ExclamationCircleOutlined } from "@ant-design/icons"

const ShipmentCount: React.FC<{ shipments: any[] }> = ({ shipments})=> {
    const { t } = useTranslation()
    const { state: shipmentDetailModal, toggle: toggleShipmentDetailModal } = useToggle()

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span style={{ marginRight: '6px'}}>{`${shipments.length}`}</span>
            <Tooltip title={t('Withdrawals.Table.ViewShipmentsModal.Tooltip')}>
                <ExclamationCircleOutlined onClick={() => toggleShipmentDetailModal()} />
            </Tooltip>
            <ShipmentDetailsModal 
                shipments={shipments}
                shipmentDetailModal={shipmentDetailModal}
                toggleShipmentDetailModal={toggleShipmentDetailModal}
            />
        </div>
    )
}

export default ShipmentCount