import { Col, Row, Spin } from "antd"
import { FC } from "react"
import { FinderAccessI } from "."
import { ShipmentV2 } from "../../../interfaces/shipments.interface"
import CardContainer from "../../Containers/CardContainer"
import ResultError from "./ResultError"
import ShipmentResult from "./ShipmentResult"

interface Props {
    isLoading: boolean;
    openModal: boolean;
    isSuccessSearch: boolean;
    shipment: ShipmentV2;
    finderAccess: FinderAccessI;
}

const styles = {
    maxwidth: {
        width: "100%",
        minHeight: "6rem"
    },
    centerElements: {
        display: "grid",
        placeItems: "center"
    },
    cardStyleOutline:{
        boxShadow: "none",
        border: "1px solid #fff",
        backgroundcolor:"red"
    }
}
const SearchCard: FC<Props> = ({ isLoading, openModal, isSuccessSearch, shipment, finderAccess }) => {
    return (
        <CardContainer containerStyle={styles.maxwidth} cardStyle={styles.cardStyleOutline} >
            <Row gutter={[20, 20]}>
                <Col span={24} style={styles.centerElements} >
                    <>
                        {
                            isLoading && isSuccessSearch === null ? (
                                <Spin spinning={isLoading} />
                            ) : (
                                <>
                                    {
                                        isSuccessSearch === true ? (
                                            <>
                                                {
                                                    shipment !== null && (
                                                        <ShipmentResult 
                                                            shipment={shipment} 
                                                            isloading={isLoading} 
                                                            finderAccess={finderAccess}
                                                        />
                                                    )
                                                }
                                            </>

                                        ) : (
                                            <ResultError isloading={isLoading} />
                                        )
                                    }
                                </>
                            )

                        }
                    </>
                </Col>
            </Row>
        </CardContainer>
    )
}

export default SearchCard