import Flag_CO from "../assets/images/CountriesFlags/colombia.svg";
import Flag_MX from "../assets/images/CountriesFlags/mexico.svg";
import Flag_US from "../assets/images/CountriesFlags/united-states-of-america.svg";
import Flag_AR from "../assets/images/CountriesFlags/argentina.svg";
import Flag_CA from "../assets/images/CountriesFlags/canada.svg";
import Flag_ES from "../assets/images/CountriesFlags/spain.svg";
import Flag_CR from "../assets/images/CountriesFlags/costa-rica.svg";
import Flag_PE from "../assets/images/CountriesFlags/peru.svg";

type Props = {
  width?: string;
  country: string;
};

export const CountriesFlag = ({ country, width }: Props) => {
  const countries: Record<string, any> = {
    PE: (
      <img
        src={Flag_PE}
        max-width="100%"
        width={width || "40px"}
        alt={country}
      />
    ),
    MX: (
      <img
        src={Flag_MX}
        max-width="100%"
        width={width || "40px"}
        loading="lazy"
        alt={country}
      />
    ),
    CA: (
      <img
        src={Flag_CA}
        max-width="100%"
        width={width || "40px"}
        alt={country}
      />
    ),
    CO: (
      <img
        src={Flag_CO}
        max-width="100%"
        width={width || "40px"}
        alt={country}
      />
    ),
    US: (
      <img
        src={Flag_US}
        max-width="100%"
        width={width || "40px"}
        alt={country}
      />
    ),
    AR: (
      <img
        src={Flag_AR}
        max-width="100%"
        width={width || "40px"}
        alt={country}
      />
    ),
    ES: (
      <img
        src={Flag_ES}
        max-width="100%"
        width={width || "40px"}
        alt={country}
      />
    ),
    CR: (
      <img
        src={Flag_CR}
        max-width="100%"
        width={width || "40px"}
        alt={country}
      />
    ),
  };

  return countries[country];
};
