// Libraries
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { AdminDataContext } from "../../../context/adminContext";
// Components
import { Table } from "../../../components/Common/Table";
import {
  personalAccountsColumn,
  getAllPersonalAccounts,
  getParcelImage
} from "../../../services/records";
// Hooks
import { useGet } from "../../../hooks/useGet";
// Types
import { PersonalAccountsI } from "../../../interfaces/users.interfaces";
// Constants
import {allParcelsObject} from "../../../constants/parcels"

const PersonalAccounts: React.FC = () => {
  // Context Variables
  const { country } = useContext(AdminDataContext);
  // Paginas y Filas Actualmente Seleccionadas
  const [currentTablePage, setCurrentTablePage] = useState<number>(0);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState<number>(5);
  const {
    data: personalAccounts,
    refetch,
    isLoading: loadingPersonalAccounts,
  } = useGet<any>(getAllPersonalAccounts);

  const FormatedData = () => {
    const personalAccountFormated = personalAccounts?.res.map((item: PersonalAccountsI) => {
      const carrier = item?.type
      return {
        ...item,
        type: carrier === allParcelsObject[carrier]?.key ? getParcelImage(carrier as AllCarriers) : 'T e s t',
        active: item.active,
        created_at: item.created_at ? (moment.unix(Math.floor(item.created_at / 1000)).format('DD/MM/YYYY')): 'No hay registro.', 
      };
    });
    return personalAccountFormated
  };

  useEffect(() => {
    refetch();
  }, [country]);//eslint-disable-line

  console.log(currentRowsPerPage, currentTablePage)

  return (
    <>
      {personalAccounts ? (
        <Table
          data={FormatedData()}
          isLoading={loadingPersonalAccounts}
          columns={personalAccountsColumn()}
          onChangePage={(page) => setCurrentTablePage(page)}
          title="Cuentas Personales"
          onChangeRowsPerPage={(rows) => setCurrentRowsPerPage(rows)}
        />
      ) : null}
    </>
  );
};

export default PersonalAccounts;
