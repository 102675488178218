import { Col } from "antd"
import { FC } from "react"
import { useStyles } from "./styles";

interface Props {
    getRootProps: any;
    getInputProps: any;
    isDragActive: boolean
}

const DragNDrop: FC<Props> = ({ getRootProps, getInputProps, isDragActive }) => {
    const classes = useStyles();
    return (
        <Col >
            <div {...getRootProps()} className={classes.input}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Suelta el archivo ...</p>
                ) : (
                    <p>Suelta el excel, o da clic para seleccionar el archivo</p>
                )}
            </div>

        </Col >
    )
}

export default DragNDrop