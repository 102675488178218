import { CommonAssets } from "../../assets/exports/CommonAssets";

interface ResponsiveLogoProps {
  collapse: boolean;
  collapseAction: any;
}

const ResponsiveLogo = ({ collapse, collapseAction }: ResponsiveLogoProps) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "10%" }}
      onClick={collapseAction}
    >
      {!collapse ? (
        <img
          src="https://firebasestorage.googleapis.com/v0/b/drenvio-8741f.appspot.com/o/logo-drenvio-sweep.gif?alt=media&amp;token=fde47609-bca9-400f-aca1-e26dc6a403b1"
          style={{
            width: "80%",
          }}
          alt="Dr.Envio Logo"
        ></img>
      ) : (
        <img
          src={CommonAssets["planeLogo"]}
          style={{
            width: "3rem",
            height: "50%",
          }}
          alt="Dr.Envio Logo"
        ></img>
      )}
    </div>
  );
};

export default ResponsiveLogo;
