/**
 * Define the types of notifications that can be sent to the users
 */
export enum NotificationType {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  WHATSAPP = 'WHATSAPP',
}

/**
 * Define the events that trigger a notification
 */
export enum NotificationEvent {
  GUIDE_CREATED = 'GUIDE_CREATED',
  GUIDE_SENT = 'GUIDE_SENT',
}

type CommonNotification = {
  event: NotificationEvent
  /**
   * User id that sends the notification
   */
  user: string
  /**
   * User email that sends the notification
   */
  email: string
  sendAt: Date
  guideNumber: string
  /**
   * Origin from where the notification was sent, could be a `phone number` or `email`
   */
  origin: string
  /**
   * Destination where the notification was sent to, could be a `phone number` or `email`
   */
  destination: string
}

/**
 * Notification model interface
 */
export type NotificationI =
  | ({ type: NotificationType.EMAIL } & CommonNotification)
  | ({
      type: NotificationType.SMS | NotificationType.WHATSAPP
      metadata: {
        originCountry: string
        destinationCountry: string
        content: string
      }
    } & CommonNotification)
