import { useContext } from 'react'

import Principal from './Dashboards/Principal/Principal'
import Marketing from './Dashboards/Marketing/Marketing'
import { AppContext } from '../../context/appContext'
import usePermission from '../../hooks/usePermission'
import { PermissionViews } from '../../interfaces/permissions.interfaces'
import CardContainer from '../../components/Containers/CardContainer'

const DashboardAdmin = () => {

  const { toggleDashboard } = useContext(AppContext)
  const { hasAccess } = usePermission();

  return (
    <section className='dashboardSection'>
      {
        toggleDashboard ? (
          <>
            {
              //@ts-ignore
              hasAccess('stats', PermissionViews.stadistics) ? (
                <Principal />
              ) : (
                <CardContainer cardStyle={{textAlign: 'center', padding: '24px'}}>
                  <p style={{margin: 0, textAlign: 'center'}}>No tienes permisos para visualizar las estadísticas de la plataforma, requieres tener el permiso de <strong>Ver Estadísticas</strong> activado.</p>
                </CardContainer>
              )
            }
          </>

        ) : (
          <Marketing />
        )
      }
    </section>
  )
}

export default DashboardAdmin
