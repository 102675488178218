import { useState } from "react";
import { Col, Row } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { IconButton } from "@material-ui/core";

import { UpdateStatusModal } from "./UpdateStatusModal";
import { Pickup } from "../../../../interfaces/pickups.interface";

type Props = {
  data: Pickup;
  refetch: () => void;
};

export const Actions = ({ data, refetch }: Props) => {
  const [pickupModalOpen, setPickupModalOpen] = useState(false);

  return (
    <>
      <Row style={{ width: "100%" }} justify="start">
        <Col span={24} style={{ textAlign: "center" }}>
          <IconButton
            title="Cambiar Status"
            style={{ padding: 0 }}
            onClick={() => setPickupModalOpen(!pickupModalOpen)}
          >
            <InfoCircleOutlined style={{ cursor: "pointer" }} />
          </IconButton>
        </Col>
      </Row>
      <UpdateStatusModal
        isOpen={pickupModalOpen}
        setIsOpen={setPickupModalOpen}
        data={data}
        refetch={refetch}
      />
    </>
  );
};
