import { useContext, useEffect } from 'react'
import { AppContext } from '../context/appContext'
import { 
    ActionsDevSection, 
    ActionsDownloads, 
    ActionsFinderSection, 
    ActionsMarketingSection, 
    ActionsPermissions, 
    ActionsPricesSection, 
    ActionsReferrals, 
    ActionsFulfillment, 
    ActionsRegisters, 
    ActionsSeachUsers, 
    ActionsSections, 
    ActionsSells, 
    ActionsStadistics, 
    PermissionViews 

} from '../interfaces/permissions.interfaces'

/**
 * Hook which helps you to know if the user hass access to specific section of the platform
 * @returns If the user has permissions
 */
const usePermission = () => {
    const { permissions } = useContext(AppContext)

    const assignPermissions = (permissions: any, permission: string, scope: string) => {
        switch (scope) {
            case PermissionViews.search_users:
                if (permissions?.search_users.actions.includes(permission as ActionsSeachUsers)) {
                    return true
                }
                return false
            case PermissionViews.registers:
                if (permissions?.registers?.actions.includes(permission as ActionsRegisters)) {
                    return true
                }
                return false
            case PermissionViews.sections:
                if (permissions?.sections?.actions.includes(permission as ActionsSections)) {
                    return true
                }
                return false
            case PermissionViews.referrals:
                if (permissions?.referrals?.actions.includes(permission as ActionsReferrals)) {
                    return true
                }
                return false
            case PermissionViews.fulfillment:
                if (permissions?.fulfillment?.actions.includes(permission as ActionsFulfillment)) {
                    return true
                }
                return false
            case PermissionViews.downloads:
                if (permissions?.downloads?.actions?.includes(permission as ActionsDownloads)) {
                    return true
                }
                return false
            case PermissionViews.permissions:
                if (permissions?.permissions?.actions?.includes(permission as ActionsPermissions)) {
                    return true
                }
                return false
            case PermissionViews.prices_section:
                if (permissions?.prices_section?.actions.includes(permission as ActionsPricesSection)) {
                    return true
                }
                return false

            case PermissionViews.stadistics:
                console.log("si entra por este lugar", permissions?.stadistics?.actions, permissions?.stadistics?.actions.includes(permission as ActionsStadistics))
                if (permissions?.stadistics?.actions.includes(permission as ActionsStadistics)) {
                    return true
                }
                return false
            case PermissionViews.marketing:
                if (permissions?.marketing?.actions.includes(permission as ActionsMarketingSection)) {
                    return true
                }
                return false
            case PermissionViews.developers:
                if (permissions?.developers?.actions.includes(permission as ActionsDevSection)) {
                    return true
                }
                return false
            case PermissionViews.finder:
                if (permissions?.finder?.actions.includes(permission as ActionsFinderSection)) {
                    return true
                }
                return false
            case PermissionViews.sells:
                if (permissions?.sells?.actions.includes(permission as ActionsSells)) {
                    return true
                }
                return false

            default:
                return false

        }
    }

    const hasAccess = (permission: string, scope: string) => {
        return assignPermissions(permissions, permission, scope)

    }

    const hasUserAccess = (userPermissions: any, permission: string, scope: string) => {
        return assignPermissions(userPermissions, permission, scope)

    }

    useEffect(() => {
    }, [permissions])

    return {
        hasAccess,
        hasUserAccess
    }
}

export default usePermission
