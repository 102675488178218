import { FC } from 'react'
import ModalContainer from '../Containers/ModalContainer'
import { Button, Row } from 'antd'
import { TableFilter } from '../Common/Table'
import { ByPriceTypes, TablePriceFilter } from './TablePriceFilter'
import { TableDateFiler } from './TableDateFilter'
import moment from 'moment'
import { TableCarrierFilter } from './TableCarrierFilter'
import { TableZoneFilter } from './TableZoneFilter'
import { TableQuotesCount } from './TableQuotesCount'
import { TableCarrierServicesFilter } from './TableCarrierServicesFilter'
import { ServicesId_MX } from '../../utils/GlobalServices'

export const TableFilterModal: FC<Props> = ({
  isOpen,
  filter,
  carriers,
  setCarrierSelected,
  setPriceFilter,
  setRangeDate,
  close,
  setZone,
  setQuotesNumber,
  setProviderSelected,
  setServiceSelected,
}) => {
  const removeFilter = () => {
    if (filter === 'carrier') setCarrierSelected(null)
    if (filter === 'date') setRangeDate(null)
    if (filter === 'price') setPriceFilter(null)
    if (setZone && filter === 'zone') setZone(null)
    if (setQuotesNumber && filter === 'quotes') setQuotesNumber(null)
    if (setProviderSelected && setServiceSelected && filter === 'services') {
      setProviderSelected(undefined)
      setServiceSelected(undefined)
    }

    close()
  }

  return (
    <ModalContainer title={'Seleciona un Filtro'} visible={isOpen} onCancel={close} footer={false}>
      <div className='JSONViewer'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Row
            style={{
              width: '100%',
              height: '100%',
            }}
            gutter={[10, 10]}
          >
            {filter === 'date' && (
              <TableDateFiler
                setValue={(e: any) => {
                  setRangeDate([
                    moment(e[0]._d).format('YYYY-MM-DD'),
                    moment(e[1]._d).format('YYYY-MM-DD'),
                  ])
                }}
              />
            )}
            {filter === 'price' && <TablePriceFilter onChange={(price) => setPriceFilter(price)} />}
            {filter === 'carrier' && (
              <TableCarrierFilter carriers={carriers} setCarrierSelected={setCarrierSelected} />
            )}
            {(setZone && filter === 'zone') && <TableZoneFilter setZoneSelected={setZone} />}
            {(setQuotesNumber && filter === 'quotes') && <TableQuotesCount setQuotesNumber={setQuotesNumber} />}
            {(setProviderSelected && setServiceSelected && filter ==='services') && <TableCarrierServicesFilter carriers={carriers} setProviderSelected={setProviderSelected} setServiceSelected={setServiceSelected} setCarrierSelected={setCarrierSelected} />}
          </Row>
          <div>
            <Button onClick={removeFilter}>Remover filtro</Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  )
}

type Props = {
  isOpen: boolean
  filter: TableFilter | null
  carriers: AllCarriers[]
  setCarrierSelected: React.Dispatch<React.SetStateAction<AllCarriers | null>>
  setPriceFilter: (value: ByPriceTypes | null) => void
  setRangeDate: (value: string[] | null) => void
  close: () => void
  setZone?: React.Dispatch<React.SetStateAction<string | null>>
  setQuotesNumber?: React.Dispatch<React.SetStateAction<number | null>>
  setProviderSelected?: React.Dispatch<React.SetStateAction<string | undefined>>
  setServiceSelected?: React.Dispatch<React.SetStateAction<ServicesId_MX | undefined>>
}
