import React, { Fragment } from 'react'
import CardContainer from '../../components/Containers/CardContainer'
import { Col, Row } from 'antd'
import LoadingAnimation from '../../components/LoadingAnimation';

export interface ResolutionUtilizationI {
    count: number;
    resolution: {
        w: number,
        h: number
    }
}

type Props = {
    data: ResolutionUtilizationI[]
    isLoading: boolean
}

const MostUsedResolutions = ({ data, isLoading }: Props) => {

    console.log("DATOS", data)

  return (
    <CardContainer cardStyle={{ marginTop: '20px' }} title='Resoluciones más utilizadas'>
        <hr />
        <Row>
            <Col span={21} style={{ margin: 'auto', fontWeight: 'bold' }}>
                <p>Resolución (px)</p>
            </Col>
            <Col span={3} style={{ margin: 'auto', fontWeight: 'bold', textAlign: 'center' }}>
                <p>Total</p>
            </Col>
        </Row>
        <Row
            align='middle'
            style={{
              maxHeight: '40vh',
              overflowY: `${
                data !== undefined &&
                data.length > 5
                  ? 'scroll'
                  : 'hidden' || 'scroll'
              }`,
            }}
        >
            {isLoading ? (
                <LoadingAnimation animationType='small' />
            ) : (
                data.map((item, index) => {
                    return (
                        <Fragment key={index+1}>
                            <Col span={21} style={{ margin: '5px auto' }}>
                                <small>{ item?.resolution?.w || 0 } x { item?.resolution?.h || 0 }</small>
                            </Col>
                            <Col span={3} style={{ margin: '5px auto', textAlign: 'center' }}>
                                <small>{ item?.count || 0 }</small>
                            </Col>
                        </Fragment>
                    )
                })
            )
            }
        </Row>
    </CardContainer>
  )
}

export default MostUsedResolutions