import { useState, FC, useEffect } from 'react';
import ReactJson from 'react-json-view';

interface props{
    currentJson: any;
    updateJson: (data: any) => void;
    currentStore: any;
    editMode: boolean;
}

const JsonEditor:FC<props> = ({currentJson, updateJson, currentStore, editMode}) => {
  const [jsonData, setJsonData] = useState(currentJson); // Estado para almacenar el JSON

  const handleJsonChange = (newData: any) => {
    setJsonData(newData.updated_src);
    updateJson(newData.updated_src)
  };

  useEffect(() =>{
    if(editMode){
      let newData = {...currentStore}
      delete newData.type
      setJsonData({
        ...newData
      })
    }else{
        setJsonData(currentJson)
    }
  },[editMode, currentStore]) //eslint-disable-line

  return (
    <div>
      <ReactJson
        src={jsonData}
        onEdit={handleJsonChange}
        onDelete={handleJsonChange}
        onAdd={handleJsonChange}
        name="jsonEditor"
        theme="monokai"
        style={{width: '650px', overflow: 'hidden'}}
        enableClipboard={false} // Opcional: desactiva la opción de copiar al portapapeles
      />
    </div>
  );
};

export default JsonEditor;