import { CSSProperties } from "react";
import { PermissionStep } from "../context/adminContext";
import { ProfileData } from "./app.interfaces";

export type PermissionStepsObject = {
    [key in PermissionStep]: React.ReactElement
}


export type UserRolesI = 'COMMON' | 'ADMIN';

export enum ActionsSeachUsers {
    discount = 'discount',
    block_users = 'block_users',
    recharge = 'recharge',
    update = 'update',
    verify = 'verify',
    assign_admin = 'assign_admin',
    user_special_prices = 'user_special_prices',
}

export enum ActionsRegisters {
    api_clients = 'api_clients',
    modify_api_clients = 'modify_api_clients',
    top_users = 'top_users',
    col_recharges = 'col_recharges',
    resellers = 'resellers',
    admin_logs = 'admin_logs',
    stp_transactions = 'stp_transactions',
    monitored_info = 'monitored_info',
    provider_info = 'provider_info'
}

export enum ActionsSections {
    shipments = 'shipments',
    cancelations = 'cancelations',
    users = 'users',
    recolections = 'recolections',
    movements = 'movements',
    reports = 'reports',
    balances = 'balances',
    api_shipments = 'api_shipments',
    personalAccounts = 'personalAccounts',
}

export enum ActionsReferrals {
    view_profiles = 'view_profiles',
    view_referrals = 'view_referrals',
    assign_referrals = 'assign_referrals',
    view_withdrawals = 'view_withdrawals',
    view_commissions = 'view_commissions',
    view_pending_withdrawal_by_platform = 'view_pending_withdrawal_by_platform',
}

export enum ActionsFulfillment {
    view_fulfill_profiles = 'view_fulfill_profiles',
}

export enum ActionsSells {
    sells = "sells",
    view_all_sells = "view_all_sells",
    view_seller_assign = "view_seller_assign",
    assign_customers = "assign_customers"
}

export enum ActionsDownloads {
    download_shipments = 'download_shipments',
    download_users = 'download_users',
    download_movements = 'download_movements',
    excess_weight = 'excess_weight',
    download_recolections = 'download_recolections',
}

export enum ActionsPermissions {
    modify_admin_permission = 'modify_admin_permission',
    update_permissions = 'update_permissions',
}


export enum ActionsPricesSection {
    prices = 'prices',
    special_prices = 'special_prices',
    modify_prices = 'modify_prices',
    modify_special_prices = 'modify_special_prices',
}

export enum ActionsMarketingSection {
    marketing_stadistics = 'marketing_stadistics',
    user_tracking = 'user_tracking',
}


export enum ActionsDevSection {
    cp_blacklist = 'cp_blacklist',
    user_blacklist = 'user_blacklist',
    error_logs = 'error_logs',
    modify_cps = 'modify_cps',
}

export enum ActionsFinderSection {
    shipments_finder = 'shipments_finder',
    update_status = 'update_status'
}

export enum ActionsStadistics {
    stats = 'stats',
}


export enum PermissionViews {
    search_users = 'search_users',
    registers = 'registers',
    sections = 'sections',
    referrals = 'referrals',
    fulfillment = 'fulfillment',
    sells = 'sells',
    downloads = 'downloads',
    permissions = 'permissions',
    prices_section = 'prices_section',
    stadistics = 'stadistics',
    marketing = 'marketing',
    developers = 'developers',
    finder = 'finder',

}

export interface UserPermissionsI {
    search_users: {
        menu: boolean;
        actions: ActionsSeachUsers[];
    },
    registers: {
        menu: boolean;
        actions: ActionsRegisters[];
    },
    sections: {
        menu: boolean;
        actions: ActionsSections[];
    },
    referrals: {
        menu: boolean;
        actions: ActionsReferrals[];
    },
    fulfillment: {
        menu: boolean;
        actions: ActionsFulfillment[];
    },
    sells: {
        menu: boolean,
        actions: ActionsSells[];
    }
    downloads: {
        menu: boolean;
        actions: ActionsDownloads[];
    },
    permissions: {
        menu: boolean;
        actions: ActionsPermissions[];
    },
    prices_section: {
        menu: boolean;
        actions: ActionsPricesSection[];
    },
    stadistics: {
        menu: boolean;
        actions: ActionsStadistics[];
    },
    marketing: {
        menu: boolean;
        actions: ActionsMarketingSection[];
    },
    developers: {
        menu: boolean;
        actions: ActionsDevSection[];
    },
    finder: {
        menu: boolean;
        actions: ActionsFinderSection[];
    }
}


export interface AdminUserI {
    _id: string;
    user: string;
    roles: [UserRolesI];
    app_version: number;
    created_at: string;
    profile: [ProfileData];
    metadata: {
        special_price?: string[];
        permissions?: UserPermissionsI;
    }
}


export interface UpdateAdminRoleI {
    admin: boolean
}

export interface PermissionFormI {
    users: {
        verify: boolean;
        search_users: boolean;
        recharge: boolean;
        update: boolean;
        block_users: boolean;
        assign_admin: boolean;
        discount: boolean;
        user_special_prices: boolean;
        
    },
    sections: {
        sections: boolean;
        shipments: boolean;
        movements: boolean;
        reports: boolean;
        recolections: boolean;
        cancelations: boolean;
        users: boolean;
        balances: boolean;
        api_shipments: boolean;
        personalAccounts: boolean;
    },
    referrals: {
        referrals: boolean;
        view_profiles: boolean;
        view_referrals: boolean;
        assign_referrals: boolean;
        view_withdrawals: boolean;
        view_commissions: boolean;
        view_pending_withdrawal_by_platform: boolean
    },
    fulfillment: {
        fulfillment: boolean;
        view_fulfill_profiles: boolean;
    },
    sells: {
        sells: boolean;
        view_seller_assign: boolean;
        assign_customers: boolean;
        view_all_sells: boolean;
    }
    registers: {
        registers: boolean;
        resellers: boolean;
        top_users: boolean;
        modify_api_clients: boolean;
        col_recharges: boolean;
        api_clients: boolean;
        stp_transactions: boolean;
        admin_logs: boolean;
        monitored_info: boolean;
        provider_info:boolean;
    },
    prices: {
        prices: boolean,
        prices_section: boolean;
        modify_prices: boolean;
        modify_special_prices: boolean;
        special_prices: boolean;
    },
    downloads: {
        downloads: boolean;
        download_movements: boolean;
        download_recolections: boolean;
        download_shipments: boolean;
        download_users: boolean;
        excess_weight: boolean;
    },
    permissions: {
        permissions: boolean;
        update_permissions: boolean;
        modify_admin_permission: boolean;
    },
    devs: {
        developers: boolean;
        cp_blacklist: boolean;
        error_logs: boolean;
        user_blacklist: boolean;
        modify_cps: boolean;
    },
    marketing: {
        marketing: boolean;
        marketing_stadistics: boolean;
        user_tracking: boolean;
    },
    stadistics: {
        stadistics: boolean;
        stats: boolean;
    },
    finder: {
        finder: boolean;
        shipments_finder: boolean;
        update_status: boolean;
    },
}

export interface PermissionFormDataI{
    verify: boolean;
    search_users: boolean;
    recharge: boolean;
    update: boolean;
    block_users: boolean;
    assign_admin: boolean;
    discount: boolean;
    user_special_prices: boolean;

    //sections
    sections: boolean;
    shipments: boolean;
    movements: boolean;
    reports: boolean;
    recolections: boolean;
    cancelations: boolean;
    users: boolean;
    balances: boolean;
    api_shipments: boolean;
    personalAccounts: boolean;
    
    //referrals
    referrals: boolean;
    view_profiles: boolean;
    view_referrals: boolean;
    assign_referrals: boolean;
    view_withdrawals: boolean;
    view_commissions: boolean;
    view_pending_withdrawal_by_platform: boolean
    
    //fulfillment
    fulfillment: boolean;
    view_fulfill_profiles: boolean;


    //sells
    sells: boolean;
    view_all_sells: boolean;
    assign_customers: boolean;
    view_seller_assign: boolean;

    //registers
    registers: boolean;
    resellers: boolean;
    top_users: boolean;
    modify_api_clients: boolean;
    col_recharges: boolean;
    api_clients: boolean;
    stp_transactions: boolean;
    admin_logs: boolean;
    monitored_info: boolean;
    provider_info: boolean;

    //prices
    prices: boolean;
    prices_section: boolean;
    modify_prices: boolean;
    modify_special_prices: boolean;
    special_prices: boolean;

    //downloads
    downloads: boolean;
    download_movements: boolean;
    download_recolections: boolean;
    download_shipments: boolean;
    download_users: boolean;
    excess_weight: boolean;

    //permissions
    permissions: boolean;
    update_permissions: boolean;
    modify_admin_permission: boolean;

    //developers
    developers: boolean;
    cp_blacklist: boolean;
    error_logs: boolean;
    user_blacklist: boolean;
    modify_cps: boolean;

    //marketing
    marketing: boolean;
    marketing_stadistics: boolean;
    user_tracking: boolean;

    //stadistics
    stadistics: boolean;
    stats: boolean;

    //finder
    finder: boolean;
    shipments_finder: boolean;
    update_status: boolean;
}

export interface UpdateUserPermissionsI {
    permissions: PermissionFormI
}

export type CardProps =
    'titleCard'
    | 'checkBox'
    | 'singleCard'
    | 'mainCheckBox'
    | 'checkboxLineContainer'
    | 'checkboxItem'
    | 'permissionContainer'
    | 'checkboxMainContainer'

export type CardContainerStylingProps = {
    [key in CardProps]: CSSProperties
}