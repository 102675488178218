import { Form } from "antd"
import { FC } from "react";
import InputContainer from "../../Containers/InputContainer"
import { ShipmentStatus } from "../CancelStatus";

interface Props{
    form: any;
    isLoadingStatus: boolean;
    onSubmit: (status: any) => void;
}
const StatusInput:FC<Props> = ({form, isLoadingStatus, onSubmit}) =>{
    return(
        <>
        <Form form={form} >
            <InputContainer
                type="select"
                valueContainerName="change_status"
                placeholder="change_status"
                style={{ width: "10rem" }}
                loading={isLoadingStatus}
                onChange={(e) => {
                    onSubmit(e)
                }}
                optionsList={[
                    ShipmentStatus.Active,
                    ShipmentStatus.Solicited,
                    ShipmentStatus.Refunded,
                    ShipmentStatus.CancelledFromProvider,
                    ShipmentStatus.CancelledButUsed
                ]}

            />
        </Form>
    </>
    )
}

export default StatusInput