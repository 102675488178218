// Libraries
import { FC } from 'react'
import { Col, Row } from 'antd'
import { UserOutlined, MailOutlined, ShakeOutlined, BuildOutlined } from '@ant-design/icons'
import CardContainer from '../Containers/CardContainer'
import InputContainer from '../Containers/InputContainer'
import { GuidePayload } from '../../interfaces/guides.interface'
import { getCountersByCarrier } from '../../utils/formCounters'

const NOVENTA_9_MINUTOS_SERVICE_ID = 'noventa9Minutos_mx_D-C01'

export const DestinyForm: FC<Props> = ({ guide }) => {
  const isNoventa9Minutos = guide.service_id.includes(NOVENTA_9_MINUTOS_SERVICE_ID)

  const counters = getCountersByCarrier(guide.shipment.carrier)

  return (
    <Col sm={24} lg={24} style={{ width: '100%' }}>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <CardContainer id='DestinoCard'>
            <Row gutter={[10, 5]}>
              <Col span={24} style={{ display: 'grid', placeItems: 'center' }}>
                <h4 style={{ color: '#2d1b6e' }}>Destino</h4>
              </Col>

              <Col span={24}>
                <h6>Contacto</h6>
              </Col>
              <Col span={12}>
                <InputContainer
                  type='text'
                  required
                  placeholder='Nombre'
                  valueContainerName='DestinyName'
                  suffix={<UserOutlined />}
                  counters={counters.name}
                />
              </Col>
              {isNoventa9Minutos && (
                <Col span={12}>
                  <InputContainer
                    type='text'
                    required
                    placeholder='Apellido'
                    valueContainerName='DestinyLastName'
                    suffix={<UserOutlined />}
                    counters={counters.name}
                  />
                </Col>
              )}
              <Col span={12}>
                <InputContainer
                  type='email'
                  required
                  placeholder='Correo'
                  valueContainerName='DestinyEmail'
                  suffix={<MailOutlined />}
                  counters={counters.email}
                />
              </Col>
              <Col span={12}>
                <InputContainer
                  type='text'
                  required
                  placeholder='Teléfono'
                  valueContainerName='DestinyPhone'
                  suffix={<ShakeOutlined />}
                  counters={counters.phone}
                />
              </Col>
              <Col span={12}>
                <InputContainer
                  type='text'
                  required
                  placeholder='Empresa'
                  valueContainerName='DestinyCompany'
                  suffix={<BuildOutlined />}
                  counters={counters.company}
                />
              </Col>

              {/* Aqui se renderean todos los componentes extras dependiendo del país de la seccion de Contacto*/}

              {/* {countryRenderedInputs?.ContactSection?.map((component) => (
                <Col span={24}>{component}</Col>
              ))} */}

              <Col span={24}>
                <h6>Dirección</h6>
              </Col>

              <Col span={12}>
                <InputContainer
                  type='text'
                  required
                  placeholder={'Calle'}
                  valueContainerName='DestinyStreet'
                  counters={counters.street}
                />
              </Col>
              <Col span={12}>
                <InputContainer
                  type='text'
                  required
                  placeholder={'Número'}
                  valueContainerName='DestinyNumber'
                  counters={counters.number}
                />
              </Col>
              <Col span={12}>
                <InputContainer
                  type='text'
                  placeholder={'Número'}
                  valueContainerName='DestinyInt_number'
                  counters={counters.int_number}
                />
              </Col>
              <Col span={12}>
                <InputContainer
                  type='text'
                  required
                  placeholder={'Distrito'}
                  valueContainerName='DestinyDistrict'
                  counters={counters.district}
                />
              </Col>
              <Col span={12}>
                <InputContainer
                  type='text'
                  required
                  placeholder={'Ciudad'}
                  valueContainerName='DestinyCity'
                />
              </Col>
              <Col span={12}>
                <InputContainer
                  type='text'
                  required
                  placeholder={'Código postal'}
                  valueContainerName='DestinyPostal_code'
                  disabled={true}
                />
              </Col>
              <Col span={24}>
                {/* <InputCountriesContainer
                  type='allCountriesStatesInputs'
                  required
                  disabled={true}
                  disabledState={quoteData.AddressData.DestinyData.state ? true : false}
                  valueContainerName='Destiny'
                  initialCountry2DigitCode={quoteData.AddressData.DestinyData.country}
                  userFormHook={form}
                /> */}
              </Col>

              {/* Aqui se renderean todos los componentes extras dependiendo del país de la seccion de Direccion*/}

              {/* {countryRenderedInputs?.DirectionSection?.map((component) => (
                <Col span={24}>{component}</Col>
              ))} */}

              <Col span={24}>
                <InputContainer
                  type='text'
                  required
                  placeholder={'Referencia'}
                  valueContainerName='DestinyReference'
                  // validateTrigger={}
                  counters={counters.reference}
                />
              </Col>
            </Row>
          </CardContainer>
        </Col>
      </Row>
    </Col>
  )
}

type Props = {
  guide: GuidePayload
}
