import { Button, Col, Row } from "antd";
import { FC } from "react";
import { DataPicker } from "./DataPicker";
import ModalContainerPicker from "./ModalContainerPicker";
import { useTranslation } from "react-i18next";

export const ModalDataPicker: FC<Props> = ({
  isOpen,
  isLoading,
  onAction,
  onCancel,
  setState,
}) => {
  const { t } = useTranslation();

  return (
    <ModalContainerPicker
      title={t("Table.RangeDateModal.Title")}
      visible={isOpen}
      onCancel={onCancel}
      style={{ padding: "0" }}
      footer={false}
    >
      <div>
        <div style={{ display: "flex" }}>
          <Row
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Col span={22}>
              <DataPicker
                setValue={(e: any) => {
                  setState(e);
                }}
              />
            </Col>
            <Col style={{ marginTop: 16 }}>
              <Button
                style={{
                  marginRight: "1rem",
                  alignItems: "center",
                  display: "flex",
                }}
                loading={isLoading}
                type="primary"
                onClick={onAction}
              >
                {t("Table.RangeDateModal.Button.Download")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </ModalContainerPicker>
  );
};

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  onCancel: () => void;
  setState: (e: any) => void;
  onAction: () => void;
};
