import { UserMenu } from "../utils/Arrays";
import { MenuOutlined } from "@ant-design/icons";
import { Layout, Menu, Typography } from "antd";
import { useState, useContext, Fragment } from "react";
import ResponsiveLogo from "./Common/ResponsiveLogo";
import Toolsbar from "./Common/Toolsbar";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingAnimation from "./LoadingAnimation";
import anime from "animejs";
import MobileSideNav from "./Common/layout/MobileSideNav";
import useDevice from "../utils/hooks/useDevice";
import { AppContext } from "../context/appContext";
import {
  userhaveMarketingPermissions,
  userhavePermissions,
} from "../utils/AppLayout";
import { AdminDataContext } from "../context/adminContext";
// import { CountriesFlag } from "./CountriesFlag";

const { Header, Sider, Content } = Layout;

const AppLayout = () => {
  const [collapsed, setcollapsed] = useState(false);
  const [sectionSelected, setSectionSelected] = useState<Sections>("Inicio");
  const { userData } = useContext(AppContext);
  const { changeCountry, country } = useContext(AdminDataContext);
  const [mobileCollapsed, setMobilecollapsed] = useState<boolean>(false);
  const { Title } = Typography;
  const toggle = () => {
    setcollapsed(!collapsed);
  };
  const { isMobile, isTablet } = useDevice();
  const pageTransition = (pageToTransition: Sections) => {
    if (pageToTransition === sectionSelected) {
    } else {
      const tl = anime.timeline({
        duration: 420,
        easing: "easeInOutSine",
      });

      tl.add({
        targets: ".content-layout",
        translateY: "150px",
        opacity: 0,
      });

      tl.add({
        targets: ".content-layout",
        translateY: "0%",
        opacity: 1,
      });

      tl.add(
        {
          targets: ".headerContainer",
          easing: "linear",
          duration: 1000,
          opacity: [1, 0, 1],
        },
        "-=1050"
      );

      setTimeout(() => {
        setSectionSelected(pageToTransition);
      }, 350);
    }
  };

  const createCountryOption = (
    label: FullCountryAdmin,
    value: CountryAdmin
  ) => {
    return (
      <option style={{ color: "black" }} value={value}>
        {label}
      </option>
    );
  };

  // useEffect(() => {
  //   if (userData.user !== 'none') {
  //     if (userData.roles.includes(UserRoles.admin)) {
  //     } else {
  //       logout({
  //         returnTo: window.location.origin
  //       })
  //     }
  //   }
  //   //eslint-disable-next-line
  // }, [userData])
  return (
    <>
      {userData.user === "none" ? (
        <LoadingAnimation fullPage={true} animationType="big" />
      ) : (
        <Layout style={{ height: "100vh" }}>
          {!isMobile && !isTablet ? (
            <Sider
              className="responsive-sidebar"
              trigger={null}
              collapsible
              collapsed={collapsed}
              style={{ overflowY: "auto", maxHeight: "100vh" }}
            >
              <ResponsiveLogo collapse={collapsed} collapseAction={toggle} />
              <Menu
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
                mode="inline"
                defaultSelectedKeys={["Inicio"]}
              >
                {Object.values(UserMenu).map((section, index: number) => {
                  if (userhavePermissions(section.identifier, userData)) {
                    return (
                      <Fragment key={index}>
                        {!section.type && (
                          <Menu.Item
                            key={section.identifier}
                            icon={section.icon}
                            onClick={() => pageTransition(section.identifier)}
                          >
                            {section.title}
                          </Menu.Item>
                        )}
                        {section.type === "subMenu" && section.children && (
                          <Menu.SubMenu
                            key={section.identifier}
                            title={section.title}
                            icon={section.icon}
                          >
                            {section.children.map((item: any, idx: number) => {
                              if (
                                userhaveMarketingPermissions(
                                  item.identifier,
                                  userData
                                )
                              ) {
                                return (
                                  <Menu.Item
                                    key={idx}
                                    icon={item.icon}
                                    onClick={() =>
                                      pageTransition(item.identifier)
                                    }
                                  >
                                    {item.title}
                                  </Menu.Item>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </Menu.SubMenu>
                        )}
                      </Fragment>
                    );
                  }
                  return null;
                })}
                <Typography style={{ marginTop: "auto", marginLeft: "10px" }}>
                  {collapsed ? "" : "Filtrar información por país:"}
                </Typography>
                <Menu.Item
                  style={{ marginTop: "20px" }}
                  key="languages"
                  // icon={<CountriesFlag country={country} width="14" />}
                >
                  <select
                    onChange={(e) => {
                      const newCountry: CountryAdmin = e.currentTarget
                        .value as CountryAdmin;
                      changeCountry(newCountry);
                    }}
                    defaultValue={country}
                    style={{
                      backgroundColor: "transparent",
                      width: "100%",
                      height: "100%",
                      outline: "none",
                      border: "none",
                    }}
                  >
                    {createCountryOption("México", "MX")}
                    {createCountryOption("Colombia", "CO")}
                    {createCountryOption("Perú", "PE")}
                  </select>
                </Menu.Item>
              </Menu>
            </Sider>
          ) : (
            <MobileSideNav
              setPage={pageTransition}
              collapsedState={mobileCollapsed}
              setCollapsedState={setMobilecollapsed}
            />
          )}

          <Layout className="site-layout" style={{ overflow: "hidden" }}>
            <Header className="ant-layout-header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="section-title">
                  <h3 className="text-white drfont fw-normal">
                    {UserMenu[sectionSelected].header}
                  </h3>
                  <Title className="text-white drfont fw-normal" level={5}>
                    {UserMenu[sectionSelected].description}
                  </Title>
                </div>
                <div className="burger">
                  {!mobileCollapsed && (
                    <MenuOutlined
                      onClick={() => setMobilecollapsed(!mobileCollapsed)}
                    />
                  )}
                </div>
                <div>
                  <Toolsbar page={sectionSelected} />
                </div>
              </div>
            </Header>
            <Content className=" content-layout">
              {UserMenu[sectionSelected].component}
            </Content>
          </Layout>
        </Layout>
      )}
    </>
  );
};

export default withAuthenticationRequired(AppLayout, {
  onRedirecting: () => <LoadingAnimation fullPage={true} animationType="big" />,
});
