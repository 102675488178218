import { Dispatch, FC, SetStateAction } from "react";
import { Col, Row, Typography } from "antd";
import ModalContainer from "../../Containers/ModalContainer";
import { Pickup } from "../../../interfaces/pickups.interface";

type PickupProps = {
  visible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  data: Pickup;
};

export const PickupsDetail: FC<PickupProps> = ({
  visible,
  setIsVisible,
  data,
}) => {
  return (
    <ModalContainer
      footer={<></>}
      title={"Detalles de Recolecciones"}
      visible={visible}
      onCancel={() => setIsVisible(false)}
    >
      <Row>
        <Col span={8}>
          <Typography style={styles.textStyle}>
            <b>{"Provedor"}</b> <br />
            {data?.service_id}
          </Typography>
          <Typography style={styles.textStyle}>
            <b>{"Nombre"}</b> <br />
            {data.address[0]?.origin.name}
          </Typography>
          <Typography style={styles.textStyle}>
            <b>{"Telefono"}</b> <br />
            {data.address[0]?.origin.phone}
          </Typography>
          <Typography style={styles.textStyle}>
            <b>{"Correo"}</b> <br />
            {data?.email}
          </Typography>
          <Typography style={styles.textStyle}>
            <b>{"Codigo Postal"}</b> <br />
            {data.address[0]?.origin.postal_code}
          </Typography>
          <Typography style={styles.textStyle}>
            <b>{"Estado"}</b> <br />
            {data.address[0]?.origin.state}
          </Typography>
          <Typography style={styles.textStyle}>
            <b>{"Ciudad"}</b> <br />
            {data.address[0]?.origin.city}
          </Typography>
          <Typography style={styles.textStyle}>
            <b>{"Colonia"}</b> <br />
            {data.address[0]?.origin.district}
          </Typography>
          <Typography style={styles.textStyle}>
            <b>{"Numero Exterior"}</b> <br />
            {data.address[0]?.origin.int_number === undefined
              ? data.address[0]?.origin.int_number
              : '""'}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Calle"}</b> <br />
            {data.address[0]?.origin.street}
          </Typography>
          <Typography style={styles.textStyle}>
            <b>{"Referencias"}</b> <br />
            {data.address[0]?.origin.reference}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Contenido del paquete"}</b> <br /> {data.packages[0].content}
          </Typography>
          <Typography style={styles.textStyle}>
            <b>{"Kilos"}</b> <br />{" "}
            {data.packages.length > 0 ? data.packages[0].weight : ""}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Medidas"}</b> <br />{" "}
            {data.packages.length > 0
              ? `${data.packages[0].width}x${data.packages[0].length}x${data.packages[0].height}`
              : ""}
          </Typography>

          <Typography style={styles.textStyle}>
            <b>{"Volumetrico"}</b> <br />
            {data.packages.length > 0 ? data.packages[0].volumetric : ""}
          </Typography>
        </Col>
      </Row>
    </ModalContainer>
  );
};

const styles = {
  textStyle: {
    marginBottom: "5px",
  },
};
