import { Dropdown } from "antd"
import menuDropdownUser from "./menuDropdownUser"
import { FC, PropsWithChildren, useState } from "react";
import useUserAccess from "../../../../hooks/useAccess";
import { UserAccessI, userActionsT } from '../interfaces';
import { UserItemI } from "../Search/SearchItem";

interface props {
    userFound: UserItemI;
    changeActions: (type: userActionsT) => void;
}

const ActionsMenu: FC<PropsWithChildren<props>> = ({ userFound, children, changeActions }) => {
    const [_modalProps, setModalProps] = useState({ //eslint-disable-line
        visible: false,
        selector: "",
    });
    const { access } = useUserAccess();

    return (
        <>
        <Dropdown
            overlay={menuDropdownUser(
                setModalProps,
                userFound,
                access as UserAccessI,
                changeActions
            )}
        >
            {children}
        </Dropdown> 
        </>
    )
}

export default ActionsMenu