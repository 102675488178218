import React, { useContext, useEffect, useState, createRef } from "react";
import moment from "moment";
import { Table, TColumnTable } from "../../../components/Common/Table";
import { AdminDataContext } from "../../../context/adminContext";
import { ApiContext } from "../../../context/apiContext";

const Facturar: React.FC = () => {
  const perBatch = 100;
  const tableRef = createRef();
  const { country } = useContext(AdminDataContext);
  const { backendApiCall } = useContext(ApiContext);
  const [batchesFetched, setBatchesFetched] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [facturasFetched, setFacturasFetched] = useState<object[]>([]);

const columns: TColumnTable = [
    {
      title: "Receptor",
      field: "receptor",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Total",
      field: "total",
    },
    {
      title: "Fecha Timbrado",
      field: "fechaTimbrado",
    },
    {
      title: "Folio",
      field: "folio",
    },
  ];

  const fetchFacturas = async () => {
    setIsLoading(true);

  const response = await backendApiCall({
      method: "GET",
      endpoint: `factura/misfacturas`,
    });
    // @ts-ignore
    const optimizedData = response.data.data.map(
      (facturas: object) => {
        return facturaData(facturas);
      }
    );
    setFacturasFetched(facturasFetched.concat(optimizedData));
    setBatchesFetched((prevBatch) => prevBatch + 1);
    setIsLoading(false);
  };

  const facturaData = (data: any): object => {

    //para que folio muestre la cadena completa
    const folioRegex = /F\s*\d+/;
    const folioMatch = data.Folio.match(folioRegex);
    const folio = folioMatch ? folioMatch[0] : '';
    return {
      folio: folio,
      receptor: data.Receptor.toString(),
      status: data.Status,
      total: data.Total[0],
      fechaTimbrado: moment(data.FechaTimbrado).format("DD/MM/yyyy"),
      //Aqui tengo la idea de como obtener la información para cada campo de la tabla 
    };
  };

  //paginación
  const handlePageChange = (page: any) => {
    if ((page + 1) * rowsPerPage === perBatch * batchesFetched) {
      fetchFacturas();
    }
  };

  useEffect(() => {
    fetchFacturas();
  }, [country]); // eslint-disable-line

  return (
    <Table
      data={facturasFetched}
      isLoading={isLoading}
      tableRef={tableRef}
      columns={columns}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={(rows) => setRowsPerPage(rows)}
      title="Facturas"
    />
  );
};

export default Facturar;
