import { UserBlacklistTypes } from "../components/Common/UserBlacklistStatus";
import { apiProps, apiResponse } from "../interfaces/app.interfaces";

export const getAllTransfers = async (
  backendApiCall: (data: apiProps) => Promise<apiResponse>
) => {
  return await backendApiCall({
    method: "GET",
    endpoint: "payments/getAllTransfers",
  });
};

export const addBalance =
  (userId: String, dto: any) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `users/user/addBalance/${userId}`,
      data: dto,
    });
  };

export const removeBalance =
  (userId: String, dto: any) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `users/user/removeBalance/${userId}`,
      data: dto,
    });
  };

  export const blockUserV2 =
  (userId: String, dto: {ban_reason: string, type: UserBlacklistTypes}) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `users/users-search/actions/block/${userId}`,
      data: {
        values: dto
      },
    });
  };

  export const unBlockUserV2 =
  (userId: String, dto: any) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `users/users-search/actions/unblock/${userId}`,
      data: dto,
    });
  };

  export const updateUserV2 =
  (userId: String, dto: {name: string, phone: string, email: string, country: string}) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `users/users-search/actions/update/${userId}`,
      data: {
        values: dto
      },
    });
  };

export const blockUserService =
  (userId: String, dto: { 
    blocked: boolean;
    ban_reason?: string;
    type?: string; 
  }) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `users/user/block/${userId}`,
      data: dto,
    });
  };

export const updateUserDataService =
  (dto: any) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PUT",
      endpoint: `users/user/${dto.userId}`,
      data: dto,
    });
  };

  export const updateProviderStatusService = (dto: any) => 
    async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
      return await backendApiCall({
        method: "PUT", 
        endpoint: `provider/updateProvider`, 
        data: dto, 
      });
    };

  export const migrateUserAddressesService =
  (dto: {
    userAuthID: string
  }) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "POST",
      endpoint: `migration/manual/addresses`,
      data: dto,
    });
  };
