import { useState, useEffect } from "react";
import { Table } from "../../components/Common/Table";
import moment from "moment";
import { FilterRequest, getDataForMkt } from "../../services/user_d";
import { usePost } from "../../hooks/usePost";
import ModalContainer from "../../components/Containers/ModalContainer";

import Auth0Logo from "../../assets/images/auth0-logo.png";
import FbookLogo from "../../assets/images/facebook-logo.png";
import GoogleLogo from "../../assets/images/google-logo.png";
import InstaLogo from "../../assets/images/instagram-logo.png";
import FlagCOL from "../../assets/images/Countries/CO.png";
import FlagMX from "../../assets/images/Countries/MX.png";
import FlagUS from "../../assets/images/Countries/US.png";
import TrackingUser from "./TrackingUser";
import {
  Avatar,
  Col,
  DatePicker,
  Divider,
  Row,
  Select,
  Tag,
  Tooltip,
} from "antd";
import { ErrorOutline, PhoneOutlined } from "@material-ui/icons";
import CardContainer from "../../components/Containers/CardContainer";

export const SwitchLogos = (logo_name: string) => {
  switch (logo_name) {
    case "auth0":
      return (
        <img
          src={Auth0Logo}
          alt={`${logo_name}-logo.png`}
          style={{ minWidth: "40px", maxWidth: "90px" }}
          title={logo_name}
        />
      );
    case "facebook":
      return (
        <img
          src={FbookLogo}
          alt={`${logo_name}-logo.png`}
          style={{ minWidth: "40px", maxWidth: "90px" }}
          title={logo_name}
        />
      );
    case "instagram":
      return (
        <img
          src={InstaLogo}
          alt={`${logo_name}-logo.png`}
          style={{ minWidth: "40px", maxWidth: "90px" }}
          title={logo_name}
        />
      );
    case "google":
      return (
        <img
          src={GoogleLogo}
          alt={`${logo_name}-logo.png`}
          style={{ minWidth: "40px", maxWidth: "90px" }}
          title={logo_name}
        />
      );
    case "colombia":
    case "CO":
      return (
        <img
          src={FlagCOL}
          alt={`${logo_name}-logo.png`}
          style={{ width: "30px" }}
          title={logo_name}
        />
      );
    case "us":
    case "US":
      return (
        <img
          src={FlagUS}
          alt={`${logo_name}-logo.png`}
          style={{ width: "30px" }}
          title={logo_name}
        />
      );
    case "mexico":
    case "MX":
      return (
        <img
          src={FlagMX}
          alt={`${logo_name}-logo.png`}
          style={{ width: "30px" }}
          title={logo_name}
        />
      );
    case "recomendado":
      return (
        <p
          style={{
            color: "#dd1b1b",
            fontWeight: "600",
            fontStyle: "italic",
            margin: "auto",
          }}
        >
          RECOMENDADO
        </p>
      );
    default:
      return <small style={{ fontWeight: "600" }}>NO DISPONIBLE</small>;
  }
};

const Tracing = () => {
  const { fetchData, isLoading } = usePost(getDataForMkt);
  const [filteredData, setFilteredData] = useState<object[]>();
  const [filter, setFilter] = useState<FilterRequest>({});
  const [registerDate, setRegisterDate] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState<{
    open: boolean;
    component: string;
    data?: any;
  }>({
    open: false,
    component: "",
  });

  const { RangePicker } = DatePicker;

  const RenderColumn = (field: any) => {
    if (field) return field;
    return <small style={{ fontWeight: "600" }}>NO DISPONIBLE</small>;
  };

  const getData = async (filter: FilterRequest) => {
    const { error, data } = await fetchData(filter);
    if (!error) {
      setFilteredData(data as object[]);
    } else {
      setFilteredData([]);
    }
  };

  const filterByCreatedAt = (e: any) => {
    let _filter: any = { ...filter };
    setRegisterDate(e);
    if (!e || e === null) {
      delete _filter.created_at;
      setFilter(_filter);
      return;
    }
    _filter.created_at = {
      $gte: moment(e[0]._d).format("YYYY-MM-DD"),
      $lte: moment(e[1]._d).format("YYYY-MM-DD"),
    };
    setFilter(_filter);
  };

  const viewMoreInfo = (data: any) => {
    console.log(data);
    setIsModalOpen({ open: true, component: "moreInfo", data });
  };

  const columns = [
    {
      title: "Seguimiento",
      render: (rowData: any) => TrackingUser(rowData),
    },
    {
      title: "Fecha de Registro",
      field: "created_at",
      render: (rowData: any) => moment(rowData.created_at).format("LL"),
    },
    {
      title: "Nombre",
      field: "name",
      render: (rowData: any) => RenderColumn(rowData.name),
    },
    {
      title: "Correo",
      field: "email",
      render: (rowData: any) => RenderColumn(rowData.email),
    },
    {
      title: "Empresa",
      field: "company",
      render: (rowData: any) => RenderColumn(rowData.company),
    },
    {
      title: "Acciones",
      render: (rowData: any) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Tooltip title="Ver más información">
              <ErrorOutline
                style={{
                  cursor: "pointer",
                  rotate: "180deg",
                  color: "#009689",
                }}
                onClick={() => viewMoreInfo(rowData)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const filterByUserActivity = (type: string) => {
    let _filter = { ...filter };
    if (type === "nuevos_usuarios") {
      _filter["userData.metadata.activity.completedRegister"] = true;
      delete _filter["userData.metadata.activity.firstRecharge"];
    } else if (type === "sin_recargar") {
      _filter["userData.metadata.activity.firstRecharge"] = { $eq: null };
      delete _filter["userData.metadata.activity.completedRegister"];
    } else if (type === "primera_recarga") {
      _filter["userData.metadata.activity.firstRecharge"] = { $ne: null };
      delete _filter["userData.metadata.activity.completedRegister"];
    } else {
      delete _filter["userData.metadata.activity.completedRegister"];
      delete _filter["userData.metadata.activity.firstRecharge"];
    }
    console.log(_filter);
    setFilter(_filter);
  };

  useEffect(() => {
    setRegisterDate([moment().startOf("month"), moment()]);
  }, []); //eslint-disable-line

  useEffect(() => {
    console.log("Se actualiza");
    getData(filter);
  }, [filter]); //eslint-disable-line

  return (
    <>
      <span style={{ fontWeight: "600", color: "#ffffff" }}>Filtros</span>
      <Row
        gutter={[10, 10]}
        style={{
          maxHeight: "100%",
          overflowY: "scroll",
          overflowX: "scroll",
        }}
      >
        <CardContainer cardStyle={{ marginRight: "10px" }}>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col span={6}>
                <RangePicker
                  onChange={(e) => filterByCreatedAt(e)}
                  placeholder={["Fecha inicial", "Fecha final"]}
                  value={registerDate}
                />
              </Col>
              <Col span={4}>
                <Select
                  placeholder="Método de registro"
                  onChange={(e) => setFilter({ ...filter, reg_method: e })}
                  allowClear
                  options={[
                    { label: "Google", value: "google" },
                    { label: "Facebook", value: "facebook" },
                    { label: "Auth0", value: "auth0" },
                  ]}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={4}>
                <Select
                  placeholder="País"
                  onChange={(e) =>
                    setFilter({ ...filter, "userData.app_country": e })
                  }
                  allowClear
                  options={[
                    { label: "Colombia", value: "CO" },
                    { label: "México", value: "MX" },
                  ]}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={4}>
                <Select
                  placeholder="Tipo de usuario"
                  onChange={(e) => filterByUserActivity(e)}
                  allowClear
                  options={[
                    { label: "Nuevos usuarios", value: "nuevos_usuarios" },
                    { label: "Sin recargar", value: "sin_recargar" },
                    { label: "Primera recarga", value: "primera_recarga" },
                  ]}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Divider></Divider>
          <Col span={24}>
            <Table
              columns={columns}
              data={filteredData ? filteredData : []}
              isLoading={isLoading}
            />
          </Col>
        </CardContainer>
      </Row>
      <ModalContainer
        title="Info del usuario"
        visible={isModalOpen?.open}
        onCancel={() =>
          setIsModalOpen({ ...isModalOpen, open: !isModalOpen.open })
        }
        footer={<></>}
      >
        <Row
          gutter={[10, 10]}
          style={{
            textAlign: "center",
          }}
        >
          {isModalOpen?.data !== undefined ? (
            <>
              <Col
                span={24}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Avatar style={{ background: "#099ad3" }} size={64}>
                  {(isModalOpen?.data?.name[0]).toUpperCase()}
                </Avatar>
                <div style={{ textAlign: "center", margin: "0 15px" }}>
                  <p>{isModalOpen?.data?.name || ""}</p>
                  <Tag color="blue">{isModalOpen?.data?.user}</Tag>
                </div>
              </Col>
              <Divider></Divider>
              <Col span={8} style={{ margin: "10px 0" }}>
                <span style={{ margin: "5px 0" }}>Télefono</span> <br />
                <Tag color="blue">
                  <PhoneOutlined style={{ fontSize: "15px" }} />{" "}
                  {isModalOpen?.data?.userData[0]?.app_country === "CO"
                    ? "+57 "
                    : "+52 "}{" "}
                  {isModalOpen?.data?.phone}
                </Tag>
              </Col>
              <Col span={8} style={{ margin: "10px 0" }}>
                <span style={{ margin: "5px 0" }}>País</span> <br />
                {SwitchLogos(isModalOpen?.data?.userData[0]?.app_country)}
              </Col>
              <Col span={8} style={{ margin: "10px 0" }}>
                <span style={{ margin: "5px 0" }}>Método de registro</span>{" "}
                <br />
                {SwitchLogos(isModalOpen?.data?.reg_method)}
              </Col>
              <Col span={24} style={{ textAlign: "center" }}>
                <span style={{ margin: "5px 0" }}>Correo</span> <br />
                <Tag color="blue">{isModalOpen?.data?.email}</Tag>
              </Col>
            </>
          ) : (
            "No hay data..."
          )}
        </Row>
      </ModalContainer>
    </>
  );
};

export default Tracing;
