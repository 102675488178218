import { THashMap } from '../interfaces/utils.interfaces'

const DEFAULT_COUNTERS: FormCounters = {
  name: {
    min: 2,
    max: 45,
  },
  phone: {
    min: 1,
    max: 10,
  },
  company: {
    min: 2,
    max: 45,
  },
  nit: {
    min: 2,
    max: 45,
  },
  email: {
    min: 2,
    max: 45,
  },
  street: {
    min: 2,
    max: 45,
  },
  number: {
    min: 1,
    max: 14,
  },
  int_number: {
    min: 1,
    max: 14,
  },
  district: {
    min: 2,
    max: 45,
  },
  reference: {
    min: 2,
    max: 45,
  },
  package_content: {
    min: 2,
    max: 35,
  },
}

export const getCountersByCarrier = (carrier: AllCarriers) => {
  const COUNTERS: THashMap<FormCounters> = {
    fedex: {
      ...DEFAULT_COUNTERS,
      name: {
        min: 3,
        max: 32,
      },
      company: {
        min: 3,
        max: 34,
      },
      street: {
        min: 3,
        max: 22,
      },
      number: {
        min: 1,
        max: 5,
      },
      int_number: {
        min: 1,
        max: 6,
      },
      district: {
        min: 3,
        max: 35,
      },
      reference: {
        min: 3,
        max: 35,
      },
    },
    estafeta: {
      ...DEFAULT_COUNTERS,
      name: {
        min: 2,
        max: 30,
      },
      street: {
        min: 2,
        max: 30,
      },
      number: {
        min: 1,
        max: 30,
      },
      reference: {
        min: 2,
        max: 25,
      },
      city: {
        min: 1,
        max: 40,
      },
      package_content: {
        min: 2,
        max: 25,
      },
    },
    dhl: {
      ...DEFAULT_COUNTERS,
      name: {
        min: 3,
        max: 29,
      },
      street: {
        min: 2,
        max: 38,
      },
    },
  }

  return COUNTERS[carrier] || DEFAULT_COUNTERS
}

export interface FormCounters {
  name: {
    min: number
    max: number
  }
  phone: {
    min: number
    max: number
  }
  company: {
    min: number
    max: number
  }
  nit: {
    min: number
    max: number
  }
  email: {
    min: number
    max: number
  }
  street: {
    min: number
    max: number
  }
  number: {
    min: number
    max: number
  }
  int_number: {
    min: number
    max: number
  }
  district: {
    min: number
    max: number
  }
  reference: {
    min: number
    max: number
  }
  city?: {
    min: number
    max: number
  }
  package_content: {
    min: number
    max: number
  }
}
