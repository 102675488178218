import { Col, Input } from "antd"
import CardContainer from "../../../../components/Containers/CardContainer"
import { ArrowRightOutlined, SettingOutlined } from "@ant-design/icons";
import { FC } from "react";
import { criteriaSearchPlaceholder } from "../utils";
import { AdvancedCriteria } from "../interfaces";
import { useMediaQuery } from "react-responsive";

interface props {
    inputValue: string;
    advancedCriteria: AdvancedCriteria;
    setAdvancedCriteria: (critria: AdvancedCriteria) => void;
    persistData: (value?: boolean) => void;
    searchAdvancedOption: () => void;
    getSearchCriteria: () => void;
    handlePaste: (e: any) => void;
    handleChangeInput: (e: any) => void;
    manageModal: () => void;
    isDrawer?: boolean;
    isOpenDrawer?: () => void;
}

interface Styles {
    inputSearch: React.CSSProperties;
    searchButton: React.CSSProperties;
    flex: React.CSSProperties;
    listContainer: React.CSSProperties;
    cardInput: React.CSSProperties;
    arrowIcon: React.CSSProperties;
    settingsIcon: React.CSSProperties;
    advancedIcon: React.CSSProperties;
    cardInputDrawer: React.CSSProperties;
}

const styles: Styles = {
    inputSearch: {
        padding: '8px',
        fontSize: '14px',
        width: '100%',
        height: '42px'
    },
    searchButton: {
        backgroundColor: '#6D71F6',
        width: '100px',
        height: 'inherit',
        border: 0
    },
    flex: {
        display: 'flex'
    },
    listContainer: {
        marginTop: '24px',
        width: '100%'
    },
    cardInput: {
        padding: '24px'

    },
    cardInputDrawer: {
        boxShadow: 'none'
    },
    arrowIcon: {
        fontSize: '16px',
        color: 'white',
        marginBottom: '4px'
    },
    settingsIcon: {
        fontSize: '22px',
        color: 'inherit'
    },
    advancedIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px'
    }
}

const InputSearch: FC<props> = ({
    inputValue,
    advancedCriteria,
    persistData,
    searchAdvancedOption,
    handlePaste,
    handleChangeInput,
    setAdvancedCriteria,
    getSearchCriteria,
    manageModal,
    isDrawer = false,
    isOpenDrawer
}) => {
    const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1199px)' })

    const onPaste = (e: any) => {
        if (advancedCriteria.criteria === 'search') {
            persistData(false)
            handlePaste(e)
            handleChangeInput(e)
        } else {
            handleChangeInput(e)
            setAdvancedCriteria({
                ...advancedCriteria,
                text: e.clipboardData.getData("text"),
            })
        }

    }

    const onChange = (e: any) => {
        if (advancedCriteria.criteria === 'search') {
            persistData(false)
            handleChangeInput(e)
            setAdvancedCriteria({
                ...advancedCriteria,
                text: e.target.value,
            })
        } else {
            handleChangeInput(e)
            setAdvancedCriteria({
                ...advancedCriteria,
                text: e.target.value,
            })
        }
    }

    return (
        <CardContainer cardStyle={ isDrawer ? styles.cardInputDrawer : styles.cardInput}>
            <Col span={24} style={styles.flex}>
                <Input
                    value={inputValue}
                    style={styles.inputSearch}
                    onPaste={(e) => onPaste(e)}
                    placeholder={criteriaSearchPlaceholder(advancedCriteria.criteria)}
                    onChange={(e) => onChange(e)}
                />
                <button
                    onClick={() => {
                        if (advancedCriteria.criteria === 'search') {
                            persistData(false)
                            getSearchCriteria()
                        } else {
                            persistData(false)
                            searchAdvancedOption()
                        }

                    }}
                    style={styles.searchButton}>
                    <ArrowRightOutlined style={styles.arrowIcon} />
                </button>
                {
                    isDrawer ? (
                        <section
                            style={styles.advancedIcon}
                            onClick={isOpenDrawer}
                            className="user-advanced-options-settings"
                        >
                            <SettingOutlined style={styles.settingsIcon} />
                        </section>
                    ) : isMobileOrTablet && (
                        <section
                            style={styles.advancedIcon}
                            onClick={manageModal}
                            className="user-advanced-options-settings"
                        >
                            <SettingOutlined style={styles.settingsIcon} />
                        </section>
                    )
                }


            </Col>
        </CardContainer>
    )
}

export default InputSearch