import { Col, Row } from "antd"
import TabsContainer from "../../components/Containers/TabsContainer"
import MyCustomers from "./MyCustomers"
import Sells from "./Sells"
import { useContext, useEffect, useState } from "react"
import usePermission from "../../hooks/usePermission"
import { ActionsSells, PermissionViews } from "../../interfaces/permissions.interfaces"
import { AppContext } from "../../context/appContext"

export interface SellAccessI {
    viewTableSells: boolean
    viewSellerAssign: boolean
}

const SellSection = () => {

    const { hasAccess } = usePermission()
    const { userData } = useContext(AppContext)
    const [tabContent, setTabContent] = useState({})
    const [ tabAccess ] = useState<SellAccessI>({
        viewTableSells: hasAccess(ActionsSells.view_all_sells, PermissionViews.sells),
        viewSellerAssign: hasAccess(ActionsSells.view_seller_assign, PermissionViews.sells),
    })

    const assignTabs = () => {
        let tabObject = {}

        if(userData.user){
            tabObject = {
                ...tabObject,
                "Mis Clientes": {
                    component: <MyCustomers user={userData?.user} />,
                    description: "Mis clientes asignados"
                },
            }
        }

        if(tabAccess.viewTableSells) {
            tabObject = {
                ...tabObject,
                "Ventas": {
                    component: <Sells />,
                    description: "Ventas"
                },
            }
        }
        if(tabAccess.viewSellerAssign) {
            tabObject = {
                ...tabObject,
                "Clientes Azael": {
                    component: <MyCustomers user="auth0|5e9e15e6c4e8ea0d97ac5992" />,
                    description: "Clientes asignados a Azael"
                },
                "Clientes Arely": {
                    component: <MyCustomers user="auth0|5f6a29130ae09d006fd10a1d" />,
                    description: "Clientes asignados a Arely"
                },
                "Clientes Valeria": {
                    component: <MyCustomers user="auth0|608aeee5260f9e00693d50ac" />,
                    description: "Clientes asignados a Valeria"
                },
                "Clientes María": {
                    component: <MyCustomers user="auth0|625da110c6f3ec0068eab355" />,
                    description: "Clientes asignados a María"
                },
            }
        }
        setTabContent(tabObject)
    }

    useEffect(() => {
        assignTabs()
        //eslint-disable-next-line
    }, [])

  return (
    <Row gutter={[10,10]} className="dashboardSection">
        <Col span={24} style={{ overflowY:'scroll' }}>
            <TabsContainer titlesAndContent={tabContent} />
        </Col>
    </Row>
  )
}

export default SellSection