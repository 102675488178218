import React, { useContext, useEffect, useState, createRef } from 'react'
import { FilterOutlined } from '@ant-design/icons'
import moment from 'moment'
import { Tag, Tooltip, Typography } from 'antd'
import { formatPrice2 } from '../../../utils/prices'
import { Table, TColumnTable } from '../../../components/Common/Table'
import { AdminDataContext } from '../../../context/adminContext'
import { ApiContext } from '../../../context/apiContext'
import { MovementStatus } from '../../../components/Common/MovementStatus'
import { useSectionFilters } from '../../../hooks/sections/useSectionFilters'
import ModalContainer from '../../../components/Containers/ModalContainer'
import { FilterList, FilterTypes } from '../../../components/Sections/FiltersList'

const Movements: React.FC = () => {
  const {
    state,
    toggle,
    byPrice,
    filterSelected,
    rangeDate,
    setByPrice,
    setFilterSelected,
    setRangeDate,
    resetFilters,
  } = useSectionFilters()

  const perBatch = 100
  const tableRef = createRef()
  const { country } = useContext(AdminDataContext)
  const { backendApiCall } = useContext(ApiContext)

  const [reload, setReload] = useState<boolean>(false)
  const [batchesFetched, setBatchesFetched] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [movementsFiltered, setMovementsFiltered] = useState<MovementsI[]>([])

  const columns: TColumnTable = [
    {
      title: 'Usuario',
      field: 'user',
      render: (rowData: any) => (
        <div style={{ cursor: 'pointer' }}>
          <Tooltip title={rowData.user_id}>{rowData.user}</Tooltip>
        </div>
      ),
    },
    {
      title: 'Tipo',
      field: 'type',
      render: (rowData: any) => <MovementStatus status={rowData.type}></MovementStatus>,
    },
    {
      title: 'Descripción',
      field: 'description',
      render: (rowData: any) => {
        return rowData.type === 'ENVIO' ||
          (rowData.type === 'REEMBOLSO' &&
            rowData.description !== 'Nuevo Movimiento') ? (
          <>
            <Tag
              color={rowData.type === 'ENVIO' ? 'blue' : 'orange'}
              style={{ marginTop: '5px' }}
            >
              Paquetería: {rowData.description.toUpperCase()}
              <br />
              {`N° de guia: ${rowData.payment_id}`}
            </Tag>
          </>
            ) : (
              <>{rowData.description}</>
            )
      }
    },

    {
      title: 'Fecha',
      field: 'date',
      render: (rowData: any) => (
        <>
          < Typography>{rowData.date}</Typography>
          < Typography>{rowData.minutes}</Typography>
        </>
      )
    },
    {
      title: 'Cantidad',
      field: 'quantity',
      render: (rowData: any) => (
        <>
          {rowData.type === 'SOBREPESO' || rowData.type === 'DESCUENTO'
            ? '$-' + rowData.quantity.split('$')[1]?.trim()
            : rowData.quantity}
        </>
      ),
    },
    {
      title: 'Saldo Anterior',
      field: 'prevBalance',
    },
    {
      title: 'Saldo Posterior',
      field: 'postBalance',
    },
    {
      title: 'Realizado por',
      field: 'made_by',
    },
  ]

  const fetchMovements = async () => {
    setIsLoading(true)

    const yesterday = moment().subtract(1, 'days').startOf('day').format('MM-DD-YYYY') //19 marzo
    const today = moment().add(1, 'days').format('MM-DD-YYYY') //20 marzo

    const rangeOne = rangeDate ? moment(rangeDate[0]).format('MM-DD-YYYY') : yesterday
    const rangeTwo = rangeDate ? moment(rangeDate[1]).format('MM-DD-YYYY') : today

    const response = await backendApiCall({
      method: 'GET',
      endpoint: `admin/movements/${country}/${batchesFetched * perBatch}/${(batchesFetched + 1) * perBatch}/${rangeOne}/${rangeTwo}/${byPrice}`,
    })

    // @ts-ignore
    const optimizedData = response.data.data.movements.res.map((movement: object) => {
      return movementsData(movement)
    })

    // setBatchesFetched((prevBatch) => prevBatch + 1)
    setReload(false)
    setMovementsFiltered(optimizedData)
    setIsLoading(false)
  }

  const movementsData = (item: any): object => {
    return {
      user: item.profile[0]?.email,
      user_id: item.user,
      payment_id: item.payment_id,
      description: item.description,
      date: moment(item.created_at).format('L'),
      minutes: moment(item.created_at).format('h:mm:ss a'),
      quantity: '$ ' + formatPrice2(item.amount, item.currency),
      type: item.type,
      made_by: item.made_by ? item?.madeBy[0]?.email || 'No aplica' : 'Sistema',
      postBalance: '$ ' + formatPrice2(item.security.after_mod, item.currency),
      prevBalance: '$ ' + formatPrice2(item.security.before_mod, item.currency),
    }
  }

  const handlePageChange = (page: any) => {
    if(page === 0 && batchesFetched > 1){
      setBatchesFetched(page)
      setReload(true)
    }
    if ((page + 1) * rowsPerPage === perBatch * batchesFetched) {
      fetchMovements()
      setBatchesFetched((prevBatch) => prevBatch + 1)
    }
  }

  useEffect(() => {
    fetchMovements()
    !reload && setBatchesFetched((prevBatch) => prevBatch + 1)
  }, [country, reload]) // eslint-disable-line

  useEffect(() => {
    if ((!byPrice && !rangeDate) || (byPrice || rangeDate)) {
        setBatchesFetched(0);
        setReload(true)
      }
  }, [byPrice, rangeDate]) // eslint-disable-line

  return (
    <>
      <Table
        data={movementsFiltered}
        isLoading={isLoading}
        tableRef={tableRef}
        columns={columns}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={(rows) => setRowsPerPage(rows)}
        title='Movimientos más recientes'
        isButton={{
          title: 'Aplicar Filtros',
          onClick: () => {
            toggle()
          },
          icon: <FilterOutlined />,
        }}
      />
      <ModalContainer
        title={'Seleciona un Filtro'}
        visible={state}
        onCancel={toggle}
        footer={false}
      >
        <div className='JSONViewer'>
          <div style={{ display: 'flex' }}>
            <FilterList
              filterSelected={filterSelected as FilterTypes}
              resetAllFilters={resetFilters}
              setByPrice={setByPrice}
              setFilterSelected={setFilterSelected}
              setIsVisible={toggle}
              setRangeDate={setRangeDate}
            />
          </div>
        </div>
      </ModalContainer>
    </>
  )
}

export default Movements

interface MovementsI {
  user: string
  user_id: string
  description: string
  date: string
  quantity: string
  type: string
  made_by: string
  postBalance: string
  prevBalance: string
  tableData: {
    id: number
  }
}
