import { Col, Form, Radio, Row } from "antd"

const styles = {
    radio: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "2rem",
        borderRadius: "12px"
    },
    leftRadio: {
        borderRadius: "12px 0 0 12px"
    },
    rightRadio: {
        borderRadius: "0 12px 12px 0"
    }
}

const VersionRadio = () => {
    return (
        <Row>
            <Col span={24} >
                <Form.Item name="version">
                    <Radio.Group
                        value="Apple"
                        optionType="button"
                        buttonStyle="solid"
                        style={styles.radio}
                        size="middle"
                    >
                        <Radio.Button style={styles.leftRadio} value="v2">Versión 2</Radio.Button>
                        <Radio.Button style={styles.rightRadio} value="v1">Versión 1</Radio.Button>
                    </Radio.Group>
                </Form.Item>
            </Col>
        </Row>
    )
}

export default VersionRadio