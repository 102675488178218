import {
    createStore, 
    applyMiddleware, 
    combineReducers,
    compose
} from 'redux'
import thunk from 'redux-thunk'

import {
   StoreI,
} from './schema';
// list ducks'
// ducks normalized name = name_d.ts
import app from './app_d'
import user from './user_d'


// combine reducers
const rootReducer = combineReducers<StoreI>({
    app,
    user,
});

// const composeEnhancers = (window as any)['_REDUX_DEVTOOLS_EXTENSION_COMPOSE_'] as typeof compose || compose;
// const STORE = createStore(
//     rootReducer, 
//     composeEnhancers(applyMiddleware(thunk))
// );
const composeEnhancers = (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;
const STORE = createStore(
    rootReducer, 
    composeEnhancers( applyMiddleware(thunk))
);
    
export default STORE;