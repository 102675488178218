import { useCallback, useContext, useState } from 'react';
import { ApiContext } from '../context/apiContext';
import { UseGetParams, RequestStatus } from '../interfaces/app.interfaces';

export const useGetParams = <T = any, K = any>(
	callback: UseGetParams<T, K>
) => {
	const { backendApiCall } = useContext(ApiContext);
	const [data, setData] = useState<T | null>(null);
	const [isLoading, setIsLoading] = useState(true);

	const fetchData = useCallback(
		async (params?: K) => {
			setIsLoading(true);
			const response = await callback(params)(backendApiCall);
			if (response.status === RequestStatus.Success) {
				setData(response.data.data);
			}
			setIsLoading(false);
		},
		[backendApiCall, callback]
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps

	return {
		data,
		isLoading,
		fetchData,
	};
};
