import { apiProps, apiResponse } from '../interfaces/app.interfaces'
import { GuidePayload } from '../interfaces/guides.interface'

export const generateGuide =
  (dto: GuidePayload) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: 'POST',
      endpoint: 'shipments/generate',
      data: dto,
    })
  }
