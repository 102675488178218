import { FC } from 'react'

interface BlogTextProps {
  title?: string
}

const BlogText: FC<BlogTextProps> = ({ title, children }) => {
  return (
    <div>
      {title && <h3 style={{ marginTop: '25px' }}>{title}</h3>}
      <p style={{ fontSize: '1.25rem', textAlign: 'justify' }} className="fw-normal">{children}</p>
    </div>
  )
}

export default BlogText
