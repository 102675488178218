import moment from 'moment'
import { useEffect, useState } from 'react'
import { FilterByDateRangeKeys } from '../components/Common/FilterByDate'

export const getDateFromToday = (days: number): Date => {
    return moment().subtract(days, 'days').startOf('day').toDate()
}

export const getStartOfDate = (range: 'month' | 'year'): Date => moment().startOf(range).toDate()

export const useFilterByDate = () => {
  const [rangeInText, setRangeInText] = useState<FilterByDateRangeKeys | null>(
    FilterByDateRangeKeys.TODAY
  )
  const [range, setRange] = useState<[Date | null, Date | null]>([null, null])

  useEffect(() => {
    if (rangeInText === FilterByDateRangeKeys.TODAY) {
      setRange([new Date(), new Date()])
      return
    }

    if (rangeInText === FilterByDateRangeKeys.YESTERDAY) {
      setRange([getDateFromToday(1), new Date()])
    }

    if (rangeInText === FilterByDateRangeKeys.LAST_7_DAYS) {
      setRange([getDateFromToday(7), new Date()])
    }

    if (rangeInText === FilterByDateRangeKeys.LAST_30_DAYS) {
      setRange([getDateFromToday(30), new Date()])
    }

    if (rangeInText === FilterByDateRangeKeys.THIS_YEAR) {
      setRange([getStartOfDate('year'), new Date()])
    }
  }, [rangeInText])

  return {
    rangeInText,
    range,
    setRangeInText,
    setRange
  }
}