import { Dropdown, RowProps, Space, Tooltip } from "antd";
import axios from "axios";
import { QueryResult } from "material-table";
import { createRef, useContext, useState } from "react";
import ReactJson from "react-json-view";
import { Table, TColumnTable } from "../../components/Common/Table";
import ModalContainer from "../../components/Containers/ModalContainer";
import { ApiContext } from "../../context/apiContext";
import moment from "moment";
import { RefreshOutlined } from "@material-ui/icons";
import { ErrorLogsActions } from "./ErrorLogsActions";
import { DownOutlined } from '@ant-design/icons'
import { useToggle } from "../../hooks/useToggle";
import { GenerateGuideModal } from "../../components/generate/GenerateGuideModal";
import { GuidePayload } from "../../interfaces/guides.interface";

const ErrorLogs = () => {
  // const { data: allLogs } = useGet(getDrEnvioLogs)
  const { backendURL, userToken } = useContext(ApiContext);
  const { state: isGenerateModalVisible, toggle: setIsGenerateModalVisible } = useToggle(false)

  const [visible, setVisible] = useState(false);
  const [logs, setLogs] = useState<{} | any>();
  const [type, setType] = useState<"payload" | "response">("payload");
  const [guidePayload, setGuidePayload] = useState<GuidePayload | null>(null)
  const [currentRowData, setCurrentRowData] = useState<any | null>(null)

  const tableRef = createRef();

  const ViewPayload = (e: any, type: "payload" | "response") => {
    setVisible(true);
    setLogs(e);
    setType(type);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const columns: TColumnTable = [
    {
      title: "Fecha",
      field: "date",
    },
    {
      title: "Usuario",
      field: "user",
      render: (rowData: any) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <Tooltip title={rowData.user || "No disponible - auth"}>
              {rowData.email ? rowData.email : "No disponible"}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Codigo",
      field: "code_error",
      render: (rowData: any) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <Tooltip title={rowData.code_error || "No disponible - auth"}>
              {rowData.code_error ? rowData.code_error : "No disponible"}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Componente",
      field: "component",
      render: (rowData: any) => {
        return rowData.component ? rowData.component : "No disponible";
      },
    },
    {
      title: "service_id",
      field: "component",
      render: (rowData: any) => {
        return rowData.service_id ? rowData.service_id : "No disponible";
      },
    },
    {
      title: "Mensaje",
      field: "msg",
      render: (rowData: any) => {
        return rowData.msg ? rowData.msg : "No disponible";
      },
    },
    {
      title: 'Info',
      field: 'info',
      render: (rowData: any) => {
        return (
          <Dropdown
            overlay={ErrorLogsActions({
              showPayloadFn: () => ViewPayload(rowData.info.payload, 'payload'),
              showResponseFn: () => ViewPayload(rowData.info.response, 'response'),
              showGenerateModalFn: rowData?.info?.payload.service_id ? () => {
                setIsGenerateModalVisible();
                setGuidePayload(JSON.parse(rowData.info.payload));
                setCurrentRowData(rowData)
              } : null,
            }) as any}
          >
            <Space
              style={{
                backgroundColor: "#6D71F6",
                height: "50%",
                width: "90%",
                justifyContent: "center",
                cursor: 'pointer',
                fontSize: '0.9rem',
                borderRadius: 6
              }}
            >
              <DownOutlined style={{ color: "#FFF", fontSize: '0.8rem', marginBottom: '8px' }} />
            </Space>
          </Dropdown>
        )
      }
    },
  ];

  const fetchData = (query: any): Promise<QueryResult<RowProps>> => {

    return new Promise((resolve, reject) => {
      axios
        .get(`${backendURL}admin/logs`, {
          headers: { Authorization: `Bearer ${userToken}` },
          params: {
            page: query.page,
            limit: query.pageSize,
            search: query.search,
          },
        })
        .then((result) => {
          resolve({
            data:
              result.data.response?.map((item: any) => ({
                date: moment(item.created_at).format("lll"),
                user: item.user,
                email: item.email,
                code_error: item.code_error,
                component: item.component,
                msg: item.msg,
                service_id: item.service_id ? item.service_id : 'No Encontrado',
                info: {
                  payload: item.payload,
                  response: item.response,
                }

              })) || [],
            page: query.page,
            totalCount: result.data.total,
          });
        });
    });
  };

  return (
    <>
      <Table
        tableRef={tableRef}
        columns={columns}
        //@ts-ignore
        data={(query) => fetchData(query)}
        title="Errores de DrEnvio"
        actions={[
          {
            icon: () => <RefreshOutlined />,
            tooltip: "Refrescar datos",
            isFreeAction: true,
            //Le coloque @ts-ignore, porque daba error. Pero funciona bien, probe muchas veces jaja
            //@ts-ignore
            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
          },
        ]}
      ></Table>
      <ModalContainer
        title={`${type === "payload" ? "Petición" : "Respuesta"}`}
        visible={visible}
        onCancel={closeModal}
        footer={false}
      >
        <div className="JSONViewer">
          {logs ? <ReactJson src={JSON.parse(logs)} /> : "Data no disponible"}
        </div>
      </ModalContainer>
      {
        isGenerateModalVisible && <GenerateGuideModal rowData={currentRowData} guide={guidePayload} onCancel={setIsGenerateModalVisible} isVisible={isGenerateModalVisible} />

      }
    </>
  );
};

export default ErrorLogs;
