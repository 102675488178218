import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Form, Input, notification } from 'antd'

const AddReferralModal: React.FC<{ 
    newReferralModal: boolean, 
    filters: any
}> = ({
    newReferralModal,
    filters
}) => {
    const { t } = useTranslation()
    const [ownerEmail, setOwnerEmail] = useState('')
    const [referralEmail, setReferralEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [formKeyOwnerEmail, setFormKeyOwnerEmail] = useState(0)
    const [formKeyReferralEmail, setFormKeyReferralEmail] = useState(0)

    const resetFormFields = ()=> {
        setOwnerEmail('')
        setReferralEmail('')
        setFormKeyOwnerEmail(key => key + 1)
        setFormKeyReferralEmail(key => key + 1)
    }

    const onOk = async ()=> {
        const errors = {
            'Usuario propietario no existe': t("Referrals.Table.AddReferralModal.Errors.NotExistentOwner"),
            'Usuario referido no existe': t("Referrals.Table.AddReferralModal.Errors.NotExistentReferral"),
            'El nuevo referido se encuentra como referido por otro usuario': t("Referrals.Table.AddReferralModal.Errors.ReferredByAnotherUser"),
            'El nuevo referido ya es parte de los referidos del usuario propietario': t("Referrals.Table.AddReferralModal.Errors.ExistingReferralByOwner"),
            'El propietario y el referido no pueden contener los mismos emails': t("Referrals.Table.AddReferralModal.Errors.SameEmails")
        }

        if (ownerEmail === '' || referralEmail === ''){
            notification.warning({
                message: t('Referrals.Table.AddReferralModal.Notification.FillFields')
            })
            return 
        }

        setLoading(true)
        const newReferral =  {
            ownerEmail,
            referralEmail,
        }

        setTimeout(async ()=> {
            const rta = await filters?.manageReferrals?.addNewReferral(newReferral)
            if (rta?.data.code === 500 ){
                setLoading(false)
                notification.error({
                    message: errors[rta?.data?.res as 'Usuario propietario no existe']
                })
                return 
            } else {
                setLoading(false)
                notification.success({
                    message: t("Referrals.Table.AddReferralModal.Notification.AddedSuccessfully")
                })
                filters?.manageReferrals?.activateNewReferral()
                filters?.manageReferrals?.toggleNewReferralModal()
                resetFormFields()
            }
        }, 1000)    
    }

    const onCancel = ()=> {
        resetFormFields()
        filters?.manageReferrals?.toggleNewReferralModal()
    }

    useEffect(()=> {
        resetFormFields()
    }, [newReferralModal])

    return (
        <Modal
            visible={newReferralModal}
            confirmLoading={loading}
            onOk={onOk}
            onCancel={onCancel}
            okText={t("Referrals.Table.AddReferralModal.Button.Ok")}
            cancelText={t("Referrals.Table.AddReferralModal.Button.Cancel")}
        >
            <h2 
                style={{textAlign: 'center', color: 'rgb(45, 27, 110)', fontSize: '2em', fontWeight: 'bold'}}
            >
                {t("Referrals.Table.AddReferralModal.Title")}
            </h2>
            <Form style={{ padding: '20px'}}>
                <Form.Item
                    label={t("Referrals.Table.AddReferralModal.OwnerEmail.Label")}
                    name={`ownerEmail${formKeyOwnerEmail}`}
                    rules={[{ required: true, message: t("Referrals.Table.AddReferralModal.OwnerEmail.ErrorMessage") }]}
                    style={{marginBottom: '10px' }}
                >
                    <Input value={ownerEmail} onChange={(e) => setOwnerEmail(e.target.value)} />
                </Form.Item>
                <Form.Item
                    label={t("Referrals.Table.AddReferralModal.ReferralEmail.Label")}
                    name={`referralEmail${formKeyReferralEmail}`}
                    rules={[{ required: true, message: t("Referrals.Table.AddReferralModal.ReferralEmail.ErrorMessage") }]}
                >
                    <Input value={referralEmail} onChange={(e) => setReferralEmail(e.target.value)} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddReferralModal