import { FC, PropsWithChildren } from "react";
import UserHeader from "../Components/UserHeader"
import { UserItemI } from "../Search/SearchItem"
import { userActionsT } from "../interfaces";

interface props {
    title: string;
    userFound: UserItemI;
    changeActions: (type: userActionsT) => void;
    showDrawer: () => void;
    roles: string[];
}

interface Styles {
    container: React.CSSProperties;
    childrenContent:React.CSSProperties; 
}

const styles: Styles = {
    container: {
        minHeight: '30rem'
        
    },
    childrenContent:{
        padding: '32px 16px'
    }
}

const SectionLayout: FC<PropsWithChildren<props>> = ({ 
    userFound, 
    title, 
    children, 
    changeActions, 
    showDrawer,
    roles 
}) => {
    return (
        <section style={styles.container}>
            <UserHeader 
                userFound={userFound} 
                changeActions={changeActions} 
                showDrawer={showDrawer}
                roles={roles}
            >
                    {title}
                </UserHeader>
            <section style={styles.childrenContent}>
                 {children}
            </section>
           
        </section>
    )
}

export default SectionLayout