import { UserData } from "../interfaces/app.interfaces";
import { ActionsMarketingSection } from "../interfaces/permissions.interfaces";

export const userhavePermissions = (identifier: string, userData: UserData) => {
  switch (identifier) {
    case "Usuarios":
      if (userData?.metadata?.permissions?.search_users?.menu) return true;
      return false;

    case "Secciones":
      if (userData?.metadata?.permissions?.sections?.menu) return true;
      return false;

    case "Referidos":
      if (userData?.metadata?.permissions?.referrals?.menu) return true;
      return false;

    case "Fulfillment":
      if (userData?.metadata?.permissions?.fulfillment?.menu) return true;
      return false;

    case "Registros":
      if (userData?.metadata?.permissions?.registers?.menu) return true;
      return false;

    case "Precios":
      if (userData?.metadata?.permissions?.prices_section?.menu) return true;
      return false;

    case "Descargas":
      if (userData?.metadata?.permissions?.downloads?.menu) return true;
      return false;

    case "Permisos":
      if (userData?.metadata?.permissions?.permissions?.menu) return true;
      return false;

    case "Desarrolladores":
      if (userData?.metadata?.permissions?.developers?.menu) return true;
      return false;

    case "Marketing":
      if (userData?.metadata?.permissions?.marketing?.menu) return true;
      return false;

    case "Buscador":
      if (userData?.metadata?.permissions?.finder?.menu) return true;
      return false;
      
    case "Ventas":
      if (userData?.metadata?.permissions?.sells?.menu) return true;
      return false;

    default:
      return true;
  }
};

export const userhaveMarketingPermissions = (permission: string, userData: UserData) => {
  switch (permission) {
    case 'Estadisticas':
      if (userData?.metadata?.permissions?.marketing?.actions.includes(ActionsMarketingSection.marketing_stadistics)) return true;
      return false;

    case 'Seguimientos':
      if (userData?.metadata?.permissions?.marketing?.actions.includes(ActionsMarketingSection.user_tracking)) return true;
      return false;

    default:
      return true;
  }
};
