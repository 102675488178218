import { Alert, Col, Row, Typography } from "antd"
import CardContainer from "../../../components/Containers/CardContainer"
import { FC } from "react";
import InputContainer from "../../../components/Containers/InputContainer";

const styles = {
    title: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        marginBottom: '1rem'
    },
}
interface props {
    currentStore: any;
}

const FormData: FC<props> = ({ currentStore }) => {

    return (

        <Row gutter={[20, 20]} style={{ width: '100%', margin: '0 1rem' }}>
            <Col span={24}>
                {
                    currentStore?.is_read_only ? (
                        <>
                            <Typography style={styles.title}>
                                Integración de solo lectura
                            </Typography>
                            <Alert
                                type="warning"
                                message="Ésta integración ha sido configurada a solo lectura, significa que no mostrará nuingun formulario al usuario."
                                showIcon
                                style={{ borderRadius: '10px' }}
                            />
                        </>
                    ) : (
                        <>
                            <Typography style={styles.title}>
                                Vista previa del formulario
                            </Typography>
                            <Alert
                                type="info"
                                message=" Los campos Nombre y URL vienen por defecto en todas las integraciones."
                                showIcon
                                style={{ borderRadius: '10px' }}
                            />
                            <br />
                            <CardContainer>
                                <InputContainer
                                    type="text"
                                    placeholder="Nombre de la tienda"
                                    valueContainerName="name"
                                />
                                <InputContainer
                                    type="text"
                                    placeholder="Url de la tienda (Sin símbolos despues de .com)"
                                    valueContainerName="url"
                                />
                                {
                                    currentStore.fields && currentStore.fields.length > 0 && currentStore.fields.map((item: any) =>
                                        <InputContainer
                                            type={item.type}
                                            placeholder={item.placeholder.es}
                                            valueContainerName={item.name}
                                        />
                                    ) 
                                }
                                                                {
                                    currentStore.meta_fields && currentStore.meta_fields.length > 0 && currentStore.meta_fields.map((item: any) =>
                                        <InputContainer
                                            type={item.type}
                                            placeholder={item.placeholder.es}
                                            valueContainerName={item.name}
                                        />
                                    ) 
                                }
                                <br/>
                                <InputContainer
                                    type="submitButton"
                                    title="Guardar claves"
                                    disabled={true}
                                />
                            </CardContainer>
                        </>
                    )
                }


            </Col>
        </Row>
    )
}

export default FormData