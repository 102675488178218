import { Select } from 'antd'
import { FC } from 'react'

export const TablePriceFilter: FC<Props> = ({ onChange }) => {
  return (
    <Select
      onChange={onChange}
      placeholder='Selecciona una opción'
      options={[
        {
          label: 'Ordenar por precios más altos',
          value: 'highest',
        },
        {
          label: 'Ordernar por precios más bajos',
          value: 'lowest',
        },
      ]}
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  )
}

type Props = {
  onChange: (price: ByPriceTypes) => void
}

export type ByPriceTypes = 'highest' | 'lowest'
