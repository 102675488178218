import { useContext, useState } from "react";

import { Col, Row, Switch, Typography } from "antd";
import InputContainer from "../../components/Containers/InputContainer";
import { PricesContext } from "../../context/PricesContext";
import { usePost } from "../../hooks/usePost";
import { updateServiceMode } from "../../services/services_s";
import usePermission from "../../hooks/usePermission";
import { ActionsPricesSection, PermissionViews } from "../../interfaces/permissions.interfaces";
import AccessMsg from "../../components/Common/AccessMsg";
// import { CardPricesTitle } from "./CardPricesTitle";

export const CardPricesTitle = () => {
  const {
    parcelSelected,
    fetchedServices,
    selectedServiceId,
    setSelectedServiceId,
    setIsUsersModalOpen,
    strategy,
  } = useContext(PricesContext);
  const { fetchData } = usePost(updateServiceMode);
  const [, setIsPricesLoading] = useState(false);
  const { hasAccess } = usePermission()

  const onChangeServiceMode = async (e: boolean) => {
    const mode = e ? "percentage" : "static";

    if (selectedServiceId) {
      setSelectedServiceId({
        ...selectedServiceId,
        mode,
      });
      await fetchData({
        mode,
        id: selectedServiceId._id,
      });
    }
  };

  const handleSelectServiceChange = (e: string) => {
    setIsPricesLoading(() => true);
    setSelectedServiceId(() =>
      fetchedServices?.find((service: any) => service.service_id === e)
    );
    setIsPricesLoading(() => false);
  };

  return (
    <Row align="middle">
      <Col flex={1}>
        <Typography.Title level={3} style={{ fontWeight: "bold" }}>
          Tarifas {parcelSelected?.commercial_name}
        </Typography.Title>
      </Col>
      <Col>
        {
          selectedServiceId &&
          (strategy === "Precios" ? (
            <AccessMsg 
              label="Modificar Tarifas" 
              access={hasAccess(ActionsPricesSection.modify_prices, PermissionViews.prices_section)} >
              <Switch
                checkedChildren={"Porcentaje"}
                unCheckedChildren={"Estatico"}
                checked={selectedServiceId?.mode === "percentage"}
                onChange={onChangeServiceMode}
                disabled={!hasAccess(ActionsPricesSection.modify_prices, PermissionViews.prices_section)}
              />
            </AccessMsg>
          ) : (
            <AccessMsg 
              label="Modificar P. Especiales" 
              access={hasAccess(ActionsPricesSection.modify_special_prices, PermissionViews.prices_section)} >
              <InputContainer
                onClick={() => setIsUsersModalOpen(true)}
                title="Agregar precio especial"
                type="button"
                disabled={!hasAccess(ActionsPricesSection.modify_special_prices, PermissionViews.prices_section)}
              />
            </AccessMsg>

          ))
        }
      </Col>
      <InputContainer
        type="select"
        optionsList={
          fetchedServices?.map((service) => service.service_id) || []
        }
        placeholder="ID del servicio"
        onChange={handleSelectServiceChange}
        valueContainerName="Test"
      />
    </Row>
  );
};
