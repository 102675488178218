import { PeopleAlt } from '@material-ui/icons'
import { Col, Row, Select, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import ModalContainer from '../../../../components/Containers/ModalContainer'
import { Table } from '../../../../components/Common/Table'
import { usePost } from '../../../../hooks/usePost'
import { FilterRequest, getDataForMkt } from '../../../../services/user_d'
import moment from 'moment'
import { SwitchLogos } from '../../../MarketingSection/Tracing'

const MethodsMostUsed = ({date}:any) => {

    const { fetchData, isLoading } = usePost(getDataForMkt);
    const [openModal, setOpenModal] = useState(false)
    const [filteredData, setFilteredData] = useState<object[]>()
    const [filter, setFilter] = useState<FilterRequest>({});

    const getData = async() => {
        const { error, data } = await fetchData(filter);
        if (!error) {
            setFilteredData(data as object[]);
        } else {
            setFilteredData([]);
        }
    }

    const RenderColumn = (field: any) => {
        if (field) return field;
        return <small style={{ fontWeight: "600" }}>NO DISPONIBLE</small>;
    };

    const columns = [
        {
            title: "Fecha de Registro",
            field: "created_at",
            render: (rowData: any) => moment(rowData.created_at).format("LL"),
        },
        {
            title: "Nombre",
            field: "name",
            render: (rowData: any) => RenderColumn(rowData.name),
        },
        {
            title: "Correo",
            field: "email",
            render: (rowData: any) => RenderColumn(rowData.email),
        },
        {
            title: "Empresa",
            field: "company",
            render: (rowData: any) => RenderColumn(rowData.company),
        },
        {
            title: "Método",
            field: "reg_method",
            render: (rowData: any) => SwitchLogos(rowData.reg_method)
        }
    ]

    useEffect(() => {
        getData()
    }, [filter])//eslint-disable-line

    const filterSelector = (
        <Select
            placeholder='Método de registro'
            onChange={(e) => setFilter({ ...filter, reg_method: e })}
            allowClear
            options={[
                {label:'Google', value:'google'},
                {label:'Facebook', value:'facebook'},
                {label:'Auth0', value:'auth0'},
            ]}
            style={{ width:'12rem' }}
            value={filter?.reg_method || null}
        />
    )

  return (
    <>
        <Tag color='blue' onClick={() => setOpenModal(true)} style={{ display:'flex', alignItems:'center', cursor:'pointer', margin:'5px 0' }}>
            <PeopleAlt />
            <small>Ver usuarios</small>
        </Tag>
        <ModalContainer
            visible={openModal}
            title="Métodos de registro más usados"
            footer={[<></>]}
            onCancel={() => setOpenModal(false)}
            customWidth='80%'
        >
            <Row>
                <Col span={24}>
                    <Table data={filteredData || []} columns={columns} isLoading={isLoading} component={filterSelector}/>
                </Col>
            </Row>
        </ModalContainer>
    </>
  )
}

export default MethodsMostUsed