import { IconButton } from "@material-ui/core"
import { Edit, Cancel } from "@material-ui/icons"
import { Tooltip } from "antd"
import { FC } from "react"

interface Props {
    editMode: boolean;
    changeEditMode: () => void
}

const ModifyAction: FC<Props> = ({ editMode, changeEditMode }) => {
    return (
        <div style={{ display: "flex" }}>
            <Tooltip title={!editMode ? "Cambiar status" : "Cancelar"}>
                <IconButton
                    onClick={changeEditMode}
                >
                    {
                        !editMode ? <Edit /> : <Cancel style={{color: "red"}} />
                    }
                </IconButton>
            </Tooltip>
        </div>
    )
}

export default ModifyAction