import { Table } from '../../components/Common/Table'
import TabsContainer from '../../components/Containers/TabsContainer'
import { useGet } from '../../hooks/useGet'
import { NotificationI, NotificationType } from '../../interfaces/notification.interface'
import { getAllNotifications } from '../../services/notification_s'
import { formatDate } from '../../utils/string'

export const Sms = () => {
  const { data } = useGet<NotificationI[]>(getAllNotifications)

  const whatsappSms = data?.filter((m) => m.type === NotificationType.WHATSAPP)
  const sms = data?.filter((m) => m.type === NotificationType.SMS)

  const smsTableData =
    sms?.map((m) => ({
      created_at: m.sendAt ?? new Date(),
      user: m.email,
      destination: m.destination,
      content: m.type === NotificationType.SMS ? m.metadata.content : '',
      guideNumber: m.guideNumber,
    })) ?? []

  const whatsappTableData =
    whatsappSms?.map((m) => ({
      created_at: m.sendAt ?? new Date(),
      user: m.email,
      destination: m.destination,
      content: m.type === NotificationType.WHATSAPP ? m.metadata.content : '',
      guideNumber: m.guideNumber,
    })) ?? []

  const emailTableData =
    data?.map((m) => ({
      created_at: m.sendAt ?? new Date(),
      user: m.email,
      destination: m.destination,
      content: 'Email de guía generada',
      guideNumber: m.guideNumber,
    })) ?? []

  return (
    <section className='dashboardSection'>
      <TabsContainer
        titlesAndContent={{
          Sms: {
            component: (
              <Table
                data={smsTableData}
                isLoading={false}
                columns={Columns()}
                // onChangePage={(page) => setCurrentTablePage(page)}
                title='Historial de mensajes'
                // onChangeRowsPerPage={(rows) => setCurrentRowsPerPage(rows)}
              />
            ),
            description: 'Sms',
          },
          Whatsapp: {
            component: (
              <Table
                data={whatsappTableData}
                isLoading={false}
                columns={Columns()}
                // onChangePage={(page) => setCurrentTablePage(page)}
                title='Historial de whatsapp'
                // onChangeRowsPerPage={(rows) => setCurrentRowsPerPage(rows)}
              />
            ),
            description: 'Sms',
          },
          Email: {
            component: (
              <Table
                data={emailTableData}
                isLoading={false}
                columns={Columns()}
                // onChangePage={(page) => setCurrentTablePage(page)}
                title='Historial de emails'
                // onChangeRowsPerPage={(rows) => setCurrentRowsPerPage(rows)}
              />
            ),
            description: 'Sms',
          },
        }}
      />
    </section>
  )
}

export const Columns = () => [
  {
    title: 'Fecha de creación',
    field: 'created_at',
    render: (input: object): JSX.Element => {
      if (!(input as any).created_at) return <></>

      const fecha = formatDate(new Date((input as any).created_at))
      return <>{fecha}</>
    },
  },
  {
    title: 'Usuario',
    field: 'user',
  },
  {
    title: 'Destinatario',
    field: 'destination',
  },

  {
    title: 'Contenido',
    field: 'content',
  },
  {
    title: 'Guía',
    field: 'guideNumber',
  },
]
