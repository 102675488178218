import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { Typography, Form, notification, Spin } from "antd";

import { PricesContext } from "../../context/PricesContext";

import ModalContainer from "../../components/Containers/ModalContainer";
import InputContainer from "../../components/Containers/InputContainer";

import { usePost } from "../../hooks/usePost";

import {
  addSpecialPriceToUser,
  updateServicePrices,
  updateUserSpecialPrice,
} from "../../services/services_s";
import { Price, SpecialPrice } from "../../interfaces/services.interface";
import { formPricesTitle } from "../../utils/prices";

export const PriceModalForm = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const {
    isPricesModalOpen,
    selectedPrice,
    setIsPricesModalOpen,
    selectedServiceId,
    setSelectedServiceId,
    setStrategy,
    setSelectedPrice,
    strategy,
  } = useContext(PricesContext);
  const { fetchData: updateServicePricesFetch, isLoading: isUpdating } =
    usePost(updateServicePrices);
  const {
    fetchData: updateUserSpecialPriceFetch,
    isLoading: isUpdatingSpecial,
  } = usePost(updateUserSpecialPrice);
  const { fetchData: addSpecialPriceToUserFetch, isLoading: isAdding } =
    usePost(addSpecialPriceToUser);

  useEffect(() => {
    form.setFieldsValue({
      price: selectedPrice?.price.toString(),
      weight: selectedPrice?.weight?.toString(),
    });
    console.log("se actualixzan estas cosas")
  }, [form, selectedPrice]);

  const strategies = {
    Precios: async ({ price, weight }: Price) => {
      console.log(price, weight, "estos dfos", selectedPrice, selectedServiceId)
      if (!selectedPrice || !selectedServiceId)
        return { error: "No se selecciono servicio " };

      const oldPrice = selectedServiceId.prices[selectedPrice.index];
      const newPrice = { price, weight, _id: "1" };

      const resp = await updateServicePricesFetch({
        newPrice,
        oldPrice,
        id: selectedServiceId._id,
      });
      if (resp.error) return;

      setSelectedServiceId({
        ...selectedServiceId,
        prices: resp.data as Price[],
      });
    },
    "Precios Especiales": async ({ price, weight }: Price) => {
      if (!selectedPrice || !selectedServiceId || !selectedPrice.user)
        return { error: "No se selecciono usuario" };

      const resp = await updateUserSpecialPriceFetch({
        newPrice: { weight, price, _id: "1" },
        oldPrice: { ...selectedPrice },
        id: selectedServiceId._id,
        user: selectedPrice.user,
      });

      if (resp.error) return;

      setSelectedServiceId({
        ...selectedServiceId,
        specialPrices: resp.data as SpecialPrice[],
      });
    },
    "Agregar Precio Especial": async ({ price, weight }: Price) => {
      if (!selectedPrice || !selectedServiceId || !selectedPrice.user)
        return { error: "No se selecciono usuario" };

      const resp = await addSpecialPriceToUserFetch({
        price: { price, weight, _id: "1" },
        user: selectedPrice.user,
        id: selectedServiceId._id,
      });
      setSelectedServiceId({
        ...selectedServiceId,
        specialPrices: resp.data as SpecialPrice[],
      });
      setStrategy("Precios Especiales");
    },
  };
  const closeModal = () => {
    if (strategy === "Agregar Precio Especial")
      setStrategy("Precios Especiales");

    setSelectedPrice(undefined);
    setIsPricesModalOpen(false);
  };

  const handleSubmit = async (dto: Price) => {
    const strategyFunc = strategies[strategy];

    const resp = await strategyFunc(dto);

    if (resp?.error) {
      notification.error({
        message: "No se selecciono precio",
      });
    }

    closeModal();
  };

  return (
    <ModalContainer
      onCancel={closeModal}
      width={600}
      title={formPricesTitle(t)[strategy]}
      footer={
        <InputContainer
          onClick={() => {
            (
              document.querySelector("button[type=submit]") as HTMLElement
            ).click();
          }}
          title="Guardar precio"
          type="submitButton"
          disabled={isAdding || isUpdating || isUpdatingSpecial}
        />
      }
      visible={isPricesModalOpen}
    >
      {isAdding || isUpdating || isUpdatingSpecial ? (
        <Spin />
      ) : selectedPrice ? (
        <>
          <Form
            initialValues={{
              weight: selectedPrice?.weight?.toString(),
              price: selectedPrice?.price?.toString(),
            }}
            form={form}
            onFinish={handleSubmit}
          >
            <Typography.Text style={{ display: "block", marginTop: 10 }}>
              {t("Precios.Form.Weight")}
            </Typography.Text>
            <InputContainer
              valueContainerName="weight"
              style={{ margin: 0 }}
              type="number"
              required
              label="Peso"
              placeholder={"Peso"}
              disabled={strategy !== "Agregar Precio Especial"}
            />
            <Typography.Text>{t("Precios.Form.Price")}</Typography.Text>
            <InputContainer
              valueContainerName="price"
              style={{ margin: 0 }}
              type="number"
              required
              label="Precio"
              placeholder={"Precio"}
            />
            <button type="submit" style={{ display: "none" }}></button>
          </Form>
        </>
      ) : (
        <Typography.Text>No se selecciono precio</Typography.Text>
      )}
    </ModalContainer>
  );
};
