import { t } from 'i18next'
import { Button, Tooltip, notification } from "antd"
import { ColumnsType } from "antd/es/table"
import { 
    PendingCommissions,
    PendingCommissionsColumnsType,
    PendingWithDrawalsByPlatformColumnsType,
    Profile, 
    ProfilesColumnsType, 
    Referral,
    ReferralsColumnsType,
    ReferralStatus,
    Shipment,
    ShipmentsColumnsType,
    Withdrawal,
    WithDrawalsHistoryColumnsType,
    WithdrawalStatus,
    WithdrawalType,
} from '../../../../interfaces/partner.interface'
import { Status } from './Status'
import ShipmentCount from './ShipmentCount'
import { allParcelsObject } from '../../../../constants/parcels'
import ReferralActions from './ReferralActions'

export const shipmentColumn = (data: Shipment[]) => {
    const uniqueCarriers = Array.from(new Set(data.map((item: Shipment) => item.carrier)))

    return [
        {
            title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Withdrawals.Table.ShipmentsDetailsModal.Column.FirstActivity')}</Tooltip>,
            dataIndex: 'created_at',
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: ShipmentsColumnsType, b: ShipmentsColumnsType) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
            render: (created_at: string) => new Date(created_at).toLocaleString()
        },
        {
            title: t('Withdrawals.Table.ShipmentsDetailsModal.Column.Tracking'),
            dataIndex: 'tracking',
            align: 'center',
            render: (tracking: string) => `${tracking}` 
        },
        {
            title: t('Withdrawals.Table.ShipmentsDetailsModal.Column.Carrier'),
            dataIndex: 'carrier',
            align: 'center',
            filters: uniqueCarriers.map((carrier: AllCarriers) => ({ text: carrier, value: carrier })),
            onFilter: (value: AllCarriers, record: Shipment) => record.carrier.indexOf(value as string) === 0,
            render: (carrier: AllCarriers) => (
                <img
                    src={allParcelsObject[carrier]?.imgSrc}
                    max-width="100%"
                    width="90px"
                    alt={`${carrier}`}
                />
            )
        },
        {
            title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Withdrawals.Table.ShipmentsDetailsModal.Column.TotalAmount')}</Tooltip>,
            dataIndex: 'price',
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: ShipmentsColumnsType, b: ShipmentsColumnsType) => a.price - b.price,
            render: (price: number) => `$${price}`
        },
        {
            title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Withdrawals.Table.ShipmentsDetailsModal.Column.Commission')}</Tooltip>,
            dataIndex: 'price',
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: ShipmentsColumnsType, b: ShipmentsColumnsType) => a.price - b.price,
            render: (price: number) => `$${(price * 0.03).toFixed(2)}`
        }
    ] as ColumnsType<ShipmentsColumnsType> 
}

export const profilesColumn = (data: Profile[]) => {
    return [
        {
            title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Profiles.Table.Column.Created')}</Tooltip>,
            dataIndex: ['partners','createdAt'],
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: ProfilesColumnsType, b: ProfilesColumnsType) => new Date(a.partners.createdAt).getTime() - new Date(b.partners.createdAt).getTime(),
            render: (createdAt: Date) => new Date(createdAt).toLocaleString()
        },
        {
            title: t('Profiles.Table.Column.Name'),
            dataIndex: 'name',
            align: 'center',
        },
        {
            title: t('Profiles.Table.Column.Email'),
            dataIndex: 'email',
            align: 'center',
        },
        {
            title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Profiles.Table.Column.LastActivity')}</Tooltip>,
            dataIndex: ['partners','updatedAt'],
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: ProfilesColumnsType, b: ProfilesColumnsType) => new Date(a.partners.updatedAt).getTime() - new Date(b.partners.updatedAt).getTime(),
            render: (updatedAt: Date) => new Date(updatedAt).toLocaleString()
        }
      ] as ColumnsType<ProfilesColumnsType> 
}

export const referralColumn = (
    data: Referral[], 
    deleteReferral: (id: string)=> any,
    activateDeleteReferral: boolean,
    setActivateDeleteReferral: (state: boolean) => void
) => {
    const uniqueReferralStatus = Array.from(new Set(data.map((item: Referral) => item.referral.status)))

    const statusMapUI = {
        aprobado: t('Referrals.Status.Approved'),
        pendiente: t('Referrals.Status.Pending'),
        rechazado: t('Referrals.Status.Refused'),
    }

    return [
        {
            title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Referrals.Table.Column.Created')}</Tooltip>,
            dataIndex: 'createdAt',
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: ReferralsColumnsType, b: ReferralsColumnsType) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
            render: (createdAt: Date) => new Date(createdAt).toLocaleString()
        },
        {
            title: t('Referrals.Table.Column.Owner'),
            dataIndex: ['profile', 'email'],
            align: 'center',
        },
        {
            title: t('Referrals.Table.Column.Referral'),
            dataIndex: ['referral', 'email'],
            align: 'center',
        },
        {
            title: t('Referrals.Table.Column.Status'),
            dataIndex: ['referral', 'status'],
            align: 'center',
            filters: uniqueReferralStatus.map((status: ReferralStatus) => ({ text: statusMapUI[status], value: status })),
            onFilter: (value: ReferralStatus, record: Referral) => record.referral.status.indexOf(value) === 0,
            render: (status) => {
                return (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Status tableType='referrals' status={status} />
                    </div>
                )
            }
        },
        {
            title: t('Referrals.Table.Column.Options'),
            dataIndex: '',
            align: 'center',
            render: (rowData: ReferralsColumnsType) => (
                <ReferralActions 
                    rowData={rowData} 
                    deleteReferral={deleteReferral}
                    activateDeleteReferral={activateDeleteReferral}
                    setActivateDeleteReferral={setActivateDeleteReferral}
                />
            )
        }
      ] as ColumnsType<ReferralsColumnsType> 
}

export const withdrawalColumn = (
    data: Withdrawal[],
) => {
    const uniqueWithdrawalHistoryStatus = Array.from(new Set(data.map((item: Withdrawal) => item.status)))
    const uniqueWithdrawalHistoryTypes = Array.from(new Set(data.map((item: Withdrawal) => item.withdrawalType)))
    const statusMapUI = {
        abonado: t('Withdrawals.Status.Paid'),
        pendiente: t('Withdrawals.Status.Pending'),
        rechazado: t('Withdrawals.Status.Refused'),
    }
    
    const typesMapUI = {
        plataforma: t('Withdrawals.Types.Platform'),
        banco: t('Withdrawals.Types.Bank'),
    }

    return [
        {
            title: t('Withdrawals.Table.Column.Created'),
            dataIndex: 'createdAt',
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: WithDrawalsHistoryColumnsType, b: WithDrawalsHistoryColumnsType) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
            render: (createdAt: Date) => new Date(createdAt).toLocaleString()
        },
        {
            title: t('Withdrawals.Table.Column.Owner'),
            dataIndex: ['profile', 'email'],
            align: 'center',
        },
        {
            title: t('Withdrawals.Table.Column.Referral'),
            dataIndex: ['referral', 'email'],
            align: 'center',
        },
        {
            title: t('Withdrawals.Table.Column.Status'),
            dataIndex: 'status',
            align: 'center',
            filters: uniqueWithdrawalHistoryStatus.map((status: WithdrawalStatus) => ({ text: statusMapUI[status], value: status })),
            onFilter: (value: WithdrawalStatus, record: Withdrawal) => record.status.indexOf(value) === 0,
            render: (status: WithdrawalStatus) => {
                return (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Status tableType='withdrawals' status={status} />
                    </div>
                )
            }
        },
        {
            title: t('Withdrawals.Table.Column.WithdrawalType'),
            dataIndex: 'withdrawalType',
            align: 'center',
            filters: uniqueWithdrawalHistoryTypes.map((status: WithdrawalType) => ({ text: typesMapUI[status], value: status })),
            onFilter: (value: WithdrawalType, record: Withdrawal) => record.withdrawalType.indexOf(value) === 0,
            render: (withdrawalType: WithdrawalType)=> `${typesMapUI[withdrawalType]}`
        },
        {
            title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Withdrawals.Table.Column.Shipments')}</Tooltip>,
            dataIndex: 'shipments',
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: WithDrawalsHistoryColumnsType, b: WithDrawalsHistoryColumnsType) => a.shipments.length - b.shipments.length,
            render: (shipments: Shipment[])=> <ShipmentCount shipments={shipments} />
        },
        {
            title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Withdrawals.Table.Column.Amount')}</Tooltip>,
            dataIndex: 'amount',
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: WithDrawalsHistoryColumnsType, b: WithDrawalsHistoryColumnsType) => a.amount - b.amount,
            render: (amount: number) => `$${amount?.toFixed(2)}`
        },
    ] as ColumnsType<WithDrawalsHistoryColumnsType> 
}

export const PendingWithdrawalsByPlatformColumn = (
    data: Withdrawal[],
    handleConfirmByPlatform: (id: string, status: 'LIQUIDADO' | 'CANCELADO') => Promise<void>
) => {
    const uniqueWithdrawalHistoryTypes = Array.from(new Set(data.map((item: Withdrawal) => item.withdrawalType)))
    const typesMapUI = {
        plataforma: t('Withdrawals.Types.Platform'),
        banco: t('Withdrawals.Types.Bank'),
    }

    return [
        {
            title: t('Withdrawals.Table.Column.Created'),
            dataIndex: 'createdAt',
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: PendingWithDrawalsByPlatformColumnsType, b: PendingWithDrawalsByPlatformColumnsType) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
            render: (createdAt: Date) => new Date(createdAt).toLocaleString()
        },
        {
            title: t('Withdrawals.Table.Column.Owner'),
            dataIndex: ['profile', 'email'],
            align: 'center',
        },
        {
            title: t('Withdrawals.Table.Column.Referral'),
            dataIndex: ['referral', 'email'],
            align: 'center',
        },
        {
            title: t('Withdrawals.Table.Column.WithdrawalType'),
            dataIndex: 'withdrawalType',
            align: 'center',
            filters: uniqueWithdrawalHistoryTypes.map((status: WithdrawalType) => ({ text: typesMapUI[status], value: status })),
            onFilter: (value: WithdrawalType, record: Withdrawal) => record.withdrawalType.indexOf(value) === 0,
            render: (withdrawalType: WithdrawalType)=> `${typesMapUI[withdrawalType]}`
        },
        {
            title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Withdrawals.Table.Column.Shipments')}</Tooltip>,
            dataIndex: 'shipments',
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: PendingWithDrawalsByPlatformColumnsType, b: PendingWithDrawalsByPlatformColumnsType) => a.shipments.length - b.shipments.length,
            render: (shipments: Shipment[])=> <ShipmentCount shipments={shipments} />
        },
        {
            title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Withdrawals.Table.Column.Amount')}</Tooltip>,
            dataIndex: 'amount',
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: PendingWithDrawalsByPlatformColumnsType, b: PendingWithDrawalsByPlatformColumnsType) => a.amount - b.amount,
            render: (amount: number) => `$${amount?.toFixed(2)}`
        },
        {
            title: '',
            dataIndex: '',
            align: 'center',
            render: (rowData: PendingWithDrawalsByPlatformColumnsType)=> (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Button 
                    className='commissionButton'
                        style={{ background: 'green', marginRight: '4px' }}
                        onClick={async ()=> {
                            if (rowData.dispersionId){
                                notification.warning({
                                    message: `${t('PendingWithdrawalsByPlatform.Table.Notification.Warning.WithdrawalsByStpPlatform')} ${rowData.dispersionId}`
                                })
                                return 
                            }
                            await handleConfirmByPlatform(rowData.id, 'LIQUIDADO')
                        }}
                    >
                        {t('PendingWithdrawalsByPlatform.Table.Column.Options.Confirm')}
                    </Button>
                    <Button 
                        className='commissionButton'
                        style={{ background: 'red'}}
                        onClick={async ()=> {
                            if (rowData.dispersionId){
                                notification.warning({
                                    message: `${t('PendingWithdrawalsByPlatform.Table.Notification.Warning.WithdrawalsByStpPlatform')} ${rowData.dispersionId}`
                                })
                                return 
                            }
                            await handleConfirmByPlatform(rowData.id, 'CANCELADO')}
                        }
                    >
                        {t('PendingWithdrawalsByPlatform.Table.Column.Options.Cancel')}
                    </Button>
                </div>
            )
        }
    ] as ColumnsType<PendingWithDrawalsByPlatformColumnsType> 
}

export const PendingCommissionsColumn = (data: any) => {
    return [
        {
            title: t('PendingCommissions.Table.Column.Created'),
            dataIndex: 'createdAt',
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: PendingCommissionsColumnsType, b: PendingCommissionsColumnsType) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
            render: (createdAt: Date) => new Date(createdAt).toLocaleString()
        },
        {
            title: t('PendingCommissions.Table.Column.Owner'),
            dataIndex: ['profile', 'email'],
            align: 'center',
        },
        {
            title: t('PendingCommissions.Table.Column.Referral'),
            dataIndex: ['referral', 'email'],
            align: 'center',
        },
        {
            title: <Tooltip title={t('Dashboard.Table.Tooltip.Sort.Ascending')}>{t('PendingCommissions.Table.Column.Shipments')}</Tooltip>,
            dataIndex: ['referral', 'shipments'],
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: PendingCommissionsColumnsType, b: PendingCommissionsColumnsType) => a.referral.shipments.length - b.referral.shipments.length,
            render: (shipments: PendingCommissions[])=> <ShipmentCount shipments={shipments} />
        },
        {
            title: <Tooltip title={t('Dashboard.Table.Tooltip.Sort.Ascending')}>{t('PendingCommissions.Table.Column.Amount')}</Tooltip>,
            dataIndex: ['referral', 'amount'],
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: PendingCommissionsColumnsType, b: PendingCommissionsColumnsType) => a.referral.amount - b.referral.amount,
            render: (amount) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Button className="commissionButton">{`$${amount?.toFixed(2)}`}</Button>
                </div>
            )
        },
    ] as ColumnsType<PendingCommissionsColumnsType> 
}