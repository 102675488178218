import { Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import { Tooltip } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import InputContainer from "../Containers/InputContainer";
import FinderComponent from "./finder";
import { useContext, useEffect } from "react";
import { AppContext } from "../../context/appContext";
import { useNavigate } from "react-router-dom";

const Toolsbar = ({ page }: any) => {
	const { logout, user, isAuthenticated } = useAuth0();
	const { setToggleDashboard, toggleDashboard } = useContext(AppContext);
	const history = useNavigate();

	const options = [
		{ name: "Principal", value: true },

		// { name: 'Marketing', value: false },
	];

	useEffect(() => {
		if (!isAuthenticated) {
			history("/logout-success");
		}
	}, [isAuthenticated, history]);

	return (
		<>
			<div className="d-flex justify-content-top">
				{page === "Inicio" && (
					<ButtonGroup className="mx-1">
						{options?.map((radio: any, idx: any) => (
							<ToggleButton
								key={idx}
								id={`radio-${idx}`}
								type="radio"
								style={{
									zIndex: 10,
								}}
								variant={idx % 2 ? "outline-light" : "outline-light"}
								name="radio"
								value={radio?.value}
								checked={toggleDashboard === radio?.value}
								onClick={() => setToggleDashboard(radio?.value)}
							>
								{radio.name}
							</ToggleButton>
						))}
					</ButtonGroup>
				)}
				<FinderComponent />
				<Tooltip title={"Verifica tu perfil"}>
					<Button variant="outline-light" className="mx-1">
						{`${user?.nickname}`}
					</Button>
				</Tooltip>
				<InputContainer
					type="iconTitleHoverButton"
					shape={"circle"}
					// style={{ border: "1px solid white", backgroundColor: "#ff5b5d" }}
					style={{ border: "1px solid white", backgroundColor: "#ff413f", marginLeft: "8px" }}
					iconOrReactElement={<LogoutOutlined />}
					title="Cerrar Sesión"
					onClick={() => {
						setTimeout(async () => {
							history("/logout-success");
						}, 4000);

						logout({
							returnTo: `${window.location.origin}/logout-success`,
							client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
						});
					}}
				/>
			</div>
		</>
	);
};
export default Toolsbar;
