import React, { useEffect, useState } from "react";
import { TableFilter, Table } from "../../components/Common/Table";
import { TableFilterModal } from "../../components/Sections/TableFilterModal";
import { useSectionFilters } from "../../hooks/sections/useSectionFilters";
import { quotesColumn } from "../../services/records";
import { usePost } from "../../hooks/usePost";
import { quotesPerCarrier } from "../../services/admin_s";
import { allCarriers } from "../../utils/ArrayCarrier";
import { ServicesId_MX } from "../../utils/GlobalServices";

const QuotesPerCarrier: React.FC = () => {
  const { state, toggle } = useSectionFilters();

  const [filter, setFilter] = useState<TableFilter | null>(null);
  const [quotes, setQuotes] = useState<number | null>(5);
  const [carrierSelected, setCarrierSelected] = useState<AllCarriers | null>(
    "estafeta"
  );
  const [providerSelected, setProviderSelected] = useState<string | undefined>(
    "nextshipping"
  );
  const [service, setService] = useState<ServicesId_MX | undefined>(
    ServicesId_MX.estafeta_mx_nextshipping_ground
  );
  const [zoneSelected, setzoneSelected] = useState<string | null>("zone 8");
  const [data, setData] = useState<any[]>([]);

  const { fetchData, isLoading } = usePost(quotesPerCarrier);

  useEffect(() => {
    if (carrierSelected && zoneSelected) {
      const getData = async () => {
        const { data } = await fetchData({
          providerSelected,
          carrierSelected,
          zoneSelected,
          rowsPerPage: quotes,
          service,
        });

        const formattedData = data.map((d: any) => {
          return {
            weight: d.weight,
            cost: d.cost,
          };
        });

        setData(formattedData);
      };
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    carrierSelected,
    zoneSelected,
    quotes,
    service,
    providerSelected,
  ]);

  return (
    <>
      <Table
        // @ts-ignore
        data={data}
        isLoading={isLoading}
        columns={quotesColumn()}
        title="Cotizaciones por Paqueteria"
        setFilter={(filter: any) => {
          setFilter(filter);
          toggle();
        }}
        filters={['zone', 'quotes', 'services']}
      />
      <TableFilterModal
        carriers={allCarriers}
        setCarrierSelected={setCarrierSelected}
        setProviderSelected={setProviderSelected}
        setServiceSelected={setService}
        setPriceFilter={() => {}}
        setRangeDate={() => {}}
        isOpen={state}
        close={toggle}
        filter={filter}
        setZone={setzoneSelected}
        setQuotesNumber={setQuotes}
      />
    </>
  );
};

export default QuotesPerCarrier;
