import { Col, Menu, Row, Typography } from 'antd'
import { InfoCircleOutlined, FormOutlined } from '@ant-design/icons'
import { FC } from 'react'
import AccessMsg from '../../components/Common/AccessMsg'

export const ErrorLogsActions: FC<Props> = ({
  showPayloadFn,
  showResponseFn,
  showGenerateModalFn,
}) => {
  const { Text } = Typography

  return (
    <Menu>
      <Menu.Item
        // disabled={!userAccess.recharge}
        onClick={showPayloadFn}
      >
        <AccessMsg label='Petición' access>
          <Row style={{ alignContent: 'center' }}>
            <Col style={{ bottom: 3, right: 5 }}>
              <InfoCircleOutlined />
            </Col>
            <Col>
              <Text>Petición</Text>
            </Col>
          </Row>
        </AccessMsg>
      </Menu.Item>
      <Menu.Item
        // disabled={!userAccess.discount}
        onClick={showResponseFn}
      >
        <AccessMsg label='Response' access>
          <Row style={{ alignContent: 'center' }}>
            <Col style={{ bottom: 3, right: 5 }}>
              <InfoCircleOutlined />
            </Col>
            <Col>
              <Text>Respuesta</Text>
            </Col>
          </Row>
        </AccessMsg>
      </Menu.Item>
      {typeof showGenerateModalFn === 'function' && (
        <Menu.Item
          // disabled={!userAccess.discount}
          onClick={showGenerateModalFn}
        >
          <AccessMsg label='Retomar guía' access>
            <Row style={{ alignContent: 'center' }}>
              <Col style={{ bottom: 3, right: 5 }}>
                <FormOutlined style={{ color: '#FD2222' }} />
              </Col>
              <Col>
                <Text>Retomar guía</Text>
              </Col>
            </Row>
          </AccessMsg>
        </Menu.Item>
      )}
      {/* <Menu.Item
        onClick={() => {
          setModalProps({ visible: true, selector: 'Update' })
        }}
      >
        <AccessMsg label='Actualizar' access={userAccess.update} placement='left'>
          <Row style={{ alignContent: 'center' }}>
            <Col style={{ bottom: 3, right: 5 }}>
              <SmileOutlined />
            </Col>
            <Col>
              <Text>Actualizar Usuario</Text>
            </Col>
          </Row>
        </AccessMsg>
      </Menu.Item> */}
    </Menu>
  )
}

type Props = {
  showPayloadFn: () => void
  showResponseFn: () => void
  showGenerateModalFn: (() => void) | null
}
