import { FC } from "react";
import { Image } from "antd";

interface Props {
  url: string;
}
const ReportImage: FC<Props> = ({ url }) => {
  return (
    <span style={{ padding: '6px' }}>
      <Image
        width={100}
        src={url}
        style={{ border: '1px solid #d1d1d1', borderRadius: '6px' }}
      />
    </span>
  )
}


export default ReportImage