export const allCarriers: AllCarriers[] = [
    "fedex", 
    "sendex",
    "dhl",
    "ups",
    "carssa",
    "ivoy",
    "scm",
    "quiken",
    "ampm",
    "estafeta",
    "tracusa",
    "paquetexpress",
    "noventa9Minutos",
    "mensajerosUrbanos",
    "uber",
    "aExpress",
    "coordinadora",
    "redServi",
    "serviEntrega",
    "jtexpress",
    "borzo",
    "entrega",
    "interrapidisimo",
    "treggo",
  ];