import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
//import { CountriesContext } from '../../context/countriesContext'
// import Shipments from '../Shipments'
import TabsContainer from "../../../components/Containers/TabsContainer";
import { AppContext } from "../../../context/appContext";
import Shipping from "./Shipping";
import Overweight from "./Overweight";
import Movement from "./Movement";
import Users from "./Users";

const Data: React.FC = () => {
  const [tabs, setTabs] = useState({});
  const { userData, setMenuSelectedText } = useContext(AppContext);
  const { t } = useTranslation();

  const onChangeTab = (data: string) => {
    const tabTitle = data.split("|")[0];
    const tabDescription = data.split("|")[1];

    setMenuSelectedText({
      title: tabTitle,
      description: tabDescription,
    });
  };

  useEffect(() => {
    return () => {
      setMenuSelectedText({
        title: undefined,
        description: undefined,
      });
    };
  }, []); //eslint-disable-line

  useEffect(() => {
    if (userData) {
      let currenTab = {
        [t("Down.Shipping.Tab")]: {
          component: <Shipping />,
          description: t("Preferences.TabSubtitle.Languages"),
        },
        [t("Down.Movement.Tab")]: {
          component: <Movement />,
          description: t("Preferences.TabSubtitle.Languages"),
        },
        [t("Down.Users.Tab")]: {
          component: <Users />,
          description: t("Preferences.TabSubtitle.Languages"),
        },
        [t("Down.Overweight.Tab")]: {
          component: <Overweight />,
          description: t("Preferences.TabSubtitle.Languages"),
        },
      };

      setTabs({ ...currenTab });
    }
  }, [userData]); //eslint-disable-line

  return (
    <Row className="dashboardSection">
      <Col span={24}>
        <TabsContainer onChange={onChangeTab} titlesAndContent={tabs} />
      </Col>
    </Row>
  );
};

export default Data;
export const TEST_URL = "https://vip.drenvio.com";
