import { Alert, Col, Row, Typography } from "antd"
import CardContainer from "../../../components/Containers/CardContainer"
import { FC } from "react";
import YouTubeEmbed from "./YoutubeEmbed";

const styles = {
    title: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        marginBottom: '1rem'
    },
}
interface props {
    currentStore: any;
}

const InstructionsData: FC<props> = ({ currentStore }) => {

    return (
        <Row gutter={[20, 20]} style={{ width: '100%', margin: '0 1rem' }}>
            <Col span={24}>
                <Typography style={styles.title}>
                    Vista previa de las instrucciones
                </Typography>
                <Alert
                    type="info"
                    message="Puedes ver una vista previa de las instrucciones de instalación, en ambos idiomas."
                    showIcon
                    style={{ borderRadius: '10px' }}
                />
                <br />
                <Col span={24}>
                    <CardContainer
                        textListDecoration="numbers"
                        textList={
                            currentStore?.steps && currentStore?.steps?.es.map((item: any) => item)
                        }
                    />
                    <br />
                </Col>
                <Typography style={styles.title}>
                    Instrucciones Inglés
                </Typography>
                <Col span={24}>
                    <CardContainer
                        textListDecoration="numbers"
                        textList={
                            currentStore?.steps && currentStore?.steps?.en.map((item: any) => item)
                        }
                    />
                    <br />
                </Col>
                {
                    currentStore?.media?.url && (
                        <>
                            <Typography style={styles.title}>
                                Video tutorial
                            </Typography>
                            <Col span={24}>
                                <CardContainer cardStyle={{ display: 'grid', placeItems: 'center' }}>
                                    <YouTubeEmbed videoId={currentStore.media.url.split('watch?v=')[1]} />
                                </CardContainer>
                                <br />
                            </Col>
                        </>
                    )
                }
            </Col>
        </Row>
    )
}

export default InstructionsData