import { FC } from "react";
import InputContainer from "../Containers/InputContainer";

export const TableQuotesCount: FC<Props> = ({ setQuotesNumber }) => {
  return (
    <div>
      <InputContainer
        placeholder="Numero de Cotizaciones"
        type="number"
        valueContainerName="value name"
        onChange={(e) => setQuotesNumber(e)}
      />
    </div>
  );
};

type Props = {
  setQuotesNumber: React.Dispatch<React.SetStateAction<number | null>>;
};
