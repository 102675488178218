import { Col, Form, Row, Spin, Typography, notification } from "antd"
import CardContainer from "../../../components/Containers/CardContainer"
import { FC, useEffect, useState } from "react";
import InputContainer from "../../../components/Containers/InputContainer";
import JsonEditor from "./JsonEditor";
import { configJson } from './config'
import { updateConfigIntegrations } from "../../../services/configuration_s";
import { usePatch } from "../../../hooks/usePatch";

interface props {
    currentStore: any;
    editMode: boolean;
    refetch: () => any;
    changeCurrentStore: (store: any) => void;
}

const EditForm: FC<props> = ({ currentStore, editMode, refetch, changeCurrentStore }) => {
    const [form] = Form.useForm();
    const [json, setJson] = useState({})
    const { isLoadingPatch, fetchDataPatch } = usePatch(updateConfigIntegrations)

    const updateJson = (data: any) => {
        delete data.type
        setJson(data)
    }

    useEffect(() => {
        let newObject = { ...currentStore }
        delete newObject.type
        updateJson(newObject)
        //eslint-disable-next-line
    }, [])

    const onFinishEdit = async (data: any) => {
        try {
            const response = await fetchDataPatch(currentStore.type, {
                config: json
            })
            if (!response.error) {
                notification.success({
                    message:
                        'Configuración actualizada correctamente'
                })
                changeCurrentStore(response)
                refetch()
            } else {
                notification.error({
                    message:
                        'Actualización fallida'
                })
            }

        } catch (error) {
            console.log(error)
            notification.error({
                message:
                    'Actualización fallida'
            })
        }
    }

    return (
        <Row gutter={[20, 20]} style={{ width: '100%', margin: '0 1rem' }}>
            <Col span={24}>
                <Spin spinning={false} style={{ width: '100%' }}>
                    <Form form={form} onFinish={onFinishEdit}>
                        <Spin spinning={isLoadingPatch}>
                            <CardContainer cardStyle={{ width: '100%' }}>
                                <Row gutter={[20, 20]} style={{ width: '100%' }}>
                                    <Col span={24}>
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                fontSize: '1.1rem',
                                                fontWeight: 'bold',
                                                marginBottom: '1rem'
                                            }}
                                        >
                                            {editMode ? 'Modifica la configuración' : 'Crear nueva integración'}
                                        </Typography>
                                        {
                                            !editMode && (
                                                <>

                                                    <Typography
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '0.9rem',
                                                            marginBottom: '0.5rem'
                                                        }}>
                                                        Selecciona tipo de integración
                                                    </Typography>
                                                    <InputContainer
                                                        type="select"
                                                        placeholder="Introduce el label"
                                                        valueContainerName="type"
                                                        style={{ width: '100%' }}
                                                        optionsList={[
                                                            {
                                                                value: 'WOOCOMMERCE',
                                                                label: 'WOOCOMMERCE'
                                                            },
                                                            {
                                                                value: 'SHOPIFY',
                                                                label: 'SHOPIFY'
                                                            }
                                                        ]}
                                                    />
                                                </>
                                            )
                                        }
                                    </Col>
                                    <Col span={24}>
                                        <JsonEditor
                                            updateJson={updateJson}
                                            currentJson={configJson}
                                            currentStore={currentStore}
                                            editMode={editMode}
                                        />
                                        <br />
                                        <InputContainer
                                            type="submitButton"
                                            title="Guardar cambios"
                                        />
                                    </Col>
                                </Row>
                            </CardContainer>
                        </Spin>
                    </Form>
                </Spin></Col></Row>
    )
}

export default EditForm