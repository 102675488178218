import { Col, Row, Select } from "antd";
import { Fragment, useState, useEffect, useCallback } from "react";
import CardContainer from "../../../../components/Containers/CardContainer";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { Tooltip, DatePicker } from "antd";
import { t } from "i18next";
import moment from "moment";
import { FilterRequest } from "../../../../services/user_d";
import { usePost } from "../../../../hooks/usePost";
import { getUserTopShipments } from "../../../../services/admin_s";

type Props = {
  country: string;
};

type UserData = {
  userEmail: string;
  total: number;
};

export const ShipmentsUserTop = ({ country }: Props) => {
  const [selectedCarrier, setSelectedCarrier] = useState("General");
  const [carriers, setCarriers] = useState<string[]>([]);
  const [topUsersByCarrier, setTopUsersByCarrier] = useState<{ [key: string]: UserData[] }>({});
  const { Option } = Select;
  const [ranges, setRanges] = useState<moment.Moment[]>([
    moment().startOf("month"),
    moment().endOf("day"),
  ]);

  const {
    fetchData,
    data: filteredData,
    isLoading,
  } = usePost<FilterRequest, any>(getUserTopShipments);

  const getData = useCallback(async () => {
    if (ranges[0] && ranges[1]) {
      await fetchData({
        "origin.country": country,
        created_at: {
          $gte: ranges[0].startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
          $lte: ranges[1].endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
        },
      });
    }
  }, [fetchData, country, ranges]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ranges, country]);

  useEffect(() => {
    if (filteredData && Array.isArray(filteredData.res)) {
      const data = filteredData.res;
      const uniqueCarriers = new Set<string>();
      const topUsersMap: { [key: string]: UserData[] } = {};

      data.forEach((item: any) => {
        if (item._id) {
          uniqueCarriers.add(item._id);
          if (item.topUsers) {
            topUsersMap[item._id] = item.topUsers;
          }
        } else {
          if (!uniqueCarriers.has("General")) {
            uniqueCarriers.add("General");
          }
          if (!topUsersMap["General"]) {
            topUsersMap["General"] = [];
          }
          topUsersMap["General"].push({
            userEmail: item.userEmail,
            total: item.total,
          });
        }
      });

      setCarriers(Array.from(uniqueCarriers).filter(carrier => carrier !== "General"));
      setTopUsersByCarrier(topUsersMap);
    }
  }, [filteredData]);

  return (
    <CardContainer
      cardStyle={{ marginTop: "20px" }}
      title="Usuarios con más envíos este Mes"
      titleStyle={{ placeItems: "center" }}
    >
      <Row>
        <Col span={24}>
          <DatePicker.RangePicker
            onChange={(e: any) => {
              setRanges([moment(e[0]).startOf("day"), moment(e[1]).endOf("day")]);
            }}
            placeholder={["Fecha inicial", "Fecha final"]}
            value={ranges as any}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      <Row justify="center" style={{ marginBottom: '16px' }}>
        <Col span={24}>
          <Select
            value={selectedCarrier}
            onChange={setSelectedCarrier}
            style={{ width: '100%', textAlign: 'center' }}
          >
            <Option value="General" style={{ textAlign: 'center' }}>
              General            </Option>
            {carriers.map((carrier) => (
              <Option key={carrier} value={carrier} style={{ textAlign: 'center' }}>
                {t(`Quote.Service.Carrier.${carrier}`)}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <hr />
      <div>
        <Row>
          <Col span={20} style={{ margin: "auto", fontWeight: "bold" }}>
            <p>Usuario</p>
          </Col>
          <Col span={4} style={{ margin: "auto", fontWeight: "bold", textAlign: "center" }}>
            <p>Envios</p>
          </Col>
        </Row>
        <Row style={{ maxHeight: "32vh", overflowY: "auto" }}>
          {isLoading ? (
            <LoadingAnimation animationType="small" />
          ) : !filteredData || !filteredData.res || filteredData.res.length === 0 ? (
            <p
              style={{
                fontWeight: "bold",
                margin: "50px auto",
                color: "#0008",
              }}
            >
              No hay datos para mostrar.
            </p>
          ) : (
            (selectedCarrier === "General"
              ? topUsersByCarrier["General"] || []
              : topUsersByCarrier[selectedCarrier] || [])
              .filter((item: UserData) => item?.userEmail && item?.total)
              .sort((a: UserData, b: UserData) => {
                const aCount = selectedCarrier === "General"
                  ? a?.total
                  : a?.total || 0;
                const bCount = selectedCarrier === "General"
                  ? b?.total
                  : b?.total || 0;
                return bCount - aCount;
              }).map((item: UserData, index: number) => {
                const displayValue =
                  selectedCarrier === "General"
                    ? item?.total
                    : item?.total || "";
                return (
                  <Fragment key={item.userEmail}>
                    {selectedCarrier === "General" || (selectedCarrier !== "General" && topUsersByCarrier[selectedCarrier]?.some((user: UserData) => user.userEmail === item.userEmail)) ? (
                      <>
                        <Col span={20} style={{ margin: "5px auto" }}>
                          <Tooltip title={item?.total}>{item?.userEmail}</Tooltip>
                        </Col>
                        <Col
                          span={4}
                          style={{ margin: "5px auto", fontWeight: "bold", textAlign: 'center', color: "#2D1B6E" }}
                        >
                          {displayValue.toLocaleString()}
                        </Col>
                      </>
                    ) : null}
                  </Fragment>
                );
              })
          )}
        </Row>
      </div>
    </CardContainer>
  );
};
