import { FC, useEffect, useState } from "react";
import CardContainer from "../../components/Containers/CardContainer";
import { Col, Statistic, Spin, Row, Badge, Card, Switch, Space } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { LogAnalitycsInterface } from "../../types";
import {
  carrierNames,
  getParcelImageForDashboard,
} from "../../services/records";
import { mainControllerData } from "../../constants/dashBoardErrorImg";

interface ParcelData {
  count: number;
  image: string;
  key: string;
  name: string;
}

interface LoadingAnimationProps {
  data: LogAnalitycsInterface;
  setData: React.Dispatch<React.SetStateAction<String>>;
  loading: boolean;
  info: React.Dispatch<React.SetStateAction<ParcelData>>;
}

const PackageList: FC<LoadingAnimationProps> = ({
  data,
  setData,
  info,
  loading,
}) => {


  const [mainControllerD, setMainControllerD] = useState<any>([]);

  const onChangeValues = (value: any) => {
    setData(value.name);
    info(value);
  };


  data?.errorbyComponent.forEach((error: any) => {
    const matchingController = mainControllerData.find(
      (controller: any) => controller.name === error._id
    );
    if (matchingController) {
      matchingController.count = error.count;
    }
  });




  const onClickSort = (e: boolean) => {
    const mainControllerDataCopy = [...mainControllerData];
    
    if (e === false) {
      mainControllerDataCopy.sort((a, b) => (a.count || 0) - (b.count || 0));
    } else {
      mainControllerDataCopy.sort((a, b) => (b.count || 0) - (a.count || 0));
    }
  
    setMainControllerD(mainControllerDataCopy);
  }

  useEffect(() => {
    onClickSort(true)
  }, [])
  

  return (
    <>
    <Space direction="vertical" size="middle" style={{marginBottom: "1em"}} >
    <Switch
        checkedChildren="Rango alto"
        unCheckedChildren="Rango bajo"
        defaultChecked
        onClick={(e) => {
          onClickSort(e)
        }}
      />
    </Space>
      {mainControllerD?.map((value: any) => {
        //const getCountErrors = data.find((iteam: { _id: any }) => iteam._id === key)
        return (
          <CardContainer
            key={value._id}
            containerStyle={{ marginBottom: "20px" }}
            onClick={() => {
              onChangeValues(value);
            }}
          >
            <Row gutter={[16, 16]} align="middle">
              <Col span={8} style={{ margin: "5px auto" }}>
                <Badge count={value?.count || 0}>
                  {getParcelImageForDashboard(
                    carrierNames(value.image) as AllCarriers,
                    "40px"
                  )}
                </Badge>
              </Col>

              {loading ? (
                <Spin spinning={loading} />
              ) : (
                <Col span={12}>
                  <Card bordered={false}>
                    <Statistic
                      title="Porcetaje de fallas"
                      value={value?.count}
                      valueStyle={
                        value?.count > 60
                          ? { color: "#cf1322" }
                          : { color: "#3f8600" }
                      }
                      prefix={
                        value?.count > 50 ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined />
                        )
                      }
                      suffix="%"
                    />
                  </Card>
                </Col>
              )}
            </Row>
          </CardContainer>
        );
      })}
    </>
  );
};

export default PackageList;
