import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Statistic,
  Radio,
  RadioChangeEvent,
  notification,
} from "antd";
import CardContainer from "../../components/Containers/CardContainer";
import { WarningFilled } from "@ant-design/icons";
import { useGet } from "../../hooks/useGet";
import {
  getLogsAnalytcs,
  getMetricError,
  getOneController,
  getReport,
} from "../../services/admin_s";
import BarChart from "../../components/Common/BarChart";
import { LogAnalitycsInterface } from "../../types";
import PackageList from "./PackageList";
import { usePost } from "../../hooks/usePost";
import { AdminDataPicker } from "../../components/Common/admin/AdminDataPicker";
import LoadingAnimation from "../../components/LoadingAnimation";
import {
  carrierNames,
  getParcelImageForDashboard,
} from "../../services/records";
import { mainControllerData } from "../../constants/dashBoardErrorImg";
import ModalContainer from "../../components/Containers/ModalContainer";
import * as XLSX from "xlsx";
import { t } from "i18next";

interface ParcelData {
  count: number;
  image: string;
  key: string;
  name: string;
}

const ErrorsAnalitycs: React.FC = () => {
  const [dataOneParcel, setDataOneParcel] = useState<any>(null);
  const [metriInfo, setMetricInfo] = useState<any>(null);
  const [getParcelId, setGetParcelId] = useState<String>("");
  const [getParceData, setGetParceData] = useState<ParcelData>({
    count: 0,
    image: "",
    key: "",
    name: "",
  });
  const [starDate, setStarDate] = useState<any>([]);
  const [open, setOpen] = useState<any>(false);
  const [openGenerate, setOpenGenerate] = useState<any>(false);

  const [value, setValue] = useState<String>("");

  const validateStartDate = starDate ? starDate[0]?._d : "null";
  const validateEndDate = starDate ? starDate[1]?._d : "null";

  const {
    data: logsData,
    isLoading,
    refetch,
  } = useGet<LogAnalitycsInterface>(
    getLogsAnalytcs(validateStartDate, validateEndDate)
  );
  const { fetchData: getComponentInfo, isLoading: responseLoading } = usePost<
    any,
    String
  >(getOneController);

  const { fetchData: generateReport, isLoading: waitingResponse } = usePost<
    any,
    String
  >(getReport);

  const { fetchData: getMetricInfo } = usePost<any, String>(getMetricError);

  const getData = async (dto: object) => {
    try {
      const response = await getComponentInfo(dto);

      if (response.data.length !== 0) {
        setDataOneParcel(response.data);
      } else {
        setDataOneParcel(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMetric = async (dto: object) => {
    try {
      const response = await getMetricInfo(dto);
      if (response.data.length !== 0) {
        setMetricInfo(response?.data);
      } else {
        setMetricInfo(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClickReturn = () => {
    setGetParcelId("");
  };

  useEffect(() => {
    const dataObject = {
      dateStart: validateStartDate ? validateStartDate : null,
      endDate: validateEndDate ? validateEndDate : null,
      component: getParcelId,
    };
    getData(dataObject);
    getMetric(dataObject);
  }, [getParcelId, starDate]); //eslint-disable-line

  useEffect(() => {
    refetch();
  }, [starDate]); //eslint-disable-line

  const totalErroOneParcel = dataOneParcel?.reduce(
    (sum: any, item: { count: number }) => sum + (item.count || 0),
    0
  );

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const getReportDownload = async (dto: String) => {
    const dataObject = {
      dateStart: validateStartDate ? validateStartDate : null,
      component: dto,
    };
    try {
      const response = await generateReport(dataObject);
      console.log("response", response);
      const worksheet = XLSX.utils.json_to_sheet(response?.data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, `${"book"}`);
      await XLSX.writeFile(workbook, `${dto}.${"xlsx"}`, { bookType: "xlsx" });
      notification.success({
        message: t("xlsx generado"),
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log("dataOneParcel", dataOneParcel);
  return (
    <section className="dashboardSection">
      <Row gutter={[16, 24]} style={{ marginBottom: "2em" }}>
        {" "}
        <Col lg={4} sm={12} xs={24}>
          <CardContainer
            title="API INTERNAS"
            subtitle={"0"}
            itemsSpacing={[0, 10]}
            iconOrImg={<WarningFilled style={customIcons} />}
            contrastColor="#54c0f9"
          />
        </Col>
        <Col lg={4} sm={12} xs={24}>
          <CardContainer
            title="API EXTERNAS"
            subtitle={"0"}
            itemsSpacing={[0, 10]}
            iconOrImg={<WarningFilled style={customIcons} />}
            contrastColor="#5FCE8C"
          />
        </Col>
        <Col span={12}>
          <CardContainer title={"Elegir Rango de Fecha"}>
            <AdminDataPicker setValue={setStarDate} />
          </CardContainer>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        {isLoading ? (
          <Col span={8} className="gutter-row">
            <LoadingAnimation animationType="small" />
          </Col>
        ) : (
          <Col span={6} className="gutter-row">
            <Col
              style={{
                height: "49rem",
                minHeight: "20rem",
                maxHeight: "28rem",
                overflowY: "scroll",
                padding: "0 10px",
              }}
            >
              {logsData !== null ? (
                <PackageList
                  info={setGetParceData}
                  data={logsData || []}
                  setData={setGetParcelId}
                  loading={isLoading}
                />
              ) : (
                <></>
              )}
            </Col>
          </Col>
        )}

        {isLoading ? (
          <Col span={8} className="gutter-row">
            <LoadingAnimation animationType="small" />
          </Col>
        ) : getParcelId !== "" ? (
          <Col span={18}>
            <CardContainer title="Resumen">
              <Row gutter={16} align="middle">
                <Col span={4} style={customTitle.subTitle}>
                  {getParcelImageForDashboard(
                    carrierNames(getParceData?.image) as AllCarriers,
                    "40px"
                  )}
                </Col>
                <Col span={6}>
                  <Statistic
                    title="Errores totales"
                    value={totalErroOneParcel || 0}
                    style={{ color: "red" }}
                  />
                </Col>

                <Col span={6}>
                  <Statistic
                    title="Controlador principal"
                    value={getParceData?.name}
                  />
                </Col>

                <Col span={4}>
                  <Button onClick={onClickReturn}>Regresar al inicio</Button>
                </Col>

                <Col span={4}>
                  <Button type="primary" onClick={() => setOpenGenerate(true)}>
                    Generar reporte
                  </Button>
                </Col>
              </Row>

              {responseLoading === false ? (
                <>
                  <Col></Col>
                  <Col style={{ marginBottom: 20 }}>
                    <Col>
                      <BarChart
                        data={dataOneParcel ? dataOneParcel : []}
                        title="Fallas por controlador"
                        isLoading={isLoading}
                        type="bar"
                      />
                    </Col>
                  </Col>
                </>
              ) : (
                <LoadingAnimation animationType="small" />
              )}
            </CardContainer>
          </Col>
        ) : (
          <Col span={18}>
            <CardContainer title="Resumen rapido de fallas">
              {logsData !== null ? (
                <>
                  <Row>
                    <Col span={6}>
                      <Statistic
                        title="Numero total de controladores"
                        value={mainControllerData.length || 0}
                      />
                      <Button
                        style={{ marginTop: 16 }}
                        onClick={() => setOpen(true)}
                        type="primary"
                      >
                        Ver resumen completo
                      </Button>
                    </Col>
                    {/*                     <Col span={8}>
                      <MostErrorAnalitycs data={logsData}></MostErrorAnalitycs>
                    </Col> */}
                    <Col span={6}>
                      <Statistic
                        title="Servicio inestable"
                        value={metriInfo?.errorCategory[0]?._id}
                        style={{ color: "red" }}
                      />
                    </Col>

                    <Col span={6}>
                      <Statistic
                        title="Controlador inestable"
                        value={metriInfo?.errorApi[0]?._id}
                        style={{ color: "red" }}
                      />
                    </Col>
                  </Row>
                  <Col>
                    <BarChart
                      data={logsData ? logsData?.errorCategory?.top : []}
                      title="Resumen de fallas"
                      isLoading={isLoading}
                      horizontal={true}
                      type="bar"
                    />
                  </Col>
                </>
              ) : (
                <></>
              )}
            </CardContainer>
          </Col>
        )}
        <ModalContainer
          footer={<></>}
          title={"Resumen Completo"}
          visible={open}
          onCancel={() => setOpen(false)}
        >
          <BarChart
            data={logsData ? logsData?.errorCategory?.fullinfo : []}
            title="Fallas"
            isLoading={isLoading}
            horizontal={false}
            type="bar"
          />
        </ModalContainer>

        <ModalContainer
          footer={
            <>
              <p>Elige el controlador para generar reporte</p>
            </>
          }
          title={"Generar Reporte"}
          visible={openGenerate}
          onCancel={() => setOpenGenerate(false)}
        >
          <Radio.Group onChange={onChange} value={value}>
            {dataOneParcel &&
              dataOneParcel?.map((iteam: any) => {
                return <Radio value={iteam?._id}>{iteam?._id}</Radio>;
              })}
            <Button
              disabled={waitingResponse}
              type="primary"
              onClick={() => getReportDownload(value)}
            >
              {" "}
              {waitingResponse ? "Generarando XLXS" : "Descargar reporte"}
            </Button>
          </Radio.Group>
        </ModalContainer>
      </Row>
    </section>
  );
};

const customIcons = {
  color: "white",
};

const customTitle = {
  mainTitle: {
    backgroundColor: "#54C0F9",
    color: "#ffff",
    borderRadius: "11px",
    display: "flex",
    justifyContent: "center",
  },
  errorController: {
    backgroundColor: "#F07C54",
    fontSize: "18px",
    colorFont: "#ffff",
    textAling: "center",
    alignItems: "center",
    borderRadius: "25px",
    display: "flex",
    justifyContent: "center",
  },
  subTitle: {
    fontSize: "20px",
    display: "flex",
    justifyContent: "center",
  },
};

export default ErrorsAnalitycs;
