import { useContext } from "react";
import { Avatar, Col, Dropdown, Menu, Row, Spin, Typography } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

import CardContainer from "../../components/Containers/CardContainer";
import { PricesCard } from "./PricesCard";

import { SpecialPrice } from "../../interfaces/services.interface";

import { PricesContext } from "../../context/PricesContext";
import { TError, usePost } from "../../hooks/usePost";

import { removeUserFromSpecialPrices } from "../../services/services_s";

type MenuProps = {
  user: string;
  removeUser: (dto: { id: string; user: string }) => Promise<
    | {
        data: null;
        error: null;
      }
    | {
        data: unknown;
        error: TError | undefined;
      }
  >;
};

type Props = {
  specialPrice: SpecialPrice;
};

const MenuCom = ({ user, removeUser }: MenuProps) => {
  const {
    selectedServiceId,
    setSelectedServiceId,
    setStrategy,
    setIsPricesModalOpen,
    setSelectedPrice,
  } = useContext(PricesContext);

  const handleDeleteUser = async () => {
    if (!selectedServiceId) return { error: "No se selecciono usuario" };

    const data = await removeUser({ id: selectedServiceId._id, user });

    if (data.error) return;

    setSelectedServiceId({
      ...selectedServiceId,
      specialPrices: data.data as SpecialPrice[],
    });
  };

  const handleAddSpecialPrice = () => {
    setStrategy(() => "Agregar Precio Especial");
    setIsPricesModalOpen(() => true);
    setSelectedPrice(() => ({
      price: 0,
      weight: 0,
      user: user,
      _id: "1",
      index: 1,
    }));
  };

  return (
    <Menu>
      <Menu.Item key={1} onClick={handleDeleteUser}>
        Eliminar Usuario
      </Menu.Item>
      <Menu.Item key={2} onClick={handleAddSpecialPrice}>
        Agregar Precio Especial
      </Menu.Item>
    </Menu>
  );
};

export const SpecialPriceCard = ({ specialPrice }: Props) => {
  const { fetchData: removeUser, isLoading: isDeletingUser } = usePost(
    removeUserFromSpecialPrices
  );

  return (
    <CardContainer
      cardStyle={{
        border: "1px solid rgba(0, 0, 0, 0.1)",
        zIndex: 4,
        width: "100%",
        boxShadow: "none",
        textAlign: isDeletingUser ? "center" : "left",
      }}
    >
      {!isDeletingUser ? (
        <>
          {" "}
          <Row align="top" style={{ margin: "20px 0", width: "100%" }}>
            <Col flex={1}>
              <Row align="middle">
                <Col style={{ marginRight: "1rem" }}>
                  <Avatar>
                    {specialPrice?.userInformation?.name
                      ?.substring(0, 1)
                      .toUpperCase()}
                  </Avatar>
                </Col>
                <Col>
                  <Typography.Text strong style={{ display: "block" }}>
                    {specialPrice?.userInformation?.email}
                  </Typography.Text>
                  <Typography.Text style={{ display: "block" }}>
                    {specialPrice?.user}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col>
              <Dropdown
                overlay={
                  <MenuCom user={specialPrice?.user} removeUser={removeUser} />
                }
                placement="bottomLeft"
              >
                <EllipsisOutlined
                  style={{
                    fontSize: 25,
                    fontWeight: "bold",
                    color: "#2D1B6E",
                  }}
                />
              </Dropdown>
            </Col>
          </Row>
          <Row gutter={[0, 10]}>
            {specialPrice?.prices?.map((price, i) => (
              <PricesCard
                key={price._id || i}
                index={i}
                price={price}
                user={specialPrice?.user}
                withDelete
              />
            ))}
          </Row>
        </>
      ) : (
        <Spin />
      )}
    </CardContainer>
  );
};
