import React from "react";
import { Col, DatePicker, Row } from "antd";
import Graph from "./Graph";
import { useSection2Dashboard } from "../../hooks/useSection2Dashboard";
import { MostUsedDestination } from "./MostUsedDestinations";
import { ShipmentsWithStatus } from "./ShipmentWithStatus";
import { VerifyServices } from "./VerifyServices";
import { VerifyPaqueterias } from "./VerifyPaqueterias";
import CardContainer from "../../../../components/Containers/CardContainer";
import { useContext } from "react";
import { AdminDataContext } from "../../../../context/adminContext";
import GraphUsersNew from "./GraphUsersNew";
import IntegrationData from "./IntegrationData";
import { Platforms } from "../../../../interfaces/integrations.interface";
import GraphPlt from "./GraphPlt";
import ShipmentsByType from "./ShipmentsByType";
import ShipmentsByWeight from "./ShipmentsByWeight";

export const ShipmentsAnalytics: React.FC = () => {
  const { country } = useContext(AdminDataContext);
  const {
    adminAnalytics,
    isLoading,
    registerDate,
    filterByCreatedAt,
    dataShiptmentsByDateMonth,
    isLoadingShipmentsByDateMonth,
    dataInternationalShipments,
    isLoadingInternationalShipments,
    dataIntegrationsByTypeAndMonth,
    dataNewUsersType,
    getDataByIntegration,
    getDataByNewUsers,
    isLoadingIntegrationsByTypeAndMonth,
    isLoadingNewUsersType,
  } = useSection2Dashboard();

  const { RangePicker } = DatePicker;

  const handleIntegrationChange = (platform: Platforms) => {
    getDataByIntegration(platform);
  };

  const handleUsersNewChange = (isVerified: string) => {
    getDataByNewUsers(isVerified);
  };
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col lg={6} xs={0} sm={0} md={0}></Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <CardContainer
            cardStyle={{ marginTop: "20px" }}
            titleStyle={{ display: "flex", justifyContent: "center" }}
            title="Filtrar por fecha"
          >
            <RangePicker
              onChange={(e) => filterByCreatedAt(e)}
              placeholder={["Fecha inicial", "Fecha final"]}
              value={registerDate as unknown as any}
              style={{ width: "100%" }}
              size="middle"
            />
          </CardContainer>
        </Col>
        <Col lg={6} xs={0} sm={0} md={0}></Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <MostUsedDestination country={country} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <ShipmentsWithStatus
            filteredData={adminAnalytics!}
            isLoading={isLoading}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <ShipmentsByType country={country} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <ShipmentsByWeight country={country} />
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Graph
            isLoading={isLoadingShipmentsByDateMonth}
            filteredData={dataShiptmentsByDateMonth}
            chartType="bar"
            filterType="month"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Graph
            isLoading={isLoadingInternationalShipments}
            filteredData={dataInternationalShipments}
            chartType="bar"
            filterType="month int"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <GraphUsersNew
            isLoading={isLoadingNewUsersType}
            filteredData={dataNewUsersType}
            onUserChange={handleUsersNewChange}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <GraphPlt
            isLoading={isLoadingIntegrationsByTypeAndMonth}
            filteredData={dataIntegrationsByTypeAndMonth}
            onIntegrationChange={handleIntegrationChange}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col xs={24} sm={24} md={12} lg={8}>
          {/*<React.Suspense fallback={<div>Loading...</div>}>
                <ShipmentsByType country={country} />
              </React.Suspense> */}
          <IntegrationData
            filteredData={adminAnalytics!}
            isLoading={isLoading}
          />
        </Col>
        {country === "MX" && (
          <>
            <Col xs={24} sm={24} md={12} lg={8}>
              <VerifyServices filteredData={{}!} isLoading={isLoading} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <VerifyPaqueterias
                filteredData={adminAnalytics!}
                isLoading={isLoading}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              {/* <ShipmentsPerProvider /> */}
            </Col>
          </>
        )}
      </Row>
    </>
  );
};
