import { CardContainerStylingProps } from "../../../interfaces/permissions.interfaces"

export const getAccountType = (user: string) => {
    if (user.includes('google')) return 'Google'
    if (user.includes('facebook')) return 'Facebook'

    return 'Email'
}

export const stylesPermissions: CardContainerStylingProps = {
    titleCard: {
        fontSize: '1.2rem',
        marginLeft: '6px'
    },
    checkBox: {
        marginLeft: '10px'
    },
    singleCard:{
        padding: '2.5rem'
    },
    mainCheckBox: {
        margin: '0 0 0 10px',
    },
    permissionContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1rem',
        marginTop: '1rem',
        gap: '2rem'
    },
    checkboxMainContainer: {
        display: 'grid',
        gridTemplateRows: '1fr',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '1rem',
        marginBottom: '1rem',
        marginTop: '1rem'
    },
    checkboxLineContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginTop: '1rem',
        gap: '25px'
    },
    checkboxItem: {
        margin: 0,
        fontWeight: 'initial'
    }
}

export const defaultFormValues = {
    //users_section
    verify: false,
    search_users: false,
    recharge: false,
    update: false,
    block_users: false,
    assign_admin: false,
    discount: false,
    user_special_prices: false,

    //sections
    sections: false,
    shipments: false,
    movements: false,
    reports: false,
    recolections: false,
    cancelations: false,
    users: false,
    balances: false,

    //registers
    registers: false,
    resellers: false,
    top_users: false,
    modify_api_clients: false,
    col_recharges: false,
    stp_transactions: false,
    api_clients: false,
    admin_logs: false,
    monitored_info: false,

    //prices
    prices: false,
    prices_section: false,
    modify_prices: false,
    modify_special_prices: false,
    special_prices: false,

    //downloads
    downloads: false,
    download_movements: false,
    download_recolections: false,
    download_shipments: false,
    download_users: false,
    excess_weight: false,

    //permissions
    permissions: false,
    update_permissions: false,
    modify_admin_permission: false,

    //developers
    developers: false,
    cp_blacklist: false,
    error_logs: false,
    user_blacklist: false,
    modify_cps: false,

    //marketing
    marketing: false,
    marketing_stadistics: false,
    user_tracking: false,

    //sells
    sells: false,
    view_all_sells: false,
    view_seller_assign: false,
    assign_customers: false,

    //stadistics
    stadistics: false,
    stats: false,

    //general
    finder: false,
    shipments_finder: false,
    update_status: false,
}