import React, { createContext, useState } from 'react'
import anime from 'animejs'
import { Platforms } from '../interfaces/integrations.interface'

export type PermissionStep =
| '1) Admin users'
| '2) Permissions'


interface ContextData {
  permissionStep: PermissionStep
  previousPermissionStep: PermissionStep
  country: CountryAdmin,
  platform: Platforms,
  verify: string
  setPermissionStepTransition: (stepSelected: PermissionStep) => void
  resetPermissionStepTransition: (stepSelected: PermissionStep) => void
  changeCountry: (newCountry: CountryAdmin) => void
  changePlatform: (newPlatform: Platforms) => void
  changeVerification: (v: string) => void
}

export const AdminDataContext = createContext<ContextData>({
  permissionStep: '1) Admin users',
  previousPermissionStep: '1) Admin users',
  country: 'MX',
  platform: Platforms.Amazon,
  verify: '',
  setPermissionStepTransition: (stepSelected: PermissionStep) => {},
  resetPermissionStepTransition: (stepSelected: PermissionStep) => {},
  changeCountry: (newCountry: CountryAdmin) => {},
  changePlatform: (newPlatform: Platforms) => {},
  changeVerification: (v: string) => {},
})


export const AdminDataContextProvider: React.FC = (props) => {

  const [permissionStep, setPermissionStep] = useState<PermissionStep>('1) Admin users')

  const [previousPermissionStep, setPreviousPermissionStep] = useState<PermissionStep>(
    '1) Admin users'
  )
  const [country, setCountry] = useState<CountryAdmin>('MX');
  const [platform, setPlatform] = useState<Platforms>(Platforms.WooCommerce);
  const [verify, setVerify] = useState<string>('');

  const setPermissionStepTransition = (stepSelected: PermissionStep) => {
    setPreviousPermissionStep(permissionStep)
    setTimeout(() => {
      setPermissionStep(stepSelected)
    }, 650)
    const tl = anime.timeline({
      targets: '.dashboardSection',
      duration: 500,
      easing: 'linear'
    })

    tl.add({
      opacity: 0
    })
    tl.add(
      {
        opacity: 1
      },
      '+=400'
    )
  }

  const resetPermissionStepTransition = (stepSelected: PermissionStep) => {
    setPreviousPermissionStep(permissionStep)
    setPermissionStep(stepSelected)
  }

  const changeCountry = (newCountry: CountryAdmin) =>{
    setCountry(newCountry)
  }

  const changePlatform = (newPlatform: Platforms) =>{
    setPlatform(newPlatform)
  }

  const changeVerification = (v: string) =>{
    setVerify(v)
  }

  return (
    <AdminDataContext.Provider
      value={{
        permissionStep,
        previousPermissionStep,
        country,
        platform,
        verify,
        changeCountry,
        changePlatform,
        resetPermissionStepTransition,
        setPermissionStepTransition,
        changeVerification
      }}
    >
      {props.children}
    </AdminDataContext.Provider>
  )
}
