import { ShipmentTable } from '../../../interfaces/shipment-table.interface'
import { getParcelImage } from '../../../services/records'

export const tableShipmentsMapper = (shipment: ShipmentTable, country: CountryAdmin) => {
  const currencySymbol = country === 'CO' ? 'COP' : 'MXN'
  const formattedTotal = shipment.price.toLocaleString('en-US', {
    style: 'currency',
    currency: currencySymbol,
  })

  const symbolPosition = formattedTotal.indexOf(currencySymbol)
  const formattedTotalWithSymbol = `${country === 'CO' ? '$' : ''} ${formattedTotal.slice(
    symbolPosition + currencySymbol.length
  )}`
  return {
    metadata: shipment.metadata || {},
    applicationDate: new Date(shipment?.created_at).toLocaleDateString(),
    user: shipment.user.user,
    mail: shipment.profile ? shipment.profile?.email : 'Email no registrado',
    tracking: shipment.tracking ? shipment.tracking : 'Rastreo no registrado',
    addressee: shipment.origin.name,
    destination: shipment.destination.name,
    Parcel: getParcelImage(shipment.carrier as AllCarriers),
    Total: formattedTotalWithSymbol,
    shipmentData: shipment,
    shipment_status: shipment.shipment_status,
    provider: shipment.service_id ? shipment.service_id.split('_')[2] || '-' : 'none',
  }
}
