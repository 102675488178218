import { Col, Form, notification, Spin, Typography } from "antd";
import { Dispatch, SetStateAction, useEffect } from "react";
import InputContainer from "../../../../components/Containers/InputContainer";
import ModalContainer, {
  modalHeight,
  modalSizes,
} from "../../../../components/Containers/ModalContainer";
import { usePost } from "../../../../hooks/usePost";
import {
  Pickup,
  PickupStatus,
  pickupStatus,
} from "../../../../interfaces/pickups.interface";
import { updatePickupStatus } from "../../../../services/records";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: Pickup;
  refetch: () => void;
};

export const UpdateStatusModal = ({
  isOpen,
  setIsOpen,
  data,
  refetch,
}: Props) => {
  const { fetchData, isLoading } = usePost(updatePickupStatus);
  const [form] = Form.useForm();

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleUpdateStatus = async ({
    status,
    folio,
  }: {
    status: PickupStatus;
    folio: string;
  }) => {
    const resp = await fetchData({
      status,
      folio,
      shipmentId: data.shipment_id,
    });

    if (resp.error) {
      notification.error({
        message: "No se pudo actualizar el estatus de la recolección",
      });
      return;
    }

    notification.success({
      message: "La recolección se ha actualizado",
    });
    refetch();
    closeModal();
  };

  useEffect(() => {
    form.setFieldsValue({
      folio: data.folio,
      status: data.pickups_status,
    });
  }, [form, data.folio, data.pickups_status]);

  return (
    <ModalContainer
      title="Actualizar estatus de la recolección"
      footer={<></>}
      size={modalSizes.LARGE}
      height={modalHeight.MEDIUM}
      visible={isOpen}
      onCancel={closeModal}
      bodyStyle={{
        flexDirection: "column",
        alignItems: "space-between",
        flex: 1,
        display: "flex",
        height: "auto",
      }}
    >
      {!isLoading ? (
        <>
          <Typography.Paragraph style={{ margin: 0, height: "min-content" }}>
            Se actualizará el estatus de recolección con número de guía:{" "}
            {data.tracking}
          </Typography.Paragraph>
          <Col flex={1}>
            <Form form={form} onFinish={handleUpdateStatus}>
              <InputContainer
                placeholder="Folio de recoleccion"
                valueContainerName="folio"
                type="text"
              />
              <InputContainer
                placeholder="Estatus del envio"
                valueContainerName="status"
                type="select"
                optionsList={pickupStatus.map((value) => ({
                  label: value,
                  value,
                }))}
              />
              <InputContainer
                type="submitButton"
                title="Actualizar status"
                disabled={isLoading}
              />
            </Form>
          </Col>
        </>
      ) : (
        <Spin />
      )}
    </ModalContainer>
  );
};
