import { Col, Row, Typography } from "antd"
import AirplaneImage from '../../../../assets/images/airplane.png'

interface Styles {
    title: React.CSSProperties;
    container: React.CSSProperties;
}

const styles: Styles = {
    title: {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    container: {
        width: '100%'
    }
}

const MaintenancePage = () => {
    return (
        <Row style={styles.container}>
            <Col span={24}>
                <Typography style={styles.title}>Pagina en mantenimiento</Typography>
            </Col>
            <Col span={24}>
                <Typography>Esta sección estará disponible proximamente.</Typography>
            </Col>
            <Col span={24}>
                <section>
                    <img alt='airplane' src={AirplaneImage} width={148} height={131} />
                </section>
            </Col>
        </Row>

    )
}

export default MaintenancePage