import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Button } from 'antd'
import { TableV3 } from '../../../../components/Common/TableV3'
import { AllColumnsType, GetAllFilteredShipmentsQuery } from '../../../../interfaces/partner.interface'
import { useToggle } from '../../../../hooks/useToggle'
import { useTranslation } from 'react-i18next'
import { referralColumn } from './schemaColumns'
import { referralDownloads } from './schemaDownloads'
import { ApiContext } from '../../../../context/apiContext'
import { AppContext } from '../../../../context/appContext'

const ITEMS_PER_PAGES = 150
const backendUrlPartner = process.env.REACT_APP_PARTNERS_BACKEND_URL

const Referrals = () => {
  const { t } = useTranslation()
  const { userData } = useContext(AppContext)
  const { userToken } = useContext(ApiContext);
  const [itemPerPages, setItemPerPages] = useState(ITEMS_PER_PAGES)
  const [rowsPerPages, setRowPerPages] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)
  const [topLimit, setTopLimit] = useState(ITEMS_PER_PAGES)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [dataColumns, setDataColumns] = useState<any>(null)
  const [downloadColumns] = useState<GetAllFilteredShipmentsQuery>({
    range: {
      from: 0,
      to: topLimit
    },
    searched: searchText,
    columns: [
        {
          id: 'createdAt',
          find: false
        },
        {
          id: 'owner',
          find: false
        },
        {
          id: 'nickName',
          find: false
        },
        {
          id: 'email',
          find: false
        },
        {
          id: 'referralStatus',
          find: false
        }
      ]
  })
  const [filterByColumns, setFilterByColumns] = useState<GetAllFilteredShipmentsQuery>({ ...downloadColumns })

  const { state: newReferralModal, toggle: toggleNewReferralModal } = useToggle()
  const [activateNewReferral, setActivateNewReferral] = useState(false)
  const [activateDeleteReferral, setActivateDeleteReferral] = useState(false)

  const addNewReferral = async (newReferral: any) => {
    try {
      const rta = await axios.post(
        `${backendUrlPartner}/admin/referrals/creationManual`,
        newReferral, 
        {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        }
      )    
      
      return rta  
    } catch (e: any){
      return e.message
    }
  }

  const deleteReferral = async (id: string) => {
    try {
      const { data } = await axios.post(
        `${backendUrlPartner}/admin/referrals/delete`,
        { id }, 
        {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        }
      )    
      
      return data 
    } catch (e: any){
      return {
        code: 500,
        error: { message: e.message },
        res: null 
      }
    }
  }

  const handleFecthShipments = async (filterByColumns: GetAllFilteredShipmentsQuery, saveState = true) => {
    const { data } = await axios.post(`${backendUrlPartner}/admin/referrals/table`, filterByColumns, {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })

    if (saveState){
      setDataColumns(data)
    } else {
      return data ?? []
    }
  }

  useEffect(() => {
    if (!dataColumns?.length) return
    const totalPages = (dataColumns?.length ?? 0) / rowsPerPages

    if (currentPage + 1 < Math.round(totalPages)) return
    setTopLimit((prevState) => prevState + itemPerPages)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]) 

  useEffect(() => {
    (async () => {
      if (topLimit === ITEMS_PER_PAGES) return
      setFilterByColumns({ ...filterByColumns, range: { ...filterByColumns.range, to: topLimit } })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topLimit]) 

  useEffect(() => {
    (async () => {
      setIsRefetching(true)
      await handleFecthShipments(filterByColumns)
      setIsRefetching(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByColumns, activateNewReferral, activateDeleteReferral]) 

  return (
    <div>
      {' '}
      <TableV3
        title={t('Referrals.Table.Title')}
        tableType="referrals"
        data={dataColumns ?? []}
        isLoading={refetching}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(row) => setRowPerPages(row)}
        optionalContent={{
          search: (
            <>
              {userData?.metadata?.permissions?.referrals?.actions?.find((item: string) => item === 'assign_referrals') && (
                <Button className='add-new-referral' onClick={()=> toggleNewReferralModal()}>
                  {t("Referrals.Table.Button.AddReferral")}
                </Button>
              )}
            </>
          )
        }}
        schemaDownload={referralDownloads}
        columns={
            referralColumn(
              dataColumns ?? [],
              deleteReferral,
              activateDeleteReferral,
              setActivateDeleteReferral
            ) as ColumnsType<AllColumnsType>
        }
        stateValues={{
          currentPage,
          rowsPerPages,
          topLimit,
          searchText,
          filterByColumns,
          downloadColumns,
          newReferralModal
        }}
        filters={{
          changeTopLimit(limit: number){
            setItemPerPages(limit)
            setTopLimit((limit * 2))
          },
          filterBySearchText(text: string) {
            setSearchText(text)
          },
          filterByColumns(filter: GetAllFilteredShipmentsQuery) {
            setFilterByColumns(filter)
          },
          filterDownloads: async (filter: GetAllFilteredShipmentsQuery) => {
            return await handleFecthShipments(filter, false)
          },
          manageReferrals: {
            activateNewReferral: () => {
                setActivateNewReferral(!activateNewReferral)
            },
            addNewReferral: async (newReferral: any) => {
                return await addNewReferral(newReferral)
            },
            toggleNewReferralModal(){
                toggleNewReferralModal()
            }
          }
        }}
      />
    </div>
  )
}

export default Referrals