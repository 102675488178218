import { useEffect, useState } from 'react'
import { ActionsSeachUsers,PermissionViews } from '../interfaces/permissions.interfaces'
import usePermission from './usePermission'
import { UserAccessI } from '../pages/DashboardSections/Users/interfaces'

/**
 * Hook which helps you to know if the user hass access to specific section of the platform
 * @returns If the user has permissions
 */
const useUserAccess = () => {
    const { hasAccess } = usePermission();
    const [access, setAccess] = useState<UserAccessI | null>(null)

    const assignUserAccess = (): UserAccessI => {
        return {
            verify: hasAccess(
                ActionsSeachUsers.verify,
                PermissionViews.search_users
            ),
            recharge: hasAccess(
                ActionsSeachUsers.recharge,
                PermissionViews.search_users
            ),
            discount: hasAccess(
                ActionsSeachUsers.discount,
                PermissionViews.search_users
            ),
            update: hasAccess(
                ActionsSeachUsers.update,
                PermissionViews.search_users
            ),
            block_users: hasAccess(
                ActionsSeachUsers.block_users,
                PermissionViews.search_users
            ),
            admin: hasAccess(
                ActionsSeachUsers.assign_admin,
                PermissionViews.search_users
            ),
            special_prices: hasAccess(
                ActionsSeachUsers.user_special_prices,
                PermissionViews.search_users
            ),
            vip: hasAccess(ActionsSeachUsers.update, PermissionViews.search_users),
            special_accounts: hasAccess(ActionsSeachUsers.update, PermissionViews.search_users),
            estafeta_special: hasAccess(ActionsSeachUsers.update, PermissionViews.search_users),
            carriersActive: hasAccess(
                ActionsSeachUsers.update,
                PermissionViews.search_users
            ),
        }

    }
    useEffect(() => {
        if(access === null){
            setAccess(assignUserAccess())
        }
       //eslint-disable-next-line
    }, [access])
    
    return {
        access,
    }
}


export default useUserAccess
