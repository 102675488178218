import { ProfilesInterface } from './services.interface'
import { AllUsersI } from './users.interfaces'
export interface Shipment {
  ShippingId?: ShippingId
  fechaCancelacion: Date
  statusCancelacion: string
  recoleccionV2: boolean
  recoleccionProgramada: string
  API: boolean
  _id: number
  provider: string
  correoUsuario: string
  destinatario: string
  destino: string
  paqueteria: string
  monto: number
  usuario: string
  status: string
  fechaEnvio: Date
  commercialInvoice: null
  label: string
  numeroTracking: string
  urlTracking: string
  beforeMod: number
  afterMod: number
  cpOrigen: string
  cpDestinatario: string
  peso: number
  calleOrigen: string
  numeroOrigen: string
  coloniaOrigen: string
  ciudadOrigen: string
  estadoOrigen: string
  calleDestino: string
  numeroDestino: string
  coloniaDestino: string
  ciudadDestino: string
  reportado: string
  nombreRemitente: string
  telefonoRemitente: string
  referenciaRemitente: string
  medidas: string
  __v: number
}

export interface AddressesInterface {
  postal_code: string
  user: string
  country: string
  email: string
  title: string
  name: string
  last_name?: string
  phone: string
  company: string
  street: string
  number: string
  int_number: string
  district?: string
  city: string
  postalCode: string
  state: string
  reference: string
}

export interface CancellationsInterface {
  cancellationDate: string
  guideId: string
  amount: number
  parcel: string
  status: ShipmentStatus
}

export interface PackagesInterface {
  user?: string
  weight: number
  main_weight: number
  height: number
  width: number
  length: number
  declared_value: number
  volumetric: number
  type: 'box' | 'envelope'
  content?: string
  name: string
}

export type FormatedShipmentForTables = ShipmentV2 & {
  profile: { email: string }
}

export const carrierArray = [
  'Filtrar todos',
  'fedex',
  'estafeta',
  'sendex',
  'dhl',
  'ups',
  'carssa',
  'ivoy',
  'scm',
  'quiken',
  'ampm',
  'estafeta',
  'tracusa',
  'paquetexpress',
  'noventa9Minutos',
  'mensajerosUrbanos',
  'uber',
  'aExpress',
  'coordinadora',
  'redServi',
  'serviEntrega',
  'vencedor',
]

export const OverweightActionArray = ['Bajar sobrepesos', 'Cambiar status masivo']

export const OverweightCarriersArray = [
  'fedex',
  'estafeta',
  '99 Minutos',
  'sendex',
  'dhl Shipandgo',
  'dhl Shipango',
  'dhl Shippo',
  'dhl Directo',
  'dhl Pak2go',
  'jtexpress',
  'ampm',
  'envia',
  'ups',
  'coordinadora'
]

export const countryArray = ['Filtrar todos', 'México', 'Colombia']

export const activityArray = ['Filtrar todos', 'Realizaron recargas', 'No han realizado recargas']

export const versionArray = ['Versión 2', 'Versión 1']

export const shipmentStatusArray = [
  'Filtrar todos',
  'Solicitada',
  'Cancelada y usada',
  'Saldo abonado',
  'Activa',
  'Entregada',
  'Cancelada de prov',
  'Todas las Canceladas',
]

export const movementStatusArray = [
  'Filtrar todos',
  'Recarga',
  'Reembolso',
  'Envio',
  'Descuento',
  'Sobrepeso',
  'Sincronizacion de Saldo',
  'Facturables',
]

/*
export const servicesIdCarriersArray = [
  { value: "all", label: "Filtrar todos" },
  //estafeta
  { value: "estafeta_mx_D-C03_terrestre", label: "Estafeta MX Terrestre" },
  { value: "estafeta_mx_D-C03_2dias", label: "Estafeta MX 2 días" },
  { value: "estafeta_mx_D-C03_next_day", label: "Estafeta MX Día Siguiente" },
  //fedex
  { value: "fedex_mx_D-C02_ground", label: "Fedex MX Terrestre Drevsto" },
  { value: "fedex_mx_D-C02_express", label: "Fedex MX Express Drevsto" },
  { value: "fedex_mx_D-C03_ground", label: "Fedex MX Terrestre Web360" },
  { value: "fedex_mx_D-C03_express", label: "Fedex MX Express Web360" },
  { value: "fedex_mx_A-P03_ground", label: "Fedex MX Terrestre Pak2go" },
  { value: "fedex_mx_A-P03_express", label: "Fedex MX Express Pak2go" },
  //dhl
  { value: "dhl_mx_A-P02_ground", label: "DHL MX Terrestre Shippo" },
  { value: "dhl_mx_A-P02_express", label: "DHL MX Express Shippo" },
  { value: "dhl_mx_A-P03_ground", label: "DHL MX Terrestre Pak2go" },
  { value: "dhl_mx_A-P03_express", label: "DHL MX Express Pak2go" },
  { value: "dhl_mx_A-P04_ground", label: "DHL MX Terrestre Shipandgo" },
  { value: "dhl_mx_A-P04_express", label: "DHL MX Express Shipandgo" },
  //sendex
  { value: "sendex_mx_D-C01_ground", label: "Sendex MX Terrestre Drenvio" },
  { value: "sendex_mx_D-C01_ground6dias", label: "Sendex MX 6 Días Drenvio" },
  { value: "sendex_mx_A-P01_ground", label: "Sendex MX Terrestre Envía" },
  { value: "sendex_mx_A-P01_ground6dias", label: "Sendex MX 6 Días Envía" },
  //paquetexpress
  { value: "paquetexpress_mx__ground", label: "Paquetexpress MX Terrestre Drenvio" },
  { value: "paquetexpress_mx__express", label: "Paquetexpress MX Express Drenvio" },
  { value: "paquetexpress_mx_A-P03_ground", label: "Paquetexpress MX Terrestre Pak2go" },
  { value: "paquetexpress_mx_A-P03_express", label: "Paquetexpress MX Express Pak2go" },
  //AMPM
  { value: "ampm_mx_D-C01_ground", label: "AMPM MX Terrestre Drenvio" },
  { value: "ampm_mx_D-C01_express", label: "AMPM MX Express Drenvio" },
  //Quiken
  { value: "quiken_mx_D-C01_ground36hrs", label: "Sendex MX Terrestre Envía" },
  { value: "quiken_mx_D-C01_ground36hrs", label: "Sendex MX 6 Días Envía" },
  //99Minutos
  { value: "noventa9Minutos_mx_D-C01_next_day", label: "99 Minutos MX Día Siguiente Drenvio" },
  { value: "noventa9Minutos_mx_D-C01_same_day", label: "99 Minutos MX Mismo Día Drenvio" },
  { value: "noventa9Minutos_mx_A-P01_next_day", label: "99 Minutos MX Día Siguiente Envía" },
  //UPS
  { value: "ups_mx_A-P02_ground", label: "UPS MX Terrestre Shippo" },
  { value: "ups_mx_A-P02_express", label: "UPS MX Express Shippo" },
  //Carssa
  { value: "carssa_mx_A-P01_ground", label: "Carssa MX Terrestre Envía" },
  //SCM
  { value: "scm_mx_A-P01_ground", label: "SCM MX Terrestre Envía" },
  //Mensajeros urbanos
  { value: "mensajerosUrbanos_mx_A-P01_ground", label: "Mensajeros Urbanos MX Terrestre Envía" },
  //fedex inter
  { value: "fedex_mx_D-C02_int_economy", label: "Fedex MX Int. Económico Drevsto" },
  { value: "fedex_mx_D-C02_int_priority", label: "Fedex MX Int. Prioridad Drevsto" },
  { value: "fedex_mx_D-C03_int_economy", label: "Fedex MX Int. Económico Web360" },
  { value: "fedex_mx_D-C03_int_priority", label: "Fedex MX Int. Prioridad Web360" },
  //Estafeta int
  { value: "dhl_mx_A-P02_int", label: "DHL MX Internacional Shippo" },
  //UPS int
  { value: "ups_mx_A-P01_int", label: "UPS MX Internacional Envía" },
  //Estafeta int
  { value: "estafeta_mx_D-C03_int", label: "Estafeta MX Internacional Web360" },
]
*/

export const serviceIdArray = [
  'Filtrar todos',
  //estafeta
  'estafeta_mx_D-C03_terrestre',
  'estafeta_mx_D-C03_2dias',
  'estafeta_mx_D-C03_next_day',
  //fedex
  'fedex_mx_D-C02_ground',
  'fedex_mx_D-C02_express',
  'fedex_mx_D-C03_ground',
  'fedex_mx_D-C03_express',
  'fedex_mx_A-P03_ground',
  'fedex_mx_A-P03_express',
  //dhl
  'dhl_mx_A-P02_ground',
  'dhl_mx_A-P02_express',
  'dhl_mx_A-P03_ground',
  'dhl_mx_A-P03_express',
  'dhl_mx_A-P04_ground',
  'dhl_mx_A-P04_express',
  'dhl_mx_A-P05_ground',
  'dhl_mx_A-P05_express',
  'dhl_mx_A-P06_ground',
  'dhl_mx_A-P06_express',
  //sendex
  'sendex_mx_D-C01_ground',
  'sendex_mx_D-C01_ground6dias',
  'sendex_mx_A-P01_ground',
  'sendex_mx_A-P01_ground6dias',
  //paquetexpress
  'paquetexpress_mx__ground',
  'paquetexpress_mx__express',
  'paquetexpress_mx_A-P03_ground',
  'paquetexpress_mx_A-P03_express',
  //AMPM
  'ampm_mx_D-C01_ground',
  'ampm_mx_D-C01_express',
  //Quiken
  'quiken_mx_D-C01_ground36hrs',
  'quiken_mx_D-C01_ground36hrs',
  //99Minutos
  'noventa9Minutos_mx_D-C01_next_day',
  'noventa9Minutos_mx_D-C01_same_day',
  'noventa9Minutos_mx_A-P01_next_day',
  //UPS
  'ups_mx_A-P02_ground',
  'ups_mx_A-P02_express',
  //Carssa
  'carssa_mx_A-P01_ground',
  //SCM
  'scm_mx_A-P01_ground',
  //Mensajeros urbanos
  'mensajerosUrbanos_mx_A-P01_ground',
  //fedex inter
  'fedex_mx_D-C02_int_economy',
  'fedex_mx_D-C02_int_priority',
  'fedex_mx_D-C03_int_economy',
  'fedex_mx_D-C03_int_priority',
  //Estafeta int
  'dhl_mx_A-P02_int',
  'dhl_mx_A-P02_ground_int',
  'dhl_mx_A-P05_ground_int',
  'dhl_mx_A-P06_ground_int',
  //UPS int
  'ups_mx_A-P01_int',
  //Estafeta int
  'estafeta_mx_D-C03_int',
]

export type Paqueteria =
  | 'fedex'
  | 'sendex'
  | 'dhl'
  | 'ups'
  | 'carssa'
  | 'ivoy'
  | 'scm'
  | 'quiken'
  | 'ampm'
  | 'estafeta'
  | 'tracusa'
  | 'paquetexpress'
  | 'noventa9Minutos'
  | 'mensajerosUrbanos'
  | 'uber'
  | 'aExpress'
  | 'coordinadora'
  | 'redServi'
  | 'serviEntrega'
  | 'vencedor'
  | 'pqexpress'

export enum ShipmentStatus {
  Solicited = 'Solicitada',
  CancelledButUsed = 'Cancelada y usada',
  Refunded = 'Saldo abonado',
  Active = 'Activa',
  Delivered = 'Entregada',
  CancelledFromProvider = 'Cancelada de prov',
  InTransit = 'En transito',
  Exception = 'Excepcion'
}

export enum ShipmentStatusV1 {
  Solicited = 'Solicitada',
  CancelledButUsed = 'Cancelada y usada',
  Refunded = 'Saldo abonado',
  Active = 'noSolicitada',
  CancelledFromProvider = 'Cancelada de prov',
}

export interface MetadataInterface {
  shippo?: {
    shippo_rate_id: string
    shippo_shipment_id: string
    shippo_guide_id: string
  }
  noventa9minutos?: {
    origin: {
      last_name: string
    }
    destination: {
      last_name: string
    }
  }
  format?: any
  account_id?: any
  isAutomaticDoc?: boolean
  extended_area?: boolean
  cancel_at?: string;
}

export interface ShipmentV2 {
  shipping_id: any
  user: AllUsersI | string
  shipment_status: ShipmentStatus
  origin: AddressesInterface
  destination: AddressesInterface
  packages: [PackagesInterface]
  label: string
  comercial_innvoice?: string
  ShippingId: ShippingId
  carrier: Paqueteria
  service: string
  service_id: string
  price: number
  insurance: number
  tracking: string
  created_at: Date
  updatedAt: Date
  movement: Movement
  metadata?: MetadataInterface
  profile?: ProfilesInterface
  _id: string
}

export const movementTypes = [
  'ENVIO',
  'SOBREPESO',
  'DESCUENTO',
  'RECARGA',
  'REEMBOLSO',
  'SINCRONIZACION DE SALDO',
  'NOTIFICATION',
  'REFERIDO',
  'ACTUALIZACIÓN DE USUARIO',
  'DESBLOQUEO DE USUARIO',
  'BLOQUEO DE USUARIO',
] as const
export type MovementTypes = (typeof movementTypes)[number]

export interface Movement {
  _id?: string
  user: string
  payment_id?: string
  type: MovementTypes
  amount: number
  currency: Currencies
  description: string
  security: {
    before_mod: number
    after_mod: number
  }
  metadata: {
    facturado: boolean
    cantidad_paypal?: number
  }
  created_at: string
  amount_paid: number
}
export interface ShipmentTable {
  applicationDate: string
  tracking: string
  addressee: string
  destination: string
  Parcel: any
  Total: string
  shipmentData: ShipmentV2
}

export type ShippingId =
  | 'S1'
  | 'G1'
  | 'E1'
  | 'N1'
  | 'N2'
  | 'N3'
  | 'N4'
  | 'N5'
  | 'N6'
  | 'N7'
  | 'N9'
  | 'N10'
