import { FC } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button } from 'react-bootstrap'
import { Tooltip } from 'antd'
import { logosImagesSrc } from '../../assets/exports/Logos'
import { Link } from 'react-router-dom'
import { PRIMARY_COLOR } from '../../constants/colors'

interface HeaderProps {
  isLogged?: boolean
}

const Header: FC<HeaderProps> = ({ isLogged }) => {
  return <>{isLogged ? <LoggedHeader /> : <UnLoggedHeader />}</>
}

const LoggedHeader = () => {
  return (
    <div
      style={{
        backgroundColor: PRIMARY_COLOR,
        padding: '3.125rem 10rem',
      }}
      className='d-flex '
    >
      <Tooltip title={'Atras'} style={{ flex: '1 1 0%' }}>
        <Button variant='outline-light' className='mx-1 d-flex align-items-center'>
          <ArrowLeftOutlined />
        </Button>
      </Tooltip>
      <div style={{ flex: '1 1 0%' }} className='d-flex justify-content-center'>
        <Link to='/'>
          <img src={logosImagesSrc['DrEnvioPolicyLogo']} alt='DrEnvio' width={231} height={53} />
        </Link>
      </div>
    </div>
  )
}

const UnLoggedHeader = () => {
  return (
    <div
      style={{
        backgroundColor: PRIMARY_COLOR,
        padding: '3.125rem 10rem',
      }}
      className='d-flex '
    >
      <div style={{ flex: '1 1 0%' }}></div>
      <div style={{ flex: '1 1 0%' }} className='d-flex justify-content-center'>
        <Link to='/'>
          <img src={logosImagesSrc['DrEnvioPolicyLogo']} alt='DrEnvio' width={231} height={53} />
        </Link>
      </div>
      <div style={{ flex: '1 1 0%', justifyContent: 'flex-end' }} className='d-flex'>
        <Button variant='outline-light' className='mx-1 d-flex align-items-center'>
          Iniciar Sesion
        </Button>
      </div>
    </div>
  )
}

export default Header
