import { Col, Typography } from "antd"
import { FC } from "react";
import CardContainer from "../../../../components/Containers/CardContainer"
import { AddressesInterface, PackagesInterface } from "../../../../interfaces/shipments.interface";
import { AddressReportInfo, PackagesReportInfo } from "./GenericInfo";

interface Props {
    report: {
        carrier: string;
        service: string;
        origin: AddressesInterface;
        packages: PackagesInterface[]
    };
}
const RecolectionsInfo: FC<Props> = ({ report }) => {
    return (
        <>
            <CardContainer title="Datos de la paquetería" cardStyle={{ marginBottom: '1.5rem' }}>
                <Col span={24} style={{ padding: '8px' }}>
                    <Typography>
                        {` - Paqueteria: `} <strong>{`${report.carrier} ${report.service}`}</strong>
                    </Typography>
                </Col>
            </CardContainer>
            <AddressReportInfo data={report.origin} title="Datos de la recolección" />
            <PackagesReportInfo data={report.packages} />
        </>
    )
}

export default RecolectionsInfo