import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { TableV3 } from '../../../../components/Common/TableV3'
import { AllColumnsType, GetAllFilteredShipmentsQuery as GetAllFilteredQuery } from '../../../../interfaces/partner.interface'
import { useTranslation } from 'react-i18next'
import { fulfillProfilesColumn } from './fulfillSchemaColumns'
import { fulfillProfilesDownloads } from './fulfillSchemaDownloads'
import { ApiContext } from '../../../../context/apiContext'

const ITEMS_PER_PAGES = 150
const backendUrlApiClientes = process.env.REACT_APP_NOT_BACKEND_URL

const FulfillProfiles = () => {
  const { t } = useTranslation()
  const { userToken } = useContext(ApiContext);
  const [rowsPerPages, setRowPerPages] = useState(20)
  const [itemPerPages, setItemPerPages] = useState(ITEMS_PER_PAGES)
  const [currentPage, setCurrentPage] = useState(1)
  const [topLimit, setTopLimit] = useState(ITEMS_PER_PAGES)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [dataColumns, setDataColumns] = useState<any>(null)
  const [downloadColumns] = useState<GetAllFilteredQuery>({
    range: {
      from: 0,
      to: topLimit
    },
    searched: searchText,
    columns: [
      {
        id: 'createdAt',
        find: false
      },
      {
        id: 'name',
        find: false
      },
      {
        id: 'email',
        find: false
      },
      {
        id: 'updatedAt',
        find: false
      },
    ]
  })
  const [filterByColumns, setFilterByColumns] = useState<GetAllFilteredQuery>({ ...downloadColumns })

  const handleFecth = async (filterByColumns: GetAllFilteredQuery, saveState = true) => {
    const { data } = await axios.post(
      `${backendUrlApiClientes}users/allFulfillUsers`, 
      filterByColumns, 
      { headers: { Authorization: `Bearer ${userToken}`}}
    )

    if (saveState){
      setDataColumns(data)
    } else {
      return data ?? []
    }
  }

  useEffect(() => {
    if (!dataColumns?.length) return
    const totalPages = (dataColumns?.length ?? 0) / rowsPerPages

    if (currentPage + 1 < Math.round(totalPages)) return
    setTopLimit((prevState) => prevState + itemPerPages)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]) 

  useEffect(() => {
    (async () => {
      if (topLimit === ITEMS_PER_PAGES) return
      setFilterByColumns({ ...filterByColumns, range: { ...filterByColumns.range, to: topLimit } })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topLimit]) 

  useEffect(() => {
    (async () => {
      setIsRefetching(true)
      await handleFecth(filterByColumns)
      setIsRefetching(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByColumns]) 

  return (
    <div>
      {' '}
      <TableV3
        title={t('FulfillmentProfiles.Table.Title')}
        tableType="fulfillProfiles"
        data={dataColumns ?? []}
        isLoading={refetching}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(row) => setRowPerPages(row)}
        config={{
          disableFilters: true
        }}
        schemaDownload={fulfillProfilesDownloads}
        columns={
            fulfillProfilesColumn(dataColumns ?? []) as ColumnsType<AllColumnsType>
        }
        stateValues={{
          currentPage,
          rowsPerPages,
          topLimit,
          searchText,
          filterByColumns,
          downloadColumns,
        }}
        filters={{
          changeTopLimit(limit: number){
            setItemPerPages(limit)
            setTopLimit((limit * 2))
          },
          filterBySearchText(text: string) {
            setSearchText(text)
          },
          filterByColumns(filter: GetAllFilteredQuery) {
            setFilterByColumns(filter)
          },
          filterDownloads: async (filter: GetAllFilteredQuery) => {
            return await handleFecth(filter, false)
          },
        }}
      />
    </div>
  )
}

export default FulfillProfiles