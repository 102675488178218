import { Col, Menu, Row, Typography } from "antd";
import {
    DollarOutlined,
    SmileOutlined,
    FrownOutlined,
} from "@ant-design/icons";
import AccessMsg from "../../../../components/Common/AccessMsg";
import { UserAccessI, userActionsT } from "../interfaces";

const menuDropdownUser = (setModalProps: any, dataToShow: any, userAccess: UserAccessI, changeActions: (type: userActionsT) => void) => {
    const { Text } = Typography;

    return (
        <Menu>
            <Menu.Item
                disabled={!userAccess?.recharge}
                onClick={() => {
                    setModalProps({ visible: true, selector: "Recharge" });
                }}
            >
                <AccessMsg label="Recargar" access={userAccess?.recharge} >
                    <Row style={{ alignContent: "center" }} onClick={() => changeActions('recharge')}>
                        <Col style={{ bottom: 3, right: 5 }}>
                            <DollarOutlined />
                        </Col>
                        <Col>
                            <Text>Recargar Saldo</Text>
                        </Col>
                    </Row>
                </AccessMsg>
            </Menu.Item>
            <Menu.Item
                disabled={!userAccess?.discount}
                onClick={() => {
                    setModalProps({ visible: true, selector: "Discount" });
                }}
            >
                <AccessMsg label="Descontar" access={userAccess?.discount} placement="left" >
                    <Row style={{ alignContent: "center" }} onClick={() => changeActions('discount')}>
                        <Col style={{ bottom: 3, right: 5 }}>
                            <DollarOutlined style={{ color: "#FD2222" }} />
                        </Col>
                        <Col>
                            <Text>Descontar Saldo</Text>
                        </Col>
                    </Row>
                </AccessMsg>
            </Menu.Item>
            <Menu.Item
                disabled={!userAccess?.update}
                onClick={() => {
                    setModalProps({ visible: true, selector: "Update" });
                }}
            >
                <AccessMsg label="Actualizar" access={userAccess?.update} placement="left" >
                    <Row style={{ alignContent: "center" }} onClick={() => changeActions('update')}>
                        <Col style={{ bottom: 3, right: 5 }}>
                            <SmileOutlined />
                        </Col>
                        <Col>
                            <Text>Actualizar Usuario</Text>
                        </Col>
                    </Row>
                </AccessMsg>
            </Menu.Item>
            <Menu.Item
                disabled={!userAccess?.block_users}
                onClick={() => {
                    setModalProps({ visible: true, selector: "Block" });
                }}
            >
                <AccessMsg label="Bloquear" access={userAccess?.block_users} placement="bottom" >
                    <Row style={{ alignContent: "center" }} onClick={() => changeActions(dataToShow.security?.isBlocked ? 'unblock': 'block')}>
                        <Col style={{ bottom: 3, right: 5 }}>
                            <FrownOutlined style={{ color: "#FD2222" }} />
                        </Col>
                        <Col>
                            <Text>
                                {dataToShow.security?.isBlocked
                                    ? "Desbloquear Usuario"
                                    : "Bloquear Usuario"}
                            </Text>
                        </Col>
                    </Row>
                </AccessMsg>
            </Menu.Item>
        </Menu>
    )
};

export default menuDropdownUser