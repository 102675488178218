import { Row } from "antd";
import { PricesContextProvider } from "../../context/PricesContext";

import { SelectParcel } from "./SelectParcel";
import { SelectPrice } from "./SelectPrice";
import { PriceModalForm } from "./PriceModalForm";
import { UsersModal } from "./UsersModal";

export const Prices = () => {
  return (
    <PricesContextProvider>
      <Row
        className="scrollNone"
        justify="space-between"
        style={{
          width: "100%",
          // margin: "0",
          height: "100%",
          maxHeight: "100%",
          overflow: "scroll",
        }}
      >
        <SelectParcel />
        <SelectPrice />
      </Row>
      <PriceModalForm />
      <UsersModal />
    </PricesContextProvider>
  );
};
