import { apiProps, apiResponse } from '../interfaces/app.interfaces'
import { FileI, OverweightI, TrackingsI } from '../pages/DashboardSections/Data/Overweight'


export const shippingData =
    (dto: {
        dateStart: Date,
        endDate: Date,
        status: string,
        service_id: string,
        carrier: string,
        version: string
    }) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
        return await backendApiCall({
            method: 'POST',
            endpoint: `allShipment/getShipment`,
            data: dto
        })

    }

export const shippingFinder =
    (dto: { version: string; tracking: string }) =>
        async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
            return await backendApiCall({
                method: 'POST',
                endpoint: `shipments/search`,
                data: dto
            })
        }

export const ShippingInfo =
    (dto: { files: FileI[], carrier: string }) =>
        async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
            return await backendApiCall({
                method: 'POST',
                endpoint: `allShipment/overweight/info`,
                data: dto
            })
        }

export const DiscountOverweights =
    (dto: { files: OverweightI[], carrier: string }) =>
        async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
            return await backendApiCall({
                method: 'POST',
                endpoint: `allShipment/overweight/discounts`,
                data: dto
            })
        }

export const TrackingInfo =
    (dto: { files: TrackingsI[] }) =>
        async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
            return await backendApiCall({
                method: 'POST',
                endpoint: `allShipment/massive-change/status`,
                data: dto
            })
        }

export const UpdateMassiveStatus =
    (dto: { files: TrackingsI[]}) =>
        async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
            return await backendApiCall({
                method: 'POST',
                endpoint: `allShipment/massive-change/update`,
                data: dto
            })
        }

export const movementData =
    (dto: {
        dateStart: Date,
        endDate: Date,
        status: string,
        user_id: string,
        version: string
    }) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
        return await backendApiCall({
            method: 'POST',
            endpoint: `allShipment/getMovements`,
            data: dto
        })

    }


export const usersData =
    (dto: {
        dateStart: Date,
        endDate: Date,
        country: string,
        activity: string
    }) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
        return await backendApiCall({
            method: 'POST',
            endpoint: `allShipment/get-users`,
            data: dto
        })

    }

