import { t } from "i18next";

export const limitTextSize = (value: string, limit: number, invert: boolean = false) => {
    if (invert) return value.length > limit ? '...' + value.substring((value.length - limit)) : value
    return value.length > limit ? value.substring(0, limit) + '...' : value
}

export const carrierNames = (carrier: string) => {
    const carrierName = t(`Quote.Carriers.${carrier}`);
    if (carrierName.includes('Quote.Carriers')) return carrier

    return carrierName

}