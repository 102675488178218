import React, { useState } from "react";
import { Row, Col, Form } from "antd";
import { t } from "i18next";
import InputContainer from "../../../components/Containers/InputContainer";
import CardContainer from "../../../components/Containers/CardContainer";
import { usePost } from "../../../hooks/usePost";
import { usersData } from "../../../services/shipping_s";
import * as XLSX from "xlsx";
import { notification } from "antd";
import { AdminDataPicker } from "../../../components/Common/admin/AdminDataPicker";
import { countryArray, activityArray } from "../../../interfaces/shipments.interface";

const styles = {
    fontSize: "1rem",
    margin: '0.5rem 0'
}


const Users: React.FC = () => {
    const [country, setCountry] = useState<string>("");
    const [rangeDate, setRangeDate] = useState<any>()
    const [activity, setActivity] = useState<string>("");
    const [form] = Form.useForm();
    const { fetchData: getData, isLoading } = usePost(usersData);

    const getCountry = (country: string) => {
        switch (country) {
            case 'México':
                return 'MX'
            case 'Colombia':
                return 'CO'
            default:
                return ''
        }
    }

    const getActivity = (country: string) => {
        switch (country) {
            case 'Realizaron recargas':
                return 'RECHARGES'
            case 'No han realizado recargas':
                return 'NO_RECHARGES'
            default:
                return ''
        }
    }

    const downloadExcel = async (type: "xlsx" | "csv") => {
        const resp = await getData({
            dateStart: new Date(rangeDate[0]._d),
            endDate: new Date(rangeDate[1]._d),
            country: getCountry(country),
            activity: getActivity(activity),
        });
        if (resp.error) {
            notification.error({
                message: t("Se ha encontrado un problema en la busqueda, intente de nuevo"),
            });
            return
        };
        if (resp.data.res.length === 0) {
            notification.error({
                message: t("No se han encontrado resultados"),
            });
            return
        } else {
            const worksheet = XLSX.utils.json_to_sheet(resp?.data?.res);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, `${"book"}`);
            await XLSX.writeFile(workbook, `${"book"}.${type}`, { bookType: type });
            notification.success({
                message: t(type + ' generado'),
            });
        }
    };

    const validate = (type: "xlsx" | "csv") => {
        form
            .validateFields()
            .then((values: any) => {
                if (values) {
                    downloadExcel(type);
                }
            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: t("Algo ha fallado"),
                });
            });
    };

    const onFinishFaild = async () => {
        notification.error({
            message: t("Algo ha fallado"),
        });
    };

    return (
        <>
            <Form form={form} onFinishFailed={onFinishFaild}>
                <Row gutter={[20, 15]} style={{ justifyContent: "center" }}>
                    <Col sm={24} md={12}>
                        <Row gutter={[5, 10]}>
                            <Col span={24}>
                                <CardContainer>
                                    <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>Usuarios</p>
                                    <br />
                                    <b>Filtrar por fecha de registro</b>
                                    <br />
                                    <AdminDataPicker
                                        setValue={setRangeDate}
                                    />
                                    <br />
                                    <b>Filtrar por país</b>
                                    <InputContainer
                                        type="select"
                                        placeholder={t("Down.Placeholder.Country")}
                                        valueContainerName={"version"}
                                        optionsList={countryArray || []}
                                        required
                                        onChange={(version) => {
                                            setCountry(version);
                                        }}
                                    />
                                    <br />
                                    <b>Filtrar por tipo de actividad</b>
                                    <InputContainer
                                        type="select"
                                        placeholder={t("Down.Placeholder.Activity")}
                                        valueContainerName={"parcel"}
                                        optionsList={activityArray || []}
                                        required
                                        onChange={(status) => {
                                            setActivity(status);
                                        }}
                                    />
                                    <br />
                                    <br />
                                    <InputContainer
                                        style={{ marginTop: "20px" }}
                                        type="button"
                                        onClick={() => validate("xlsx")}
                                        title="Generar Excel"
                                        isButtonLoading={isLoading}
                                        disabled={!country || !activity || !rangeDate}
                                    />

                                    <InputContainer
                                        style={{ marginTop: "20px" }}
                                        type="button"
                                        onClick={() => validate("csv")}
                                        isButtonLoading={isLoading}
                                        title="Generar CSV"
                                        disabled={!country || !activity || !rangeDate}
                                    />
                                </CardContainer>
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={24} md={12}>
                        <Row gutter={[5, 10]}>
                            <Col span={24}>
                                <CardContainer
                                    title={t("Down.Shipping.Title.Instruction")}
                                    titleStyle={{ justifyContent: "center" }}
                                />
                            </Col>
                            <Col span={24}>
                                <CardContainer>
                                    <ul>
                                        <li style={styles}>{t("Down.Users.List.Data")}</li>
                                        <li style={styles}>{t("Down.Users.List.Filter")}</li>
                                        <li style={styles}>{t("Down.Users.List.Package")}</li>
                                        <li style={styles}>{t("Down.Users.List.Cvs")}</li>
                                    </ul>
                                </CardContainer>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default Users;
