import { AdminDataPicker } from '../Common/admin/AdminDataPicker'
import { FC } from 'react'

export const TableDateFiler: FC<Props> = ({ setValue }) => {
  return <AdminDataPicker setValue={setValue} />
}

type Props = {
  setValue: (e: any) => void
}
