// interface ComponentsProps {
    
// }

const Components = () => {
    return (   
        <div>ggggg</div>
    );
}
 
export default Components;