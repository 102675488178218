import { useState, useEffect, useContext } from "react";
import Chart from "react-apexcharts";
import CardContainer from "../../../../components/Containers/CardContainer";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { Col, Row } from "antd";
import { AppContext } from "../../../../context/appContext";
import { Platforms } from "../../../../interfaces/integrations.interface";
import InputContainer from "../../../../components/Containers/InputContainer";

type Props = {
  filteredData: any;
  isLoading: boolean;
  onIntegrationChange: (platform: Platforms) => void;
  FooderData?: any;
};

const GraphPlt = ({
  isLoading,
  filteredData,
  onIntegrationChange,
  FooderData,
}: Props) => {
  const [categories, setCategories] = useState<string[]>([]);
  const [data, setData] = useState<number[]>([]);
  const [selectedTitle, setSelectedTitle] = useState<string>(
    Platforms.WooCommerce
  );
  const { userData } = useContext(AppContext);

  const handleData = async () => {
    if (!filteredData) return;
    filteredData.sort((a: any, b: any) => {
      const meses = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];
      return meses.indexOf(a.mes) - meses.indexOf(b.mes);
    });

    const months = [];
    const count = [];

    for (const obj of filteredData) {
      months.push(obj.mes);
      count.push(obj.count);
    }
    setCategories(months);
    setData(count);
  };

  const handleIntegrationChange = (event: any) => {
    const selectedPlatform = event as Platforms;
    onIntegrationChange(selectedPlatform);
    setSelectedTitle(selectedPlatform);
  };

  useEffect(() => {
    handleData();
  }, [filteredData]); //eslint-disable-line

  const options = {
    chart: {
      id: `integrations-bar`,
    },
    xaxis: {
      categories,
    },
  };

  const series = [
    {
      name: "series",
      data,
    },
  ];

  return (
    <>
      {(userData?.roles[0] === "ADMIN" || userData?.roles[0] === "DEV") && (
        <CardContainer
          cardStyle={{ marginTop: "20px" }}
          title={`Integraciones por mes (${selectedTitle})`}
          responsiveText={true}
        >
          {isLoading ? (
            <LoadingAnimation animationType="small" />
          ) : (
            <>
              {filteredData && (
                <InputContainer
                  optionsList={Object.values(Platforms)}
                  placeholder="Plataformas"
                  type="select"
                  valueContainerName="value name"
                  onChange={(e) => handleIntegrationChange(e)}
                  style={{ width: "50%" }}
                />
              )}
              <Chart
                options={options}
                series={series}
                type="bar"
                width="100%"
              />
            </>
          )}
          {FooderData != null && (
            <Row gutter={[8, 8]} justify="center" align="middle">
              <Col span={4} offset={2}>
                <b>Totales</b>
              </Col>
              <Col span={4} offset={2}>
                <b>En espera</b>
              </Col>

              <Col span={4} offset={2}>
                <b>Agendado</b>
              </Col>

              <Col span={4} offset={2}>
                <b>Solicitada</b>
              </Col>

              <Col span={4} offset={2}>
                <p>{FooderData?.total[0]?.totalPickups}</p>
              </Col>

              <Col span={4} offset={2}>
                <p>{FooderData?.pickups[0]?.count}</p>
              </Col>

              <Col span={4} offset={2}>
                <p>{FooderData?.pickups[1]?.count}</p>
              </Col>

              <Col span={4} offset={2}>
                <p>{FooderData?.pickups[2]?.count}</p>
              </Col>
            </Row>
          )}
        </CardContainer>
      )}
    </>
  );
};

export default GraphPlt;
