import { apiProps, apiResponse } from "../interfaces/app.interfaces"
import { UpdateAdminRoleI, UpdateUserPermissionsI } from "../interfaces/permissions.interfaces"

export const getAdminUsers = async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: 'GET',
      endpoint: 'admin/permissions/users'
    })
  }


  export const editUserPermissions = (Id: String, dto: UpdateUserPermissionsI) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: 'PATCH',
      endpoint: `admin/permissions/users/${Id}`,
      data: dto,
    })
  }

  export const editAdminRole = (Id: String, dto: UpdateAdminRoleI) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: 'PATCH',
      endpoint: `admin/permissions/admin_role/${Id}`,
      data: dto,
    })
  }

