import { Col, Row, Typography } from "antd"
import CardContainer from "../../../components/Containers/CardContainer"
import ViewItem from "./ViewItem"
import { FC } from "react";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import renderToolbar from "./renderToolbar";
import { Viewer, Worker } from '@react-pdf-viewer/core';

const styles = {
    title: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        marginBottom: '1rem'
    },
}
interface props {
    currentStore: any;
}

const ConfigData: FC<props> = ({ currentStore }) => {
    const pdfJsMain =
        "https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js";

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
    });

    return (
        <>
            <Row gutter={[20, 20]} style={{ width: '100%', margin: '0 1rem' }}>
                <Col span={24}>
                    <Typography style={styles.title}>
                        Configuraciónes generales
                    </Typography>
                </Col>
            </Row>
            <CardContainer cardStyle={{ margin: '0 1rem' }}>
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <ViewItem label='Label' text={`${currentStore?.label}`} />
                        <ViewItem
                            label='Mostrar productos'
                            tagList={currentStore?.show_products
                                ? [`Habilitado`]
                                : [`Deshabilitado`]}
                        />
                    </Col>
                </Row>
            </CardContainer>
            {
                currentStore?.pdf_manual && currentStore?.pdf_manual.length > 0 && (
                    <>
                        <Col span={24}>
                            <Typography style={styles.title}>
                                Manual de instalación
                            </Typography>
                        </Col>
                        <CardContainer direction="vertical" cardStyle={{ margin: '0 1rem' }}>
                            <Worker workerUrl={pdfJsMain}>
                                <div>
                                    {currentStore?.pdf_manual ? (
                                        <Viewer
                                            fileUrl={currentStore?.pdf_manual}
                                            plugins={[defaultLayoutPluginInstance]}
                                        />
                                    ) : (
                                        <p> PDF NOT FOUND</p>
                                    )}
                                </div>
                            </Worker>
                        </CardContainer>
                    </>
                )
            }

        </>
    )
}

export default ConfigData