import moment from 'moment'
import { AdminDataPicker } from '../Common/admin/AdminDataPicker'

import { CalendarOutlined, DollarOutlined } from '@ant-design/icons'
import { Button, Col, Row, Select } from 'antd'
import { Dispatch, FC, SetStateAction } from 'react'
import { ArrowLeftOutlined } from '@material-ui/icons'

export const FilterList: FC<Props> = ({
  setByPrice,
  setIsVisible,
  setRangeDate,
  setFilterSelected,
  resetAllFilters,
  filterSelected,
  skipPriceFilter,
}) => {
  const list: Filters = {
    ByDate: {
      name: 'Rango de Fechas',
      component: (
        <AdminDataPicker
          setValue={(e: any) => {
            setRangeDate([
              moment(e[0]._d).format('YYYY-MM-DD'),
              moment(e[1]._d).format('YYYY-MM-DD'),
            ])
            setIsVisible(false)
          }}
        />
      ),
      icon: <CalendarOutlined />,
    },
    ByPrice: {
      name: 'Orden de Precios',
      component: (
        <Select
          onChange={(e: ByPriceTypes) => {
            setByPrice(e)
            setIsVisible(false)
          }}
          placeholder='Selecciona una opción'
          options={[
            {
              label: 'Ordenar por precios más altos',
              value: 'highest',
            },
            {
              label: 'Ordernar por precios más bajos',
              value: 'lowest',
            },
          ]}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      ),
      icon: <DollarOutlined />,
    },
  }

  return (
    <>
      {filterSelected ? (
        <Row
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <Col span={2}>
            <Button key={'GoBack'} icon={<ArrowLeftOutlined />} onClick={resetAllFilters} />
          </Col>

          <Col span={22}>{list[filterSelected].component}</Col>
        </Row>
      ) : (
        <Row
          style={{
            width: '100%',
            height: '100%',
          }}
          gutter={[10, 10]}
        >
          {Object.entries(list).map((filterEntry) => {
            const [key, value] = filterEntry

            if (key === 'ByPrice' && skipPriceFilter) return null

            return (
              <Col span={12}>
                <Button
                  icon={value.icon}
                  block
                  // @ts-ignore
                  onClick={() => setFilterSelected(key)}
                >
                  {value.name}
                </Button>
              </Col>
            )
          })}
        </Row>
      )}
    </>
  )
}

type Props = {
  setRangeDate: Dispatch<SetStateAction<[string, string] | null>>
  setIsVisible: (param: boolean) => void
  setByPrice: (param: ByPriceTypes) => void
  setFilterSelected: Dispatch<SetStateAction<FilterTypes | null>>
  resetAllFilters: () => void
  filterSelected: FilterTypes
  skipPriceFilter?: boolean
}

export type ByPriceTypes = 'highest' | 'lowest'

export type FilterTypes = 'ByDate' | 'ByPrice'

interface FilterProps {
  name: string
  component: React.ReactElement | null
  icon: React.ReactElement
}

export type Filters = {
  [key in FilterTypes]: FilterProps
}
