import CarrierCardSkeleton from "./CarrierCardSkeleton"

const CarrierSkeletonList = () => {
    return (
        <>
            <CarrierCardSkeleton />
            <CarrierCardSkeleton />
            <CarrierCardSkeleton />
            <CarrierCardSkeleton />
            <CarrierCardSkeleton />
            <CarrierCardSkeleton />
            <CarrierCardSkeleton />
            <CarrierCardSkeleton />
        </>
    )
}

export default CarrierSkeletonList