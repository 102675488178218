import { FC } from "react";
import { DatePicker } from "antd";

export const AdminDataPicker: FC<Props> = ({ setValue }: any) => {
  const { RangePicker } = DatePicker;
  const dateFormat = "DD/MM/YYYY";

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <RangePicker
        placeholder={["Fecha inicial", "Fecha final"]}
        style={{ width: "100%" }}
        onChange={(val) => setValue(val)}
        format={dateFormat}
      />
    </div>
  );
};

type Props = {
  setValue: React.Dispatch<React.SetStateAction<Date>>;
};
