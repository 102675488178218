import { FC } from "react";
import Moneyback from "../../../src/assets/images/Movements/back_status_ico.png";
import Shipment from "../../../src/assets/images/Movements/send_status_ico.png";
import Pkg from "../../../src/assets/images/Movements/box_status_ico.png";
import Money from "../../../src/assets/images/Movements/recar_status_ico.png";
import { StatusReport } from "../../interfaces/reports.interfaces";

interface Movements {
  status: StatusReport;
}

export const ReportStatus: FC<Movements> = ({ status }) => {
  const statusbadged = (type: string) => {
    switch (type) {
      case "Abierto":
        return (
          <div style={{ backgroundColor: "#5FCE8C", ...chipStyle }}>
            <img style={chipStyle.imgStye} src={Money} alt="money" />
            {type}
          </div>
        );
      case "Pendiente":
        return (
          <div style={{ backgroundColor: "#FF413F", ...chipStyle }}>
            <img style={chipStyle.imgStye} src={Pkg} alt="Pkg" />
            {type}
          </div>
        );
      case "Resuelto":
        return (
          <div style={{ backgroundColor: "#54C0F9", ...chipStyle }}>
            <img style={chipStyle.imgStye} src={Shipment} alt="Shipment" />
            {type}
          </div>
        );
      case "Cerrado":
        return (
          <div style={{ backgroundColor: "#F07C54", ...chipStyle }}>
            <img style={chipStyle.imgStye} src={Moneyback} alt="Moneyback" />
            {type}
          </div>
        );
      default:
        return <div></div>;
    }
  };

  return <>{statusbadged(status)}</>;
};

const chipStyle = {
  display: "flex",
  fontWeight: "500",
  width: "120px",
  height: "25px",
  borderRadius: "30px",
  padding: "3%",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  imgStye: { width: "14px", marginRight: "8px" },
};
