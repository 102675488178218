import { t } from 'i18next'
import { Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"
import { FulfillProfile, FulfillProfilesColumnsType } from '../../../../interfaces/fulfillment.interface'

export const fulfillProfilesColumn = (data: FulfillProfile[]) => {
    return [
        {
            title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('FulfillmentProfiles.Table.Column.Created')}</Tooltip>,
            dataIndex: ['fulfill','createdAt'],
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: FulfillProfilesColumnsType, b: FulfillProfilesColumnsType) => new Date(a.fulfill.createdAt).getTime() - new Date(b.fulfill.createdAt).getTime(),
            render: (createdAt: Date) => new Date(createdAt).toLocaleString()
        },
        {
            title: t('FulfillmentProfiles.Table.Column.Name'),
            dataIndex: 'name',
            align: 'center',
        },
        {
            title: t('FulfillmentProfiles.Table.Column.Email'),
            dataIndex: 'email',
            align: 'center',
        },
        {
            title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('FulfillmentProfiles.Table.Column.LastActivity')}</Tooltip>,
            dataIndex: ['fulfill','updatedAt'],
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: FulfillProfilesColumnsType, b: FulfillProfilesColumnsType) => new Date(a.fulfill.updatedAt).getTime() - new Date(b.fulfill.updatedAt).getTime(),
            render: (updatedAt: Date) => new Date(updatedAt).toLocaleString()
        }
      ] as ColumnsType<FulfillProfilesColumnsType> 
}