import React, { createContext, useEffect, useState } from "react";
import { useGet } from "../hooks/useGet";
import { Price, ServicesInterface } from "../interfaces/services.interface";
import { getServicesByParcel } from "../services/services_s";
import { ParcelLocalData } from "../types";

type PriceWithIndex = Price & { index: number; user?: string };
type PriceFormStrategies =
  | "Precios"
  | "Precios Especiales"
  | "Agregar Precio Especial";

interface ContextData {
  parcelSelected: ParcelLocalData | undefined;
  setParcelSelected: React.Dispatch<
    React.SetStateAction<ParcelLocalData | undefined>
  >;
  selectedServiceId: ServicesInterface | undefined;
  setSelectedServiceId: React.Dispatch<
    React.SetStateAction<ServicesInterface | undefined>
  >;
  isParcelDataLoading: boolean;
  fetchedServices: ServicesInterface[] | null;
  selectedPrice: PriceWithIndex | undefined;
  setSelectedPrice: React.Dispatch<
    React.SetStateAction<PriceWithIndex | undefined>
  >;
  isPricesModalOpen: boolean;
  setIsPricesModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isUsersModalOpen: boolean;
  setIsUsersModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  strategy: PriceFormStrategies;
  setStrategy: React.Dispatch<React.SetStateAction<PriceFormStrategies>>;
  refetch: () => void;
}

export const PricesContext = createContext<ContextData>({
  parcelSelected: undefined,
  setParcelSelected: () => {},
  selectedServiceId: undefined,
  isParcelDataLoading: false,
  setSelectedServiceId: () => {},
  fetchedServices: null,
  selectedPrice: undefined,
  setSelectedPrice: () => {},
  isPricesModalOpen: false,
  setIsPricesModalOpen: () => {},
  isUsersModalOpen: false,
  setIsUsersModalOpen: () => {},
  strategy: "Precios",
  setStrategy: () => {},
  refetch: () => {},
});

export const PricesContextProvider: React.FC = (props) => {
  const [parcelSelected, setParcelSelected] = useState<
    ParcelLocalData | undefined
  >(undefined);

  const [selectedPrice, setSelectedPrice] = useState<
    PriceWithIndex | undefined
  >(undefined);

  const [selectedServiceId, setSelectedServiceId] = useState<
    ServicesInterface | undefined
  >(undefined);

  const [isUsersModalOpen, setIsUsersModalOpen] = useState<boolean>(false);

  const [isPricesModalOpen, setIsPricesModalOpen] = useState<boolean>(false);

  const [strategy, setStrategy] = useState<PriceFormStrategies>("Precios");

  const {
    data: fetchedServices,
    refetch,
    isLoading: isParcelDataLoading,
  } = useGet<ServicesInterface[]>(getServicesByParcel(parcelSelected?.key));

  useEffect(() => {
    setSelectedServiceId(undefined);
    setSelectedPrice(undefined);
    setStrategy("Precios");
    refetch();
  }, [parcelSelected]); //eslint-disable-line

  return (
    <PricesContext.Provider
      value={{
        parcelSelected,
        setParcelSelected,
        fetchedServices,
        isParcelDataLoading,
        selectedServiceId,
        setSelectedServiceId,
        selectedPrice,
        setSelectedPrice,
        isPricesModalOpen,
        setIsPricesModalOpen,
        isUsersModalOpen,
        setIsUsersModalOpen,
        strategy,
        setStrategy,
        refetch,
      }}
    >
      {props.children}
    </PricesContext.Provider>
  );
};
