import React, { useState, useContext, useMemo, useEffect } from "react";
import { Table } from "../../../../components/Common/Table";
import { myRecollectionsColumn } from "../../../../services/records";
import { getParcelImage } from "../../../../services/records";
import { Pickup } from "../../../../interfaces/pickups.interface";
import { Modal, Select } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { ApiContext } from "../../../../context/apiContext";
import { AdminDataContext } from "../../../../context/adminContext";
import { AppContext } from "../../../../context/appContext";
import { ActionsRegisters } from "../../../../interfaces/permissions.interfaces";

const Recollections: React.FC = () => {
  const { Option } = Select;
  const perBatch = 500;
  const { backendApiCall } = useContext(ApiContext);
  const { userData } = useContext(AppContext);
  const [batchesFetched, setBatchesFetched] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { country } = useContext(AdminDataContext)
  const [recollectionsFetched, setRecollectionsFetched] = useState<Pickup[]>(
    []
  );

  const [modalVisible, setModalVisible] = useState(false);
  const [handleClick, setHandleClick] = useState(false);
  const recollectionsData = (item: any, userData: any) => {
    const parceText = item?.service_id.split("_mx_" || "_co_")
    return {
      guide: item.tracking ? item.tracking : "No hay guía registrada",
      user: item.email ? item.email : "Usuario no registrado",
      carrier: getParcelImage(item.carrier),
      service_id: userData.metadata?.permissions?.registers?.actions?.includes(ActionsRegisters.provider_info)? parceText[1]  : item.carrier,
      created_at: new Date(item.date_from).toLocaleDateString(),
      pickup_date: new Date(item.date_to).toLocaleDateString(),
      folio: item.folio || "Folio No Disponible",
      pickups_status: item.pickups_status,
      address: `${item.address[0] ? item.address[0].origin.street : ""} #${
        item.address[0] ? item.address[0].origin.number : ""
      } Col. ${item.address[0] ? item.address[0].origin.district : ""}`,
      data: item,
    };
  };

  const fetchRecollections = async ( ) => {
    setIsLoading(true);
    const response = await backendApiCall({
      method: "GET",
      endpoint: `users/pickups/getAllRecollections/${
        batchesFetched * perBatch
      }/${(batchesFetched + 1) * perBatch}/${country}`,
    });
    
    // @ts-ignore
    const optimizedData = response.data.data.map((cancellation: object) => {
      return recollectionsData(cancellation, userData);
    });
    setRecollectionsFetched(recollectionsFetched.concat(optimizedData));
    setBatchesFetched((prevBatch) => prevBatch + 1);
    setIsLoading(false);
  };


  const handlePageChange = (page: any) => {
    if ((page + 1) * rowsPerPage === perBatch * batchesFetched) {
      fetchRecollections();
    }
  };

  const OptDataScheduled = useMemo(() => {
    return recollectionsFetched
      ?.map((data) => {
        if (data.pickups_status === "Agendado") {
          return data;
        } else {
          return {};
        }
      })
      .filter((data) => Object.keys(data).length !== 0);
  }, [recollectionsFetched]);

  const OptDataRequested = useMemo(() => {
    return recollectionsFetched
      ?.map((data) => {
        if (data.pickups_status === "Solicitada" || data.pickups_status === "En espera") {
          return data;
        } else {
          return {};
        }
      })
      .filter((data) => Object.keys(data).length !== 0);
  }, [recollectionsFetched]);

  const handleModalVisible = (visible: boolean) => {
    setModalVisible(visible);
  };
  const handleSelectChange = (value: string) => {
    setHandleClick(value === "Solicitado"); // Si el valor seleccionado es "Home", handleClick es true, de lo contrario, es false
  };

  useEffect(() => {
    fetchRecollections();
  }, [country]); //eslint-disable-line
  return (
    <div>
      <Modal
        visible={modalVisible}
        onCancel={() => handleModalVisible(false)}
        footer={null}
      >
        <h1 style={{ color: "#2D1B6E", textAlign: "center" }}>Filtros</h1>
        <br />
        <h2 style={{ fontSize: "20px" }}>Filtrar por estado</h2>
        <span>
          Se filtrara el estado de recoleccion por agendado o solicitado{" "}
        </span>
        <Select
          style={{ width: "30%" }}
          defaultValue="Agendado"
          onChange={handleSelectChange}
        >
          <Option value="Agendado">Agendado</Option>
          <Option value="Solicitado">Solicitado</Option>
        </Select>
        <hr />
      </Modal>
      <Table
        isButton={{
          icon: <FilterOutlined />,
          title: "Filtrar",
          onClick: () => handleModalVisible(true),
        }}
        data={handleClick ? OptDataRequested : OptDataScheduled}
        columns={myRecollectionsColumn(fetchRecollections)}
        title="Recolecciones más recientes"
        isLoading={isLoading}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={(rows) => setRowsPerPage(rows)}
      />
    </div>
  );
};

export default Recollections;
