import { Tag, Tooltip, Typography } from "antd";
import { apiProps } from "../interfaces/app.interfaces";
import { apiResponse } from "../interfaces/app.interfaces";
import { allParcelsObject } from "../constants/parcels";
import { CancelStatus } from "../components/Common/CancelStatus";
import { ShipmentsActions } from "../components/Common/ShipmentsActions";
import { MovementStatus } from "../components/Common/MovementStatus";
import { Actions as CancelationsActions } from "../pages/DashboardSections/Records/Cancellations/Actions";
import { Actions as RecollectionsActions } from "../pages/DashboardSections/Records/Recollections/Actions";
import { ShipmentStatus } from "../utils/GlobalServices";
import { PickupStatus } from "../interfaces/pickups.interface";
import { UpdateStatusForm } from "../pages/DashboardSections/Records/Cancellations/UpdateStatusForm";
import { FormatedShipmentForTables } from "../interfaces/shipments.interface";
import { DetailsActions } from "../pages/DashboardSections/Records/Recollections/DetailsAction";
import { PersonalAccountStatus } from "../components/Common/PersonalAccountStatus";
import { format } from 'date-fns';
import { t } from "i18next";
// Get All Records Data

export function linkRastreo(carrier: any, tracking: any) {
	switch (carrier) {
		case 'ampm':
			return 'http://www.grupoampm.com/rastreo/';
		case 'carssa':
			return 'https://www.grupocarssa.com/new/';
		case 'dostavista':
			return 'https://dostavista.mx/track';
		case 'estafeta':
			return 'https://www.estafeta.com/Herramientas/Rastreo';
		case 'noventa9Minutos':
			return 'https://www.99minutos.com/search';
		case 'paquetexpress':
			return 'https://www.paquetexpress.com.mx/';
		case 'scm':
			return 'https://www.scmpaqueteria.mx/guias.php';
		case 'sendex':
			return 'http://www.sendex.mx/Rastreo/Rastreo/';
		case 'ups':
			return 'https://www.ups.com/track?loc=en_US&requester=ST/';
		case 'fedex':
			return `https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=${tracking}`;
		case 'dhl':
			return `https://www.dhl.com/en/express/tracking.html?AWB=${tracking}`;
		case 'quiken':
			return `http://quiken.mx/rastreo?num=${tracking}`;
		case 'uber':
			return '';
		default:
			return 'https://vip.drenvio.com/';
	}
}

export const getAllShipments =
  (user?: string, country?: string) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "GET",
      endpoint: `shipments/getAllShipments${country && "/" + country}/${user}`,
    });
  };

export const getAllShipmentsFinder =
  (user?: string, country?: string) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "GET",
      endpoint: `shipments/getAllShipmentsByUser/${user}`,
    });
  };

export const getAllShipmentsByUser =
  (user: string) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "GET",
      endpoint: `shipments/getAllShipmentsByUser/${user}`,
    });
  };

export const getAllCancellations =
  (country?: string) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "GET",
      endpoint: `shipments/getAllCancellations/${country}`,
    });
  };

export const getAllRecollections = async (
  backendApiCall: (data: apiProps) => Promise<apiResponse>
) => {
  return await backendApiCall({
    method: "GET",
    endpoint: "users/pickups/getAllRecollections",
  });
};

export const getAllMovements = async (
  backendApiCall: (data: apiProps) => Promise<apiResponse>
) => {
  return await backendApiCall({
    method: "GET",
    endpoint: "movements/getAllMovements",
  });
};

export const getAllUsers =
  (country?: CountryAdmin) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "GET",
      endpoint: `users/getAllUsers/${country}`,
    });
  };

export const getAllMovementsByUser =
  (user: string) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "GET",
      endpoint: "movements/getMovementsByUser/" + user,
    });
  };

export const getAllPersonalAccounts = async (
  backendApiCall: (data: apiProps) => Promise<apiResponse>
) => {
  return await backendApiCall({
    method: "GET",
    endpoint: "users/preferences/personalAccounts",
  });
};

export const updateShipmentStatus =
  (dto: { status: ShipmentStatus; tracking: string }) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `shipments/status/${dto.tracking}`,
      data: dto,
    });
  };

export const updateShipmentStatusV1 =
  (dto: { status: ShipmentStatus; tracking: string }) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `shipments/status/v1/${dto.tracking}`,
      data: dto,
    });
  };

export const updatePickupStatus =
  (dto: { status: PickupStatus; folio: string; shipmentId: string }) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `users/pickups/updateStatus/${dto.shipmentId}`,
      data: { pickups_status: dto.status, folio: dto.folio },
    });
  };

export const getAllReports = async (
  backendApiCall: (data: apiProps) => Promise<apiResponse>
) => {
  return await backendApiCall({
    method: "GET",
    endpoint: "users/reports/admin",
  });
};

// All Records Colum Formats

export const myShipmentsColumn = (isById: Boolean) => [
  {
    title: "Fecha de solicitud",
    field: "applicationDate",
    render: (rowData: any) => {
      const fecha = new Date(rowData.shipmentData.created_at); 
      const fechaFormateada = format(fecha, ' h:mm a'); 
      return (
        <div>
          <p>
            {rowData.applicationDate}
          </p>
          <p style={{ fontSize: '12px' }}> 
            {fechaFormateada}
          </p>
        </div>
      );
    }
  },
  isById
    ? {
        title: "Usuario",
        field: "mail",
        render: (rowData: any) => {
          return (
            <div style={{ display: "flex" }}>
              <Tooltip title={rowData.user}>
                <p style={{ cursor: "pointer" }}>{rowData.mail}</p>
              </Tooltip>
            </div>
          );
        },
      }
    : {},
    {
      title: "Estatus",
      field: "shipment_status",
      render: (rowData: any) => {
        return <CancelStatus status={rowData.shipment_status} />;
      },
    },
    
  {
    title: "Rastreo",
    field: "tracking",
    render: (rowData: any) => (
      <a href={linkRastreo(rowData.shipmentData.carrier, rowData.tracking)} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
        {rowData.tracking}
        {console.log("rowData: ", rowData)}
      </a>
    )
  },
  {
    title: "Remitente",
    field: "addressee",
  },
  {
    title: "Destinatario",
    field: "destination",
  },
  {
    title: "Paqueteria",
    field: "Parcel",
  },
  {
    title: "Total",
    field: "Total",
    render: (rowData: any) =>
      rowData?.metadata?.extended_area ? (
        <b>{rowData.Total} ZE</b>
      ) : (
        rowData.Total
      ),
  },
  {
    title: "Proveedor",
    field: "provider",
  },
  {
    title: "PDF",
    field: "actions",
    render: (rowData: any) => (
      <ShipmentsActions data={rowData as unknown as any} disabledMenu={false} />
    ),
  },
];

export const myCancellationsColumn = (
  refetch: () => void,
  setData: React.Dispatch<
    React.SetStateAction<FormatedShipmentForTables[] | null>
  >
) => [
  {
    title: "Fecha de cancelación",
    field: "cancel_at",
    render: (rowData: any) => {
      const dateToShow = rowData.cancel_at ? rowData.cancel_at : rowData?.created_at;
      return (
        <div>
          {dateToShow}
        </div>
      )
    }
  },
  {
    title: "Usuario",
    field: "mail",
    render: (rowData: any) => {
      return (
        <div style={{ display: "flex" }}>
          <Tooltip title={rowData.user}>
            <p>{rowData.mail}</p>
          </Tooltip>
        </div>
      );
    },
  },
  {
    title: "Estatus",
    field: "shipment_status",
    render: (rowData: any) => <CancelStatus status={rowData.shipment_status} />,
  },
  {
    title: "Número de guía",
    field: "tracking",
  },
  {
    title: "Monto",
    field: "price",
  },
  {
    title: "Paquetería",
    field: "carrier",
  },
  {
    title: "Status",
    field: "shipment_status",
    render: (rowData: any) => (
      <UpdateStatusForm refetch={refetch} data={rowData} setData={setData} />
    ),
  },
  {
    title: "Acciones",
    field: "actions",
    render: (rowData: any) => (
      <CancelationsActions refetch={refetch} data={rowData} />
    ),
  },
];

export const myMovementsColumn = [
  {
    title: "Fecha",
    field: "date",
  },
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Tipo",
    field: "type",
    render: (rowData: any) => (
      <MovementStatus status={rowData.type}></MovementStatus>
    ),
  },
  {
    title: "Cantidad",
    field: "quantity",
  },
  {
    title: "Saldo Anterior",
    field: "prevBalance",
  },
  {
    title: "Saldo Posterior",
    field: "postBalance",
  },
  {
    title: "Realizado por",
    field: "made_by",
  },
];

export const UserMovementsColumn = [
  {
    title: "Fecha",
    field: "date",
    render: (rowData: any) => (
      <>
        <Typography>{rowData.date}</Typography>
        <Typography>{rowData.minutes}</Typography>
      </>
    ),
  },
  {
    title: "Tipo",
    field: "type",
    render: (rowData: any) => (
      <MovementStatus status={rowData.type}></MovementStatus>
    ),
  },
  {
    title: "Descripción",
    field: "description",
    render: (rowData: any) => {
      return rowData.type === "ENVIO" ||
        (rowData.type === "REEMBOLSO" &&
          rowData.description !== "Nuevo Movimiento") ? (
        <>
          <Tag
            color={rowData.type === "ENVIO" ? "blue" : "orange"}
            style={{ marginTop: "5px" }}
          >
            Paquetería: {rowData.description.toUpperCase()}
            <br />
            {`N° de guia: ${rowData.payment_id}`}
          </Tag>
        </>
      ) : (
        <>{rowData.description}</>
      );
    },
  },
  {
    title: "Cantidad",
    field: "quantity",
  },
  {
    title: "Saldo Anterior",
    field: "prevBalance",
  },
  {
    title: "Saldo Posterior",
    field: "postBalance",
  },
  {
    title: "Realizado por",
    field: "made_by",
  },
];

export const myUsersColumn = [
  {
    title: "Fecha de Creación",
    field: "created_at",
  },
  {
    title: "Correo",
    field: "email",
  },
  {
    title: "Teléfono",
    field: "phone",
  },
  {
    title: "País",
    field: "country",
  },
  {
    title: "Balance",
    field: "balance",
  },
  {
    title: "Verificado",
    field: "verified",
  },
];

export const myRecollectionsColumn = (refetch: () => void | any) => [
  {
    title: "Fecha de Solicitud",
    field: "created_at",
  },
  {
    title: "Usuario",
    field: "user",
  },
  {
    title: "Fecha de Recolección",
    field: "pickup_date",
  },
  {
    title: "Id de Prooveedor",
    field: "service_id",
  },
  {
    title: "Guía",
    field: "guide",
    render: (rowData: any) => (
      <a href={linkRastreo(rowData.data.carrier, rowData.guide)} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
        {rowData.guide}
      </a>
    )
  },
  {
    title: "Paquetería",
    field: "carrier",
  },
  {
    title: "Folio",
    field: "folio",
  },
  {
    title: "Dirección de Recolección",
    field: "address",
  },
  {
    title: "Status",
    field: "pickups_status",
    render: (rowData: any) => (
      <CancelStatus
        status={transformedPickupStauts[rowData.pickups_status as PickupStatus]}
        customText={rowData.pickups_status}
        withIcon={false}
      />
    ),
  },
  {
    title: "Acciones",
    field: "actions",
    render: (rowData: any) => (
      <RecollectionsActions refetch={refetch} data={rowData.data} />
    ),
  },
  {
    title: "Detalles",
    field: "details",
    render: (rowData: any) => (
      <DetailsActions refetch={refetch} data={rowData.data} />
    ),
  },
];

export const personalAccountsColumn = () => [
  {
    title: "Fecha de creación",
    field: "created_at",
  },
  {
    title: "Paquetería",
    field: "type",
  },

  {
    title: "Titular",
    field: "name",
  },
  {
    title: "Descripción",
    field: "title",
  },
  {
    title: "Estatus",
    field: "active",
    render: (rowData:any) => {
      return <PersonalAccountStatus active={rowData.active} />;
    },
  },
];

export const quotesColumn = () => {
  const baseColumns = [
    {
      title: 'Peso',
      field: 'weight'
    },
    {
      title: 'Costo',
      field: 'cost'
    },
  ];

  return baseColumns;
};


const transformedPickupStauts: Record<PickupStatus, string> = {
  Agendado: "Activa",
  "Cancelada de prov": "Cancelada de prov",
  Solicitada: "Solicitada",
  "En espera": "En espera",
  "Sin disponibilidad": "Sin disponibilidad",
};

// Miscelaneous Functions

export const getParcelImage = (parcel: AllCarriers, Width?: string) => {
  return allParcelsObject[parcel] ? (
    <img
      src={allParcelsObject[parcel].imgSrc}
      max-width="100%"
      width={Width || "90px"}
      alt={parcel}
    />
  ) : (
    parcel
  );
};

export const getParcelImageForDashboard = (
  parcel: AllCarriers,
  width?: string
) => {
  return allParcelsObject[parcel] ? (
    <img
      src={allParcelsObject[parcel].dashboardImageSrc}
      max-width="100%"
      width={width || "90px"}
      alt={parcel}
    />
  ) : (
    parcel
  );
};

export const getControllerImageForLogErrors = (
  parcel: AllCarriers,
  width?: string
) => {
  return allParcelsObject[parcel] ? (
    <img
      src={allParcelsObject[parcel].dashboardImageSrc}
      max-width="100%"
      width={width || "90px"}
      alt={parcel}
    />
  ) : (
    parcel
  );
};

export const carrierNames = (carrier: string) => {
  const carrierName = t(`Quote.Carriers.Raw.${carrier}`);
  if (carrierName.includes("Quote.Carriers.Raw")) return carrier;

  return carrierName;
};
