import { BlockCp, UnBlockCp } from '../../components/Common/actions/Cp'
import { TColumnTable } from '../../components/Common/Table'

export const getBlackListColumns = (refetchFn: () => void): TColumnTable => {
  return [
    { title: 'Codigo Postal', field: 'cp' },
    { title: 'Usuario', field: 'mail' },
    { title: 'Auth0', field: 'user' },
    { title: 'Fecha', field: 'banDate' },
    { title: 'Razon', field: 'blockReason' },
    {
      title: 'Bloqueo',
      field: 'banned',
      render: (rowData: any) => (
        <div
          style={{
            padding: '5px',
            width: '120px',
            borderRadius: '14px',
            textAlign: 'center',
            color: rowData.banned === true || rowData.banned === false ? 'white' : '',
            backgroundColor:
              rowData.banned === true ? 'red' : rowData.banned === false ? 'green' : '',
          }}
        >
          {rowData.banned ? 'Bloqueado' : 'Desbloqueado'}
        </div>
      ),
    },
    {
      title: 'Actions',
      render(data, type) {
        return (
          <div>
            <UnBlockCp cp={(data as any).cp} refetchFn={refetchFn} />
            <BlockCp cp={(data as any).cp} refetchFn={refetchFn} />
          </div>
        )
      },
    },
  ]
}
