import { UseGet, apiProps, apiResponse } from "../interfaces/app.interfaces";

export const getConfigIntegrations: UseGet = async (backendApiCall) => {
    return await backendApiCall({
        method: 'GET',
        endpoint: `integrations/config-stores`,
    });
};

export const addConfigIntegrations =
  (dto: any) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "POST",
      endpoint: `integrations/config-stores`,
      data: dto,
    });
  };


export const updateConfigIntegrations =
  (type: String, dto: any) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `integrations/config-stores`,
      data: {
        ...dto,
        type
      },
    });
  };


export const deleteConfigIntegrations =
  (Id: String) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: 'DELETE',
      endpoint: `integrations/config-stores`,
      data: {
        type: Id
      }
    })
  }

  export const uploadPDFUrl =
  (dto: any) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "POST",
      endpoint: `integrations/upload-instructions`,
      data: dto,
    });
  };



  export const blockUserService =
  (userId: String, dto: { 
    blocked: boolean;
    ban_reason?: string;
    type?: string; 
  }) =>
  async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
    return await backendApiCall({
      method: "PATCH",
      endpoint: `users/user/block/${userId}`,
      data: dto,
    });
  };

