import { Row, Col, Typography, Spin, message, Empty } from "antd"
import { FC, useContext, useEffect } from "react"
import CardContainer from "../../../components/Containers/CardContainer"
import { AdminDataContext } from "../../../context/adminContext"
import { usePatch } from "../../../hooks/usePatch"
import { AdminUserI } from "../../../interfaces/permissions.interfaces"
import { editAdminRole } from "../../../services/permission_s"
import UserItemList from "./UserItemList"
import { getAccountType } from "./utils"

interface props {
    users: AdminUserI[] | undefined;
    loading: boolean;
    changeUsers: (data: AdminUserI | unknown) => void;
    changeUserPermissions: (data: any) => void
}

const AdminUsersScreen: FC<props> = ({ users, loading, changeUserPermissions, changeUsers }) => {
    const { setPermissionStepTransition } = useContext(AdminDataContext)
    const { fetchDataPatch: fetchDataPatchRole } = usePatch(editAdminRole)

    const changeStep = (user: any = {}) => {
        changeUserPermissions(user)
        setPermissionStepTransition('2) Permissions')
    }

    const updateUserAdmin = async (state: boolean, id: string) => {
        try {
            await fetchDataPatchRole(
                id,
                {
                    admin: state
                })

            const updatedUsers = users?.map((el: AdminUserI) => {
                if (el._id === id) {
                    let newRoles;

                    if (state) {
                        el.roles.push('ADMIN')
                        newRoles = el.roles
                    } else {
                        newRoles = el.roles.filter(el => el !== 'ADMIN')
                    }

                    return {
                        ...el,
                        roles: newRoles
                    }
                }
                return el


            })

            changeUsers(updatedUsers)
            message.success('El estatus ha sido actualizado', 10)


        } catch (error) {
            console.log(error)
            message.error('El estatus No sido actualizado', 10)
        }
    }

    useEffect(() => {

    }, [users])

    return (
        <Row gutter={[50, 10]}>
            <Col span={12}>
                <CardContainer
                    cardStyle={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '30px'
                    }}>
                    <Typography
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontWeight: 'bold',
                            fontSize: '1.6rem'
                        }}>
                        Usuarios Administradores
                    </Typography>
                </CardContainer>

                <Col span={24} style={{ padding: 0 }}>
                    <Col style={{
                        minHeight: '40rem',
                        maxHeight: '40rem',
                        overflowY: users && users?.length > 5 ? 'scroll' : 'hidden',
                        padding: '0 10px'
                    }}>
                        {
                            users && users?.length > 0 ? users?.map((user: any) => (
                                <UserItemList
                                    key={user._id}
                                    user={user}
                                    accountType={getAccountType(user.user)}
                                    onClickUser={() => changeStep(user)}
                                    updateUserAdmin={updateUserAdmin}
                                />
                            )) : <Col style={{ width: '100%', height: '100%' }}>

                                {
                                    loading ? (

                                        <Spin style={{
                                            width: '100%',
                                            height: '15rem'
                                        }} spinning={loading} />
                                    ) : (
                                        <Empty
                                            imageStyle={{
                                                height: 85
                                            }}
                                            style={{
                                                width: '100%',
                                                display: 'grid',
                                                placeItems: 'center'
                                            }}
                                            description={<span>No hay usuarios Administradores</span>}
                                        />
                                    )
                                }
                            </Col>
                        }

                    </Col>

                </Col>
            </Col>

            <Col span={8}>
                <CardContainer
                    cardStyle={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '30px'
                    }}>
                    <Typography
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontWeight: 'bold',
                            fontSize: '1.4rem'
                        }}>
                        Instrucciones
                    </Typography>
                </CardContainer>
                <CardContainer
                    textList={[
                        'Activa o desactiva los permisos de administrador.',
                        'Los usuarios con permiso administativos podrán realizar login en la plataforma administrativa.',
                        'Los usuarios que no tengan permisos de administrador no tendrán accesos a la plataforma administrativa.',
                        'Al seleccionar un usuario se podrá activar y desactivar los permisos individuales para acceder a cada sección de esta plataforma.'
                    ]} />
            </Col>
        </Row>
    )
}

export default AdminUsersScreen