import { AxiosResponse } from 'axios';

import { UserPermissionsI } from './permissions.interfaces';

export enum UserRoles {
	common = 'COMMON',
	admin = 'ADMIN',
	dev = 'DEV',
}

export enum AppCountry {
	mx = 'MX',
	col = 'COL',
	undefined = 'UNDEFINED',
}
export interface UserData {
	_id?: string;
	user: string;
	balance: number;
	roles: [UserRoles];
	security: {
		senders: [string];
		senders_verified: boolean;
		identity_verified: boolean;
	};
	app_country: string;
	metadata: any;
	app_version: number;
	currency?: string;
}

export interface SubUsersInterface {
	type: number;
	user: string;
	verified: boolean;
}

export interface UserMetadataI {
	permissions?: UserPermissionsI;
	special_price?: string[];
	stp_account?: string;
	reseller?: {
		active: boolean;
		date: string;
		shipments: {
			carrier: string;
			tracking: string;
		}[];
	};
	activity: {
		completedRegister: any
		completedProfile: any
		OTP: any
		firstRechange: any
		lastRechange: any
	}
}


export interface UserInterface {
	_id?: string;
	user: string;
	balance: number;
	roles: [UserRoles];
	security: {
		senders: [string];
		senders_verified: boolean;
		identity_verified: boolean;
		admin_verified: boolean;
	};
	app_country: string;
	metadata: UserMetadataI;
	created_at?: string;
	app_version: number;
	parent: {
		user: string;
		verified: boolean;
	};
	currency?: any;
	sub_users: SubUsersInterface[];
}

export interface ProfileData {
	user?: string;
	email?: string;
	phone: string;
	company?: string;
	shipments_per_month?: string;
	off_platform?: boolean;
	material?: string;
	name?: string;
	another_provider?: string;
}

export enum RequestStatus {
	Success = 'successs',
	Fetching = 'fetching',
	Error = 'error',
}
export type apiResponse<T = any> =
	| {
			status: RequestStatus.Success | RequestStatus.Fetching;
			data: T;
	  }
	| {
			status: RequestStatus.Error;
			data: {
				msg: string;
			};
	  };

export type apiProps<T = any> = {
	method: 'POST' | 'PATCH' | 'PUT' | 'DELETE' | 'GET';
	endpoint: string;
	data?: T;
	params?: { [id: string]: string | number | Date };
};

export interface OTPVerificationResponse {
	success: Boolean;
	error?: any;
}

export interface TopBuyers {
	_id: string;
	shipments: number;
	spend: number;
	email: string;
	last_shipment: Date;
	created_at: Date;
}

export type BackendApiCall<T = any> = (
	data: apiProps
) => Promise<apiResponse<AxiosResponse<T>>>;

export type UseGet<T = any> = (
	backendApiCall: BackendApiCall<T>
) => Promise<apiResponse<AxiosResponse<T>>>;

export type UseGetParams<T = any, K = any> = (
	params?: K
) => (
	backendApiCall: BackendApiCall<T>
) => Promise<apiResponse<AxiosResponse<T>>>;
